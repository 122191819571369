/**
 * Course Content Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  courseName: "",

  isLoading: false,
  isLoadingSso: false,
};

function ExternalLearningCourseReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.EXTERNAL_LEARNING_COURSE_LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.EXTERNAL_LEARNING_COURSE_GET_SSO_REQUEST:
      return {
        ...state,
        isLoadingSso: true,
      };

    case ActionTypes.EXTERNAL_LEARNING_COURSE_LOAD_SUCCESS:
      return {
        ...state,
        courseName: action.payload.courseName,
        isLoading: false,
      };

    case ActionTypes.EXTERNAL_LEARNING_COURSE_GET_SSO_FAILURE:
      return {
        ...state,
        isLoadingSso: true,
      };

    default:
      return state;
  }
}

export default ExternalLearningCourseReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectExternalLearningCourse = (state) =>
  state.externalLearningCourse;
