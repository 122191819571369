import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import VoucherPurchases from "./voucher-purchases";
import * as dataTableActionCreators from "./redux/datatable/action-creators";
import * as dialogActionCreators from "./redux/dialog/action-creators";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = state => {
  return {
    dataTable: state.adminVouchers.DataTable,
    dialog: state.adminVouchers.Dialog
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: (payload) => dispatch(dataTableActionCreators.onLoad(payload)),
  reset: () => dispatch(dataTableActionCreators.reset()),
  toggleEditDialog: (payload) =>
    dispatch(dialogActionCreators.toggleEditDialog(payload)),
  onInputChange: ({ name, value, error }) => {
    dispatch(dataTableActionCreators.formInputChange(name, value, error));
  },
  onEdit: (payload) => dispatch(dialogActionCreators.updateVoucher(payload)),
  onDelete: (payload) => dispatch(dialogActionCreators.deleteVoucher(payload)),
  onMarkAsPaid: (payload) => dispatch(dialogActionCreators.markAsPaid(payload)),
  onDialogInputChange: ({ name, value, error }) => {
    dispatch(dialogActionCreators.formInputChange(name, value, error));
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  toggleDeleteDialog: (payload) =>
    dispatch(dialogActionCreators.toggleDeleteDialog(payload)),
  toggleMarkAsPaidDialog: (payload) =>
    dispatch(dialogActionCreators.toggleMarkAsPaidDialog(payload)),
  toggleVouchersDialog: (payload) =>
    dispatch(dialogActionCreators.toggleVouchersDialog(payload)),
  downloadTaxInvoice: (invoiceGuid) =>
    dispatch(dataTableActionCreators.downloadTaxInvoice(invoiceGuid)),
  onSettingsChanged: (settings) => {
    dispatch(dataTableActionCreators.keepDataTableSettings(settings));
  },
  clearDataTableSearchbox: () =>
    dispatch(dataTableActionCreators.clearDataTableSearchboxFilter()),
  dispatch,
  history: ownProps.history,
});

const VoucherPurchasesContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VoucherPurchases)
);

export default VoucherPurchasesContainer;
