import { connect } from "react-redux";
import UpdateProfile from "./Update-Profile";
import { selectUpdateProfile } from "./redux/reducer";
import { onSubmit, getUserInfo } from "./redux/action-creators";
import * as actionCreators from "./redux/action-creators";
import * as sysActionCreators from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const updateProfile = selectUpdateProfile(state);
  return {
    updateProfile: updateProfile,
    fields: updateProfile.fields,
    fieldErrors: updateProfile.fieldErrors,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch: dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    // Validation code here
    if (validate(stateProps)) return;
    dispatchProps.dispatch(onSubmit(stateProps.updateProfile.fields));
  },
  getUserInfo: () => {
    dispatchProps.dispatch(getUserInfo());
  },
  validate: () => {
    return validate(stateProps);
  },
});

const validate = (stateProps) => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

  if (!fields.firstName) return true;
  if (!fields.lastName) return true;
  if (!fields.email) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const UpdateProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(UpdateProfile);

export default UpdateProfileContainer;
