import React from "react";
import svg from "assets/svgs/green-tick.svg";
import "./green-tick-balls.scss";

const GreenTickBalls = ({ items, bigBalls = false }) => {
  return (
    <div className={bigBalls ? "green-tick-balls" : "small-green-tick-balls"}>
      {items.map((item, index) => (
        <div key={index} className="green-tick-balls-item">
          <div className="green-tick-balls-item-icon">
            <img src={svg} alt="" />
          </div>
          <div className="green-tick-balls-item-text">{item}</div>
        </div>
      ))}
    </div>
  );
};

export default GreenTickBalls;
