import React from "react";

export const courses = [
  {
    courseId: 6, // NB: this uses the same quiz questions as courseId: 4
    courseTitle: "NSW Food Safety Supervisor Certificate",
    courseCost: 179,
    courseContentPrefix: "FSSHR",
    certificateCodes: ["SITXFSA005", "SITXFSA006"],
    certificateCodesString: "SITXFSA005, SITXFSA006",
    unitOfCompetencies: [
      "Use Hygienic Practices for Food Safety",
      "Participate in Safe Food Handling Practices",
    ],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/nsw-food-safety-supervisor-certificate.webp",
    validStates: <></>,
    courseReadMorePage: "/courses/nsw-food-safety-supervisor-certificate/",
    courseGuideUrl: "/Print-Course-Content-Guide/6/",
    courseName: "NSW Food Safety Supervisor",
    courseTags: "Hospitality, Retail, Health & Community",
    courseInfo: {
      heading: <>NSW Food Safety Supervisor Certificate</>,
      pageTitle: "NSW Food Safety Supervisor Certificate",
      pageDescription: "",
      intro: (
        <>
          Gain the essential skills and certification you need to ensure Food
          Safety in your workplace with the NSW Food Safety Supervisor
          Certificate. Designed for Food Safety Supervisors, this course
          provides in-depth training on best practices in Food Safety and meet
          regulatory requirements in New South Wales.
        </>
      ),
      additionalContent: (
        <div className="mt-4">
          <p>
            <em>
              Train to Gain Pty Ltd: RTO # 22361 T/A Food Safety Education is
              Approved by the NSW Food Authority – RTO Approval Number: 25085,
              and is compliant with the new FSANZ Standard&nbsp;3.2.2A.
            </em>
          </p>
          <p>
            <a
              href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos"
              target="_blank"
              rel="noopener noreferrer"
            >
              See us listed as &lsquo;Food Safety Education&rsquo; on the
              Approved Training providers list on the NSW Food Authority
              website.
            </a>
          </p>
        </div>
      ),
      image:
        "https://solvable.azureedge.net/train-to-gain/images/sample-nsw-food-safety-supervisor-certificate-2024.webp",
      units: [
        "SITXFSA005 - Use Hygienic Practices for Food Safety",
        "SITXFSA006 - Participate in Safe Food Handling Practices",
      ],
    },
    currentThirdPartyFormVersion: 2,
    thirdPartyFormAdditionalContent: {
      unitsOfCompetencyLabel: <>Including the NSW 4&nbsp;Focus&nbsp;Area’s</>,
      unitsOfCompetencyAdditionalPoints: [
        "Allergen Management ",
        "Cleaning and Sanitising Practices",
        "Safe Egg Handling",
        "Food Act Offences",
      ],
    },
  },
  {
    courseId: 8, // NB: this uses the same quiz questions as courseId: 4
    courseTitle:
      "NSW Refresher Course - Includes your NSW Food Safety Supervisor Certificate",
    courseCost: 149,
    courseContentPrefix: "FSSHR",
    certificateCodes: ["SITXFSA005", "SITXFSA006"],
    certificateCodesString: "SITXFSA005, SITXFSA006",
    unitOfCompetencies: [
      "Use Hygienic Practices for Food Safety",
      "Participate in Safe Food Handling Practices",
    ],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/nsw-food-safety-supervisor-certificate-refresher.webp",
    validStates: <></>,
    courseReadMorePage:
      "/courses/nsw-food-safety-supervisor-certificate-refresher/",
    courseGuideUrl: "/Print-Course-Content-Guide/8/",
    courseName: "NSW Food Safety Refresher",
    courseTags: "NSW Food Safety Supervisor Certificate",
    courseInfo: {
      heading: <>NSW Food Safety Refresher Certificate</>,
      pageTitle: "NSW Food Safety Refresher Certificate",
      pageDescription: "",
      intro: (
        <>
          This NSW Refresher course ensures that you meet the legal obligations
          to operate food safely and helps you stay informed about new standards
          and practices to maintain high food safety levels.
          <br />
          <br />
          You are required by the NSW Food Authority to complete this refresher
          training in order to legally handle food for another 5 years in NSW.
          <br />
          <br />
          Students seeking to recertify must renew a NSW Food Safety Refresher
          course that is due to expire, or has expired, within the immediately
          preceding 90 days.
        </>
      ),
      additionalContent: (
        <div className="mt-4">
          <p>
            <em>
              Train to Gain Pty Ltd: RTO # 22361 T/A Food Safety Education is
              Approved by the NSW Food Authority – RTO Approval Number: 25085,
              and is compliant with the new FSANZ Standard&nbsp;3.2.2A.
            </em>
          </p>
          <p>
            <a
              href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos"
              target="_blank"
              rel="noopener noreferrer"
            >
              See us listed as &lsquo;Food Safety Education&rsquo; on the
              Approved Training providers list on the NSW Food Authority
              website.
            </a>
          </p>
        </div>
      ),
      image:
        "https://solvable.azureedge.net/train-to-gain/images/sample-nsw-food-safety-supervisor-certificate-2024.webp",
      units: [
        "SITXFSA005 - Use Hygienic Practices for Food Safety",
        "SITXFSA006 - Participate in Safe Food Handling Practices",
      ],
    },
    currentThirdPartyFormVersion: 2,
    thirdPartyFormAdditionalContent: {
      unitsOfCompetencyLabel: (
        <>Including the NSW 4&nbsp;Focus&nbsp;Area&rsquo;s</>
      ),
      unitsOfCompetencyAdditionalPoints: [
        "Allergen Management ",
        "Cleaning and Sanitising Practices",
        "Safe Egg Handling",
        "Food Act Offences",
      ],
    },
  },
  {
    courseId: 4,
    courseTitle: "Food Safety Supervisor Hospitality & Retail",
    courseCost: 139,
    courseContentPrefix: "FSSHR",
    certificateCodes: ["SITXFSA005", "SITXFSA006"],
    certificateCodesString: "SITXFSA005, SITXFSA006",
    unitOfCompetencies: [
      "Use Hygienic Practices for Food Safety",
      "Participate in Safe Food Handling Practices",
    ],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/food-safety-supervisor-certificate-hospitality-and-retail.webp",
    validStates: (
      <>
        Nationwide{" "}
        <strong>
          <u>Except&nbsp;NSW</u>
        </strong>
      </>
    ),
    courseReadMorePage:
      "/courses/food-safety-supervisor-certificate-hospitality-and-retail/",
    courseGuideUrl: "/Print-Course-Content-Guide/4/",
    courseName: "Food Safety Supervisor",
    courseTags: "Hospitality & Retail (Except NSW)",
    courseInfo: {
      heading: <>Food Safety Supervisor Hospitality &amp; Retail Certificate</>,
      pageTitle: "Food Safety Supervisor Hospitality & Retail Certificate",
      pageDescription: "",
      intro: (
        <>
          This Accredited Online Food Safety Supervisor course empowers
          businesses in the hospitality and retail industries to protect their
          customers, employees, and reputation, while complying with regulatory
          standards.
        </>
      ),
      units: [
        "SITXFSA005 - Use Hygienic Practices for Food Safety",
        "SITXFSA006 - Participate in Safe Food Handling Practices",
      ],
    },
    currentThirdPartyFormVersion: 1,
  },
  {
    courseId: 2,
    courseTitle: "Food Handling Level 1 - Hospitality & Retail",
    courseCost: 55,
    courseContentPrefix: "FH1",
    certificateCodes: ["SITXFSA005"],
    certificateCodesString: "SITXFSA005",
    unitOfCompetencies: ["Use Hygienic Practices for Food Safety"],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/food-handling-level-1-certificate-hospitality-and-retail.webp",
    courseReadMorePage:
      "/courses/food-handling-level-1-certificate-hospitality-and-retail/",
    courseGuideUrl: "/Print-Course-Content-Guide/2/",
    courseName: "Food Handling Level 1",
    courseTags: "Hospitality & Retail",
    courseInfo: {
      heading: <>Food Handling Level 1 Hospitality &amp; Retail Certificate</>,
      pageTitle: "Food Handling Level 1 Hospitality & Retail Certificate",
      pageDescription: "",
      intro: (
        <>
          This Accredited online Food Handling course, is what you need to start
          your Food Safety career in the hospitality and or retail sector and
          complies with national and local Food Safety regulations.
        </>
      ),
      units: ["SITXFSA005 - Use Hygienic Practices for Food Safety"],
    },
    currentThirdPartyFormVersion: 1,
  },
  {
    courseId: 3,
    courseTitle: "Food Handling Level 2 - Hospitality & Retail",
    courseCost: 89,
    courseContentPrefix: "FH2",
    certificateCodes: ["SITXFSA006"],
    certificateCodesString: "SITXFSA006",
    unitOfCompetencies: ["Participate in Safe Food Handling Practices"],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/food-handling-level-2-certificate-hospitality-and-retail.webp",
    courseReadMorePage:
      "/courses/food-handling-level-2-certificate-hospitality-and-retail/",
    courseGuideUrl: "/Print-Course-Content-Guide/3/",
    courseName: "Food Handling Level 2",
    courseTags: "Hospitality & Retail",
    courseInfo: {
      heading: <>Food Handling Level 2 Hospitality &amp; Retail Certificate</>,
      pageTitle: "Food Handling Level 2 Hospitality & Retail Certificate",
      pageDescription: "",
      intro: (
        <>
          Accredited Safe Food Handling training ensures that both individuals
          and businesses can provide food that is safe, healthy, and of high
          quality. This course helps Food Handlers comply with national and
          local Food Safety regulations.
        </>
      ),
      units: ["SITXFSA006 - Participate in Safe Food Handling Practices"],
    },
    currentThirdPartyFormVersion: 1,
  },
  {
    courseId: 1009,
    courseTitle: "Food Handling Level 1 - Health & Community",
    courseCost: 50,
    courseContentPrefix: "FSSACL1",
    certificateCodes: ["HLTFSE001"],
    certificateCodesString: "HLTFSE001",
    unitOfCompetencies: ["Follow basic food safety practices"],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/food-handling-level-1-certificate-health-and-community.webp",
    courseReadMorePage:
      "/courses/food-handling-level-1-certificate-health-and-community/",
    courseGuideUrl: "/Print-Course-Content-Guide/1009/",
    courseName: "Food Handling Level 1",
    courseTags: "Health & Community",
    courseInfo: {
      heading: <>Food Handling Level 1 Health &amp; Community Certificate</>,
      pageTitle: "Food Handling Level 1 Health & Community Certificate",
      pageDescription: "",
      intro: (
        <>
          Becoming an Accredited Food Handler is crucial for public health,
          legal compliance, and career advancement, as well as for maintaining
          high standards of hygiene and safety in food service operations. This
          course helps Food Handlers comply with national and local Food Safety
          regulations.
        </>
      ),
      units: ["HLTFSE001 - Follow Basic Food Safety Practices"],
    },
    currentThirdPartyFormVersion: 1,
  },
  {
    courseId: 1008,
    courseTitle: "Food Safety Supervisor Health & Community",
    courseCost: 198,
    courseContentPrefix: "FSSAC",
    certificateCodes: ["HLTFSE001", "HLTFSE005", "HLTFSE007"],
    certificateCodesString: "HLTFSE001, HLTFSE005, HLTFSE007",
    unitOfCompetencies: [
      "Follow basic food safety practices",
      "Apply and monitor food safety requirements",
      "Oversee the day-to-day implementation of food safety in the workplace",
    ],
    courseImage:
      "https://solvable.azureedge.net/train-to-gain/images/courses/food-safety-supervisor-certificate-health-and-community.webp",
    courseReadMorePage:
      "/courses/food-safety-supervisor-certificate-health-and-community/",
    courseGuideUrl: "/Print-Course-Content-Guide/1008/",
    courseName: "Food Safety Supervisor",
    courseTags: "Health & Community",
    courseInfo: {
      heading: <>Food Safety Supervisor Health &amp; Community Certificate</>,
      pageTitle: "Food Safety Supervisor Health & Community Certificate",
      pageDescription: "",
      intro: (
        <>
          Completing an Accredited Food Safety Supervisor course can greatly
          contribute to maintaining high safety standards and safeguarding the
          health of vulnerable individuals. This course helps Food Safety
          Supervisors comply with national and local Food Safety regulations.
        </>
      ),
      units: [
        "HLTFSE001 - Follow basic food safety practices",
        "HLTFSE005 - Apply and monitor food safety requirements",
        "HLTFSE007 - Oversee the day-to-day implementation of food safety in the workplace",
      ],
    },
    currentThirdPartyFormVersion: 1,
  },
];

export const nswCertificateReprint = {
  courseId: 7,
  courseTitle: "The NSW Food Safety Supervisor Certificate Reprint",
  courseCost: 45,
  courseContentPrefix: "FSSHR",
  certificateCodes: ["SITXFSA005", "SITXFSA006"],
  certificateCodesString: "SITXFSA005, SITXFSA006",
  unitOfCompetencies: [
    "Use Hygienic Practices for Food Safety",
    "Participate in Safe Food Handling Practices",
  ],
  courseImage:
    "https://solvable.azureedge.net/train-to-gain/images/courses/nsw-food-safety-supervisor-certificate.webp",
  validStates: <></>,
  courseReadMorePage: "/courses/nsw-food-safety-supervisor-certificate/",
  courseGuideUrl: "/Print-Course-Content-Guide/6/",
  courseInfo: {
    heading: <></>,
    pageTitle: "",
    pageDescription: "",
    intro: <></>,
    units: [
      "SITXFSA005 - Use Hygienic Practices for Food Safety",
      "SITXFSA006 - Participate in Safe Food Handling Practices",
    ],
  },
};
