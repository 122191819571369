/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  // CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_REQUEST:
  //   "CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_REQUEST",
  // CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_SUCCESS:
  //   "CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_SUCCESS",
  // CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_FAILURE:
  //   "CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_FAILURE",

  // Form Actions
  CORPORATE_VOUCHERS_REGISTER_FORM_INPUT_CHANGE:
    "CORPORATE_VOUCHERS_REGISTER_FORM_INPUT_CHANGE"
};
