import React from "react";
import { Lightbox } from "react-modal-image";

const ImagePreviewFx = ({
  isOpen,
  imageLargeUrl,
  showRotate,
  closePreview,
  alternateText
}) => {
  return (
    <>
      {isOpen && (
        <Lightbox
          medium={imageLargeUrl}
          showRotate={showRotate}
          alt={alternateText}
          onClose={closePreview}
        />
      )}
    </>
  );
};

export default ImagePreviewFx;
