import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import RegisterBoxContainer from "components/Register-Box/Register-Box.Container";
import CourseService from "services/course-service";
import { selectCertificateRegister } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import "./certificate-register.scss";

const CertificateRegisterView = () => {
  const { courseId } = useParams();

  const courseService = CourseService();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const course = courseService.getCourse(courseId);
    setCourse(course);
  }, [courseService, courseId]);

  return (
    <>
      {course && (
        <>
          <PageTagging title={`${course.courseInfo.pageTitle} | Enrol Now`} />
          <ContentSection bgClass="veggies-bg off-white flex-100">
            <div className="certificate-register">
              <h1>Let&rsquo;s Get Started!</h1>
              <h3 className="mb-3">{course.courseTitle}</h3>
              <h5 className="mb-3">${course.courseCost}.00</h5>
              {course.validStates && (
                <h4 className="mb-3">{course.validStates}</h4>
              )}
              <Row>
                <Col md={6}>
                  <RegisterBoxContainer
                    courseId={course.courseId}
                    registerType={"student"}
                  />
                </Col>
              </Row>
            </div>
          </ContentSection>
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const certificateRegister = selectCertificateRegister(state);

  return {
    certificateRegister: certificateRegister,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history,
});

const CertificateRegister = connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateRegisterView);

export default CertificateRegister;
