import SolvableDataTable from "../../../components/solvable-datatable/solvable-datatable";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import React, { Component } from "react";
import EditDialog from "./Modals/edit";
import DeleteDialog from "./Modals/delete";
import MarkAsPaidDialog from "./Modals/mark-as-paid";
import VouchersDialog from "./Modals/vouchers-list";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {}
    };
    this.actionButtonHandler = this.actionButtonHandler.bind(this);
  }

  columns = [
    {
      Header: "Company Name",
      accessor: "companyName",
      searchable: true
    },
    {
      Header: "Name",
      accessor: "name",
      searchable: true
    },
    {
      accessor: "userId",
      show: false
    },
    {
      accessor: "loginAsUserToken",
      show: false
    },
    {
      accessor: "prePurchaseId",
      show: false
    },
    {
      accessor: "vouchers",
      show: false
    },
    {
      accessor: "invoiceGuid",
      show: false
    },
    {
      Header: "Email",
      accessor: "email",
      searchable: true
    },
    {
      Header: "Phone",
      accessor: "phone",
      searchable: true
    },
    {
      Header: "Amount Due",
      accessor: "amountDue",
      searchable: true
    },
    {
      Header: "Amount Paid",
      accessor: "amountPaid",
      searchable: true
    },
    {
      Header: "Discount",
      accessor: "discountAmount",
      searchable: true
    },
    {
      Header: "Credit Card Purchase",
      accessor: "isCreditCardPurchase",
      searchable: true
    },
    {
      Header: "Purchased Date",
      accessor: "purchasedDateTime",
      searchable: true
    }
  ];

  actionButtonHandler = evt => {
    const {
      toggleEditDialog,
      downloadTaxInvoice,
      toggleDeleteDialog,
      toggleVouchersDialog,
      toggleMarkAsPaidDialog
    } = this.props;
    switch (evt.type) {
      case "button1":
        toggleEditDialog(evt.data);
        break;
      case "button2":
        toggleMarkAsPaidDialog(evt.data);
        break;
      case "button3":
        toggleDeleteDialog(evt.data);
        break;
      case "button4":
        toggleVouchersDialog(evt.data.vouchers);
        break;
      case "button5":
        downloadTaxInvoice(evt.data.invoiceGuid);
        break;
    }
  };

  render() {
    const { data, isProcessing, settings } = this.props.dataTable;
    const {
      toggleDeleteDialog,
      toggleVouchersDialog,
      toggleEditDialog,
      toggleMarkAsPaidDialog,
      onFieldError,
      onEdit,
      onMarkAsPaid,
      onDelete,
      onSettingsChanged,
      onDialogInputChange
    } = this.props;
    const {
      isProcessing: isDialogProcessing,
      dialogs,
      fields,
      vouchers
    } = this.props.dialog;
    return (
      <div>
        <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
          <EditDialog
            toggleEditDialog={toggleEditDialog}
            isProcessing={isDialogProcessing}
            onEdit={onEdit}
            onFieldError={onFieldError}
            onDialogInputChange={onDialogInputChange}
            isDialogProcessing={isDialogProcessing}
            dialogs={dialogs}
            fields={fields}
          />
          <DeleteDialog
            toggleDeleteDialog={toggleDeleteDialog}
            isProcessing={isDialogProcessing}
            isDialogProcessing={isDialogProcessing}
            dialogs={dialogs}
            onDelete={onDelete}
            fields={fields}
          />
          <MarkAsPaidDialog
            toggleMarkAsPaidDialog={toggleMarkAsPaidDialog}
            isProcessing={isDialogProcessing}
            isDialogProcessing={isDialogProcessing}
            dialogs={dialogs}
            onMarkAsPaid={onMarkAsPaid}
            fields={fields}
          />
          <VouchersDialog
            dialogs={dialogs}
            toggleVouchersDialog={toggleVouchersDialog}
            vouchers={vouchers}
          />
          <SolvableDataTable
            defaultPageSize={50}
            columns={this.columns}
            actionButtons={{
              type: "customButton",
              containerWidth: 249,
              custom: [
                {
                  target: "button1",
                  icon: "fas fa-edit",
                  tooltip: "Edit voucher",
                },
                {
                  target: "button2",
                  icon: "fas fa-check",
                  hideFn: (row) => row.amountPaid !== "$0.00",
                  tooltip: "Mark as paid",
                },
                {
                  target: "button3",
                  icon: "fas fa-trash-alt",
                  tooltip: "Delete voucher",
                },
                {
                  target: "button4",
                  icon: "fas fa-list-alt",
                  tooltip: "View vouchers",
                },
                {
                  target: "button5",
                  icon: "fas fa-download",
                  tooltip: "Download tax invoice",
                  hideFn: (row) => row.invoiceGuid === null,
                },
                {
                  target: "button6",
                  icon: "fas fa-address-card",
                  tooltip:
                    "You can right click “Open link in incognito window” to avoid having to log out and in again",
                  to: (row) => {
                    return `/login/${row.userId}/${row.loginAsUserToken}`;
                  },
                },
              ],
            }}
            keepSettings={true}
            stateSettings={settings}
            onSettingsChanged={onSettingsChanged}
            onActionButtonClick={this.actionButtonHandler}
            data={data}
          />
        </BlockUiFx>
      </div>
    );
  }
}

export default DataTable;
