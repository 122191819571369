/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import {
  addNotification,
  onLoginSuccess,
} from "../../../redux/system/system-action-creators";
import RegisterService from "../Register-Service";
import Auth from "../../../coreLib/auth/auth";
import UserIdentity from "./../../../coreLib/auth/userIdentity";

// =================
//  Register
// =================

// Async
export const onRegister = (fields, courseId, registerType, history) => {
  return (dispatch) => {
    const registerService = RegisterService();
    dispatch(onRegisterRequest());

    if (fields.lastName === "") fields.lastName = fields.firstName;

    registerService
      .registerUser(fields, registerType)
      .then((resp) => {
        dispatch(onRegisterSuccess(resp));

        Auth.setToken(
          resp.data.access_token,
          resp.data.refresh_token,
          resp.data.expires_in
        );

        let userIdentity = UserIdentity();
        const user = {
          // Todo: implement id fetching here
          firstName: userIdentity.getFirstName(),
          lastName: userIdentity.getLastName(),
          userType: userIdentity.userType(),
        };
        dispatch(onLoginSuccess(user));

        // Redirect
        if (registerType === "student") {
          history.push(`/course-enrolment/${courseId}/step-1/`);
        } else {
          history.push("/Corporate/Purchase-Vouchers/");
        }
      })
      .catch((err) => {
        // Todo: Implement generic error handler in the web template - rcnet
        let error = err.response.data && err.response.data.Message;
        dispatch(onRegisterFailure());
        dispatch(addNotification(error, "error"));
      });
  };
};

const onRegisterRequest = () => ({
  type: ActionTypes.REGISTER_BOX_REGISTER_REQUEST,
});
const onRegisterSuccess = () => ({
  type: ActionTypes.REGISTER_BOX_REGISTER_SUCCESS,
});
const onRegisterFailure = () => ({
  type: ActionTypes.REGISTER_BOX_REGISTER_FAILURE,
});

export const toggleNoLastName = () => {
  return (dispatch) =>
    dispatch({
      type: ActionTypes.REGISTER_BOX_FORM_NO_LAST_NAME,
    });
};

// ----------------------------
//  Form Actions
// ----------------------------

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.REGISTER_BOX_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});
