/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  EXTERNAL_LEARNING_COURSE_LOAD_REQUEST:
    "EXTERNAL_LEARNING_COURSE_LOAD_REQUEST",
  EXTERNAL_LEARNING_COURSE_LOAD_SUCCESS:
    "EXTERNAL_LEARNING_COURSE_LOAD_SUCCESS",
  EXTERNAL_LEARNING_COURSE_LOAD_FAILURE:
    "EXTERNAL_LEARNING_COURSE_LOAD_FAILURE",

  EXTERNAL_LEARNING_COURSE_GET_SSO_REQUEST:
    "EXTERNAL_LEARNING_COURSE_GET_SSO_REQUEST",
  EXTERNAL_LEARNING_COURSE_GET_SSO_FAILURE:
    "EXTERNAL_LEARNING_COURSE_GET_SSO_FAILURE",
};
