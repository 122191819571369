export const FoodHandlingLevel1CertificateHealthAndCommunityThirdPartyFormTasks = [
  {
    taskId: 313,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task: "Handle food according to the food safety program",
    displayOrder: 1,
  },
  {
    taskId: 314,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Identify and report processes and practices which are not consistent with the food safety program",
    displayOrder: 2,
  },
  {
    taskId: 315,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Take corrective action, within level of responsibility, and according to the food safety program",
    displayOrder: 3,
  },
  {
    taskId: 316,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Report any corrective action to supervisor, and according to legislative and organisation protocols",
    displayOrder: 4,
  },
  {
    taskId: 317,
    courseId: 1009,
    version: 1,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Ensure personal hygiene meets the requirements of the food safety program",
    displayOrder: 5,
  },
  {
    taskId: 318,
    courseId: 1009,
    version: 1,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Report health conditions and/or illness, as required by the food safety program",
    displayOrder: 6,
  },
  {
    taskId: 319,
    courseId: 1009,
    version: 1,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Wear appropriate clothing and footwear as required by the food safety program",
    displayOrder: 7,
  },
  {
    taskId: 320,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Maintain the workplace in a clean and tidy order to meet workplace requirements",
    displayOrder: 8,
  },
  {
    taskId: 321,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Comply with workplace measures to prevent pests entering food premises",
    displayOrder: 9,
  },
  {
    taskId: 322,
    courseId: 1009,
    version: 1,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task: "Identify and report indicators of pest presence",
    displayOrder: 10,
  },
  {
    taskId: 323,
    courseId: 1009,
    version: 1,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task:
      "Keep food for disposal separate, according to food safety program requirements",
    displayOrder: 11,
  },
  {
    taskId: 324,
    courseId: 1009,
    version: 1,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task: "Dispose of food according to the food safety program requirements",
    displayOrder: 12,
  },
  {
    taskId: 325,
    courseId: 1009,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task:
      "Apply knowledge of aspects of the food safety program relevant to the work role",
    displayOrder: 13,
  },
  {
    taskId: 326,
    courseId: 1009,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with personal hygiene requirements",
    displayOrder: 14,
  },
  {
    taskId: 327,
    courseId: 1009,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with workplace cleanliness requirements",
    displayOrder: 15,
  },
  {
    taskId: 328,
    courseId: 1009,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Demonstrate appropriate disposal of food",
    displayOrder: 16,
  },
];
