/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  // CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_REQUEST:
  //   "CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_REQUEST",
  // CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_SUCCESS:
  //   "CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_SUCCESS",
  // CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_FAILURE:
  //   "CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_FAILURE",

  // Form Actions
  CERTIFICATE_REGISTER_FORM_INPUT_CHANGE:
    "CERTIFICATE_REGISTER_FORM_INPUT_CHANGE"
};
