/* eslint-disable jsx-a11y/anchor-is-valid */
import "./address-field.scss";

import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { default as ReactSelectAsync } from "react-select/async";
import { Label } from "reactstrap";
import AddressEntry from "./../../Address-Entry/Address-Entry";
import Loqate from "./utils/loqate";
import debounce from "lodash/debounce";

const LoqateAddressField = forwardRef(
  (
    {
      name,
      label,
      labelClassName,
      placeholder,
      allowAutoSearch,
      hasData,
      form,
      initialValue,
      onInputChange,
      onAddressSelect,
      fields,
      validate,
      onFieldError,
      clearAddress,
      toggleManualEntry,
      debounceDelay = 50,
    },
    ref
  ) => {
    const NOTIFICATION_DEBOUNCE = 2000;

    const [isManualEntry, setIsManuallEntry] = useState(false);

    const autoSearch = !allowAutoSearch ? true : allowAutoSearch;

    const [selectedAddress, setSelectedAddress] = useState(initialValue);
    const [savedInput, setSavedInput] = useState();

    const [activeTimeoutHandlerId, setActiveTimeoutHandlerId] = useState();
    const [errorMessage, setErrorMessage] = useState();

    let loqate = useMemo(() => Loqate.initialise(), []);

    useEffect(() => setIsManuallEntry(hasData ? true : false), []);
    useEffect(() => {
      setSelectedAddress(initialValue);
    }, [initialValue]);

    const _selectSuggestedAddress = async (selectedOption) => {
      _detectChange(selectedOption?.value);

      if (!selectedOption) {
        clearAddress();
        return;
      }

      const addressId = selectedOption.value;
      const results = await loqate.retrieveAddress(addressId);
      const addressDetails = results[0];

      if (!addressDetails) {
        return;
      }

      const fullAddress = addressDetails.fullAddress;

      setErrorMessage();
      setSavedInput();
      setSelectedAddress(fullAddress);

      onAddressSelect(addressDetails);
    };

    const _getAddressSuggestions = async (searchTerm) => {
      if (!searchTerm || searchTerm === "") {
        return [];
      }

      const addressSuggestions = await loqate.findAddress({
        country: "au",
        fullAddress: searchTerm,
      });

      if (!addressSuggestions || !addressSuggestions.length) {
        return [];
      }

      const selectAddressOptions = Object.entries(addressSuggestions).map(
        ([key, item]) => {
          return { label: item["fullAddress"], value: item["id"] };
        }
      );

      return selectAddressOptions ?? [];
    };

    const _loadAddressSuggestions = (inputValue, callback) => {
      const timeoutHandlerId = setTimeout(async () => {
        callback(await _getAddressSuggestions(inputValue));
      }, debounceDelay);

      // Cancel last active timeout if new request comes in.
      if (activeTimeoutHandlerId !== timeoutHandlerId) {
        clearTimeout(activeTimeoutHandlerId);
      }

      setActiveTimeoutHandlerId(timeoutHandlerId);
    };

    const _debouncedCallback = debounce(
      (error) => onFieldError(error),
      NOTIFICATION_DEBOUNCE
    );

    const _handleLoqateAddressInputChange = (value, action) => {
      if (action.action === "input-change") {
        setSavedInput(value);
        setSelectedAddress();
        return;
      }

      if (action.action === "input-blur" && !selectedAddress) {
        let errorMessage = _detectChange(value);
        if (!errorMessage) return;

        setErrorMessage(errorMessage);
        _debouncedCallback(errorMessage);
      }
    };

    const _isValidComponentInstance = () => {
      return true;
    };

    const _validateField = () => {
      let errorMessage = _detectChange(selectedAddress);
      if (errorMessage) {
        _debouncedCallback(errorMessage);
      }

      return { name, error: errorMessage };
    };

    const _detectChange = (value) => {
      const errorMessage = validate ? (!isManualEntry ? validate(value) : false) : false;
      if (errorMessage) {
        setErrorMessage(errorMessage);
      }

      onInputChange({ name, value, error: errorMessage });
      return errorMessage;
    };

    // The useImperativeHandle Hook allows us to expose a value, state, or function inside a child component to the parent component
    // which is useful for functional component's use-case wherein the parent component needs to access specific functions.
    useImperativeHandle(ref, () => ({
      _isValidComponentInstance,
      _detectChange,
      _validateField,
    }));

    return (
      <div className="mb-2 loqate-field-address">
        <div className={isManualEntry || !autoSearch ? "hide-address" : ""}>
          <Label className={labelClassName && labelClassName}>
            {label ? `${label} Address` : "Address"}
          </Label>
          <ReactSelectAsync
            name={name}
            cacheOptions
            placeholder={placeholder}
            isClearable={true}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            loadOptions={_loadAddressSuggestions}
            onChange={_selectSuggestedAddress}
            onInputChange={_handleLoqateAddressInputChange}
            className={`loqate-address${errorMessage ? " field-error " : ""}`}
            inputValue={savedInput}
            value={
              selectedAddress ? { label: selectedAddress, value: 0 } : null
            }
          />
        </div>

        {/* Only visible when the user chose to enter address manually*/}
        {(isManualEntry || !autoSearch) && (
          <div className="mt-2">
            <AddressEntry
              unitNumber={fields.unitNumber}
              streetNumber={fields.streetNumber}
              streetName={fields.streetName}
              city={fields.city}
              state={fields.state}
              postcode={fields.postcode}
              onFieldError={onFieldError}
              onInputChange={onInputChange}
              form={form}
            />
          </div>
        )}

        {/* Link to hide or show the auto-suggest field and manual address entry */}
        {autoSearch && (
          <div className="mt-2 mb-3">
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                setIsManuallEntry(!isManualEntry);
                toggleManualEntry();
              }}
            >
              <small>
                {isManualEntry
                  ? "Enter address using auto-suggest address box."
                  : "Enter address manually."}
              </small>
            </a>
          </div>
        )}
      </div>
    );
  }
);

LoqateAddressField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  allowAutoSearch: PropTypes.bool,
  hasData: PropTypes.bool,
  onChange: PropTypes.func,
  onAddressSelect: PropTypes.func,
  validate: PropTypes.func,
  onFieldError: PropTypes.func,
  debounceDelay: PropTypes.number,
};

export default LoqateAddressField;
