import { ActionTypes } from "./action-types";
import HttpService from "../services/api-service";
import { addNotification } from "../../../../redux/system/system-action-creators";

export const onLoad = (payload) => (dispatch) => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_ONLOAD_REQUEST });
  service
    .loadCourse(payload)
    .then((resp) => {
      dispatch(onLoadSuccess(resp.data));
    })
    .catch(() => {
      dispatch(onLoadFailure());
      dispatch(addNotification("Error loading user course.", "warning"));
    });
};

const onLoadSuccess = (payload) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADMIN_EDIT_COURSES_ONLOAD_SUCCESS,
    payload,
  });
};

const onLoadFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_ONLOAD_FAILURE });
};

export const updateCourse = (payload) => (dispatch) => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_UPDATE_REQUEST });
  service
    .updateCourse(payload)
    .then((resp) => {
      dispatch(onUpdateCourseSuccess());
      dispatch(addNotification("User course has been successfully updated."));
    })
    .catch(() => {
      dispatch(onUpdateCourseFailure());
      dispatch(addNotification("Unable to update the user course", "warning"));
    });
};

const onUpdateCourseSuccess = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_UPDATE_SUCCESS });
};

const onUpdateCourseFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_UPDATE_FAILURE });
};

export const deleteCourse = (payload, history) => (dispatch) => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_DELETE_REQUEST });
  service
    .deleteCourse(payload.userCourseId)
    .then((resp) => {
      dispatch(onDeleteCourseSuccess());
      dispatch(addNotification("User course has been successfully updated."));
      history.push("/admin/User-Courses");
    })
    .catch(() => {
      dispatch(onDeleteCourseFailure());
      dispatch(addNotification("Unable to update the user course", "warning"));
    });
};

const onDeleteCourseSuccess = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_DELETE_SUCCESS });
};

const onDeleteCourseFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_DELETE_FAILURE });
};

export const toggleDeleteDialog = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_SHOW_DELETE_DIALOG });
};

export const toggleCourseTransferConfirmation = () => (dispatch) => {
  dispatch({
    type: ActionTypes.ADMIN_EDIT_COURSES_SHOW_COURSE_TRANSFER_CONFIRMATION,
  });
};

export const handleCourseTransfer = (userCourseId, history) => async (
  dispatch
) => {
  let service = HttpService();

  dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_TRANSFER_REQUEST });

  await service
    .transferUserCourse(userCourseId)
    .then((resp) => {
      dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_TRANSFER_SUCCESS });
      dispatch(
        addNotification(
          "The user course has been successfully transferred to a new user."
        )
      );

      history.push("/admin/User-Courses");
    })
    .catch((err) => {
      dispatch({ type: ActionTypes.ADMIN_EDIT_COURSES_TRANSFER_FAILURE });

      let status = err.response?.status;
      let errorMessage = err.response?.data?.Message;

      if (status === 400 && errorMessage) {
        dispatch(addNotification(errorMessage, "warning"));
        return;
      }

      dispatch(
        addNotification(
          "Unable to transfer the user course to a new user",
          "error"
        )
      );
    });
};

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_EDIT_COURSES_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
