import ActionTypes from "./action-types";
import ApiService from "../services/api-service";
import {
  addErrorNotification,
  addNotification,
} from "redux/system/system-action-creators";

const operateRequest = () => ({
  type: ActionTypes.SYNC_COURSES_OPERATE_REQUEST,
});

const operateFailure = () => ({
  type: ActionTypes.SYNC_COURSES_OPERATE_FAILURE,
});

const syncCourseRequest = (payload) => ({
  type: ActionTypes.SYNC_COURSES_SYNC_REQUEST,
  payload,
});

const syncCourseSuccess = (payload) => ({
  type: ActionTypes.SYNC_COURSES_SYNC_SUCCESS,
  payload,
});

const publishCourseSuccess = () => ({
  type: ActionTypes.SYNC_COURSES_PUBLISH_SUCCESS,
});

export const syncCoursesFromElearnd = () => async (dispatch) => {
  dispatch(syncCourseRequest());

  const apiService = ApiService();

  return await apiService
    .syncCoursesFromElearnd()
    .then((resp) => {
      if (resp.data.isSuccessful) {
        dispatch(syncCourseSuccess(resp.data.syncedCourses));
        dispatch(addNotification("Successfully synced courses from eLearnd."));

        return {
          isSuccessful: true,
        };
      }

      dispatch(operateFailure());
      return {
        isSuccessful: false,
        validationMessages: resp.data.validationMessages,
      };
    })
    .catch((error) => {
      dispatch(operateFailure());
      dispatch(
        addErrorNotification(
          error,
          "Sorry, unable to sync courses from eLearnd."
        )
      );
    });
};

export const publishCourse = (courseId) => async (dispatch) => {
  dispatch(operateRequest());

  const apiService = ApiService();

  await apiService
    .publishCourse(courseId)
    .then((resp) => {
      dispatch(addNotification("Successfully published course."));
      dispatch(publishCourseSuccess());
      dispatch(syncCoursesFromElearnd());
    })
    .catch((error) => {
      dispatch(operateFailure());
      dispatch(addErrorNotification(error, "Sorry, unable to publish course."));
    });
};
