export const ActionTypes = {
  ADMIN_PROMO_CODES_ONLOAD_REQUEST: "ADMIN_PROMO_CODES_ONLOAD_REQUEST",
  ADMIN_PROMO_CODES_ONLOAD_SUCCESS: "ADMIN_PROMO_CODES_ONLOAD_SUCCESS",
  ADMIN_PROMO_CODES_ONLOAD_FAILURE: "ADMIN_PROMO_CODES_ONLOAD_FAILURE",
  ADMIN_PROMO_CODES_FILTER_FORM_INPUT_CHANGE:
    "ADMIN_PROMO_CODES_FILTER_FORM_INPUT_CHANGE",
  ADMIN_PROMO_CODES_DELETE_REQUEST: "ADMIN_PROMO_CODES_DELETE_REQUEST",
  ADMIN_PROMO_CODES_DELETE_SUCCESS: "ADMIN_PROMO_CODES_DELETE_SUCCESS",
  ADMIN_PROMO_CODES_DELETE_FAILURE: "ADMIN_PROMO_CODES_DELETE_FAILURE",
  ADMIN_PROMO_CODES_EDIT_REQUEST: "ADMIN_PROMO_CODES_EDIT_REQUEST",
  ADMIN_PROMO_CODES_EDIT_SUCCESS: "ADMIN_PROMO_CODES_EDIT_SUCCESS",
  ADMIN_PROMO_CODES_ADD_SUCCESS: "ADMIN_PROMO_CODES_ADD_SUCCESS",
  ADMIN_PROMO_CODES_EDIT_FAILURE: "ADMIN_PROMO_CODES_EDIT_FAILURE",
  ADMIN_PROMO_CODES_RESET: "ADMIN_PROMO_CODES_RESET",
  ADMIN_PROMO_CODES_DATATABLE_KEEP_SETTINGS:
    "ADMIN_PROMO_CODES_DATATABLE_KEEP_SETTINGS",
  ADMIN_PROMO_CODES_DATATABLE_CLEAR_SEARCHBOX:
    "ADMIN_PROMO_CODES_DATATABLE_CLEAR_SEARCHBOX",
};
