export const ActionTypes = {
         ADMIN_EDIT_COURSES_ONLOAD_REQUEST: "ADMIN_EDIT_COURSES_ONLOAD_REQUEST",
         ADMIN_EDIT_COURSES_ONLOAD_SUCCESS: "ADMIN_EDIT_COURSES_ONLOAD_SUCCESS",
         ADMIN_EDIT_COURSES_ONLOAD_FAILURE: "ADMIN_EDIT_COURSES_ONLOAD_FAILURE",

         ADMIN_EDIT_COURSES_FORM_INPUT_CHANGE:
           "ADMIN_EDIT_COURSES_FORM_INPUT_CHANGE",

         ADMIN_EDIT_COURSES_UPDATE_REQUEST: "ADMIN_EDIT_COURSES_UPDATE_REQUEST",
         ADMIN_EDIT_COURSES_UPDATE_SUCCESS: "ADMIN_EDIT_COURSES_UPDATE_SUCCESS",
         ADMIN_EDIT_COURSES_UPDATE_FAILURE: "ADMIN_EDIT_COURSES_UPDATE_FAILURE",

         ADMIN_EDIT_COURSES_DELETE_REQUEST: "ADMIN_EDIT_COURSES_DELETE_REQUEST",
         ADMIN_EDIT_COURSES_DELETE_SUCCESS: "ADMIN_EDIT_COURSES_DELETE_SUCCESS",
         ADMIN_EDIT_COURSES_DELETE_FAILURE: "ADMIN_EDIT_COURSES_DELETE_FAILURE",

         ADMIN_EDIT_COURSES_SHOW_DELETE_DIALOG:
           "ADMIN_EDIT_COURSES_SHOW_DELETE_DIALOG",

         ADMIN_EDIT_COURSES_SHOW_COURSE_TRANSFER_CONFIRMATION:
           "ADMIN_EDIT_COURSES_SHOW_COURSE_TRANSFER_CONFIRMATION",

         ADMIN_EDIT_COURSES_TRANSFER_REQUEST:
           "ADMIN_EDIT_COURSES_TRANSFER_REQUEST",
         ADMIN_EDIT_COURSES_TRANSFER_SUCCESS:
           "ADMIN_EDIT_COURSES_TRANSFER_SUCCESS",
         ADMIN_EDIT_COURSES_TRANSFER_FAILURE:
           "ADMIN_EDIT_COURSES_TRANSFER_FAILURE",
       };
