/* eslint-disable default-case */
import IdentityService from "../../../coreLib/auth/userIdentity";
import { addNotification } from "../../../redux/system/system-action-creators";
import { ActionTypes } from "./action-types";
import Service from "./service";

export const init = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_INIT,
});

export const applyPromoCode = (promoCode, courses, showNotif) => (dispatch) => {
  let service = Service();
  service
    .applyPromoCode(promoCode, courses)
    .then((resp) => {
      let { promoCodeValid } = resp.data;
      if (promoCodeValid) {
        dispatch(applyPromoCodeSuccess(resp.data));
        if (showNotif)
          dispatch(addNotification("Promo code has been applied."));
      } else {
        dispatch(addNotification("Invalid promo code", "warning"));
        dispatch(applyPromoCodeFailure());
      }
    })
    .catch((err) => {
      dispatch(addNotification("Invalid promo code", "warning"));
      dispatch(applyPromoCodeFailure());
    });
};

const applyPromoCodeSuccess = (payload) => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_SUCCESS,
  payload,
});

const applyPromoCodeFailure = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_FAILURE,
});

export const setEmail = () => (dispatch) => {
  let email = IdentityService().getEmail();
  dispatch(formInputChange("email", email));
};

export const onCreditCardInputChange = (card) => (dispatch) => {
  const { field, value, meta } = card;
  dispatch(formInputChange(field, value));
  let isCreditCardValid = Object.values(meta).every((m) => m === undefined);
  dispatch(setCardValidity(isCreditCardValid));
  dispatch(validateFunc());
};

export const onInputQuantityChange = (payload) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_QUANTITY_CHANGE,
    payload,
  });

  let state = getState().purchaseCorporateVoucher;
  if (state.isPromoCodeApplied)
    dispatch(applyPromoCode(state.fields.promoCode, state.courses, false));
  dispatch(validateFunc());
};

const setCardValidity = (value) => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SET_CARD_VALIDITY,
  payload: value,
});

export const updateAutocompleteAddress = (data) => {
  return (dispatch) => {
    dispatch({
      type:
        ActionTypes.PURCHASE_CORPORATE_VOUCHERS_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS,
      payload: data,
    });
  };
};

export const toggleManualAddressEntry = () => (dispatch) => {
  dispatch({
    type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_TOGGLE_MANUAL_ADDRESS_ENTRY,
  });
  dispatch(validateFunc());
};

export const togglePromoCodeEntry = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_TOGGLE_PROMO_CODE_ENTRY,
});

const showManualAddressEntry = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SHOW_MANUAL_ADDRESS_ENTRY,
});

export const clearAddress = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_CLEAR_ADDRESS,
});

export const onSubmit = (stateProps, fields, history) => (
  dispatch,
  getState
) => {
  const {
    isCreditCardValid,
    totalPrice,
    fields,
    fieldErrors,
    addressEntry,
  } = getState().purchaseCorporateVoucher;

  if (!fields.companyName) {
    dispatch(addNotification("Company name is required", "error"));
    return true;
  }
  if (!fields.phone) {
    dispatch(addNotification("Phone is required", "error"));
    return true;
  }
  if (!fields.email) {
    dispatch(addNotification("Email is required", "error"));
    return true;
  }

  if (!addressEntry.enterAddressManually && !addressEntry.fullAddress) {
    dispatch(addNotification("Address is required", "error"));
    return true;
  }

  if (!fields.postcode) {
    dispatch(addNotification("Post Code is required", "error"));
    return true;
  }
  if (!fields.state) {
    dispatch(addNotification("State is required", "error"));
    return true;
  }
  if (!fields.city) {
    dispatch(addNotification("City is required", "error"));
    return true;
  }
  if (!fields.streetName) {
    dispatch(addNotification("Street name is required", "error"));
    return true;
  }
  if (!fields.streetNumber) {
    dispatch(addNotification("Street number is required", "error"));
    if (!addressEntry.enterAddressManually) {
      dispatch(showManualAddressEntry());
    }
    return true;
  }
  if (!fields.paymentMethod) {
    dispatch(addNotification("Payment method is required", "error"));
    return true;
  }
  if (fields.paymentMethod === "Credit Card") {
    if (!fields.cardName) {
      dispatch(addNotification("Please enter card name", "error"));
      return true;
    }
    if (!fields.cardNumber || !fields.cardExpiryDate || !fields.cardCvc) {
      dispatch(
        addNotification("Please enter a valid credit card details", "error")
      );
      return true;
    }
  }
  if (totalPrice < 1) {
    dispatch(addNotification("Please add voucher items", "error"));
    return true;
  }

  dispatch(onSubmitRequest());

  let service = Service();
  service
    .purchase(fields, stateProps.courses)
    .then((resp) => {
      const { purchaseSuccessful, errorMessage } = resp.data;
      if (purchaseSuccessful) {
        history.push("/Corporate");
        dispatch(onSubmitSuccess());
      } else {
        dispatch(onSubmitFailure());
        dispatch(addNotification(errorMessage, "warning"));
      }
    })
    .catch((err) => {
      dispatch(onSubmitFailure());
      dispatch(
        addNotification("here was an error during submission.", "warning")
      );
    });
};

const onSubmitRequest = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SUBMIT_REQUEST,
});
const onSubmitSuccess = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SUBMIT_SUCCESS,
});
const onSubmitFailure = () => ({
  type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SUBMIT_FAILURE,
});

export const formInputChange = (name, value, error) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_FORM_INPUT_CHANGE,
      payload: {
        name: name,
        value: value,
        error: error,
      },
    });

    switch (name) {
      case "paymentMethod":
        value === "Credit Card"
          ? dispatch({ type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_CREDIT })
          : dispatch({ type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_INVOICE });
        break;
    }

    dispatch(validateFunc(name));
  };
};

const validateFunc = (name) => {
  return (dispatch, getState) => {
    const {
      isCreditCardValid,
      totalPrice,
      fields,
      fieldErrors,
      addressEntry,
    } = getState().purchaseCorporateVoucher;

    let isFormValid = !validate(
      name,
      fields,
      totalPrice,
      fieldErrors,
      addressEntry,
      isCreditCardValid
    );

    dispatch({
      type: ActionTypes.PURCHASE_CORPORATE_VOUCHERS_VALIDATE,
      payload: isFormValid,
    });
  };
};

const validate = (
  name,
  fields,
  totalPrice,
  fieldErrors,
  addressEntry,
  isCreditCardValid
) => {
  let doesFieldExistAndEmpty =
    fields[name] !== undefined && fields[name].length === 0;

  if (doesFieldExistAndEmpty) {
    return true;
  }

  if (
    name === "cardName" &&
    fields.paymentMethod === "Credit Card" &&
    !isCreditCardValid
  ) {
    return true;
  }

  if (
    name === "postalAddress" &&
    !addressEntry.enterAddressManually &&
    !addressEntry.fullAddress
  ) {
    return true;
  }

  if (addressEntry.enterAddressManually && !fields.streetNumber) {
    return true;
  }

  if (totalPrice < 1) {
    return true;
  }

  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

  if (errMessages.length > 0) {
    return true;
  }

  return false;
};
