import React from "react";
import { Input, Label } from "reactstrap";

const CourseQuizQuestionMultiSelect = ({
  questionId,
  options,
  onQuizInputChange
}) => {
  const onChange = e => {
    const selectedIndex = parseInt(
      e.target.attributes.getNamedItem("data-index").value
    );

    onQuizInputChange(selectedIndex);
  };

  return (
    <div id="quizQuestions">
      {options.items.map((value, i) => (
        <div className="form-check mt-2" key={i}>
          <Input
            id={`quizQuestion-${questionId}-${i}`}
            name={`quizQuestion-${questionId}`}
            type="radio"
            className="form-check-item quiz-question-item"
            onChange={onChange}
            data-index={i}
          />
          <Label
            className="form-check-label"
            for={`quizQuestion-${questionId}-${i}`}
          >
            {value}
          </Label>
        </div>
      ))}
    </div>
  );
};

export default CourseQuizQuestionMultiSelect;
