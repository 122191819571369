import React, { Component } from "react";
import { FormGroup, Row, Col, Table } from "reactstrap";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import formHandler from "../../../components/Common/Fields/form-handler";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import DeleteDialog from "./delete-dialog";
import ImportNatFileFormDialog from "./components/dialogs/import";

class AvetmissReportingSubmission extends Component {
  componentDidMount() {
    const { onLoadForm } = this.props;
    const { files } = this.props.avetmissReportingSubmission;

    if (!files || files.length === 0) onLoadForm();
  }

  render() {
    const form = formHandler();

    const {
      isLoading,
      toggleDeleteDialog,
      showDeleteDialog,
      isDeleting,
      onSubmit,
      onDelete,
      onImport,
      toggleImportDialog,
      showImportDialog,
      isImporting,
      isExporting,
      exportNatFiles,
    } = this.props;

    const {
      files,
      year,
      period,
      version,
      collectionStartDate,
      collectionEndDate,
      state,
    } = this.props.avetmissReportingSubmission;
    return (
      <>
        <PageTitle title="Avetmiss Validation Service | Submission" />
        <ContentSection bgColor="grey-shadow">
          <h1>Avetmiss Validation Service | Submission</h1>
        </ContentSection>
        <ContentSection>
          <h5>
            Avetmiss Reporting for {period} {year}
          </h5>
          <Row form>
            <Col>
              <Table striped>
                <thead>
                  <tr>
                    <th>File Id</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <b>{file.filename}</b>
                        </td>
                        <td>{file.description}</td>
                        <td>
                          {file.generatedSuccessfully
                            ? "Generated Successfully"
                            : file.errorMessage}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          <FormGroup>
            {!isDeleting && (
              <ButtonFx
                type="submit"
                className="ttg-btn mr-2 mb-2"
                isLoading={isLoading}
                onClick={() => {
                  form.validate({
                    validFn: () =>
                      onSubmit(
                        year,
                        period,
                        version,
                        collectionStartDate,
                        collectionEndDate,
                        state
                      ),
                    invalidFn: () => console.log(form.getFieldErrors()),
                  });
                }}
              >
                Submit for Validation
              </ButtonFx>
            )}

            {!isLoading && (
              <ButtonFx
                className="ttg-btn mb-2 mr-2"
                isLoading={isDeleting}
                onClick={() => toggleDeleteDialog()}
              >
                Delete NAT Files
              </ButtonFx>
            )}

            {!isLoading && (
              <ButtonFx
                className="ttg-btn mb-2 mr-2"
                isLoading={isExporting}
                onClick={() => exportNatFiles()}
              >
                Export NAT Files
              </ButtonFx>
            )}

            {!isLoading && (
              <ButtonFx
                className="ttg-btn mb-2"
                isLoading={isImporting}
                onClick={() => toggleImportDialog()}
              >
                Import NAT Files
              </ButtonFx>
            )}
          </FormGroup>
        </ContentSection>

        <DeleteDialog
          onDelete={onDelete}
          toggleDeleteDialog={toggleDeleteDialog}
          isDeleting={isDeleting}
          showDeleteDialog={showDeleteDialog}
        />

        <ImportNatFileFormDialog
          isOpen={showImportDialog}
          close={toggleImportDialog}
          isImporting={isImporting}
          import={async (value) => {
            try {
              await onImport(value);
            } catch (e) {}
          }}
        />
      </>
    );
  }
}

export default AvetmissReportingSubmission;
