/**
 * Course Quiz Service
 */
import HttpClient from "../../../coreLib/http/httpClient";

const CourseQuizService = () => {
  let httpClient = HttpClient();

  const getStatus = (courseContentToken) =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/status`);

  const updateQuizAnswer = (courseContentToken, data) => {
    let promiseDeferred = new Promise((resolve, reject) => {
      httpClient
        .patch(`/v1/userCourse/${courseContentToken}/quiz/answer`, data)
        .then((resp) => {
          // Get latest status
          getStatus(courseContentToken)
            .then((resp) => resolve(resp.data))
            .catch((err) => reject(err));
        })
        .catch((err) => reject(err));
    });

    return promiseDeferred;
  };

  return {
    updateQuizAnswer,
  };
};

export default CourseQuizService;
