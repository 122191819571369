import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import FilterSection from "./filter-section";

class DownloadReports extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <PageTitle title="Admin - Users | Train to Gain" />
        <ContentSection padding={3} bgColor="grey-shadow">
          <h1>Download Reports</h1>
        </ContentSection>
        <ContentSection padding={3}>
          <FilterSection {...this.props} />
        </ContentSection>
      </>
    );
  }
}

export default DownloadReports;
