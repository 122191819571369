/**
 * System-wide Action types
 */
export const ActionTypes = {
         // Notifications
         SYS_ADD_NOTIFICATION: "SYS_ADD_NOTIFICATION",
         SYS_ADD_ERROR_NOTIFICATION: "SYS_ADD_NOTIFICATION",

         // Login
         SYS_LOGIN_REQUEST: "SYS_LOGIN_REQUEST",
         SYS_LOGIN_SUCCESS: "SYS_LOGIN_SUCCESS",
         SYS_LOGIN_FAILURE: "SYS_LOGIN_FAILURE",
         SYS_LOGOUT_REQUEST: "SYS_LOGOUT_REQUEST",
         SYS_LOGOUT_SUCCESS: "SYS_LOGOUT_SUCCESS",
         SYS_LOGOUT_FAILURE: "SYS_LOGOUT_FAILURE",
         SYS_LOGIN_VALIDATION_ERROR: "SYS_LOGIN_VALIDATION_ERROR",
         SYS_LOGIN_FORM_INPUT_CHANGE: "SYS_LOGIN_FORM_INPUT_CHANGE",
         SYS_LOGIN_USER_LOAD_IDENTITY_STATE:
           "SYS_LOGIN_USER_LOAD_IDENTITY_STATE",
         SYS_LOGIN_IMPERSONATION_REQUEST: "SYS_LOGIN_IMPERSONATION_REQUEST",
         SYS_LOGIN_IMPERSONATION_SUCCESS: "SYS_LOGIN_IMPERSONATION_SUCCESS",
         SYS_LOGIN_IMPERSONATION_FAILURE: "SYS_LOGIN_IMPERSONATION_FAILURE",
         SYS_LOGIN_IMPERSONATION_INIT: "SYS_LOGIN_IMPERSONATION_INIT",

         // Error Handler
         SYS_HANDLE_ERROR: "HANDLE_ERROR",
         SYS_HANDLE_ERROR_SHOW_FORM: "SYS_HANDLE_ERROR_SHOW_FORM",
         SYS_HANDLE_ERROR_HIDE_FORM: "SYS_HANDLE_ERROR_HIDE_FORM",
       };
