/**
 * Survey Service
 */
import HttpClient from "./../../coreLib/http/httpClient";

const ThirdPartyFormService = () => {
  let httpClient = HttpClient();

  const getUserCourseIds = (courseContentToken) =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/ids`);

  const getStatus = (courseContentToken) =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/status`);

  const getThirdPartyForm = (courseContentToken) => {
    let promise1 = getUserCourseIds(courseContentToken);
    let promise2 = getStatus(courseContentToken);

    let promiseDeferred = new Promise((resolve, reject) => {
      //getUserCourseIds(courseContentToken)
      Promise.all([promise1, promise2])
        .then((resp) => {
          // Extract access token
          let accessToken = `${courseContentToken.substring(0, 8)}-${
            resp[0].data.thirdPartyAccessToken
          }`;

          httpClient
            .get(`/v1/userCourse/third-party-form/${accessToken}`)
            .then((result) => {
              resolve({
                ...result.data,
                courseId: resp[0].data.courseId,
                studentFirstName: resp[0].data.firstName,
                studentLastName: resp[0].data.lastName,
                accessToken,
                status: { ...resp[1].data },
                isSampleForm: false,
              });
            })
            .catch((e) => reject(e));
        })
        .catch((err) => reject(err));
    });

    return promiseDeferred;
  };

  const updateThirdPartyFormObserver = (
    accessToken,
    data,
    sendEmail = false
  ) => {
    let payload = {
      firstName: data.observerFirstName,
      lastName: data.observerLastName,
      email: data.observerEmail,
      sendEmailToObserver: sendEmail,
    };

    return httpClient.patch(
      `/v1/userCourse/third-party-form/${accessToken}/update-observer`,
      payload
    );
  };

  const saveProgress = (accessToken, payload) =>
    httpClient.put(`/v1/userCourse/third-party-form/${accessToken}`, payload);

  return {
    getUserCourseIds,
    getThirdPartyForm,
    updateThirdPartyFormObserver,
    saveProgress,
  };
};

export default ThirdPartyFormService;
