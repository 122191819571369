/**
 * System-wide Action Creator definitions
 */
import { ActionTypes } from "./system-action-types";
import LoginService from "../../services/login-service";
import * as Constants from "../../constants";
import UserIdentity from "./../../coreLib/auth/userIdentity";
import ErrorService from "./../../coreLib/error/errorService";
import LoginImpersonationService from "./../../services/login-impersonation-service";

// =================
//  Notifications
// =================
export function addNotification(message, level) {
  return {
    type: ActionTypes.SYS_ADD_NOTIFICATION,
    message,
    level,
  };
}

/*
  error - Error object from the api response
  defaultErrorMessage - fallback error message (defined by the user)
*/
export function addErrorNotification(error, defaultErrorMessage) {
  let errorMessage =
    error &&
      error.response &&
      error.response.status &&
      error.response.status === 500
      ? error.response.data.Message
      : error.response.status === 400 && error.response.data.Message
        ? error.response.data.Message
        : error.message
          ? error.message
          : defaultErrorMessage

  return {
    type: ActionTypes.SYS_ADD_ERROR_NOTIFICATION,
    message: errorMessage,
    level: 'error',
  }
}


// =================
//  Login
// =================

// Async
export const onLogin = (username, password, returnUrl, historyObj) => {
  return (dispatch) => {
    if (!username || username === undefined || username === "") {
      dispatch(addNotification("Please enter your email address.", "warning"));
      return;
    }

    if (!Constants.emailRegex.test(username)) {
      dispatch(
        addNotification("Please enter a valid email address.", "warning")
      );
      return;
    }

    if (!password || password === undefined || password === "") {
      dispatch(addNotification("Please enter your password.", "warning"));
      return;
    }

    const loginService = LoginService();

    dispatch(onLoginRequest());

    loginService
      .login(username, password)
      .then((resp) => {
        dispatch(onLoginSuccess(resp));
        handleLoginRedirects(returnUrl, historyObj);
      })
      .catch((error) => {
        dispatch(onLoginFailure());
        if (error.response.data && error.response.data.invalid_grant) {
          dispatch(
            addNotification(error.response.data.invalid_grant[0], "error")
          );
        } else {
          dispatch(
            addNotification("Could not connect to the server.", "error")
          );
        }
      });
  };
};

const getRedirectUrl = (returnUrl) => {
  let redirectTo = returnUrl;
  if (returnUrl === "/") {
    let userIdent = UserIdentity();
    let role = userIdent.getRole();
    switch (role) {
      case "Admin":
        redirectTo = "/admin/users";
        break;
      case "Student":
        redirectTo = "/your-courses";
        break;
      case "Business":
        redirectTo = "/corporate";
        break;
      default:
        redirectTo = "/your-courses";
    }
  }

  return redirectTo;
};

const handleLoginRedirects = (returnUrl, historyObj) => {
  let redirectToUrl = getRedirectUrl(returnUrl);
  historyObj.push(redirectToUrl);
};

const onLoginRequest = () => ({
  type: ActionTypes.SYS_LOGIN_REQUEST,
});

export const onLoginSuccess = (user) => ({
  type: ActionTypes.SYS_LOGIN_SUCCESS,
  payload: {
    firstName: user.firstName,
    lastName: user.lastName,
    id: user.id,
    email: user.email,
    userType: user.userType,
  },
});

const onLoginFailure = () => ({
  type: ActionTypes.SYS_LOGIN_FAILURE,
});

export const onLogout = (logoutRedirectUrl, historyObj) => {
  return (dispatch) => {
    dispatch(onLogoutRequest());

    const loginService = LoginService();

    loginService
      .logout()
      .then((resp) => {
        dispatch(onLogoutSuccess());
        historyObj.push(logoutRedirectUrl);
      })
      .catch((error) => {
        dispatch(onLogoutFailure());
      });
  };
};

const onLogoutRequest = () => ({
  type: ActionTypes.SYS_LOGOUT_REQUEST,
});
const onLogoutSuccess = () => ({
  type: ActionTypes.SYS_LOGOUT_SUCCESS,
});

const onLogoutFailure = () => ({
  type: ActionTypes.SYS_LOGOUT_FAILURE,
});

export const onLoginInputChange = (name, value, error) => ({
  type: ActionTypes.SYS_LOGIN_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});

export const loadUserIdentityState = (userInfo) => ({
  type: ActionTypes.SYS_LOGIN_USER_LOAD_IDENTITY_STATE,
  payload: {
    ...userInfo,
  },
});

// =============================================
//  Login Impersonation
// =============================================

// Async
export const onImpersonateLogin = (userId, token, historyObj) => {
  return (dispatch) => {
    const service = LoginImpersonationService();

    dispatch(onLoginImpersonationRequest());

    service
      .impersonateLogin(userId, token)
      .then((resp) => {
        let redirectToUrl = getRedirectUrl("/");
        dispatch(onLoginImpersonationSuccess(resp, redirectToUrl));
      })
      .catch((error) => {
        dispatch(onLoginImpersonationFailure());
        if (error.response.data && error.response.data.invalid_grant) {
          dispatch(
            addNotification(error.response.data.invalid_grant[0], "error")
          );
        } else {
          dispatch(
            addNotification("Could not connect to the server.", "error")
          );
        }
      });
  };
};

export const loginImpersonationInit = () => ({
  type: ActionTypes.SYS_LOGIN_IMPERSONATION_INIT,
});

const onLoginImpersonationRequest = () => ({
  type: ActionTypes.SYS_LOGIN_IMPERSONATION_REQUEST,
});

const onLoginImpersonationSuccess = (user, url) => ({
  type: ActionTypes.SYS_LOGIN_IMPERSONATION_SUCCESS,
  payload: {
    firstName: user.firstName,
    lastName: user.lastName,
    id: user.id,
    email: user.email,
    redirectToUrl: url,
    userType: user.userType,
  },
});

const onLoginImpersonationFailure = () => ({
  type: ActionTypes.SYS_LOGIN_IMPERSONATION_FAILURE,
});

// =============================================
//  Handle Error
// (Just in case we need Popup error handler)
// =============================================
export const handleError = (error) => {
  return (dispatch) => {
    const errorService = ErrorService();

    //dispatch(resetPasswordRequest());
    errorService
      .resolveError(error)
      .then((resp) => {
        dispatch(showErrorForm(resp));
      })
      .catch((err) => {
        dispatch(hideErrorForm());
      });
  };
};

const showErrorForm = (data) => ({
  type: ActionTypes.SYS_HANDLE_ERROR_SHOW_FORM,
  payload: {
    message: data.message,
    error: data.error,
    isModelState: data.isModelState,
    status: data.status,
  },
});

export const hideErrorForm = () => ({
  type: ActionTypes.SYS_HANDLE_ERROR_HIDE_FORM,
});
