import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";

class ToolTipFx extends Component {
    static propTypes = {
        placement: PropTypes.string,
        target: PropTypes.string
    };

    static defaultProps = {
        placement: "auto"
    };

  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    return (
      <Tooltip
        placement={this.props.placement}
        target={this.props.target}
        isOpen={this.state.tooltipOpen}
        toggle={this.toggle}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

export default ToolTipFx;
