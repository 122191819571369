import HttpClient from "coreLib/http/httpClient";

const ApiService = () => {
  let httpClient = HttpClient();

  const getSso = async (userCourseContentToken) => {
    return await httpClient.get(
      `/v1/usercourse/${userCourseContentToken}/elearnd-sso`
    );
  };

  return {
    getSso,
  };
};

export default ApiService;
