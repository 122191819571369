/**
 * Survey Service
 */
import HttpClient from "../../coreLib/http/httpClient";

const CourseCompletedService = () => {
  let httpClient = HttpClient();

  const getStatus = courseContentToken => {
    let promise1 = httpClient.get(
      `/v1/userCourse/${courseContentToken}/status`
    );
    let promise2 = httpClient.get(
      `/v1/userCourse/${courseContentToken}/course`
    );

    let promiseDeferred = new Promise((resolve, reject) => {
      Promise.all([promise1, promise2])
        .then(values => {
          const result = {
            data: {
              status: values[0].data,
              courseInfo: values[1].data
            }
          };

          resolve(result);
        })
        .catch(err => reject(err));
    });

    return promiseDeferred;
  };

  return {
    getStatus
  };
};

export default CourseCompletedService;
