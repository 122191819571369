import React from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import LoginBoxContainer from "components/Login-Box/Login-Box.Container";
import LinkButton from "components/link-button/link-button";
import { selectLoginData } from "../../redux/system/login-reducer";

const LoginView = () => {
  return (
    <ContentSection bgClass="veggies-bg off-white flex-100">
      <PageTagging title="Login - Food Safety Education | Train to Gain" />
      <div className="ttg-container">
        <Row>
          <Col md={6}>
            <h1>Login</h1>
            <h5>Already a student? Login below.</h5>
            <LoginBoxContainer />
          </Col>
          <Col md={6}>
            <h1>Not a student?</h1>
            <LinkButton
              to="/online-food-safety-training-courses/"
              className="ttg-btn ttg-orange-outline-btn"
              btnText="Start Your Food Safety Training Now"
            />
          </Col>
        </Row>
      </div>
    </ContentSection>
  );
};

function mapStateToProps(state) {
  const logInData = selectLoginData(state);
  return {
    logInData: logInData,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
});

const Login = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(LoginView)
);

export default Login;
