import { ActionTypes } from "./action-types";

const initialState = {
  isProcessing: false,
  fields: {
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  },
  fieldErrors: {},
  vouchers: [],
  dialogs: {
    showDeleteDialog: false,
    showMarkAsPaidDialog: false,
    showEditDialog: false,
    showVouchersDialog: false
  }
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_EDIT_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showEditDialog: !state.dialogs.showEditDialog
        },
        fields: action.payload ? action.payload : {}
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_VOUCHERS_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showVouchersDialog: !state.dialogs.showVouchersDialog
        },
        vouchers: action.payload ? action.payload : []
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_DELETE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        },
        fields: action.payload ? action.payload : {}
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_MARK_AS_PAID_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showMarkAsPaidDialog: !state.dialogs.showMarkAsPaidDialog
        },
        fields: action.payload ? action.payload : {}
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DIALOG_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_REQUEST:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_REQUEST:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_REQUEST:
      return {
        ...state,
        isProcessing: true
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_FAILURE:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showEditDialog: false
        }
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_FAILURE:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: false
        }
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_FAILURE:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showMarkAsPaidDialog: false
        }
      };
    default:
      return state;
  }
}

export default Reducer;
