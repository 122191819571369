import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const submitStep1 = payload => {
    return httpClient.post("v1/enrolment/step1", payload);
  };

  const applyDiscountCode = (courseId, discountCode) => {
    return httpClient.post("v1/enrolment/get-discounted-course-price", {
      courseId,
      promoCode: discountCode
    });
  };

  return {
    submitStep1,
    applyDiscountCode
  };
};

export default Service;
