import currencyFormatter from "currency-formatter";
import isNil from "lodash/isNil";
import React, { Component } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../assets/styles/enrolment-forms.scss";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import CheckboxField from "../../../components/Common/Fields/Checkbox-Field";
import formHandler from "../../../components/Common/Fields/form-handler";
import InputField from "../../../components/Common/Fields/Input-Field";
import PaymentInputs from "../../../components/Common/Fields/Payment-Inputs";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "components/Common/content-section";
import IdentityService from "../../../coreLib/auth/userIdentity";
import LoqateAddressField from "./../../../components/Common/Loqate-Single-Address-Field/index";

// Combination of validations
const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

class CourseEnrolmentStep1 extends Component {
  constructor(props) {
    super(props);
    const { match, onLoadCourseDetails } = this.props;
    onLoadCourseDetails(match.params.courseId);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    let identityService = IdentityService();

    this.props.init(identityService.hasCourses());
    this.props.setEmail();
  }

  onAutocompleteAddressSelect = (address) => {
    this.props.updateAutocompleteAddress(address);
  };

  onChange(value) {
    if (!value) {
      this.props.clearAddress();
    }
  }

  render() {
    const {
      onInputChange,
      toggleDiscountCodeForm,
      applyDiscountCode,
      isApplyingDiscountCode,
      isDiscountCodeApplied,
      discountedPrice,
      showDiscountCodeForm,
      onCreditCardChange,
      hasPromoCode,
      onFieldError,
      togglePromoCodeEntry,
      onSubmitEnrolment,
      isSubmitting,
      showConfirmNamePopup,
      toggleShowConfirmNamePopup,
      onLogoutAndRegister,
      clearAddress,
      toggleManualAddressEntry,
    } = this.props;
    const { fullAddress } = this.props.addressEntry;
    const {
      cardName,
      cardNumber,
      cardExpiryDate,
      cardCvc,
      email,
      promoCode,
      discountCode,
      understandTerms,
    } = this.props.fields;
    const {
      courseTitle,
      courseCode,
      coursePrice,
      validStates,
    } = this.props.courseDetails;
    const { firstName, lastName } = this.props.loginData.loggedInUser;
    const form = formHandler();
    return (
      <ContentSection bgClass="veggies-bg off-white flex-100">
        <div className="ttg-container">
          <PageTitle title="Course Payment" />
          <Form className="ttg-form">
            <h1>Course Payment</h1>
            <h2>{courseTitle}</h2>
            <h5>
              ({courseCode}) - ${coursePrice}.00
            </h5>
            {validStates && <h4>{validStates}</h4>}
            <BlockUiFx blocking={isSubmitting} lowerZIndex={true}>
              <h5>Your Details</h5>

              <FormGroup>
                <LoqateAddressField
                  name={"postalAddress"}
                  placeholder={
                    this.props.match.params.courseId === 6 ||
                    this.props.match.params.courseId === 8
                      ? "Enter your postal address"
                      : "Enter your address"
                  }
                  form={form}
                  initialValue={fullAddress}
                  onInputChange={onInputChange}
                  onAddressSelect={this.onAutocompleteAddressSelect}
                  fields={this.props.fields}
                  onFieldError={onFieldError}
                  validate={(val) => validators.required("Address", val)}
                  ref={form.add}
                  clearAddress={clearAddress}
                  toggleManualEntry={toggleManualAddressEntry}
                />
              </FormGroup>

              <FormGroup>
                <div>
                  <Label for="email">Email Invoice To</Label>
                  <InputField
                    name="email"
                    ref={form.add}
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    onChange={onInputChange}
                    className="mxw-400"
                    onFieldError={onFieldError}
                    validate={(val) => emailValidator("Email Address", val)}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div className="mb-3">
                  <a
                    href="/UserFiles/files/Student-Handbook-2024.11.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ttg-btn my-3"
                  >
                    View the Student Handbook
                  </a>
                </div>

                <CheckboxField
                  ref={form.add}
                  validate={(val) =>
                    !val
                      ? "Please tick the student handbook acknowledgement."
                      : false
                  }
                  name="understandTerms"
                  label="I declare that I have read and understood Train to Gain Pty Ltd T/A Food Safety Education's Student Handbook before course commencement (please tick this to continue)"
                  value={understandTerms}
                  onFieldError={onFieldError}
                  onChange={onInputChange}
                />
              </FormGroup>
              <h5 className="mt-5">Payment Information</h5>

              {hasPromoCode && (
                <FormGroup>
                  <div className="mb-2">
                    <Label for="promoCode">Voucher Code</Label>
                    <InputField
                      name="promoCode"
                      placeholder="Voucher Code"
                      ref={form.add}
                      type="text"
                      value={promoCode}
                      onChange={onInputChange}
                      className="mxw-400"
                      onFieldError={onFieldError}
                      validate={(val) =>
                        validators.required("Voucher Code", val)
                      }
                      style={{ maxWidth: "250px" }}
                    />
                  </div>

                  <ButtonFx
                    type="submit"
                    className="ttg-btn"
                    isLoading={isSubmitting}
                    onClick={() =>
                      form.validate({
                        validFn: () => {
                          onSubmitEnrolment();
                        },
                        invalidFn: () => console.log(form.getFieldErrors()),
                      })
                    }
                  >
                    Apply
                  </ButtonFx>
                  {"  "}
                  <Button
                    className="ttg-btn ml-1"
                    onClick={togglePromoCodeEntry}
                  >
                    Cancel
                  </Button>
                </FormGroup>
              )}

              {showDiscountCodeForm && !isDiscountCodeApplied && (
                <FormGroup>
                  <div className="mb-2">
                    <Label for="discountCode">Promo Code</Label>
                    <InputField
                      name="discountCode"
                      placeholder="Promo Code"
                      ref={form.add}
                      type="text"
                      value={discountCode}
                      onChange={onInputChange}
                      className="mxw-400"
                      onFieldError={onFieldError}
                      validate={(val) => validators.required("Promo Code", val)}
                      style={{ maxWidth: "250px" }}
                    />
                  </div>

                  <ButtonFx
                    type="submit"
                    className="ttg-btn mr-1"
                    isLoading={isApplyingDiscountCode}
                    onClick={() => applyDiscountCode()}
                  >
                    Apply
                  </ButtonFx>
                  <Button
                    disabled={isApplyingDiscountCode}
                    className="ttg-btn"
                    onClick={toggleDiscountCodeForm}
                  >
                    Cancel
                  </Button>
                </FormGroup>
              )}

              <>
                {!hasPromoCode &&
                  !showDiscountCodeForm &&
                  !isDiscountCodeApplied && (
                    <div className="mb-2">
                      <button
                        className="ttg-btn sml-ttg-btn"
                        onClick={(event) => {
                          event.preventDefault();
                          togglePromoCodeEntry();
                        }}
                      >
                        I Have a Voucher Code
                      </button>
                      {/* <button
                        className="ttg-btn sml-ttg-btn ml-2"
                        onClick={(event) => {
                          event.preventDefault();
                          toggleDiscountCodeForm();
                        }}
                      >
                        I Have a Promo Code
                      </button> */}
                    </div>
                  )}

                {!hasPromoCode && !showDiscountCodeForm && (
                  <span>
                    <FormGroup>
                      <div className="mt-4">
                        <Label>Credit Card Details</Label>
                      </div>
                      <PaymentInputs
                        onInputChange={onInputChange}
                        onFieldError={onFieldError}
                        cardName={cardName}
                        form={form}
                        cardNumber={cardNumber}
                        cardExpiryDate={cardExpiryDate}
                        cardCvc={cardCvc}
                        onChange={onCreditCardChange}
                      />
                    </FormGroup>
                    <h2 className="mt-2">
                      <div
                        style={
                          isDiscountCodeApplied
                            ? { textDecoration: "line-through" }
                            : {}
                        }
                        className={isDiscountCodeApplied ? "text-muted" : ""}
                      >
                        {currencyFormatter.format(coursePrice, {
                          locale: "en-AU",
                        })}
                      </div>
                      {isDiscountCodeApplied && (
                        <div>
                          {currencyFormatter.format(discountedPrice, {
                            locale: "en-AU",
                          })}
                        </div>
                      )}
                    </h2>
                    <FormGroup>
                      <ButtonFx
                        type="submit"
                        className="ttg-btn"
                        isLoading={isSubmitting}
                        onClick={() => {
                          if (isNil(cardNumber) || !cardNumber) {
                            onInputChange({
                              name: "cardNumber",
                              value: "",
                              error: "Card number is required",
                            });
                            onFieldError("Card number is required.");
                          }

                          if (isNil(cardExpiryDate) || !cardExpiryDate) {
                            onInputChange({
                              name: "cardExpiryDate",
                              value: "",
                              error: "Card expiry date is required.",
                            });
                            onFieldError("Card expiry date is required.");
                          }

                          if (isNil(cardCvc) || !cardCvc) {
                            onInputChange({
                              name: "cardCvc",
                              value: "",
                              error: "Card CVC is required.",
                            });
                            onFieldError("Card CVC is required.");
                          }
                          form.validate({
                            validFn: () => {
                              onSubmitEnrolment();
                            },
                            invalidFn: () => console.log(form.getFieldErrors()),
                          });
                        }}
                      >
                        Pay Now
                      </ButtonFx>
                    </FormGroup>
                  </span>
                )}

                <Modal
                  isOpen={showConfirmNamePopup}
                  toggle={toggleShowConfirmNamePopup}
                >
                  <ModalHeader toggle={toggleShowConfirmNamePopup}>
                    Please Confirm Your Name
                  </ModalHeader>
                  <ModalBody>
                    Before you get started, we just wanted to{" "}
                    <strong>
                      confirm your name is {firstName}
                      {firstName === lastName ? "" : ` ${lastName}`}
                    </strong>
                    .
                    <br />
                    <br />
                    If this isn&rsquo;t your name, please click the 'No,
                    That&rsquo;s Not Me' button.
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={toggleShowConfirmNamePopup}
                    >
                      Yes, I am {firstName}{" "}
                      {firstName === lastName ? "" : ` ${lastName}`}
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={() =>
                        onLogoutAndRegister(this.props.match.params.courseId)
                      }
                    >
                      No, That&rsquo;s Not Me
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
              <KeyboardEventHandler
                handleKeys={["enter"]}
                onKeyEvent={(key, e) =>
                  form.validate({
                    validFn: () => {
                      onSubmitEnrolment();
                    },
                    invalidFn: () => console.log(form.getFieldErrors()),
                  })
                }
              />
            </BlockUiFx>
          </Form>
        </div>
      </ContentSection>
    );
  }
}

export default CourseEnrolmentStep1;
