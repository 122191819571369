/**
 * Action Creator definitions
 */
import { addNotification } from "../../../redux/system/system-action-creators";
import ApiService from "../services/api-service";
import CourseQuizService from "../services/course-quiz-service";
import UserCourseService from "./../../../services/user-course-service";
import { ActionTypes } from "./action-types";

// ----------------------------
//  Actions
// ----------------------------

export const onLoadSection = (courseContentToken, history) => async (
  dispatch
) => {
  dispatch(loadSectionRequest());

  const userCourseService = UserCourseService();

  await userCourseService
    .getCourseDetailsAndStatus(courseContentToken)
    .then(async (resp) => {
      const { id, status } = resp.data;

      // Redirect logic see #feature-354
      if (
        status.hasCompleted &&
        !status.thirdPartyFormCompleted &&
        !status.courseExpired
      ) {
        history.push(`/third-party-form/${courseContentToken}`);
      } else if (status.courseCompleted) {
        history.push(`/course-completed/${courseContentToken}`);
      } else if (!status.redirectToQuiz && !status.courseExpired) {
        history.push(`/course-content/${courseContentToken}`);
        // } else if (status.courseCompleted || status.surveyCompleted) {
        //   history.push(`/your-courses`);
      } else if (!status.courseExpired) {
        let data = await loadSection(
          id,
          status.currentSection,
          status.currentQuestion,
          courseContentToken
        );

        const {
          courseId,
          courseTitle,
          sectionCount,
          sectionId,
          questions,
          currentQuestion,
          courseGuideUrl,
        } = data;

        dispatch(
          loadSectionSuccess(
            courseId,
            courseTitle,
            sectionCount,
            sectionId,
            questions,
            currentQuestion,
            courseGuideUrl
          )
        );
      } else {
        dispatch(
          addNotification(
            "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
            "error"
          )
        );
        history.push("/your-courses/");
      }
    })
    .catch((err) => {
      dispatch(
        addNotification(
          "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
          "error"
        )
      );
      history.push("/your-courses/");
    });
};

const loadSection = async (
  courseId,
  sectionId,
  currentQuestion,
  courseContentToken
) => {
  const FINAL_QUIZ_SECTION = 99;
  const apiService = ApiService();

  const courseDetailsAndQuestions = await apiService.getCourseDetailsAndQuestions(
    courseContentToken
  );

  let selectedQuestions = courseDetailsAndQuestions.questions.filter((x) => {
    return x.courseId === courseId && x.sectionId === sectionId;
  });

  let hasFinalQuizSections = courseDetailsAndQuestions.questions.find(
    (x) => x.sectionId === FINAL_QUIZ_SECTION
  );

  return {
    courseId,
    courseTitle: courseDetailsAndQuestions.courseTitle,
    sectionCount:
      courseDetailsAndQuestions.sectionCount - (hasFinalQuizSections ? 1 : 0),
    sectionId,
    questions: selectedQuestions,
    currentQuestion,
    courseGuideUrl: `/Print-Course-Content-Guide/${courseContentToken}`,
  };
};

const loadSectionRequest = () => ({
  type: ActionTypes.COURSE_QUIZ_LOAD_SECTION_REQUEST,
});

const loadSectionSuccess = (
  courseId,
  courseName,
  totalSections,
  currentSection,
  questions,
  currentQuestion,
  courseGuideUrl
) => ({
  type: ActionTypes.COURSE_QUIZ_LOAD_SECTION_SUCCESS,
  payload: {
    courseId,
    courseName,
    totalSections,
    currentSection,
    questions,
    currentQuestion,
    courseGuideUrl,
  },
});

const loadSectionFailure = () => ({
  type: ActionTypes.COURSE_QUIZ_LOAD_SECTION_FAILURE,
});

export const onSubmitAnswer = (
  courseContentToken,
  courseId,
  currentQuestion,
  selectedIndex,
  selectedDropdownIndexes,
  history
) => {
  return (dispatch) => {
    dispatch(submitAnswerRequest());

    // QuestionType supported: DropDown = 0, MultipleChoice = 1, TrueOrFalse = 2
    let isCorrect = false;

    if (currentQuestion.questionType === 0) {
      // Dropdown question
      isCorrect = currentQuestion.options.every((o) => {
        let key = o.title.replace(/\s/g, "");
        return o.correctIndex === selectedDropdownIndexes[key];
      });
    } else {
      // Radio button list
      isCorrect = currentQuestion.options[0].correctIndex === selectedIndex;
    }

    if (!isCorrect) {
      dispatch(submitAnswerFailure());
      return;
    }

    const { questionId, sectionId, questionNo } = currentQuestion;
    let payload = { questionId, sectionId, questionNo };

    const courseService = CourseQuizService();
    courseService
      .updateQuizAnswer(courseContentToken, payload)
      .then((resp) => {
        handleSubmitAnswerRedirects(
          courseId,
          courseContentToken,
          resp,
          dispatch,
          history
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          addNotification("Unable to save quiz answer to server.", "error")
        );
      });
  };
};

const handleSubmitAnswerRedirects = async (
  courseId,
  courseContentToken,
  resp,
  dispatch,
  history
) => {
  const {
    courseCompleted,
    redirectToQuiz,
    currentSection,
    currentQuestion,
    surveyCompleted,
  } = resp;

  if (surveyCompleted) {
    history.push(`/course-survey/${courseContentToken}`);
  } else if (!redirectToQuiz) {
    history.push(`/course-content/${courseContentToken}`);
  } else if (resp.currentSection === 99) {
    // Reload the dataset for section 99 (Final quiz).
    let data = await loadSection(
      courseId,
      currentSection,
      currentQuestion,
      courseContentToken
    );

    dispatch(
      loadSectionSuccess(
        data.courseId,
        data.courseTitle,
        data.sectionCount,
        data.sectionId,
        data.questions,
        data.currentQuestion,
        data.courseGuideUrl
      )
    );
  }

  dispatch(submitAnswerSuccess(resp));
};

const submitAnswerRequest = () => ({
  type: ActionTypes.COURSE_QUIZ_SUBMIT_ANSWER_REQUEST,
});

const submitAnswerSuccess = (data) => ({
  type: ActionTypes.COURSE_QUIZ_SUBMIT_ANSWER_SUCCESS,
  payload: data,
});

const submitAnswerFailure = () => ({
  type: ActionTypes.COURSE_QUIZ_SUBMIT_ANSWER_FAILURE,
});

export const onQuizInputChange = (selectedIndex) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.COURSE_QUIZ_CHANGE_SELECTED_ANSWER,
      payload: {
        selectedIndex: selectedIndex,
      },
    });
  };
};

export const onQuizDropdownInputChange = (selectedObject) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.COURSE_QUIZ_DROPDOWN_CHANGE_SELECTED_ANSWER,
      payload: {
        selectedObject: selectedObject,
      },
    });
  };
};

export const onRedirectToCourseContent = (courseContentToken, history) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.COURSE_QUIZ_ALLOW_REDIRECT_TO_COURSE_CONTENT,
    });

    history.push(`/course-content/${courseContentToken}`);
  };
};
