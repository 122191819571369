import React from "react";
import svg1 from "../../assets/svgs/infographic-1.svg";
import svg2 from "../../assets/svgs/infographic-2.svg";
import svg3 from "../../assets/svgs/infographic-3.svg";
import svg4 from "../../assets/svgs/infographic-4.svg";
import svg5 from "../../assets/svgs/infographic-5.svg";
import "./infographic.scss";

const Infographic = () => {
  const items = [
    {
      svg: svg1,
      imageClass: "infographic-1",
      value: "100% Online Training",
    },
    {
      svg: svg2,
      imageClass: "infographic-2",
      value: "Nationally Accredited Training",
    },
    {
      svg: svg3,
      imageClass: "infographic-3",
      value: "Standard 3.2.2A Compliant",
    },
    {
      svg: svg4,
      imageClass: "infographic-4",
      value: "Print Out Your Certificate Today",
    },
    {
      svg: svg5,
      imageClass: "infographic-5",
      value: "2-6 Hours Average Course Duration",
    },
  ];

  const getItem = (item, index) => {
    return (
      <div key={index} className="infographic-item">
        {/* <div className={item.imageClass}>{item.svg}</div> */}
        <img src={item.svg} className={item.imageClass} alt="" />
        <span>{item.value}</span>
      </div>
    );
  };

  return (
    <div className="infographic">
      {items.map((item, index) => getItem(item, index))}
    </div>
  );
};

export default Infographic;
