import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionCreators from "./redux/action-creators";
import CourseCompleted from "./Course-Completed";
import { selectCourseCompleted } from "./redux/reducer";

function mapStateToProps(state) {
  const stateData = selectCourseCompleted(state);

  return {
    hasBeenCompleted: stateData.hasBeenCompleted,
    thirdPartyFormCompleted: stateData.thirdPartyFormCompleted,
    courseTitle: stateData.courseTitle,
    courseId: stateData.courseId,
    isLoading: stateData.isLoading
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history,
  courseContentToken: ownProps.match.params.id
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  loadData: () => {
    dispatchProps.dispatch(
      actionCreators.loadData(
        dispatchProps.courseContentToken,
        dispatchProps.history
      )
    );
  },
  onRedirectToThirdParty: () => {
    dispatchProps.history.push(
      `/third-party-form/${dispatchProps.courseContentToken}`
    );
  }
});

const CourseCompletedContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(CourseCompleted)
);

export default CourseCompletedContainer;
