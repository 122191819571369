import { connect } from "react-redux";
import ContactBox from "./Contact-Box";
import { selectContactBox } from "./redux/reducer";
import { onSubmit } from "./redux/action-creators";
import * as actionCreators from "./redux/action-creators";
import * as sysActionCreators from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const contactBox = selectContactBox(state);

  return {
    contactBox: contactBox,
    fields: contactBox.fields,
    fieldErrors: contactBox.fieldErrors
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: error =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    if (validate(stateProps)) return;
    dispatchProps.dispatch(onSubmit(stateProps.contactBox.fields));
  },
  validate: () => {
    return validate(stateProps);
  }
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

  if (!fields.name) return true;
  if (!fields.email) return true;
  if (!fields.message) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const ContactBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(ContactBox);

export default ContactBoxContainer;
