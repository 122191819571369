/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import UserCourseService from "./../../../services/user-course-service";
import ApiService from "../services/api-service";

// ----------------------------
//  Actions
// ----------------------------

export const onLoadSection = (courseContentToken, allowRedirect, history) => {
  return async (dispatch) => {
    dispatch(loadSectionRequest());

    const userCourseService = UserCourseService();

    await userCourseService
      .getCourseDetailsAndStatus(courseContentToken)
      .then(async (resp) => {
        const { id, status } = resp.data;
        // Redirect logic see #feature-354 and as per Adam's page redirect notes
        if (
          status.hasCompleted &&
          !status.thirdPartyFormCompleted &&
          !status.courseExpired
        ) {
          history.push(`/third-party-form/${courseContentToken}`);
        } else if (
          status.redirectToQuiz &&
          !allowRedirect &&
          !status.courseExpired
        ) {
          history.push(`/course-quiz/${courseContentToken}`);
        } else if (status.hasCompleted) {
          history.push(`/course-completed/${courseContentToken}`);
        } else if (status.isExternalLearning) {
          history.push(`/elearning/${courseContentToken}`);
        } else if (!status.courseExpired) {
          await loadSection(
            id,
            status.currentSection,
            history,
            dispatch,
            courseContentToken
          );
        } else {
          dispatch(
            addNotification(
              "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
              "error"
            )
          );
          history.push("/your-courses/");
        }
      })
      .catch((err) => {
        dispatch(
          addNotification(
            "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
            "error"
          )
        );
        history.push("/your-courses/");
      });
  };
};

const loadSection = async (
  courseId,
  sectionId,
  history,
  dispatch,
  userCourseContentToken
) => {
  const apiService = ApiService();

  try {
    const courseDetailsAndContents = await apiService.getCourseDetailsAndContents(
      userCourseContentToken
    );

    const selectedSectionId = sectionId === 99 ? 1 : sectionId;
    const selectedSection =
      courseDetailsAndContents.sections?.find(
        (x) => x.sectionId === selectedSectionId
      ) ?? null;

    const numberOfSections = courseDetailsAndContents.sections?.length ?? 0;

    dispatch(
      loadSectionSuccess(
        courseId,
        courseDetailsAndContents.courseTitle,
        courseDetailsAndContents.sections,
        numberOfSections,
        sectionId,
        0,
        selectedSection.content
      )
    );
  } catch (ex) {
    dispatch(loadSectionFailure());
    dispatch(
      addNotification(
        "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
        "error"
      )
    );
    history.push("/your-courses/");
  }
};

const loadSectionRequest = () => ({
  type: ActionTypes.COURSE_CONTENT_LOAD_SECTION_REQUEST,
});

const loadSectionSuccess = (
  courseId,
  courseName,
  sections,
  totalSections,
  currentSection,
  currentPageIndex,
  content
) => ({
  type: ActionTypes.COURSE_CONTENT_LOAD_SECTION_SUCCESS,
  payload: {
    courseId: courseId,
    courseName: courseName,
    sections: sections,
    totalSections: totalSections,
    currentSection: currentSection,
    currentPageIndex: currentPageIndex,
    content: content,
  },
});

const loadSectionFailure = () => ({
  type: ActionTypes.COURSE_CONTENT_LOAD_SECTION_FAILURE,
});

export const onNextPage = () => ({
  type: ActionTypes.COURSE_CONTENT_TOGGLE_NEXT_PAGE,
});

export const onPreviousPage = () => ({
  type: ActionTypes.COURSE_CONTENT_TOGGLE_PREVIOUS_PAGE,
});

export const onLoadQuiz = (courseContentToken, history) => {
  return (dispatch) => {
    dispatch(loadQuizRequest());

    const userCourseService = UserCourseService();

    userCourseService
      .updateRedirectToQuiz(courseContentToken)
      .then((resp) => {
        dispatch(loadQuizSuccess());
        history.push(`/course-quiz/${courseContentToken}/`);
      })
      .catch((err) => {
        dispatch(loadQuizFailure(err));
        dispatch(
          addNotification("Sorry, we couldn't find that quiz.", "error")
        );
      });
  };
};

const loadQuizRequest = () => ({
  type: ActionTypes.COURSE_CONTENT_LOAD_QUIZ_REQUEST,
});

const loadQuizSuccess = () => ({
  type: ActionTypes.COURSE_CONTENT_LOAD_QUIZ_SUCCESS,
});

const loadQuizFailure = (error) => ({
  type: ActionTypes.COURSE_CONTENT_LOAD_QUIZ_FAILURE,
  payload: error,
});
