export const ActionTypes = {
  ADMIN_USERS_ONLOAD_REQUEST: "ADMIN_USERS_ONLOAD_REQUEST",
  ADMIN_USERS_ONLOAD_SUCCESS: "ADMIN_USERS_ONLOAD_SUCCESS",
  ADMIN_USERS_ONLOAD_FAILURE: "ADMIN_USERS_ONLOAD_FAILURE",
  ADMIN_USERS_FILTER_FORM_INPUT_CHANGE: "ADMIN_USERS_FILTER_FORM_INPUT_CHANGE",
  ADMIN_USERS_DELETE_REQUEST: "ADMIN_USERS_DELETE_REQUEST",
  ADMIN_USERS_DELETE_SUCCESS: "ADMIN_USERS_DELETE_SUCCESS",
  ADMIN_USERS_DELETE_FAILURE: "ADMIN_USERS_DELETE_FAILURE",
  ADMIN_USERS_EDIT_REQUEST: "ADMIN_USERS_EDIT_REQUEST",
  ADMIN_USERS_EDIT_SUCCESS: "ADMIN_USERS_EDIT_SUCCESS",
  ADMIN_USERS_EDIT_FAILURE: "ADMIN_USERS_EDIT_FAILURE",
  ADMIN_USERS_RESET: "ADMIN_USERS_RESET",
  ADMIN_USERS_DATATABLE_KEEP_SETTINGS: "ADMIN_USERS_DATATABLE_KEEP_SETTINGS",
  ADMIN_USERS_DATATABLE_CLEAR_SEARCHBOX:
    "ADMIN_USERS_DATATABLE_CLEAR_SEARCHBOX",
};
