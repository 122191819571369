Object.defineProperty(exports, "__esModule", { value: true });

function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = _interopDefault(require("react"));

var jcb = React.createElement(
  "g",
  {
    fill: "none"
  },
  React.createElement("path", {
    d:
      "m.20535714 16h4.51785715c1.0278125 0 2.25892857-1.1946667 2.25892857-2.1333333v-13.8666667h-4.51785715c-1.0278125 0-2.25892857 1.19466667-2.25892857 3.2z",
    fill: "#047ab1"
  }),
  React.createElement("path", {
    d:
      "m2.76924107 10.816c-.86733559.0001606-1.73039558-.1147397-2.56388393-.3413333v-1.17333337c.64678874.37770431 1.38610045.59084099 2.14598215.61866667.8696875 0 1.35535714-.576 1.35535714-1.36533333v-3.22133334h2.14598214v3.22133334c0 1.25866666-.70026786 2.26133333-3.0834375 2.26133333z",
    fill: "#fff"
  }),
  React.createElement("path", {
    d:
      "m8.11160714 16h4.51785716c1.0278125 0 2.2589286-1.1946667 2.2589286-2.1333333v-13.8666667h-4.5178572c-1.02781249 0-2.25892856 1.19466667-2.25892856 3.2z",
    fill: "#d42d06"
  }),
  React.createElement("path", {
    d:
      "m8.11160714 6.08c.65508929-.59733333 1.78455357-.97066667 3.61428576-.88533333.9939285.04266666 2.0330357.32 2.0330357.32v1.184c-.5943231-.3394747-1.2623758-.54734656-1.9539732-.608-1.3892411-.11733334-2.23633933.61866666-2.23633933 1.90933333s.84709823 2.0266667 2.23633933 1.92c.6920185-.06606555 1.3596342-.27744592 1.9539732-.61866667v1.17333337s-1.0391072.288-2.0330357.3306666c-1.82973219.0853334-2.95919647-.288-3.61428576-.8853333z",
    fill: "#fff"
  }),
  React.createElement("path", {
    d:
      "m16.0178571 16h4.5178572c1.0278125 0 2.2589286-1.1946667 2.2589286-2.1333333v-13.8666667h-4.5178572c-1.0278125 0-2.2589286 1.19466667-2.2589286 3.2z",
    fill: "#67b637"
  }),
  React.createElement("path", {
    d:
      "m21.6651786 9.28c0 .8533333-.7002679 1.3866667-1.6377232 1.3866667h-4.0095983v-5.33333337h3.6481697l.2597768.01066667c.8245089.04266667 1.4344196.50133333 1.4344196 1.29066667 0 .61866666-.4179018 1.152-1.1746428 1.28v.032c.8358035.05333333 1.4795982.55466666 1.4795982 1.33333333zm-2.880134-3.104c-.0486104-.00686658-.0976798-.01043129-.1468303-.01066667h-1.3553572v1.344h1.5021875c.2823661-.064.5195536-.30933333.5195536-.672 0-.36266666-.2371875-.608-.5195536-.66133333zm.1694197 2.176c-.059755-.00886168-.1202559-.01243275-.1807143-.01066667h-1.4908929v1.46133334h1.4908929l.1807143-.02133334c.2823661-.064.5195536-.34133333.5195536-.71466666 0-.37333334-.2258929-.64-.5195536-.71466667z",
    fill: "#fff"
  })
);

exports.default = jcb;
