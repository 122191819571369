import React from "react";
import ContentSection from "components/Common/content-section";
import SelectACourse from "features/select-a-course/select-a-course";
import WhyTrainWithUsPoints from "features/why-train-with-us-points/why-train-with-us-points";
import "./certificates.scss";
import PageTagging from "components/Common/page-tagging/page-tagging";

const Certificates = () => {
  return (
    <>
      <PageTagging title="Online Food Safety Training | Food Safety Education" />
      <ContentSection bgClass="veggies-bg off-white">
        <div className="certificates">
          <SelectACourse />
        </div>
      </ContentSection>
      <ContentSection bgClass="food-bowl">
        <div className="certificates-why-train-with-us">
          <h1>So Why Train With Us?</h1>
          <WhyTrainWithUsPoints />
        </div>
      </ContentSection>
    </>
  );
};

export default Certificates;
