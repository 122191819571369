/**
 * Forgot Password API service
 */
import HttpClient from "../../coreLib/http/httpClient";

const ForgotPasswordService = () => {
  let httpClient = HttpClient();

  const requestResetPassword = email =>
    httpClient.patch(`/v1/accounts/password/reset/request`, {
      username: email
    });

  return {
    requestResetPassword
  };
};

export default ForgotPasswordService;
