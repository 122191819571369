/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  AVETMISS_AUTHORISE_AVS_LOAD_FORM_REQUEST:
    "AVETMISS_AUTHORISE_AVS_LOAD_FORM_REQUEST",
  AVETMISS_AUTHORISE_AVS_LOAD_FORM_SUCCESS:
    "AVETMISS_AUTHORISE_AVS_LOAD_FORM_SUCCESS",
  AVETMISS_AUTHORISE_AVS_LOAD_FORM_FAILURE:
    "AVETMISS_AUTHORISE_AVS_LOAD_FORM_FAILURE",
  AVETMISS_AUTHORISE_AVS_SUBMIT_REQUEST:
    "AVETMISS_AUTHORISE_AVS_SUBMIT_REQUEST",
  AVETMISS_AUTHORISE_AVS_SUBMIT_SUCCESS:
    "AVETMISS_AUTHORISE_AVS_SUBMIT_SUCCESS",
  AVETMISS_AUTHORISE_AVS_SUBMIT_FAILURE: "AVETMISS_AUTHORISE_AVS_SUBMIT_FAILURE"
};
