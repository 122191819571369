import React from "react";
import "./course-content-sections.scss";

const getContentSections = (currentSection, totalSections) => {
  const sections = Array.apply(1, { length: totalSections }).map(
    Number.call,
    Number
  );

  return sections.map((item, i) => (
    <div
      className={`section ${currentSection - 1 === item ? "current" : ""}`}
      key={i}
    >
      {item + 1}
    </div>
  ));
};

const CourseContentSections = ({ currentSection, totalSections }) => {
  return (
    <div className="course-content-sections">
      {getContentSections(currentSection, totalSections)}
    </div>
  );
};

export default CourseContentSections;
