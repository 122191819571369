import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";

class AuthoriseAvs extends Component {
  componentDidMount() {
    const { onLoadForm } = this.props;

    onLoadForm();
  }

  render() {
    const { isLoading, onSubmit } = this.props;
    const {
      userCode,
      deviceCode,
      verificationUrl,
      errorMessage,
    } = this.props.authoriseAvs;

    return (
      <>
        <PageTitle title="Avetmiss Validation Service | Authorise" />
        <ContentSection bgColor="grey-shadow">
          <h1>Avetmiss Validation Service | Authorise</h1>
          <h4>
            The security token this website uses to communicate with the
            Avetmiss Validation Service has expired.
          </h4>
        </ContentSection>
        <ContentSection>
          <p>
            Please click the link below, and enter the code listed below. Once
            you have authorised the website, please click the 'Verify Access'
            button below to verify this website can access the Avetmiss
            Validation Service. Please note, you will need to be logged into the
            Avetmiss Validation Service with an admin account before you click
            the link below.
          </p>
          <p>
            <a href={verificationUrl} target="_blank" rel="noopener noreferrer">
              {verificationUrl}
            </a>
          </p>
          <pre>{userCode}</pre>
          <ButtonFx
            type="submit"
            className="ttg-btn"
            isLoading={isLoading}
            onClick={() => onSubmit(deviceCode)}
          >
            Verify Access
          </ButtonFx>
        </ContentSection>
      </>
    );
  }
}

export default AuthoriseAvs;
