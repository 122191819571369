import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  isDownloading: false,
  hasResult: false,
  verificationResult: {
    verificationSuccessful: false,
    errorMessage: null,
    addressErrors: [],
  },
  year: "",
  period: "",
  version: "",
  collectionStartDate: "",
  collectionEndDate: "",
  state: "",
};

function AvetmissAddressValidationReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasResult: true,
        verificationResult: {
          ...state.verificationResult,
          verificationSuccessful: action.payload.verificationSuccessful,
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors,
          addressErrors: action.payload.addressErrors,
        },
        year: action.payload.year,
        period: action.payload.period,
        version: action.payload.version,
        collectionStartDate: action.payload.collectionStartDate,
        collectionEndDate: action.payload.collectionEndDate,
        state: action.payload.state,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST:
      return {
        ...state,
        isDownloading: true,
      };
    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS:
    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE:
      return {
        ...state,
        isDownloading: false,
      };

    case ActionTypes.AVETMISS_ADDRESS_VALIDATION_FORM_INPUT_CHANGE:
      let errors = [...state.verificationResult.addressErrors];

      let error = errors.find(
        (x) =>
          x.natFileNumber === action.payload.natFileNumber &&
          x.recordNumber === action.payload.recordNumber
      );

      error.fieldValue = action.payload.fieldValue;
      error.updated = true;

      let stateResult = Object.assign({}, state, {
        verificationResult: {
          ...state.verificationResult,
          addressErrors: [...errors],
        },
      });

      return stateResult;

    default:
      return state;
  }
}

export default AvetmissAddressValidationReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectAvetmissAddressValidation = (state) =>
  state.avetmissAddressValidation;
