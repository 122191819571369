/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  AVETMISS_REPORTING_LOAD_FORM_REQUEST: "AVETMISS_REPORTING_LOAD_FORM_REQUEST",
  AVETMISS_REPORTING_LOAD_FORM_SUCCESS: "AVETMISS_REPORTING_LOAD_FORM_SUCCESS",
  AVETMISS_REPORTING_LOAD_FORM_FAILURE: "AVETMISS_REPORTING_LOAD_FORM_FAILURE",
  AVETMISS_REPORTING_YEAR_CHANGE: "AVETMISS_REPORTING_YEAR_CHANGE",
  AVETMISS_REPORTING_PERIOD_CHANGE: "AVETMISS_REPORTING_PERIOD_CHANGE",
  AVETMISS_REPORTING_SUBMIT_REQUEST: "AVETMISS_REPORTING_SUBMIT_REQUEST",
  AVETMISS_REPORTING_SUBMIT_SUCCESS: "AVETMISS_REPORTING_SUBMIT_SUCCESS",
  AVETMISS_REPORTING_SUBMIT_FAILURE: "AVETMISS_REPORTING_SUBMIT_FAILURE",
  AVETMISS_REPORTING_FORM_INPUT_CHANGE: "AVETMISS_REPORTING_FORM_INPUT_CHANGE"
};
