export const ActionTypes = {
  UPDATE_PROFILE_FORM_INPUT_CHANGE: "UPDATE_PROFILE_FORM_INPUT_CHANGE",
  UPDATE_PROFILE_ON_SUBMIT: "UPDATE_PROFILE_ON_SUBMIT",
  UPDATE_PROFILE_ON_SUBMIT_SUCCESS: "UPDATE_PROFILE_ON_SUBMIT_SUCCESS",
  UPDATE_PROFILE_ON_SUBMIT_FAILURE: "UPDATE_PROFILE_ON_SUBMIT_FAILURE",

  UPDATE_PROFILE_ON_LOAD: "UPDATE_PROFILE_ON_LOAD",
  UPDATE_PROFILE_ON_LOAD_SUCCESS: "UPDATE_PROFILE_ON_LOAD_SUCCESS",
  UPDATE_PROFILE_ON_LOAD_FAILURE: "UPDATE_PROFILE_ON_LOAD_FAILURE"
};
