import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import ValidationError from "./components/validation-error";
import * as Selectors from "./redux/reducer";
import * as Actions from "./redux/action-creators";
import "./style.scss";

const AvetmissAddressValidation = () => {
  const addressValidationState = useSelector((state) =>
    Selectors.selectAvetmissAddressValidation(state)
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useState(() => {
    let hasNoAddressErrors = !addressValidationState.verificationResult
      .addressErrors?.length;

    if (hasNoAddressErrors) {
      history.push("/Admin/Avetmiss/Validation/");
    }
  }, []);

  const onSubmit = async (errors) => {
    dispatch(
      Actions.submitChanges(
        addressValidationState.collectionStartDate,
        addressValidationState.collectionEndDate,
        addressValidationState.state,
        addressValidationState.version,
        addressValidationState.year,
        addressValidationState.period,
        errors,
        history
      )
    );
  };

  const onInputChange = async ({ name, value, error }) => {
    dispatch(Actions.formInputChange(name, value, error));
  };

  return (
    <>
      <PageTitle title="Avetmiss Validation Service | Validation" />
      <ContentSection isFullWidth={true} bgColor="grey-shadow">
        <h1>Avetmiss Validation Service | Address</h1>
        <p>
          <strong>State identifiers</strong>
          <br />
          NSW: 01
          <br />
          VIC: 02
          <br />
          QLD: 03
          <br />
          SA: 04
          <br />
          WA: 05
          <br />
          TAS: 06
          <br />
          NT: 07
          <br />
          ACT: 08
        </p>
      </ContentSection>
      <ContentSection isFullWidth={true}>
        {!addressValidationState.verificationResult.verificationSuccessful && (
          <>
            <table className="avetmiss-validation">
              <tbody>
                {addressValidationState.verificationResult.addressErrors.map(
                  (error, i) => (
                    <ValidationError
                      key={i}
                      natFileNumber={error.natFileNumber}
                      fileId={error.fileId}
                      recordNumber={error.recordNumber}
                      errorMessage={error.errorMessage}
                      fieldValue={error.fieldValue}
                      onInputChange={onInputChange}
                    />
                  )
                )}
              </tbody>
            </table>
            <ButtonFx
              type="submit"
              className="ttg-btn mt-2"
              isLoading={addressValidationState.isLoading}
              onClick={() =>
                onSubmit(
                  addressValidationState.verificationResult.addressErrors
                )
              }
            >
              Save Changes
            </ButtonFx>
          </>
        )}
      </ContentSection>
    </>
  );
};

export default AvetmissAddressValidation;
