import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EditUserCourse from "./edit-user-course";
import * as actionCreators from "./redux/action-creators";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = state => state.adminEditCourse;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: (payload) => dispatch(actionCreators.onLoad(payload)),
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  updateCourse: (payload) => dispatch(actionCreators.updateCourse(payload)),
  deleteCourse: (payload) =>
    dispatch(actionCreators.deleteCourse(payload, ownProps.history)),
  toggleDeleteDialog: () => dispatch(actionCreators.toggleDeleteDialog()),
  toggleCourseTransferConfirmation: () =>
    dispatch(actionCreators.toggleCourseTransferConfirmation()),
  onCourseTransferConfirmation: (evt, userCourseId) => {
    dispatch(actionCreators.toggleCourseTransferConfirmation());

    if (evt.type !== "ok") return;

    dispatch(
      actionCreators.handleCourseTransfer(userCourseId, ownProps.history)
    );
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch,
  history: ownProps.history,
});

const EditUserCourseContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUserCourse)
);

export default EditUserCourseContainer;
