/**
 * Course Enrolment Step 2 Container
 * Adam Luck @solvable Jun 13, 2019
 */
import { connect } from "react-redux";
import { selectLoginData } from "../../../redux/system/login-reducer";
import * as SysActions from "../../../redux/system/system-action-creators";
import * as Actions from "./redux/action-creators";
import { selectEnrolment } from "../redux/enrolment-reducer";
import Step2Form from "./Step-2-Form";
import { selectStep2 } from "./redux/reducer";

const mapStateToProps = (state) => {
  const step2Data = selectStep2(state);
  const loginData = selectLoginData(state);
  const enrolmentData = selectEnrolment(state);

  return {
    fields: step2Data.fields,
    formValid: step2Data.formValid,
    isProcessing: step2Data.isProcessing,
    loginData: loginData,
    fieldErrors: step2Data.fieldErrors,
    courseDetails: enrolmentData.courseDetails,
    fieldVisibility: step2Data.fieldVisibility,
    addressEntry: step2Data.addressEntry,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadCourseDetails: (courseId) => {
    dispatch(Actions.onLoadCourseDetails(courseId));
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(Actions.step2FormInputChange(name, value, error));
  },
  onDateOfBirthChange: ({ name, value, error }) =>
    dispatch(Actions.updateDOBField(value)),
  toggleWhatIsUsi: () => {
    dispatch(Actions.toggleWhatIsUsi());
  },
  onFieldError: (error) =>
    dispatch(SysActions.addNotification(error, "warning")),
  clearAddress: () => {
    dispatch(Actions.clearFormAddress());
  },
  updateAutocompleteAddress: (address) => {
    dispatch(Actions.updateAutocompleteAddress(address));
  },
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  step2OnInit: (courseId) => {
    dispatchProps.dispatch(
      Actions.step2OnInit(courseId, dispatchProps.history)
    );
  },
  onSubmitEnrolment: () => {
    dispatchProps.dispatch(
      Actions.onSubmitEnrolmentStep2(
        stateProps.courseDetails.courseId,
        stateProps.fields,
        dispatchProps.history
      )
    );
  },
});

const Step2FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(Step2Form);

export default Step2FormContainer;
