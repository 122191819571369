import { connect } from "react-redux";
import * as actionCreators from "./redux/action-creators";
import CourseSurvey from "./Course-Survey";
import { addNotification } from "../../redux/system/system-action-creators";
import { selectSurvey } from "./redux/reducer";

function mapStateToProps(state) {
  const surveyStateData = selectSurvey(state);

  return {
    userCourseId: surveyStateData.userCourseId,
    surveyId: surveyStateData.surveyId,
    surveyQuestions: surveyStateData.surveyQuestions,
    aatItems: surveyStateData.yesNoLookups,
    rplItems: surveyStateData.yesNoLookups,
    fields: surveyStateData.fields,
    fieldErrors: surveyStateData.fieldErrors,

    isLoading: surveyStateData.isLoading
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFieldError: error => dispatch(addNotification(error, "Error")),
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  loadData: courseContentToken => {
    dispatchProps.dispatch(
      actionCreators.loadData(courseContentToken, dispatchProps.history)
    );
  },
  onSubmit: () => {
    if (validate(stateProps)) return;

    if (!stateProps.surveyId) {
      const createPayload = {
        userCourseId: stateProps.userCourseId,
        fields: stateProps.fields
      };

      dispatchProps.dispatch(
        actionCreators.createSurvey(
          createPayload,
          dispatchProps.history,
          dispatchProps.match.params.id
        )
      );
    } else {
      const updatePayload = {
        userCourseId: stateProps.userCourseId,
        fields: stateProps.fields
      };

      dispatchProps.dispatch(
        actionCreators.updateSurvey(
          stateProps.surveyId,
          updatePayload,
          dispatchProps.history,
          dispatchProps.match.params.id
        )
      );
    }
  },
  validate: () => {
    return validate(stateProps);
  }
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

  if (!fields.bestAspects) return true;
  if (!fields.needImprovement) return true;
  if (!fields.uat || fields.uat === "-1") return true;
  if (!fields.rpl || fields.rpl === "-1") return true;

  if (errMessages.length > 0) return true;

  return false;
};

const CourseSurveyContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(CourseSurvey);

export default CourseSurveyContainer;
