import React from "react";
import "./action-buttons-factory.scss";

const ActionButtonsFactory = eventHandler => {
  const getPreDefinedActionButtons = options => {
    if (!options)
      throw new Error("Invalid option for predefined action buttons.");

    switch (options.type) {
      default:
        return <span></span>;
    }
  };

  return {
    getPreDefinedActionButtons
  };
};

export default ActionButtonsFactory;
