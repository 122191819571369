import HttpClient from "../../../../coreLib/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const loadCourse = (payload) => {
    return httpClient.get(`v1/admin/users/course/${payload}`);
  };

  const updateCourse = (payload) => {
    let model = {
      ...payload,
      enrolmentStep2Completed: getValue(payload.enrolmentStep2Completed),
      enrolmentStep3Completed: getValue(payload.enrolmentStep3Completed),
      thirdPartyFormCompleted: getValue(payload.thirdPartyFormCompleted),
      surveyCompleted: getValue(payload.surveyCompleted),
      courseCompleted: getValue(payload.courseCompleted),
      redirectToQuiz: getValue(payload.redirectToQuiz),
    };
    return httpClient.put(
      `v1/admin/users/course/${payload.userCourseId}`,
      model
    );
  };

  const getValue = (itm) => (itm || itm === "on" ? true : false);

  const deleteCourse = (userCourseId) =>
    httpClient.delete(`v1/admin/users/course/${userCourseId}`);

  const transferUserCourse = (userCourseId) =>
    httpClient.post(`v1/admin/users/courses/${userCourseId}/reassignment`);

  return {
    loadCourse,
    deleteCourse,
    updateCourse,
    transferUserCourse,
  };
};

export default Service;
