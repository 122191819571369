import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";

export default class VouchersListDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dialogs, vouchers, toggleVouchersDialog } = this.props;
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={dialogs.showVouchersDialog}>
          <ModalHeader toggle={() => toggleVouchersDialog()}>
            Vouchers
          </ModalHeader>
          <ModalBody>
            <section>
              <Table striped>
                <thead>
                  <tr>
                    <th>Voucher Code</th>
                    <th>Course</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {vouchers.map((voucher, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <b>{voucher.voucherCode}</b>
                        </td>
                        <td>{voucher.course}</td>
                        <td>{voucher.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </section>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() => toggleVouchersDialog()}
              className="ttg-btn"
              isLoading={false}
            >
              Close
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
