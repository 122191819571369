import { ActionTypes } from "./action-types";
import ApiService from "../services/api-service";
import { addNotification } from "../../../../redux/system/system-action-creators";
import CourseService from "../../../../services/course-service";
import * as EnrolmentActionTypes from "../../redux/action-types";
import DateService from "../../../../services/date-service";

export const updateAutocompleteAddress = (data) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_2_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS,
      payload: data,
    });
  };
};

export const step2OnInit = (id, history) => {
  return (dispatch) => {
    dispatch(step2OnInitStarted());

    ApiService()
      .getStep2(id)
      .then((resp) => {
        dispatch(step2OnInitSucceeded(resp.data));

        const {
          enrolmentCompleted,
          redirectToStep3,
          courseId,
          userCourseId,
        } = resp.data;

        if (enrolmentCompleted) {
          history.push(`/Your-Courses/`);
          return false;
        }

        if (redirectToStep3) {
          history.push(`/course-enrolment/${userCourseId}/step-3/`);
          return false;
        }
        dispatch(onLoadCourseDetails(courseId));
        dispatch(validateStep2Func());
      })
      .catch((err) => {
        dispatch(step2OnInitFailed());

        history.push(`/Your-Courses/`);

        dispatch(
          addNotification(
            err.response.data.Message
              ? err.response.data.Message
              : "There was an issue loading the form.",
            "warning"
          )
        );
      });
  };
};

const step2OnInitStarted = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_2_INIT,
    });
  };
};

const step2OnInitSucceeded = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_2_INIT_SUCCEEDED,
      payload,
    });
  };
};

const step2OnInitFailed = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_2_INIT_FAILED,
    });
  };
};

// Async
export const onLoadCourseDetails = (courseId) => {
  return (dispatch) => {
    dispatch(onLoadCourseDetailsRequest());
    const courseService = CourseService();
    var course = courseService.getCourse(courseId);

    if (course != null) {
      dispatch(
        onLoadCourseDetailsSuccess(
          course.courseId,
          course.courseTitle,
          course.certificateCodesString,
          course.courseCost,
          course.validStates
        )
      );
    } else dispatch(onLoadCourseDetailsFailure());
  };
};

const onLoadCourseDetailsFailure = () => ({
  type: EnrolmentActionTypes.ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_FAILURE,
});

const onLoadCourseDetailsSuccess = (
  courseId,
  courseTitle,
  courseCode,
  coursePrice,
  validStates
) => ({
  type: EnrolmentActionTypes.ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_SUCCESS,
  payload: {
    courseId: courseId,
    courseTitle: courseTitle,
    courseCode: courseCode,
    coursePrice: coursePrice,
    validStates: validStates,
  },
});

const onLoadCourseDetailsRequest = () => ({
  type: EnrolmentActionTypes.ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_REQUEST,
});

export const step2FormInputChange = (name, value, error) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.STEP_2_FORM_INPUT_CHANGE,
      payload: {
        name: name,
        value: value,
        error: error,
      },
    });

    switch (name) {
      case "completedTertiary":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_TERTIARY,
          payload: value === "TRUE" ? true : false,
        });
        break;
      case "hasUsi":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_HAS_USI,
          payload: value === "TRUE" ? true : false,
        });
        break;
      case "disability":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_DISABILITY,
          payload: value === "TRUE" ? true : false,
        });
        break;
      case "bornInAustralia":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_COUNTRY,
          payload: value === "FALSE" ? true : false,
        });
        break;
      case "languageAtHome":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_OTHER_LANGUAGE,
          payload: value === "Other Language" ? true : false,
        });
        break;
      case "goodUnderstandingOfEnglish":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_UNDERSTANDING_OF_ENGLISH,
          payload: value === "FALSE" ? true : false,
        });
        break;
      case "understandFalseInfo":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_UNDERSTAND_FALSE_INFO,
          payload: value === "FALSE" ? true : false,
        });
        break;
      case "understandTimeToComplete":
        dispatch({
          type: ActionTypes.STEP_2_TOGGLE_UNDERSTAND_TIME_TO_COMPLETE,
          payload: value === "FALSE" ? true : false,
        });
        break;
      default:
        break;
    }
    dispatch(validateStep2Func());
  };
};

const validateStep2Func = () => {
  return (dispatch, getState) => {
    let fieldVisibility = getState().courseEnrolment.step2.fieldVisibility;
    let fields = getState().courseEnrolment.step2.fields;
    let fieldErrors = getState().courseEnrolment.step2.fieldErrors;
    let isFormValid = !validateStep2(fields, fieldVisibility, fieldErrors);
    dispatch({
      type: ActionTypes.STEP_2_FORM_VALID,
      payload: isFormValid,
    });
  };
};

const validateStep2 = (fields, fieldVisibility, fieldErrors) => {
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
  if (!fields.phone) return true;
  if (!fields.disability) return true;
  if (!fields.bornInAustralia) return true;
  if (!fields.languageAtHome) return true;
  if (!fields.goodUnderstandingOfEnglish) return true;
  if (!fields.understandTimeToComplete) return true;
  if (!fields.hasUsi) return true;
  if (!fields.completedTertiary) return true;
  if (!fields.streetNumber) return true;
  if (!fields.streetName) return true;
  if (!fields.city) return true;
  if (!fields.state) return true;
  if (!fields.gender) return true;
  if (!fields.postcode) return true;
  if (!fields.levelOfSchool) return true;
  if (!fields.stillEnrolledInSchool) return true;
  if (!fields.completedTertiary) return true;
  if (!fields.reasonForUndertakingCourse) return true;
  if (!fields.usiFirstName) return true;
  if (!fields.howDidYouHear) return true;
  if (!fields.usiLastName) return true;
  if (!fields.understandFalseInfo) return true;
  if (!fields.understandTimeToComplete) return true;
  if (!fields.aboriginalOrTorresStraitIslandOrigin) return true;
  if (!fields.goodUnderstandingOfEnglish) return true;
  if (fieldVisibility.showLevelOfEducation && !fields.levelOfEducation)
    return true;
  if (fieldVisibility.showOtherLanguage && !fields.languageAtHomeOther)
    return true;
  if (
    fieldVisibility.showGuardianAcknowledgment &&
    !fields.guardianAcknowledgment
  )
    return true;

  if (fieldVisibility.showCountry && !fields.country) {
    return true;
  }
  if (fieldVisibility.showHasUsi && !fields.usiNumber) {
    return true;
  }
  if (fieldVisibility.showDisability && !fields.disabilityType) {
    return true;
  }
  if (!fields.studentAcknowledgment) {
    return true;
  }
  if (errMessages.length > 0) return true;
  return false;
};

export const clearFormAddress = () => ({
  type: ActionTypes.STEP_2_CLEAR_ADDRESS,
});

export const updateDOBField = (data) => ({
  type: ActionTypes.STEP_2_UPDATE_DOB_FIELD,
  payload: data,
});

const onSubmitEnrolmentStep2Request = () => ({
  type: ActionTypes.STEP_2_SUBMIT_ENROLMENT_REQUEST,
});
const onSubmitEnrolmentStep2Success = () => ({
  type: ActionTypes.STEP_2_SUBMIT_ENROLMENT_SUCCESS,
});
const onSubmitEnrolmentStep2Failure = () => ({
  type: ActionTypes.STEP_2_SUBMIT_ENROLMENT_FAILURE,
});

// Async
export const onSubmitEnrolmentStep2 = (courseId, fields, history) => {
  const {
    phone,
    dateOfBirth,
    gender,
    streetNumber,
    streetName,
    city,
    state,
    postcode,
    levelOfSchool,
    stillEnrolledInSchool,
    howDidYouHear,
    usiMiddleName,
    studentAcknowledgment,
    guardianAcknowledgment,
    usiNumber,
    usiFirstName,
    usiLastName,
    reasonForUndertakingCourse,
    completedTertiary,
  } = fields;
  return (dispatch) => {
    if (!phone || phone === undefined || phone === "") {
      dispatch(addNotification("Telephone number is required.", "warning"));
      return;
    }
    if (!howDidYouHear || howDidYouHear === undefined || howDidYouHear === "") {
      dispatch(
        addNotification(
          "How did you hear about our company and courses is required.",
          "warning"
        )
      );
      return;
    }
    if (!dateOfBirth || dateOfBirth === undefined || dateOfBirth === "") {
      dispatch(addNotification("Date of birth is required.", "warning"));
      return;
    }
    if (!gender || gender === undefined || gender === "") {
      dispatch(addNotification("Gender is required.", "warning"));
      return;
    }
    if (!streetNumber || streetNumber === undefined || streetNumber === "") {
      dispatch(addNotification("Street number is required.", "warning"));
      return;
    }
    if (!streetName || streetName === undefined || streetName === "") {
      dispatch(addNotification("Street name is required.", "warning"));
      return;
    }
    if (!city || city === undefined || city === "") {
      dispatch(addNotification("City is required.", "warning"));
      return;
    }
    if (!gender || gender === undefined || gender === "") {
      dispatch(addNotification("Gender is required.", "warning"));
      return;
    }
    if (!state || state === undefined || state === "" || state === "-1") {
      dispatch(addNotification("State is required.", "warning"));
      return;
    }
    if (!postcode || postcode === undefined || postcode === "") {
      dispatch(addNotification("Post code is required.", "warning"));
      return;
    }

    if (!dateOfBirth || dateOfBirth === undefined || dateOfBirth === "") {
      dispatch(addNotification("Date of birth is required.", "warning"));
      return;
    }

    if (!levelOfSchool || levelOfSchool === undefined || levelOfSchool === "") {
      dispatch(addNotification("Level of school is required.", "warning"));
      return;
    }

    if (
      !stillEnrolledInSchool ||
      stillEnrolledInSchool === undefined ||
      stillEnrolledInSchool === ""
    ) {
      dispatch(
        addNotification(
          "Are you still enrolled in secondary or senior secondary education is required.",
          "warning"
        )
      );
      return;
    }

    if (!usiFirstName || usiFirstName === undefined || usiFirstName === "") {
      dispatch(addNotification("USI first name is required.", "warning"));
      return;
    }

    if (!usiLastName || usiLastName === undefined || usiLastName === "") {
      dispatch(addNotification("USI last name is required.", "warning"));
      return;
    }
    if (!usiNumber || usiNumber === undefined || usiNumber === "") {
      dispatch(addNotification("USI number is required.", "warning"));
      return;
    }
    if (
      !reasonForUndertakingCourse ||
      reasonForUndertakingCourse === undefined ||
      reasonForUndertakingCourse === ""
    ) {
      dispatch(
        addNotification("Reason for undertaking course is required.", "warning")
      );
      return;
    }
    if (
      !completedTertiary ||
      completedTertiary === undefined ||
      completedTertiary === ""
    ) {
      dispatch(addNotification("completed tertiary is required.", "warning"));
      return;
    }

    if (!studentAcknowledgment) {
      dispatch(
        addNotification("Please tick STUDENT acknowledgement", "warning")
      );
      return;
    }

    let age = getAge(dateOfBirth);

    if (!guardianAcknowledgment && age < 18) {
      dispatch(
        addNotification(
          "Please tick PARENT/GUARDIAN acknowledgement",
          "warning"
        )
      );
      return;
    }

    let dateService = DateService();

    fields.dateOfBirth = dateService.toIsoDate(fields.dateOfBirth);

    dispatch(onSubmitEnrolmentStep2Request());
    ApiService()
      .submitStep2(fields)
      .then((resp) => {
        dispatch(onSubmitEnrolmentStep2Success());
        dispatch(
          addNotification("Awesome, we’re nearly done. Last step!", "success")
        );
        history.push(`/course-enrolment/${fields.userCourseId}/step-3/`);
      })
      .catch((err) => {
        dispatch(onSubmitEnrolmentStep2Failure());
        dispatch(
          addNotification(
            err.response.data.Message
              ? err.response.data.Message
              : "There was an issue submitting the form",
            "warning"
          )
        );
      });
  };
};

// ----------------------------
//  Step 2 Actions
// ----------------------------

export const toggleWhatIsUsi = () => ({
  type: ActionTypes.STEP_2_TOGGLE_WHAT_IS_USI,
});

export const toggleGuardianAcknowledgment = (age) => ({
  type: ActionTypes.STEP_2_TOGGLE_GUARDIAN_ACKNOWLEDGEMENT,
  payload: age < 18 ? true : false,
});

const getAge = (DOB) => {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1;
  }
  return age;
};
