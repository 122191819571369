/**
 * Course Content Reducer
 */
import { ActionTypes } from "./action-types";

const FINAL_QUIZ_SECTION = 99;

const initialState = {
  courseId: 0,
  courseName: "",
  totalSections: 0,

  currentSection: 0,
  questions: [],
  currentQuestionIndex: 0,
  totalQuestions: 0,
  currentProgress: 0,
  currentQuestion: {},
  showIncorrectAnswer: false,
  selectedIndex: -1,
  selectedDropdownIndexes: {},
  courseGuideUrl: "#",

  isLoading: false,
  isFinalQuiz: false
};

function CourseQuizReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.COURSE_QUIZ_LOAD_SECTION_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.COURSE_QUIZ_LOAD_SECTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courseId: action.payload.courseId,
        courseName: action.payload.courseName,
        totalSections: action.payload.totalSections,
        currentSection: action.payload.currentSection,
        questions: action.payload.questions,
        currentQuestionIndex: action.payload.currentQuestion - 1,
        currentQuestion:
          action.payload.questions[action.payload.currentQuestion - 1],
        totalQuestions: action.payload.questions.length,
        currentProgress: Math.ceil(
          ((action.payload.currentQuestion - 1) /
            action.payload.questions.length) *
            100
        ),
        selectedIndex: -1,
        courseGuideUrl: action.payload.courseGuideUrl,
        isFinalQuiz: action.payload.currentSection === FINAL_QUIZ_SECTION
      };

    case ActionTypes.COURSE_QUIZ_LOAD_SECTION_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.COURSE_QUIZ_SUBMIT_ANSWER_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.COURSE_QUIZ_SUBMIT_ANSWER_SUCCESS:
      return {
        ...state,
        currentQuestion: state.questions[action.payload.currentQuestion - 1],
        currentProgress: Math.ceil(
          ((action.payload.currentQuestion - 1) / state.questions.length) * 100
        ),
        currentQuestionIndex: action.payload.currentQuestion - 1,
        showIncorrectAnswer: false,
        selectedIndex: -1,
        selectedDropdownIndexes: {},
        isLoading: false
      };

    case ActionTypes.COURSE_QUIZ_SUBMIT_ANSWER_FAILURE:
      return {
        ...state,
        showIncorrectAnswer: true,
        isLoading: false
      };

    case ActionTypes.COURSE_QUIZ_CHANGE_SELECTED_ANSWER:
      return {
        ...state,
        selectedIndex: action.payload.selectedIndex,
        showIncorrectAnswer: false
      };

    case ActionTypes.COURSE_QUIZ_DROPDOWN_CHANGE_SELECTED_ANSWER:
      const { title, selectedIndex } = action.payload.selectedObject;
      let key = title.replace(/\s/g, "");

      return {
        ...state,
        selectedDropdownIndexes: {
          ...state.selectedDropdownIndexes,
          [key]: selectedIndex
        }
      };

    default:
      return state;
  }
}

export default CourseQuizReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectCourseQuiz = state => state.courseQuiz;
