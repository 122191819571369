import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import Datatable from "./datatable/datatable.container";
import "../Start/corporate-start.scss";

class CorporateStart extends Component {
  columns = [
    {
      Header: "Course",
      accessor: "course",
      searchable: true,
    },
    {
      Header: "Redeemed By",
      accessor: "redeemedBy",
      searchable: true,
      maxWidth: 200,
    },
    {
      Header: "Voucher",
      accessor: "voucherCode",
      searchable: true,
      maxWidth: 90,
    },
    {
      Header: "Status",
      accessor: "status",
      searchable: true,
      maxWidth: 150,
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      width: 90,
    },
  ];

  data = [];
  render() {
    return (
      <>
        <PageTitle title="Business Vouchers" />
        <ContentSection bgColor="grey-shadow" padding={4}>
          <h1>Business Vouchers</h1>
          <Link
            to="/Corporate/Purchase-Vouchers/"
            className="btn ttg-btn mb-2 mr-2"
          >
            Purchase New Vouchers
          </Link>

          <Link to="/corporate-tax-invoice/" className="btn ttg-btn mb-2 mr-2">
            View Tax Invoices
          </Link>

          <Link to="/your-courses/" className="btn ttg-btn mb-2">
            Access My Training
          </Link>
        </ContentSection>
        <ContentSection padding={3}>
          <div className="corporate-vouchers-wrapper">
            <Datatable />
          </div>
        </ContentSection>
      </>
    );
  }
}

export default CorporateStart;
