import { ActionTypes } from "./action-types";
import HttpService from "../service";

export const onLoad = () => dispatch => {
  dispatch(loadRequest());
};

export const loadRequest = params => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PROMO_CODES_ONLOAD_REQUEST });
  service
    .load(params)
    .then(resp => {
      dispatch(onLoadSuccess(resp.data));
    })
    .catch(() => {
      dispatch(onLoadFailure());
    });
};

const onLoadSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_ONLOAD_SUCCESS,
    payload
  });
};

const onLoadFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_ONLOAD_FAILURE
  });
};

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_PROMO_CODES_FILTER_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});

export const keepDataTableSettings = settings => ({
  type: ActionTypes.ADMIN_PROMO_CODES_DATATABLE_KEEP_SETTINGS,
  payload: {
    settings
  }
});

export const clearDataTableSearchboxFilter = () => ({
  type: ActionTypes.ADMIN_PROMO_CODES_DATATABLE_CLEAR_SEARCHBOX,
});
