/**
 * Survey Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  surveyId: null,
  userCourseId: null,
  surveyQuestions: [],
  yesNoLookups: [
    {
      label: "Yes",
      value: "1"
    },
    { label: "No", value: "0" }
  ],

  fields: {
    bestAspects: "",
    needImprovement: "",
    uat: "-1",
    rpl: "-1"
  },
  fieldErrors: {},

  isLoading: false
};

function CourseSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SURVEY_LOAD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.SURVEY_LOAD_DATA_SUCCESS:
      let surveyResProps = {};
      if (action.payload.surveyResponses) {
        action.payload.surveyQuestions.forEach(o => {
          let responseFindResult = action.payload.surveyResponses.find(
            f => f.questionId == o.questionId
          );

          surveyResProps[`question-${o.questionId}`] = responseFindResult
            ? responseFindResult.response
            : null;
        });
      }

      // Check in edit mode
      let uat = "-1";
      let rpl = "-1";
      if (action.payload.hasOwnProperty("surveyId")) {
        uat = action.payload.undertakingApprenticeshipOrTraineeship ? "1" : "0";
        rpl = action.payload.rpl ? "1" : "0";
      }

      return {
        ...state,
        surveyId: action.payload.surveyId,
        userCourseId: action.payload.userCourseId,
        surveyQuestions: [...action.payload.surveyQuestions],
        fields: {
          ...state.fields,
          bestAspects: action.payload.bestAspects,
          needImprovement: action.payload.needImprovement,
          uat: uat,
          rpl: rpl,
          ...surveyResProps
        },
        isLoading: false
      };

    case ActionTypes.SURVEY_LOAD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.SURVEY_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      });

      return stateResult;

    default:
      return state;
  }
}

export default CourseSurveyReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectSurvey = state => state.survey;
