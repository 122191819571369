/**
 * Basic Modal
 * Type: Presentational Component
 *
 * Exposed Props:
 * - isOpen
 * - toggle
 * - className
 * - title
 * - isLoading
 * - showCancel
 * - cancelButtonText
 * - okButtonText
 * - disableOkButton
 *
 * rcnet @solvable 2019
 */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import ButtonFx from "../Button-Fx/Button-Fx";

const BasicModal = (props) => (
  <Modal
    isOpen={props.isOpen}
    toggle={props.toggle}
    className={props.className}
    size={props.size}
    backdrop={props.backdrop}
    autoFocus={false}
  >
    <ModalHeader toggle={() => props.toggle("cancel")}>
      {props.title}
    </ModalHeader>
    <ModalBody>{props.children}</ModalBody>
    <ModalFooter>
      {props.showOk ? (
        <ButtonFx
          className="ttg-btn"
          isLoading={props.isLoading}
          disabled={props.disableOkButton}
          onClick={() => props.toggle("ok")}
        >
          {props.okButtonText ? props.okButtonText : "Ok"}
        </ButtonFx>
      ) : null}{" "}
      {props.showCancel ? (
        <Button
          className="ttg-btn ttg-blue-btn"
          color="secondary"
          onClick={() => props.toggle("cancel")}
          disabled={props.isLoading}
        >
          {props.cancelButtonText ? props.cancelButtonText : "Cancel"}
        </Button>
      ) : null}
    </ModalFooter>
  </Modal>
);

BasicModal.propTypes = {
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["static"])]),
  size: PropTypes.string,
  showCancel: PropTypes.bool,
  disableOkButton: PropTypes.bool,
};

BasicModal.defaultProps = {
  backdrop: "static",
  size: "md",
  showOk: true,
  showCancel: true,
  disableOkButton: false,
};

export default BasicModal;
