import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const getUserInfo = id => {
    return httpClient.get(`v1/accounts/${id}`);
  };

  const updateProfile = (id, payload) => {
    return httpClient.put(`v1/accounts/${id}`, payload);
  };

  return {
    getUserInfo,
    updateProfile
  };
};

export default Service;
