import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

const LinkButton = ({
  btnText,
  to,
  isPrimaryBtn = true,
  isTertiaryBtn = false,
  isLinkBtn = false,
  isLargeBtn = false,
  marginLeft = 0,
  marginRight = 0,
  beforeIcon,
  afterIcon,
  className,
}) => {
  return (
    <Link
      to={to}
      className={
        className && className !== ""
          ? className
          : isLinkBtn
          ? ""
          : `btn btn-${
              isPrimaryBtn && !isTertiaryBtn
                ? "primary"
                : isTertiaryBtn
                ? "tertiary"
                : "secondary"
            }${isLargeBtn ? " btn-lg" : ""}${
              marginLeft > 0 ? ` ms-${marginLeft}` : ""
            }${marginRight > 0 ? ` me-${marginRight}` : ""}`
      }
    >
      {beforeIcon && <i className={`${beforeIcon} mr-2`}></i>}
      {btnText}
      {afterIcon && <i className={`${afterIcon} ms-2`}></i>}
    </Link>
  );
};

LinkButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isPrimaryBtn: PropTypes.bool,
  isTertiaryBtn: PropTypes.bool,
  isLinkBtn: PropTypes.bool,
  isLargeBtn: PropTypes.bool,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  beforeIcon: PropTypes.string, // font-awesome class (i.e. fas fa-angle-right)
  afterIcon: PropTypes.string,
  className: PropTypes.string, // this will override the other classes
};

export default LinkButton;
