import { ActionTypes } from "./action-types";

const initialState = {
  isProcessing: false,
  isDeleting: false,
  fields: {
    userCourseId: "",
    firstName: "",
    lastName: "",
    usiNumber: "",
    email: "",
    phone: "",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
    enrolmentStep2Completed: "",
    enrolmentStep3Completed: "",
    thirdPartyFormCompleted: "",
    surveyCompleted: "",
    courseCompleted: "",
    redirectToQuiz: "",
    setcount: "",
    lastSectionCompleted: "",
    lastQuestionCompleted: "",
    previousCertificateNumber: "",
    nswCertificateDetails: "",
  },
  fieldErrors: {},
  showDeleteDialog: false,
  showCourseTransferConfirmation: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_EDIT_COURSES_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_DELETE_SUCCESS:
    case ActionTypes.ADMIN_EDIT_COURSES_DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        showDeleteDialog: false,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showDeleteDialog: !state.showDeleteDialog,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_UPDATE_REQUEST:
    case ActionTypes.ADMIN_EDIT_COURSES_ONLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      };
    case ActionTypes.ADMIN_EDIT_COURSES_ONLOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        fields: action.payload,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_UPDATE_SUCCESS:
    case ActionTypes.ADMIN_EDIT_COURSES_UPDATE_FAILURE:
    case ActionTypes.ADMIN_EDIT_COURSES_ONLOAD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_SHOW_COURSE_TRANSFER_CONFIRMATION:
      return {
        ...state,
        showCourseTransferConfirmation: !state.showCourseTransferConfirmation,
      };

    case ActionTypes.ADMIN_EDIT_COURSES_TRANSFER_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.ADMIN_EDIT_COURSES_TRANSFER_SUCCESS:
    case ActionTypes.ADMIN_EDIT_COURSES_TRANSFER_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    default:
      return state;
  }
}

export default Reducer;
