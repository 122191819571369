import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addNotification } from "../../../redux/system/system-action-creators";
import { selectNotificationData } from "../../../redux/system/notification-reducer";
import ToastNotification from "./Toast-Notification";

function mapStateToProps(state) {
  const notification = selectNotificationData(state);

  return {
    notification: notification
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addNotification
      },
      dispatch
    )
  };
}

const ToastNotificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastNotification);

export default ToastNotificationContainer;
