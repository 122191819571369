import React from "react";
import { Row, Col } from "reactstrap";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "../../../assets/images/cards";
import InputField from "../../../components/Common/Fields/Input-Field";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import "../../../components/Common/Fields/Fields.scss";
import "../../../assets/styles/enrolment-forms.scss";
import "./payment-inputs.scss";
import withSizes from "react-sizes";
import ToogleDisplay from "react-toggle-display";
import KeyboardEventHandler from "react-keyboard-event-handler";
import isNil from "lodash/isNil";

const cardNameValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (value.length < 5)
    return "The card name must be at least 5 characters long.";

  return false;
};

const PaymentInputs = (props) => {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta,
  } = usePaymentInputs();

  return (
    <div className="ttg-form">
      <KeyboardEventHandler
        handleKeys={["enter"]}
        onKeyEvent={(key, e) => {
          const { cardNumber, cardExpiryDate, cardCvc } = props;
          if (isNil(cardNumber) || !cardNumber) {
            props.onChange({ field: "cardNumber", value: "", meta: {} });
            props.onFieldError("Card number is required.");
          }

          if (isNil(cardExpiryDate) || !cardExpiryDate) {
            props.onChange({ field: "cardExpiryDate", value: "", meta: {} });
            props.onFieldError("Card expiry date is required.");
          }

          if (isNil(cardCvc) || !cardCvc) {
            props.onChange({ field: "cardCvc", value: "", meta: {} });
            props.onFieldError("Card CVC is required.");
          }
        }}
      />
      <ToogleDisplay if={!props.isMobile}>
        <span className="payment-area">
          <Row className="big-screen">
            <Col>
              <div className="card-name-container">
                <div className="card-name">
                  <InputField
                    name="cardName"
                    value={props.cardName}
                    placeholder="Card Name"
                    ref={props.form.add}
                    type="text"
                    onChange={props.onInputChange}
                    onFieldError={props.onFieldError}
                    validate={(val) => cardNameValidator("Card Name", val)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <PaymentInputsWrapper {...wrapperProps} className="payment-info">
                <svg {...getCardImageProps({ images })} />
                <input
                  className={
                    props.cardNumber == null || props.cardNumber
                      ? "mr-1"
                      : "mr-1 field-error"
                  }
                  style={{ border: "1px solid #ced4da" }}
                  maxLength={19}
                  {...getCardNumberProps({
                    onBlur: (getCardNumberProps) => {
                      props.onChange({
                        field: "cardNumber",
                        value: getCardNumberProps.target.value,
                        meta: meta.erroredInputs,
                      });
                    },
                  })}
                />
                <input
                  className={
                    props.cardExpiryDate == null || props.cardExpiryDate
                      ? "mr-1"
                      : "mr-1 field-error"
                  }
                  style={{ border: "1px solid #ced4da" }}
                  {...getExpiryDateProps({
                    onBlur: (getExpiryDateProps) => {
                      props.onChange({
                        field: "cardExpiryDate",
                        value: getExpiryDateProps.target.value,
                        meta: meta.erroredInputs,
                      });
                    },
                  })}
                />
                <input
                  className={
                    props.cardCvc == null || props.cardCvc
                      ? "mr-1"
                      : "mr-1 field-error"
                  }
                  style={{ border: "1px solid #ced4da" }}
                  {...getCVCProps({
                    onBlur: (getCVCProps) => {
                      props.onChange({
                        field: "cardCvc",
                        value: getCVCProps.target.value,
                        meta: meta.erroredInputs,
                      });
                    },
                  })}
                />
              </PaymentInputsWrapper>
            </Col>
          </Row>
        </span>
      </ToogleDisplay>
      <ToogleDisplay if={props.isMobile}>
        <span className="payment-area">
          <Row className="small-screen">
            <Col>
              <div className="card-name-container">
                <div className="card-name">
                  <InputField
                    name="cardName"
                    value={props.cardName}
                    placeholder="Card Name"
                    ref={props.form.add}
                    type="text"
                    onChange={props.onInputChange}
                    onFieldError={props.onFieldError}
                    validate={(val) => cardNameValidator("Card Name", val)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <PaymentInputsWrapper
                {...wrapperProps}
                className="payment-info payment-container"
              >
                <table>
                  <tr>
                    <td>
                      <svg
                        className="ml-3"
                        {...getCardImageProps({ images })}
                      />
                    </td>
                    <td style={{ padding: "3px 5px 3px 0px" }}>
                      <input
                        className={
                          props.cardNumber == null || props.cardNumber
                            ? "mr-1"
                            : "mr-1 field-error"
                        }
                        style={{
                          border: "1px solid #ced4da",
                          width: "250px",
                        }}
                        maxLength={19}
                        {...getCardNumberProps({
                          onBlur: (getCardNumberProps) => {
                            props.onChange({
                              field: "cardNumber",
                              value: getCardNumberProps.target.value,
                              meta: meta.erroredInputs,
                            });
                          },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ padding: "3px 5px 3px 0px" }}>
                      <input
                        className={
                          props.cardExpiryDate == null || props.cardExpiryDate
                            ? "mr-1"
                            : "mr-1 field-error"
                        }
                        style={{
                          border: "1px solid #ced4da",
                          width: "250px",
                        }}
                        {...getExpiryDateProps({
                          onBlur: (getExpiryDateProps) => {
                            props.onChange({
                              field: "cardExpiryDate",
                              value: getExpiryDateProps.target.value,
                              meta: meta.erroredInputs,
                            });
                          },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ padding: "3px 5px 3px 0px" }}>
                      <input
                        className={
                          props.cardCvc == null || props.cardCvc
                            ? "mr-1"
                            : "mr-1 field-error"
                        }
                        style={{
                          border: "1px solid #ced4da",
                          width: "250px",
                        }}
                        {...getCVCProps({
                          onBlur: (getCVCProps) => {
                            props.onChange({
                              field: "cardCvc",
                              value: getCVCProps.target.value,
                              meta: meta.erroredInputs,
                            });
                          },
                        })}
                      />
                    </td>
                  </tr>
                </table>
              </PaymentInputsWrapper>
            </Col>
          </Row>
        </span>
      </ToogleDisplay>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 800,
});

export default withSizes(mapSizesToProps)(PaymentInputs);
