import { connect } from "react-redux";
import YourCourses from "./Your-Courses";
import { selectYourCourses } from "../../views/Start/redux/reducer";
import * as actionCreators from "../../views/Start/redux/action-creators";

function mapStateToProps(state) {
  const yourCourses = selectYourCourses(state);
  return {
    yourCourses: yourCourses,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  loadCourses: () => {
    dispatch(actionCreators.loadCourses());
  },
  downloadCertificate: (courseId) => {
    dispatch(actionCreators.downloadCertificate(courseId));
  },
  downloadThirdPartyForm: (thirdPartyAccessToken) => {
    dispatch(actionCreators.downloadThirdPartyForm(thirdPartyAccessToken));
  },
  downloadTaxInvoice: (invoiceId) => {
    dispatch(actionCreators.downloadTaxInvoice(invoiceId));
  },
  dispatch: dispatch,
  history: ownProps.history,
});

const YourCoursesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YourCourses);

export default YourCoursesContainer;
