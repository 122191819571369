import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import PrivateRoute from "./components/Common/Private-Route/Private-Route";
import ConfigureStore from "./redux/configure-store";
import ScrollToTop from "./components/Scroll-To-Top/Scroll-To-Top";
import ToastNotificationContainer from "./components/Common/Toast/Toast-Container";
import ErrorModalContainer from "./components/Common/Error-Modal/Error-Modal-Container";
import Header from "./components/Header/header";
import FooterContainer from "./components/Footer/Footer.Container";
import Home from "./views/Home/home";
import ContactUs from "./views/Contact-Us/contact-us";
import Login from "./views/Login/login";
import NewEnrolment from "./views/Course-Enrolment/New-Enrolment/new-enrolment";
import Step1FormContainer from "./views/Course-Enrolment/Step-1/Step-1-Form.Container";
import Step2FormContainer from "./views/Course-Enrolment/Step-2/Step-2-Form.Container";
import Step3FormContainer from "./views/Course-Enrolment/Step-3/Step-3-Form.Container";
import CorporateVouchers from "./views/Coporate-Vouchers/corporate-vouchers.container";
import CorporatePurchaseVouchers from "./views/Purchase-Corporate-Vouchers/purchase-corporate-vouchers.container";
import CourseContentContainer from "./views/Course-Content/Course-Content.Container";
import CourseQuizContainer from "./views/Course-Quiz/Course-Quiz.Container";
import ContentPage from "./views/Content-Page";
import Certificates from "./views/Certificates/certificates";
import CertificateRegister from "views/Certificates/certificate-register";
import Start from "./views/Start/start";
import CorporateVouchersRegister from "./views/Corporate-Vouchers/corporate-vouchers-register";
import ThirdPartyFormContainer from "./views/Third-Party-Form/Third-Party-Form.Container";
import UpdateProfile from "./views/Update-Profile/Update-Profile.Container";
import ChangePassword from "./views/Change-Password/Change-Password.Container";
import Error403 from "./views/Pages/403/Error-403";
import ForgotPasswordView from "./views/Forgot-Password/Forgot-Password-View";
import ResetPasswordView from "./views/Forgot-Password/Reset-Password-View";
import NSWReprint from "./views/NSW-Reprint-Page/nsw-reprint-page.container";
import CorporateTaxInvoice from "./views/Corporate-Tax-Invoice/corporate-tax-invoice.container";

import "./App.scss";
import FAQs from "./views/FAQs/faqs";
import CourseCompletedContainer from "./views/Course-Completed/Course-Completed.Container";
import CourseSurveyContainer from "./views/Course-Survey/Course-Survey.Container";
import PrintCourseContentGuide from "./views/Print-Course-Content-Guide/Print-Course-Content-Guide";
//Admin Pages
import Users from "./views/Admin/Users/users.container";
import UserCourses from "./views/Admin/UserCourses/users-courses.container";
import EditUserCourse from "./views/Admin/Edit-User-Course/edit-user-course.container";
import DowloadReportsPage from "./views/Admin/Download-Reports/download-reports.container";
import VoucherPurchases from "./views/Admin/View-Voucher-Purchases/voucher-purchases.container";
import NswCertificatesContainer from "./views/Admin/Nsw-Certificates/Nsw-Certificates.Container";
import NswReprintsViewContainer from "./views/Admin/Nsw-Reprints-View/Nsw-Reprints-View.Container";
import PromoCodesContainer from "./views/Admin/Promo-Codes/promo-codes.container";
import AppWrapperContainer from "./components/Common/App-Wrapper/App-Wrapper-Container";
import LoginImpersonationContainer from "./components/Common/Login-Impersonation/Login-Impersonation.Container";
import AuthoriseAvsContainer from "./views/Avetmiss/Authorise-Avs/authorise-avs.container";
import AvetmissReportingContainer from "./views/Avetmiss/Avetmiss-Reporting/avetmiss-reporting.container";
import AvetmissReportingSubmissionContainer from "./views/Avetmiss/Avetmiss-Reporting-Submission/avetmiss-reporting-submission.container";
import AvetmissReportingValidationContainer from "./views/Avetmiss/Avetmiss-Reporting-Validation/avetmiss-reporting-validation.container";
import AvetmissAddressValidation from "./views/Avetmiss/Avetmiss-Address-Validation";
import SyncView from "./views/Admin/Sync/sync-view";
import CourseInfoPage from "views/Course-Info-Page/course-info-page";
import eLearningCourse from "views/external-learning-course/external-learning-course";

function App() {
  const store = ConfigureStore();

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <AppWrapperContainer>
            <Header />
            <main role="main">
              <ToastNotificationContainer />
              <ErrorModalContainer />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route
                  exact
                  path="/courses/:courseSlug/"
                  component={CourseInfoPage}
                />
                <Route
                  exact
                  path="/Certificates/Enrol/:courseId/"
                  component={CertificateRegister}
                />
                <Route
                  exact
                  path="/online-food-safety-training-courses/"
                  component={Certificates}
                />
                <Route
                  exact
                  path="/online-food-safety-training-courses-for-your-business/"
                  component={CorporateVouchersRegister}
                />
                <Route exact path="/FAQs/:faqPage/" component={FAQs} />
                <Route exact path="/FAQs/" component={FAQs} />
                <Route exact path="/Login/" component={Login} />
                <Route exact path="/contact-us/" component={ContactUs} />
                <PrivateRoute
                  exact
                  path="/course-content/:userCourseId/"
                  component={CourseContentContainer}
                />
                <PrivateRoute
                  exact
                  path="/course-quiz/:userCourseId/"
                  component={CourseQuizContainer}
                />
                <PrivateRoute
                  exact
                  path="/course-enrolment/new/"
                  component={NewEnrolment}
                />
                <PrivateRoute
                  exact
                  path="/course-enrolment/:courseId/step-1/"
                  component={Step1FormContainer}
                />
                <PrivateRoute
                  exact
                  path="/course-enrolment/:courseId/step-2/"
                  component={Step2FormContainer}
                />
                <PrivateRoute
                  exact
                  path="/course-enrolment/:courseId/step-3/"
                  component={Step3FormContainer}
                />
                <Route
                  exact
                  path="/third-party-form/:userCourseId/"
                  component={ThirdPartyFormContainer}
                />
                <PrivateRoute exact path="/Your-Courses/" component={Start} />
                <PrivateRoute
                  exact
                  path="/Corporate/"
                  component={CorporateVouchers}
                />
                <PrivateRoute
                  exact
                  path="/Corporate/Purchase-Vouchers/"
                  component={CorporatePurchaseVouchers}
                />
                <PrivateRoute
                  exact
                  path="/Update-Profile/"
                  component={UpdateProfile}
                />
                <PrivateRoute
                  exact
                  path="/Change-Password/"
                  component={ChangePassword}
                />
                {/* <PrivateRoute
                  exact
                  path="/Corporate/"
                  component={CorporatePurchaseVouchers}
                /> */}
                <Route
                  exact
                  path="/Forgot-Password"
                  component={ForgotPasswordView}
                />
                <PrivateRoute
                  exact
                  path="/course-enrolment/replacement-nsw-certificate/"
                  component={NSWReprint}
                />
                <PrivateRoute
                  exact
                  path="/corporate-tax-invoice/"
                  component={CorporateTaxInvoice}
                />
                <Route
                  exact
                  path="/Reset-Password/:resetToken/"
                  component={ResetPasswordView}
                />
                <PrivateRoute
                  exact
                  path="/Course-Completed/:id/"
                  component={CourseCompletedContainer}
                />
                <PrivateRoute
                  exact
                  path="/elearning/:id/"
                  component={eLearningCourse}
                />
                <PrivateRoute
                  exact
                  path="/Course-Survey/:id"
                  component={CourseSurveyContainer}
                />
                <Route exact path="/Pages/Error-403/" component={Error403} />
                <PrivateRoute
                  exact
                  path="/Print-Course-Content-Guide/:token"
                  component={PrintCourseContentGuide}
                />
                {/* Admin Routes */}
                <PrivateRoute exact path="/Admin/Users" component={Users} />
                <PrivateRoute
                  exact
                  path="/Admin/User-Courses"
                  component={UserCourses}
                />
                <PrivateRoute
                  exact
                  path="/Admin/User-Courses/:id"
                  component={UserCourses}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Edit-User-Course/:userCourseId"
                  component={EditUserCourse}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Download-Reports"
                  component={DowloadReportsPage}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Voucher-Purchases"
                  component={VoucherPurchases}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Nsw-Certificates"
                  component={NswCertificatesContainer}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Nsw-Reprints"
                  component={NswReprintsViewContainer}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Promo-Codes"
                  component={PromoCodesContainer}
                />
                <Route
                  exact
                  path="/login/:userId/:token"
                  component={LoginImpersonationContainer}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Avetmiss/Reporting"
                  component={AvetmissReportingContainer}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Avetmiss/Submission"
                  component={AvetmissReportingSubmissionContainer}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Avetmiss/Validation"
                  component={AvetmissReportingValidationContainer}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Avetmiss/Address-Validation"
                  component={AvetmissAddressValidation}
                />
                <PrivateRoute
                  exact
                  path="/Admin/Avetmiss/Authorise"
                  component={AuthoriseAvsContainer}
                />
                <PrivateRoute exact path="/Admin/Sync" component={SyncView} />
                {/* Admin Routes */}
                <Route exact path="*" component={ContentPage} />
              </Switch>
            </main>
            <FooterContainer />
          </AppWrapperContainer>
        </ScrollToTop>
      </Router>
    </Provider>
  );
}

export default App;
