import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const PageTagging = ({ title, description }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && description.length > 0 && (
        <meta name="description" content={description} />
      )}
    </Helmet>
  );
};

PageTagging.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default PageTagging;
