import { ActionTypes } from "./action-types";
import HttpService from "./service";
import { addNotification } from "../../../../redux/system/system-action-creators";
import { saveAs } from "file-saver";

export const loadRequest = params => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_DOWNLOAD_REPORT_REQUEST });
  service
    .load(params)
    .then(resp => {
      let file = new Blob([resp.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      saveAs(file, "report.xlsx");
      dispatch(onLoadSuccess());
    })
    .catch(() => {
      dispatch(onLoadFailure());
      dispatch(addNotification("Request failed.", "warning"));
    });
};

const onLoadSuccess = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_DOWNLOAD_REPORT_SUCCESS
  });
};

const onLoadFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_DOWNLOAD_REPORT_FAILURE
  });
};

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_DOWNLOAD_REPORT_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
