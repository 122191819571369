/**
 * Utility function to render navigation items based on their type (internal link, external link, or JavaScript function).
 * Handles onClick events for link items to manage dropdown visibility.
 *
 * Dependencies: react-router-dom for internal links.
 * Adam Luck - Solvable - 2024-06-29
 */

import React from "react";
import { Link } from "react-router-dom";

export const renderNavItem = (
  item,
  index,
  handleClick,
  handleHashNavigation
) => {
  if (typeof item.link === "function")
    return (
      <button
        onClick={() => {
          item.link();
          handleClick();
        }}
        key={index}
      >
        {item.title}
      </button>
    );

  if (item.link.startsWith("http"))
    return (
      <a
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        key={index}
      >
        {item.title}
      </a>
    );

  if (
    item.link.startsWith("#") ||
    (item.link.startsWith("/") && item.link.includes("#"))
  ) {
    return (
      <a
        href={item.link}
        onClick={(e) => {
          e.preventDefault();
          handleHashNavigation(item.link);
        }}
        key={index}
      >
        {item.title}
      </a>
    );
  }

  return (
    <Link to={item.link} key={index} onClick={handleClick}>
      {item.title}
    </Link>
  );
};
