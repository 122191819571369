import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import HttpService from "./service";
import YourCoursesService from "../../../views/Start/redux/service";
import IdentityService from "../../../coreLib/auth/userIdentity";
import { saveAs } from "file-saver";

export const onLoad = () => dispatch => {
  let httpService = HttpService();
  let identity = IdentityService();
  let userId = identity.getUserId();

  dispatch(init());
  dispatch(onLoadRequest());
  httpService
    .getTaxInvoices(userId)
    .then(resp => {
      dispatch(onLoadSuccess(resp.data));
    })
    .catch(err => {
      dispatch(onLoadFailure());
      dispatch(
        addNotification("There was an error loading the data.", "warning")
      );
    });
};

const init = () => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_ON_INIT
});

const onLoadRequest = () => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_ON_LOAD_REQUEST
});

const onLoadSuccess = payload => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_ON_LOAD_SUCCESS,
  payload
});

const onLoadFailure = () => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_ON_LOAD_FAILURE
});

export const downloadTaxInvoice = invoiceGuid => dispatch => {
  let yourCoursesService = YourCoursesService();
  dispatch(onDownloadRequest());
  yourCoursesService
    .downloadTaxInvoice(invoiceGuid)
    .then(resp => {
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "taxinvoice.pdf");
      dispatch(onDownloadSuccess());
    })
    .catch(err => {
      dispatch(onDownloadFailure());
      dispatch(
        addNotification(
          "There was an error downloading the tax invoice.",
          "warning"
        )
      );
    });
};

const onDownloadRequest = () => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_DOWNLOAD_REQUEST
});

const onDownloadSuccess = () => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_DOWNLOAD_SUCCESS
});

const onDownloadFailure = () => ({
  type: ActionTypes.CORPORATE_TAX_INVOICE_DOWNLOAD_FAILURE
});
