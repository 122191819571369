import IdentityService from "../../../coreLib/auth/userIdentity";
import { addNotification } from "../../../redux/system/system-action-creators";
import { ActionTypes } from "./action-types";
import Service from "./service";

export const init = () => ({
  type: ActionTypes.NSW_REPRINT_INIT,
});

export const setEmail = () => (dispatch) => {
  let email = IdentityService().getEmail();
  dispatch(formInputChange("email", email));
};

export const onCreditCardInputChange = (card) => (dispatch) => {
  const { field, value, meta } = card;
  dispatch(formInputChange(field, value));
  let isCreditCardValid = Object.values(meta).every((m) => m === undefined);
  dispatch(setCardValidity(isCreditCardValid));
};

const setCardValidity = (value) => ({
  type: ActionTypes.NSW_REPRINT_SET_CARD_VALIDITY,
  payload: value,
});

export const updateAutocompleteAddress = (data) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.NSW_REPRINT_AUTOCOMPLETE_ADDRESS_UPDATE,
      payload: data,
    });
  };
};

export const toggleManualAddressEntry = () => ({
  type: ActionTypes.NSW_REPRINT_TOGGLE_MANUAL_ADDRESS_ENTRY,
});

export const togglePromoCodeEntry = () => ({
  type: ActionTypes.NSW_REPRINT_TOGGLE_PROMO_CODE_ENTRY,
});

const showManualAddressEntry = () => ({
  type: ActionTypes.NSW_REPRINT_SHOW_MANUAL_ADDRESS_ENTRY,
});

export const clearAddress = () => ({
  type: ActionTypes.NSW_REPRINT_CLEAR_ADDRESS,
});

export const toggleShowConfirmPopup = () => ({
  type: ActionTypes.NSW_REPRINT_SHOW_CONFIRM_POPUP,
});

export const onSubmit = (stateProps, fields, history) => {
  const {
    streetNumber,
    streetName,
    city,
    state,
    postcode,
    email,
    cardNumber,
    cardExpiryDate,
    cardName,
    cardCvc,
    promoCode,
  } = fields;
  return (dispatch, getState) => {
    const { hasPromoCode } = stateProps;
    const { addressEntry } = getState().nswReprint;

    if (!addressEntry.enterAddressManually && !addressEntry.fullAddress) {
      dispatch(addNotification("Address is required", "error"));
      return true;
    }

    if (!streetNumber || streetNumber === undefined || streetNumber === "") {
      dispatch(addNotification("Street number is required.", "warning"));
      dispatch(showManualAddressEntry());
      return;
    }

    if (!streetName || streetName === undefined || streetName === "") {
      dispatch(addNotification("Street name is required.", "warning"));
      return;
    }

    if (!city || city === undefined || city === "") {
      dispatch(addNotification("City is required.", "warning"));
      return;
    }

    if (!state || state === undefined || state === "") {
      dispatch(addNotification("State is required.", "warning"));
      return;
    }

    if (!postcode || postcode === undefined || postcode === "") {
      dispatch(addNotification("Post code is required.", "warning"));
      return;
    }

    if (!email || email === undefined || email === "") {
      dispatch(addNotification("Email address code is required.", "warning"));
      return;
    }

    if (!cardName) {
      dispatch(addNotification("Please enter a card name", "error"));
      return true;
    }
    if (!cardNumber || !cardExpiryDate || !cardCvc) {
      dispatch(
        addNotification("Please enter a valid credit card details", "error")
      );
      return true;
    }
    // Submit Validation

    let model = {
      ...fields,
      cardExpiryDate: fields.cardExpiryDate.replace(/ /g, ""),
    };

    dispatch(onSubmitRequest());
    Service()
      .submit(model)
      .then((resp) => {
        const { purchaseSuccessful, errorMessage } = resp.data;
        if (!purchaseSuccessful) {
          dispatch(onSubmitFailure());
          dispatch(addNotification(errorMessage, "warning"));
        } else {
          dispatch(onSubmitSuccess());
        }
      })
      .catch((err) => {
        dispatch(onSubmitFailure());
        dispatch(
          addNotification(
            err.response.data.Message
              ? err.response.data.Message
              : err.response.data.title
              ? err.response.data.title
              : "There was an issue submitting the form",
            "warning"
          )
        );
      });
  };
};

const onSubmitRequest = () => ({
  type: ActionTypes.NSW_REPRINT_SUBMIT_ENROLMENT_REQUEST,
});
const onSubmitSuccess = () => ({
  type: ActionTypes.NSW_REPRINT_SUBMIT_ENROLMENT_SUCCESS,
});
const onSubmitFailure = () => ({
  type: ActionTypes.NSW_REPRINT_SUBMIT_ENROLMENT_FAILURE,
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.NSW_REPRINT_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});
