/**
 * Avetmiss Reporting Validation Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  isDownloading: false,
  isDeleting: false,
  showDeleteDialog: false,
  hasResult: false,
  verificationResult: {
    verificationSuccessful: false,
    errorMessage: null,
    errors: [],
  },
};

function AvetmissReportingValidationReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------
    case ActionTypes.AVETMISS_REPORTING_VALIDATION_LOAD_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasResult: true,
        verificationResult: {
          ...state.verificationResult,
          verificationSuccessful: action.payload.verificationSuccessful,
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors,
        },
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_LOAD_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_LOAD_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        showDeleteDialog: false,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        showDeleteDialog: false,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showDeleteDialog: !state.showDeleteDialog,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST:
      return {
        ...state,
        isDownloading: true,
      };
    case ActionTypes.AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS:
    case ActionTypes.AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE:
      return {
        ...state,
        isDownloading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_VALIDATION_FORM_INPUT_CHANGE:
      let errors = [...state.verificationResult.errors];

      let error = errors.find(
        (x) =>
          x.natFileNumber === action.payload.natFileNumber &&
          x.recordNumber === action.payload.recordNumber
      );

      error.fieldValue = action.payload.fieldValue;
      error.updated = true;

      let stateResult = Object.assign({}, state, {
        verificationResult: {
          ...state.verificationResult,
          errors: [...errors],
        },
      });

      return stateResult;

    default:
      return state;
  }
}

export default AvetmissReportingValidationReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectAvetmissReportingValidation = state =>
  state.avetmissReportingValidation;
