import React, { Component } from "react";
import ContentSection from "../../components/Content-Section";
import ResponsiveHtml from "components/Common/responsive-html-renderer/responsive-html";
import ApiService from "./services/api-service";

class PrintCourseContentGuide extends Component {
  state = {
    sectionId: 0,
    sections: [],
    sectionCount: 0,
  };

  componentDidMount() {
    const { match } = this.props;
    this.loadCourseContent(match.params.token);
  }

  loadCourseContent = async (userCourseToken) => {
    const apiService = ApiService();

    const contentGuide = await apiService.getCourseContentGuide(
      userCourseToken
    );

    const sectionCount = contentGuide.sections.length;

    let sections = [];

    for (let i = 1; i <= sectionCount; i++) {
      const section = this.getSection(contentGuide.sections, i);

      if (section != null) {
        sections.push(section);
      }
    }

    this.setState({
      sections: sections,
      sectionCount: sectionCount,
    });
  };

  getSection = (sections, sectionNumber) => {
    return sections.find((x) => x.sectionId === sectionNumber);
  };

  getSectionStyle = (sectionNumber) => {
    return sectionNumber === 0 ? {} : { paddingTop: "160px" };
  };

  getTableOfContents = () => {
    let items = [];

    for (let i = 1; i <= this.state.sections.length; i++) {
      items.push(
        <li key={i}>
          <a href={`#section-${i}`}>Section {i}</a>
        </li>
      );
    }

    items.push(
      <li>
        <a href={`#how-to-save`}>How to save for offline use</a>
      </li>
    );

    return items;
  };

  render() {
    return (
      <>
        <ContentSection padding={2}>
          <a href="https://www.foodsafetyeducation.com.au">
            <img
              src="https://www.foodsafetyeducation.com.au/Images/Train-to-Gain-Logo.jpg"
              alt="Train to Gain Pty Ltd T/A Food Safety Education"
            />
          </a>
        </ContentSection>
        <ContentSection padding={2}>
          <h3>Table of Contents</h3>
          <ul>{this.getTableOfContents()}</ul>
        </ContentSection>

        {this.state.sections.map((section, a) => (
          <div id={`section-${a + 1}`} key={a} style={this.getSectionStyle(a)}>
            {section.content.map((content, i) => (
              <ContentSection key={section + i} padding={2}>
                <div className="cms-styles-common cms-styles-rendered">
                  <ResponsiveHtml html={content.html} />
                </div>
              </ContentSection>
            ))}
          </div>
        ))}

        <div id="how-to-save" style={this.getSectionStyle(99)}>
          <ContentSection padding={2}>
            <h1>How to save for offline use</h1>
            <p>
              You can come back to the Food Safety Education website anytime to
              access your course guide. We would encourage you not save or print
              this material as it does change from time to time. By coming back,
              you are always guaranteed to have access to the very latest course
              guide.
            </p>
            <p>
              If you wish to save a copy to read later, you can save this page,
              or print to PDF. If you are using a browser, such as Google
              Chrome, you can save the page to read later.{" "}
              <a
                href="https://support.google.com/chrome/answer/7343019?co=GENIE.Platform%3DDesktop"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for instructions on how to save this page in Google
                Chrome
              </a>
              .
            </p>
          </ContentSection>
        </div>
      </>
    );
  }
}

export default PrintCourseContentGuide;
