import React from "react";
import PropTypes from "prop-types";
import ContentSectionContainer from "../../content-section-container/content-section-container";
import "./custom-svgs.scss";

function CustomSvgs({
  id,
  children,
  bgInnerColor = "#fff",
  padding = 1,
  specificClass,
  isFullWidth = false,
  customSvgTop,
  customSvgBottom,
}) {
  return (
    <div
      className={`content-section-custom-svgs${
        specificClass ? ` ${specificClass}` : ""
      }`}
      style={{ backgroundColor: bgInnerColor }}
      {...(id ? { id } : {})}
    >
      {customSvgTop && (
        <section className="content-section-custom-svgs-top">
          <div className="svg-container">{customSvgTop}</div>
        </section>
      )}
      <ContentSectionContainer
        padding={padding}
        isFullWidth={isFullWidth}
        children={children}
      />
      {customSvgBottom && (
        <section className="content-section-custom-svgs-bottom">
          <div className="svg-container">{customSvgBottom}</div>
        </section>
      )}
    </div>
  );
}

CustomSvgs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string,
  bgOuterColor: PropTypes.string,
  bgInnerColor: PropTypes.string,
  padding: PropTypes.number,
  specificClass: PropTypes.string,
  isFullWidth: PropTypes.bool,
  customSvgTop: PropTypes.element,
  customSvgBottom: PropTypes.element,
};

export default CustomSvgs;
