/**
 * Toast Notification
 */
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ToastNotification extends Component {
  componentWillReceiveProps(newProps) {
    let { message, level } = newProps.notification;
    if (!level || level === undefined) level = "success";

    switch (level.toLowerCase()) {
      case "error":
        toast.error(message);
        break;
      case "warning":
        toast.error(message);
        break;
      default:
        toast.success(message);
        break;
    }
  }

  render() {
    return (
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    );
  }
}

export default ToastNotification;
