import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoginBox from "./Login-Box";
import { selectLoginData } from "../../redux/system/login-reducer";
import {
  onLogin,
  onLoginInputChange
} from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const loginData = selectLoginData(state);

  return {
    loginData: loginData
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(onLoginInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLogin: e => {
    e.preventDefault();
    let returnUrl = getRedirectPath(ownProps);
    dispatchProps.dispatch(
      onLogin(
        stateProps.loginData.fields.username,
        stateProps.loginData.fields.password,
        returnUrl,
        ownProps.history
      )
    );
  }
});

/**
 * Redirect ReturnUrl if its provided otherwise go to default route '/' :-)
 */
const getRedirectPath = props => {
  const locationState = props.location.state;
  const pathName =
    locationState && locationState.from && locationState.from.pathname;
  return pathName || "/";
};

// const validate = stateProps => {
//   if (
//     !stateProps.loginData.fields.username ||
//     stateProps.loginData.fields.username == undefined ||
//     stateProps.loginData.fields.username === ""
//   ) {
//     dispatch(
//       addNotification("Please enter your email address........", "warning")
//     );
//     return false;
//   }

//   return true;
// };

const LoginBoxContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(LoginBox)
);

export default LoginBoxContainer;
