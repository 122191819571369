/**
 * Step 1 Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  isApplyingDiscountCode: false,
  isDiscountCodeApplied: false,
  showDiscountCodeForm: false,
  discountedPrice: 0,
  addressEntry: {
    displayAutocompleteAddressField: false,
    enterAddressManually: false,
    showSearchAddress: true,
    fullAddress: "",
  },
  hasPromoCode: false,
  fields: {
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
    cardNumber: null,
    cardExpiryDate: null,
    cardName: "",
    cardCvc: null,
    email: "",
    promoCode: "",
    discountCode: "",
    understandTerms: false,
  },
  isCreditCardValid: false,
  fieldErrors: {},
  isSubmitting: false,
  isSubmitted: false,
  showConfirmNamePopup: false,
};

function Step1Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.STEP_1_INIT:
      return {
        ...state,
        addressEntry: {
          displayAutocompleteAddressField: false,
          enterAddressManually: false,
          showSearchAddress: true,
          fullAddress: "",
        },
        hasPromoCode: false,
        fields: {
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
          cardNumber: null,
          cardExpiryDate: null,
          cardName: "",
          cardCvc: null,
          email: "",
          promoCode: "",
        },
        isCreditCardValid: false,
        fieldErrors: {},
        isSubmitting: false,
        isSubmitted: false,
        showConfirmNamePopup: action.payload.hasCourses,
      };
    case ActionTypes.STEP_1_SET_CARD_VALIDITY:
      return {
        ...state,
        isCreditCardValid: action.payload,
      };

    case ActionTypes.STEP_1_CLEAR_ADDRESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: "",
        },
        fields: {
          ...state.fields,
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
        },
      };

    case ActionTypes.STEP_1_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: action.payload.fullAddress,
          enterAddressManually: false,
        },
        fields: {
          ...state.fields,
          unitNumber: `${(
            action.payload.subdwelling +
            " " +
            action.payload.buildingName
          ).trim()}`,
          streetNumber: action.payload.streetNumber,
          streetName: action.payload.street,
          city: action.payload.locality,
          state: action.payload.state,
          postcode: action.payload.postcode,
        },
      };

    case ActionTypes.STEP_1_TOGGLE_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: !state.addressEntry.enterAddressManually,
        },
      };

    case ActionTypes.STEP_1_SHOW_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: true,
        },
      };

    case ActionTypes.STEP_1_TOGGLE_PROMO_CODE_ENTRY:
      return {
        ...state,
        hasPromoCode: !state.hasPromoCode,
        fields: {
          ...state.fields,
          cardNumber: null,
          cardExpiryDate: "",
          cardCvc: null,
          cardName: null,
          promoCode: "",
        },
        isCreditCardValid: false,
      };

    case ActionTypes.STEP_1_SUBMIT_ENROLMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
      };

    case ActionTypes.STEP_1_SUBMIT_ENROLMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };

    case ActionTypes.STEP_1_SUBMIT_ENROLMENT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
      };

    case ActionTypes.STEP_1_APPLY_DISCOUNT_CODE_REQUEST:
      return {
        ...state,
        isApplyingDiscountCode: true,
      };

    case ActionTypes.STEP_1_APPLY_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        isApplyingDiscountCode: false,
        isDiscountCodeApplied: true,
        discountedPrice: action.totalAfterDiscount,
        showDiscountCodeForm: false,
      };

    case ActionTypes.STEP_1_APPLY_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        isApplyingDiscountCode: false,
        fields: {
          ...state.fields,
          discountCode: "",
        },
      };

    case ActionTypes.STEP_1_APPLY_DISCOUNT_TOGGLE_DISCOUNT_CODE:
      return {
        ...state,
        showDiscountCodeForm: !state.showDiscountCodeForm,
      };

    case ActionTypes.STEP_1_FORM_INPUT_CHANGE:
      let stateResultInput = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });
      return stateResultInput;

    case ActionTypes.STEP_1_TOGGLE_SHOW_CONFIRM_NAME_POPUP:
      return {
        ...state,
        showConfirmNamePopup: false,
      };

    default:
      return state;
  }
}

export default Step1Reducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectStep1 = (state) => state.courseEnrolment.step1;
