import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const submit = payload => {
    return httpClient.post("v1/userCourse/nsw-reprint", payload);
  };

  return {
    submit
  };
};

export default Service;
