/**
 * Register Box Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  isSubmitted: false,
  submittedTo: "",
  fields: {
    email: ""
  },
  fieldErrors: {}
};

function ForgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.FORGOT_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.FORGOT_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitted: true,
        submittedTo: state.fields.email,
        fields: initialState.fields
      };

    case ActionTypes.FORGOT_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    // ----------------------------
    //  Form Action Types
    // ----------------------------

    case ActionTypes.FORGOT_PASSWORD_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        },
        isSubmitted: false,
        submittedTo: ""
      });

      return stateResult;

    default:
      return state;
  }
}

export default ForgotPasswordReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectForgotPassword = state => state.forgotPassword;
