import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../../redux/system/system-action-creators";
import HttpService from "../service";

export const onDelete = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PROMO_CODES_DELETE_REQUEST });
  service
    .deletePromoCode(payload.id)
    .then(() => {
      dispatch(onDeleteSuccess(payload));
      dispatch(addNotification("Promo code has been successfully deleted."));
    })
    .catch(() => {
      dispatch(onDeleteFailure());
      dispatch(
        addNotification(
          "There was an error deleting the promo code.",
          "warning"
        )
      );
    });
};

const onDeleteSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_DELETE_SUCCESS,
    payload
  });
};

export const onAdd = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PROMO_CODES_ADD_REQUEST });
  service
    .addPromoCode(payload)
    .then(() => {
      dispatch(onAddSuccess(payload));
      dispatch(addNotification("Promo code has been successfully added."));
    })
    .catch(() => {
      dispatch(onAddFailure());
      dispatch(addNotification("Unable to add a promo code.", "warning"));
    });
};

const onAddSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_ADD_SUCCESS,
    payload
  });
};

const onAddFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_ADD_FAILURE
  });
};

export const onEdit = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PROMO_CODES_EDIT_REQUEST });
  service
    .editPromoCode(payload)
    .then(() => {
      dispatch(onEditSuccess(payload));
      dispatch(addNotification("Promo code has been successfully edited."));
    })
    .catch(() => {
      dispatch(onEditFailure());
      dispatch(
        addNotification("There was an error updating the promo code", "warning")
      );
    });
};

const onEditSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_EDIT_SUCCESS,
    payload
  });
};

const onEditFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_EDIT_FAILURE
  });
};

const onDeleteFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PROMO_CODES_DELETE_FAILURE
  });
};

export const toggleDeleteDialog = payload => ({
  type: ActionTypes.ADMIN_PROMO_CODES_SHOW_DELETE_DIALOG,
  payload
});

export const toggleEditDialog = payload => ({
  type: ActionTypes.ADMIN_PROMO_CODES_SHOW_EDIT_DIALOG,
  payload
});

export const toggleAddDialog = payload => ({
  type: ActionTypes.ADMIN_PROMO_CODES_SHOW_EDIT_DIALOG,
  payload
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_PROMO_CODES_DIALOG_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
