import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Button, Form } from "reactstrap";
import InputField from "components/Common/Fields/Input-Field";
import ButtonFx from "components/Common/Button-Fx/Button-Fx";
import Auth from "coreLib/auth/auth";
import UserIdentity from "coreLib/auth/userIdentity";
import { addNotification } from "../../redux/system/system-action-creators";
import { selectLoginData } from "../../redux/system/login-reducer";
import {
  onLogin,
  onLogout,
  onLoginInputChange,
  loadUserIdentityState,
} from "redux/system/system-action-creators";
import Config from "config";
import "./login-bar.scss";

const LoginBarView = (props) => {
  const { loggedIn, isLoading } = props.loginData;
  const { pathname } = props.history.location;
  const { firstName } = props.loginData.loggedInUser;
  const { username, password } = props.loginData.fields;
  const { onLogin, onLogout, onInputChange, isMobile } = props;

  return (
    <div className="login-bar-wrap d-none d-md-block">
      <div className="login-bar">
        <div className="login-contact">
          <i className="fas fa-phone" /> &nbsp;
          <a href="tel:+61400757645" target="_blank" rel="noopener noreferrer">
            0400 757 645
          </a>
          {/* &nbsp; &#124; &nbsp;
          <i className="fas fa-envelope" /> &nbsp;
          <a
            href="mailto:contact@traintogain.com.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
          </a> */}
        </div>
        <div>
          {!loggedIn && !pathname.toLowerCase().includes("login") && (
            <>
              <Form onSubmit={onLogin} className="header-login">
                <InputField
                  name="username"
                  placeholder="Email"
                  type="email"
                  value={username}
                  onChange={onInputChange}
                />
                <InputField
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={onInputChange}
                />
                <ButtonFx
                  type="submit"
                  className="ttg-btn ttg-light-orange-btn"
                  onClick={onLogin}
                  isLoading={isLoading}
                >
                  Login
                </ButtonFx>
                <Link to="/Forgot-Password/" className="forgot-pass">
                  Forgot&nbsp;your&nbsp;Password?
                </Link>
              </Form>
            </>
          )}
          {loggedIn && (
            <>
              <div className="welcome-name">Welcome {firstName}</div>
              <Button className="ttg-btn" onClick={onLogout}>
                Logout
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const loginData = selectLoginData(state);

  return {
    loginData: loginData,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: async () => {
    // Note: We will reload user identity from local storage to redux state if user is authenticated.
    if (!Auth.isAuthenticated()) return;

    let userIdentity = UserIdentity();

    const user = {
      firstName: userIdentity.getFirstName(),
      lastName: userIdentity.getLastName(),
      id: userIdentity.getUserId(),
      email: userIdentity.getEmail(),
      hasCourses: userIdentity.hasCourses(),
      userType: userIdentity.userType(),
    };

    await dispatch(loadUserIdentityState(user));
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(onLoginInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLogin: (e) => {
    const { username, password } = stateProps.loginData.fields;

    if (!username && !password) {
      dispatchProps.dispatch(
        addNotification(
          "Please enter your email address and password.",
          "warning"
        )
      );
      return;
    }

    if (!username) {
      dispatchProps.dispatch(
        addNotification("Please enter your email address.", "warning")
      );
      return;
    }

    if (!password) {
      dispatchProps.dispatch(
        addNotification("Please enter your password.", "warning")
      );
      return;
    }

    let returnUrl = getRedirectPath(ownProps);
    dispatchProps.dispatch(
      onLogin(username, password, returnUrl, ownProps.history)
    );
  },
  onLogout: () => {
    dispatchProps.dispatch(
      onLogout(Config.logoutRedirectUrl, ownProps.history)
    );
  },
});

/**
 * Redirect ReturnUrl if its provided otherwise go to default route '/' :-)
 */
const getRedirectPath = (props) => {
  const locationState = props.location.state;
  const pathName =
    locationState && locationState.from && locationState.from.pathname;
  return pathName || "/";
};

const LoginBar = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(LoginBarView)
);

export default LoginBar;
