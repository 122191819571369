/**
 * Toggle button component for the dropdown menu in the priority navigation.
 * Uses DefaultIcon if no children are provided.
 * Adam Luck - Solvable - 2024-06-29
 */

import React from "react";
import { DefaultIcon } from "./toggle-button-icon";

const ToggleButton = (props) => (
  <div className="priority-nav-btn" {...props}>
    {props.children || <DefaultIcon />}
  </div>
);

export default React.memo(ToggleButton);
