import { ActionTypes } from "./action-types";
import HttpService from "../service";
import { addNotification } from "../../../../../redux/system/system-action-creators";

export const onDelete = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_USER_COURSES_DELETE_REQUEST });
  service
    .deleteCourse(payload.userCourseId)
    .then(() => {
      dispatch(onDeleteSuccess(payload));
      dispatch(addNotification("User course has been successfully deleted."));
    })
    .catch(() => {
      dispatch(onDeleteFailure());
      dispatch(addNotification("Unable to delete the user course.", "warning"));
    });
};

const onDeleteSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_USER_COURSES_DELETE_SUCCESS,
    payload
  });
};

const onDeleteFailure = () => dispatch => {
  dispatch({ type: ActionTypes.ADMIN_USER_COURSES_DELETE_FAILURE });
};

export const toggleDeleteDialog = payload => ({
  type: ActionTypes.ADMIN_USER_COURSES_SHOW_DELETE_DIALOG,
  payload
});
