/**
 * Course Content Reducer
 */
import { ActionTypes } from "./action-types";
import * as QuizActionTypes from "./../../Course-Quiz/redux/action-types";

const initialState = {
  courseId: "",
  courseName: "",
  content: [],
  totalSections: 0,
  totalPages: 0,

  currentSection: 0,
  currentPageIndex: 0,
  currentSectionContent: "",

  allowRedirect: false,
  isLoading: false,
  error: null,

  sections: [],
};

function CourseContentReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.COURSE_CONTENT_LOAD_SECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.COURSE_CONTENT_LOAD_SECTION_SUCCESS:
      return {
        ...state,
        courseId: action.payload.courseId,
        courseName: action.payload.courseName,
        sections: action.payload.sections,
        content: action.payload.content,
        totalSections: action.payload.totalSections,
        totalPages: action.payload.content.length,
        currentSection: action.payload.currentSection,
        currentPageIndex: action.payload.currentPageIndex,
        currentSectionContent:
          action.payload.content[action.payload.currentPageIndex].html,
        allowRedirect: false,
        isLoading: false,
      };

    case ActionTypes.COURSE_CONTENT_LOAD_SECTION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.COURSE_CONTENT_TOGGLE_NEXT_PAGE:
      return {
        ...state,
        currentPageIndex: state.currentPageIndex + 1,
        currentSectionContent: state.content[state.currentPageIndex + 1].html,
      };

    case ActionTypes.COURSE_CONTENT_TOGGLE_PREVIOUS_PAGE:
      return {
        ...state,
        currentPageIndex: state.currentPageIndex - 1,
        currentSectionContent: state.content[state.currentPageIndex - 1].html,
      };

    case ActionTypes.COURSE_CONTENT_LOAD_QUIZ_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.COURSE_CONTENT_LOAD_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.COURSE_CONTENT_LOAD_QUIZ_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case QuizActionTypes.ActionTypes
      .COURSE_QUIZ_ALLOW_REDIRECT_TO_COURSE_CONTENT:
      return {
        ...state,
        allowRedirect: true,
      };

    default:
      return state;
  }
}

export default CourseContentReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectCourseContent = state => state.courseContent;
