import React from "react";
import { Button } from "reactstrap";

const HelpQuestion = ({
  question,
  response,
  setResponseHandler,
  buttonOneText = "Yes",
  buttonTwoText = "No",
}) => {
  return (
    <>
      <div className="help-question-text">{question}</div>
      <Button
        className={`ttg-btn ttg-lg-btn mx-1 mb-3 ${
          response !== null && response === true
            ? "ttg-orange-btn"
            : "ttg-orange-outline-btn"
        }`}
        onClick={() => setResponseHandler(true)}
      >
        {buttonOneText}
      </Button>
      <Button
        className={`ttg-btn ttg-lg-btn mx-1 mb-3 ${
          response !== null && response === false
            ? "ttg-blue-btn"
            : "ttg-blue-outline-btn"
        }`}
        onClick={() => setResponseHandler(false)}
      >
        {buttonTwoText}
      </Button>
    </>
  );
};

export default HelpQuestion;
