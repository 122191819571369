import React, { Component } from "react";
import PropTypes from "prop-types";

class PageTitle extends Component {
  componentDidMount() {
    document.title = this.props.title
      ? this.props.title.length > 50
        ? this.props.title
        : `${this.props.title}${
            /Food Safety Education/m.test(this.props.title)
              ? ""
              : " | Food Safety Education"
          }`
      : "Food Safety Education | Train to Gain";
  }

  render() {
    return <></>;
  }
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
