import { ActionTypes } from "./action-types";
import moment from "moment";

const initialState = {
  isLoading: false,
  data: [],
  settings: {},
  error: {}
};

function NswCertificatesViewReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.NSW_CERTIFICATES_LOAD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.NSW_CERTIFICATES_LOAD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.map((item) => {
          return {
            ...item,
            certificateDateTime: moment(item.certificateDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            status:
              item.status == 0
                ? "Unactioned"
                : item.status == 1
                ? "Underway"
                : "Completed",
          };
        }),
      };
    case ActionTypes.NSW_CERTIFICATES_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    case ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data
          .map((item) => {
            if (item.userCourseId === action.payload.userCourseId) {
              return {
                ...action.payload,
                status:
                  action.payload.status == 0
                    ? "Unactioned"
                    : action.payload.status == 1
                    ? "Underway"
                    : "Completed",
              };
            } else {
              return item;
            }
          })
          .filter((item) => item.status !== "Completed"),
      };

    case ActionTypes.NSW_CERTIFICATES_LOAD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.NSW_CERTIFICATES_DATATABLE_CLEAR_SEARCHBOX:
       return {
         ...state,
         settings: {
           ...state.settings,
           searchText: "",
         },
       };
       
    default:
      return state;
  }
}

export default NswCertificatesViewReducer;
