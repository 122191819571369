import React from "react";
import NswFoodSafetySupervisorCertificate from "./nsw-food-safety-supervisor-certificate";
import NswFoodSafetySupervisorCertificateRefresher from "./nsw-food-safety-supervisor-certificate-refresher";
import FoodSafetySupervisorCertificateHospitalityAndRetail from "./food-safety-supervisor-certificate-hospitality-and-retail";
import FoodHandlingLevel1CertificateHospitalityAndRetail from "./food-handling-level-1-certificate-hospitality-and-retail";
import FoodHandlingLevel2CertificateHospitalityAndRetail from "./food-handling-level-2-certificate-hospitality-and-retail";
import FoodHandlingLevel1CertificateHealthAndCommunity from "./food-handling-level-1-certificate-health-and-community";
import FoodSafetySupervisorCertificateHealthAndCommunity from "./food-safety-supervisor-certificate-health-and-community";

export const getCourseContent = (courseId) => {
  switch (courseId) {
    case 6:
      return <NswFoodSafetySupervisorCertificate />;
    case 8:
      return <NswFoodSafetySupervisorCertificateRefresher />;
    case 4:
      return <FoodSafetySupervisorCertificateHospitalityAndRetail />;
    case 2:
      return <FoodHandlingLevel1CertificateHospitalityAndRetail />;
    case 3:
      return <FoodHandlingLevel2CertificateHospitalityAndRetail />;
    case 1009:
      return <FoodHandlingLevel1CertificateHealthAndCommunity />;
    case 1008:
      return <FoodSafetySupervisorCertificateHealthAndCommunity />;
    default:
      return <></>;
  }
};
