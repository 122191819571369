/**
 * Login Service
 */
import HttpClient from "../coreLib/http/httpClient";
import Config from "../config";
import Auth from "../coreLib/auth/auth";
import UserIdentity from "../coreLib/auth/userIdentity";

const LoginService = () => {
  let httpClient = HttpClient();
  let userIdentity = UserIdentity();

  /**
   * Perform local-signin to server
   */
  const login = (userName, password) => {
    const userReqModel = {
      UserName: userName,
      Password: password,
      ClientId: Config.clientId,
      GrantType: "password",
    };

    let promise = new Promise((resolve, reject) => {
      httpClient
        .post("/v1/auth/token", userReqModel)
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );

          const user = {
            firstName: userIdentity.getFirstName(),
            lastName: userIdentity.getLastName(),
            id: userIdentity.getUserId(),
            email: userIdentity.getEmail(),
            userType: userIdentity.userType(),
          };
          resolve(user);
        })
        .catch((error) => reject(error));
    });

    return promise;
  };

  // Note: G-login Not supported yet!
  const loginUsingGoogleToken = (idToken) => {
    let promise = new Promise((resolve, reject) => {
      httpClient
        .post("/v1/auth/external/google", {
          token: idToken,
        })
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );

          const user = {
            firstName: userIdentity.getFirstName(),
            lastName: userIdentity.getLastName(),
            userType: userIdentity.userType(),
          };
          resolve(user);
        })
        .catch((error) => reject(error));
    });

    return promise;
  };

  const logout = () => {
    let promise = new Promise((resolve, reject) => {
      try {
        Auth.removeToken();
        console.info("User logout successful");
        resolve("User logout successful");
      } catch (ex) {
        reject(ex);
      }
    });

    return promise;
  };

  return {
    login,
    loginUsingGoogleToken,
    logout,
  };
};

export default LoginService;
