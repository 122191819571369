/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import {
  addNotification,
  onLoginSuccess,
} from "../../../redux/system/system-action-creators";
import ResetPasswordService from "../Reset-Password-Service";
import Auth from "../../../coreLib/auth/auth";
import UserIdentity from "./../../../coreLib/auth/userIdentity";

// =================
//  Reset Password
// =================

// Async
export const onResetPassword = (data, resetToken, history) => {
  return (dispatch) => {
    const service = ResetPasswordService();
    dispatch(onResetPasswordRequest());

    service
      .resetPassword(data, resetToken)
      .then((resp) => {
        dispatch(onResetPasswordSuccess(resp));

        Auth.setToken(
          resp.data.access_token,
          resp.data.refresh_token,
          resp.data.expires_in
        );

        let userIdentity = UserIdentity();
        const user = {
          // Todo: implement id fetching here
          firstName: userIdentity.getFirstName(),
          lastName: userIdentity.getLastName(),
          userType: userIdentity.userType(),
        };
        dispatch(onLoginSuccess(user));

        // Redirect to home
        history.push("/");
      })
      .catch((err) => {
        // Todo: Implement generic error handler in the web template - rcnet
        let error = err.response.data && err.response.data.Message;
        dispatch(onResetPasswordFailure());
        dispatch(addNotification(error, "error"));
      });
  };
};

const onResetPasswordRequest = () => ({
  type: ActionTypes.FORGOT_PASSWORD_APPLY_RESET_REQUEST,
});
const onResetPasswordSuccess = () => ({
  type: ActionTypes.FORGOT_PASSWORD_APPLY_RESET_SUCCESS,
});
const onResetPasswordFailure = () => ({
  type: ActionTypes.FORGOT_PASSWORD_APPLY_RESET_FAILURE,
});

// ----------------------------
//  Form Actions
// ----------------------------
export const formInputChange = (name, value, error) => ({
  type: ActionTypes.FORGOT_PASSWORD_APPLY_RESET_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});
