import React, { Component } from "react";
import SolvableDataTable from "../../../components/solvable-datatable/solvable-datatable";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import EmailModal from "./Email-Modal";
import isNil from "lodash/isNil";
class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
    };
    this.actionButtonHandler = this.actionButtonHandler.bind(this);
  }

  componentDidMount() {
    this.props.onLoad();
  }

  onSettingsChanged = (settings) => {
    this.setState({ settings });
  };

  actionButtonHandler = (evt) => {
    switch (evt.type) {
      case "button1":
        this.props.downloadCertificate(evt.data.userCourseId);
        break;
      case "button2":
        this.props.downloadThirdPartyForm(evt.data.thirdPartyFormAccessToken);
        break;
      case "button3":
        this.props.showEmailDialog();
        this.props.setEmailDetails(evt.data);
        break;
      case "button4":
        this.props.history.push(`/third-party-form/${evt.data.userCourseId}`);
        break;
    }
  };

  columns = [
    {
      Header: "Purchase Date",
      accessor: "purchaseDate",
      searchable: true,
    },
    {
      accessor: "hasBeenCompleted",
      show: false,
    },
    {
      accessor: "thirdPartyFormCompleted",
      show: false,
    },
    {
      Header: "Course",
      accessor: "course",
      searchable: true,
      Cell: (row) => <span title={row.value}>{row.value}</span>,
    },
    {
      Header: "Redeemed By",
      accessor: "redeemedBy",
      searchable: true,
      maxWidth: 200,
    },
    {
      Header: "Voucher",
      accessor: "voucher",
      searchable: true,
      maxWidth: 90,
    },
    {
      Header: "Status",
      accessor: "status",
      searchable: true,
      maxWidth: 150,
    },
  ];

  render() {
    return (
      <div>
        <EmailModal {...this.props} />
        <BlockUiFx blocking={this.props.isProcessing} lowerZIndex={true}>
          <SolvableDataTable
            defaultPageSize={50}
            actionButtons={{
              type: "customButton",
              containerWidth: 130,
              custom: [
                {
                  target: "button1",
                  icon: "fas fa-download",
                  tooltip: "Download Certificate",
                  hideFn: (row) => !row.hasBeenCompleted,
                },
                {
                  target: "button2",
                  icon: "fas fa-clipboard-list",
                  tooltip: "Download Third Party Form",
                  hideFn: (row) => !row.hasBeenCompleted,
                },
                {
                  target: "button3",
                  icon: "fas fa-envelope",
                  tooltip: "Email Voucher",
                  hideFn: (row) => row.status !== "Not Yet Redeemed",
                },
                {
                  target: "button4",
                  icon: "fas fa-link",
                  tooltip: "Third Party Form",
                  hideFn: (row) =>
                    row._original.userCourseId == null ||
                    !(
                      row.status !== "Not Yet Redeemed" &&
                      !row.thirdPartyFormCompleted
                    ),
                },
              ],
            }}
            columns={this.columns}
            onActionButtonClick={this.actionButtonHandler}
            data={this.props.data}
          />
        </BlockUiFx>
      </div>
    );
  }
}

export default DataTable;
