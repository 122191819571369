export const ActionTypes = {
  CORPORATE_TAX_INVOICE_ON_INIT: "CORPORATE_TAX_INVOICE_ON_INIT",
  CORPORATE_TAX_INVOICE_ON_LOAD_REQUEST:
    "CORPORATE_TAX_INVOICE_ON_LOAD_REQUEST",
  CORPORATE_TAX_INVOICE_ON_LOAD_SUCCESS:
    "CORPORATE_TAX_INVOICE_ON_LOAD_SUCCESS",
  CORPORATE_TAX_INVOICE_ON_LOAD_FAILURE:
    "CORPORATE_TAX_INVOICE_ON_LOAD_FAILURE",

  CORPORATE_TAX_INVOICE_DOWNLOAD_REQUEST:
    "CORPORATE_TAX_INVOICE_DOWNLOAD_REQUEST",
  CORPORATE_TAX_INVOICE_DOWNLOAD_SUCCESS:
    "CORPORATE_TAX_INVOICE_DOWNLOAD_SUCCESS",
  CORPORATE_TAX_INVOICE_DOWNLOAD_FAILURE:
    "CORPORATE_TAX_INVOICE_DOWNLOAD_FAILURE"
};
