import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const changePassword = (id, payload) => {
    return httpClient.patch(`v1/accounts/${id}/password/change`, payload);
  };

  return {
    changePassword
  };
};

export default Service;
