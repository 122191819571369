export const ActionTypes = {
  NSW_CERTIFICATES_LOAD_DATA_REQUEST: "NSW_CERTIFICATES_LOAD_DATA_REQUEST",
  NSW_CERTIFICATES_LOAD_DATA_SUCCESS: "NSW_CERTIFICATES_LOAD_DATA_SUCCESS",
  NSW_CERTIFICATES_LOAD_DATA_FAILURE: "NSW_CERTIFICATES_LOAD_DATA_FAILURE",
  NSW_CERTIFICATES_VIEW_UPDATE_SUCCESS: "NSW_CERTIFICATES_VIEW_UPDATE_SUCCESS",
  NSW_CERTIFICATES_DATATABLE_KEEP_SETTINGS:
    "NSW_CERTIFICATES_DATATABLE_KEEP_SETTINGS",
  NSW_CERTIFICATES_DATATABLE_CLEAR_SEARCHBOX:
    "NSW_CERTIFICATES_DATATABLE_CLEAR_SEARCHBOX",
};
