/**
 * Survey Service
 */
import HttpClient from "./../../coreLib/http/httpClient";

const CourseSurveyService = () => {
  let httpClient = HttpClient();

  const getCourseId = courseContentToken =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/ids`);

  const getStatus = courseContentToken =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/status`);

  const getSurveys = courseContentToken => {
    let promise1 = getCourseId(courseContentToken);
    let promise2 = getStatus(courseContentToken);

    let promiseDeferred = new Promise((resolve, reject) => {
      Promise.all([promise1, promise2])
        .then(resp => {
          httpClient
            .get(`/v1/survey/${resp[0].data.userCourseId}`)
            .then(result => {
              resolve({
                ...result.data,
                userCourseId: resp[0].data.userCourseId,
                status: resp[1].data
              });
            })
            .catch(e => reject(e));
        })
        .catch(err => reject(err));
    });

    return promiseDeferred;
  };

  const createSurvey = data => {
    let payload = {
      userCourseId: data.userCourseId,
      bestAspects: data.bestAspects,
      needImprovement: data.needImprovement,
      undertakingApprenticeshipOrTraineeship: data.uat === "1",
      rpl: data.rpl === "1",
      surveyResponses: data.surveyResponses
    };

    return httpClient.post("v1/survey", payload);
  };

  const updateSurvey = (surveyId, data) => {
    let payload = {
      userCourseId: data.userCourseId,
      bestAspects: data.bestAspects,
      needImprovement: data.needImprovement,
      undertakingApprenticeshipOrTraineeship: data.uat === "1",
      rpl: data.rpl === "1",
      surveyResponses: data.surveyResponses
    };

    return httpClient.put(`v1/survey/${surveyId}`, payload);
  };

  return {
    getCourseId,
    getSurveys,
    createSurvey,
    updateSurvey
  };
};

export default CourseSurveyService;
