import React from "react";
import ContentSection from "components/Common/content-section";
import GreenTickBalls from "features/green-tick-balls/green-tick-balls";
import "./whats-next.scss";
import LinkButton from "components/link-button/link-button";

const WhatsNext = () => {
  return (
    <ContentSection bgClass="veggies-bg">
      <div className="whats-next">
        <div>
          <div className="heading-text">What&rsquo;s Next</div>
          <div className="sub-heading-text">
            Ready to earn your Food Safety Certification? Follow these easy
            steps to get started.
          </div>
        </div>
        <div className="whats-next-image">
          <img
            src="https://solvable.azureedge.net/train-to-gain/images/whats-next.webp"
            alt=""
          />
          <div className="whats-next-image-overlay">
            <div className="whats-next-image-overlay-text-left">30+</div>
            <div className="whats-next-image-overlay-text-right">
              Over 30 Years Hospitality, Health &amp; Retail Training Experience
            </div>
          </div>
        </div>
        <GreenTickBalls
          items={[
            "Choose Your Course",
            "Click on Start Now, Register & Pay",
            "Complete Enrolment, Online Training & Assessment",
            "Your Food Safety Certificate is Emailed to You Instantly",
          ]}
        />

        <LinkButton
          to="/online-food-safety-training-courses/"
          className="ttg-btn ttg-lg-btn ttg-orange-outline-btn"
          btnText="Get Started"
        />
      </div>
    </ContentSection>
  );
};

export default WhatsNext;
