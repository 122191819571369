import React, { Component } from "react";
import {
  Input,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import formHandler from "../../../../components/Common/Fields/form-handler";
import InputField from "../../../../components/Common/Fields/Input-Field";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";
import DatePickerField from "../../../../components/Common/Fields/Date-Picker-Field";
import SelectField from "../../../../components/Common/Fields/Select-Field";

class ViewDetailsNswReprints extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isModalOpen, fields, isModalLoading } = this.props.dialog;
    const { onToggle, onDialogInputChange, onFieldError, update } = this.props;
    const form = formHandler();

    return (
      <Modal unmountOnClose={true} isOpen={isModalOpen}>
        <ModalHeader toggle={() => onToggle()}>
          NSW Certificate Details
        </ModalHeader>
        <ModalBody>
          <Form className="ttg-form">
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    placeholder="First Name"
                    className="form-control"
                    type="text"
                    value={fields.firstName}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    placeholder="Last Name"
                    className="form-control"
                    type="text"
                    value={fields.lastName}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    placeholder="Email"
                    className="form-control"
                    type="text"
                    value={fields.email}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    placeholder="Phone"
                    className="form-control"
                    type="text"
                    value={fields.phone}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Certificate Number</Label>
                  <Input
                    placeholder="Certificate Number"
                    className="form-control"
                    type="text"
                    value={fields.certificateNumber}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Certificate Date/Time</Label>
                  <Input
                    placeholder="Certificate Date/Time"
                    className="form-control"
                    type="text"
                    value={fields.certificateDateTime}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>Postal Address</Label>
              <Input
                placeholder="Postal Address"
                className="form-control"
                type="textarea"
                value={fields.postalAddress}
                readOnly
              />
            </FormGroup>

            <Row>
              <Col className="mb-4">
                <a href={`/Admin/Edit-User-Course/${fields.userCourseId}`}>
                  Edit these details
                </a>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>NSW Certificate Number</Label>
                  <InputField
                    name="nswCertificateNumber"
                    placeholder="NSW Certificate Number"
                    ref={form.add}
                    value={fields.nswCertificateNumber}
                    onFieldError={onFieldError}
                    onChange={onDialogInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Express Post Number</Label>
                  <InputField
                    name="expressPostNumber"
                    placeholder="Express Post Number"
                    ref={form.add}
                    value={fields.expressPostNumber}
                    onFieldError={onFieldError}
                    onChange={onDialogInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Date Sent</Label>
              <div>
                <DatePickerField
                  name="dateSent"
                  value={fields.dateSent}
                  minDate={new Date(1930, 1, 1)}
                  maxDate={new Date()}
                  onChange={onDialogInputChange}
                  onFieldError={onFieldError}
                  ref={form.add}
                ></DatePickerField>
              </div>
            </FormGroup>

            <FormGroup>
              <label>Status</label>
              <SelectField
                name="status"
                ref={form.add}
                value={fields.status}
                onFieldError={onFieldError}
                onChange={onDialogInputChange}
              >
                <option value={-1}>- Select -</option>
                <option value={0}>Unactioned</option>
                <option value={1}>Underway</option>
                <option value={2}>Completed</option>
              </SelectField>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonFx
            type="submit"
            onClick={() => {
              form.validate({
                validFn: () => {
                  update(fields);
                },
              });
            }}
            className="ttg-btn"
            isLoading={isModalLoading}
          >
            Update
          </ButtonFx>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ViewDetailsNswReprints;
