/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "redux/system/system-action-creators";
import UserCourseService from "services/user-course-service";
import CourseService from "services/course-service";
import ApiService from "../services/api-service";

// ----------------------------
//  Actions
// ----------------------------

export const onLoadSection = (courseContentToken, history) => {
  return async (dispatch) => {
    dispatch(loadSectionRequest());

    const userCourseService = UserCourseService();

    await userCourseService
      .getCourseDetailsAndStatus(courseContentToken)
      .then(async (resp) => {
        const { id, status } = resp.data;
        if (!status.isExternalLearning) {
          history.push("/Your-Courses/");
        } else if (!status.courseExpired) {
          const courseService = CourseService();

          const course = courseService.getCourse(id);

          dispatch(loadSectionSuccess(course.courseTitle));
        } else {
          dispatch(
            addNotification(
              "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
              "error"
            )
          );
          history.push("/your-courses/");
        }
      })
      .catch((err) => {
        dispatch(
          addNotification(
            "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
            "error"
          )
        );
        history.push("/your-courses/");
      });
  };
};

export const onLoadSso = (courseContentToken) => {
  return async (dispatch) => {
    dispatch(loadSsoRequest());

    const apiService = ApiService();

    await apiService
      .getSso(courseContentToken)
      .then((resp) => {
        window.location.href = resp.data;
      })
      .catch(() => {
        dispatch(loadSsoFailure());

        dispatch(
          addNotification(
            "Sorry, something went wrong trying to login to eLearnd. Please try again. If the problem continues, please contact us for assistance.",
            "error"
          )
        );
      });
  };
};

const loadSectionRequest = () => ({
  type: ActionTypes.EXTERNAL_LEARNING_COURSE_LOAD_REQUEST,
});

const loadSectionSuccess = (courseName) => ({
  type: ActionTypes.EXTERNAL_LEARNING_COURSE_LOAD_SUCCESS,
  payload: {
    courseName,
  },
});

const loadSsoRequest = () => ({
  type: ActionTypes.EXTERNAL_LEARNING_COURSE_GET_SSO_REQUEST,
});

const loadSsoFailure = () => ({
  type: ActionTypes.EXTERNAL_LEARNING_COURSE_GET_SSO_FAILURE,
});
