import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "../../../components/Common/Fields/Fields.scss";

export default class EmailModal extends Component {
  constructor(props) {
    super(props);
  }

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email Address is required")
  });

  render() {
    const { showEmailAddressDialog, sendVoucher } = this.props;
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={showEmailAddressDialog}>
          <ModalHeader toggle={() => this.props.showEmailDialog()}>
            Email Address
          </ModalHeader>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
            }}
            validationSchema={this.validationSchema}
            onSubmit={(values) => sendVoucher(values)}
          >
            {({ isSubmitting, isValid, errors, touched }) => (
              <Form className="ttg-form">
                <ModalBody>
                  <Field
                    placeholder="First Name"
                    className={
                      errors.firstName && touched.firstName
                        ? "form-control field-error mt-3"
                        : "form-control mt-3"
                    }
                    type="text"
                    name="firstName"
                  />
                  <Field
                    placeholder="Last Name"
                    className={
                      errors.lastName && touched.lastName
                        ? "form-control field-error mt-3"
                        : "form-control mt-3"
                    }
                    type="text"
                    name="lastName"
                  />
                  <Field
                    placeholder="Email Address"
                    className={
                      errors.email && touched.email
                        ? "form-control field-error mt-3"
                        : "form-control mt-3"
                    }
                    type="email"
                    name="email"
                  />
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn ttg-btn"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Send
                  </button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}
