import React, { Component } from "react";
import CourseQuizQuestionMultiSelect from "./Course-Quiz-Question-Multi-Select";
import CourseQuizQuestionDropDown from "./Course-Quiz-Question-Drop-Down";
import "./Course-Quiz-Question.scss";

class CourseQuizQuestion extends Component {
  getQuizQuestion = (questionId, questionType, options, onQuizInputChange) => {
    switch (questionType) {
      // questionType: DropDown = 0, MultipleChoice = 1, TrueOrFalse = 2
      case 0:
        return options.map((option, i) => (
          <CourseQuizQuestionDropDown
            key={i}
            options={option}
            onInputChange={onQuizInputChange}
          />
        ));
      case 1:
      case 2:
        return (
          <CourseQuizQuestionMultiSelect
            questionId={questionId}
            options={options[0]}
            onQuizInputChange={onQuizInputChange}
          />
        );
      default:
        return null;
    }
  };

  componentWillReceiveProps(newProps) {
    // this clears the radiobutton check status.
    // for some reason, they won't clear when rendered
    // even with different names and ids
    if (
      newProps.selectedIndex === -1 &&
      document.getElementById("quizQuestions")
    ) {
      const children = document.getElementsByClassName("quiz-question-item");

      for (let i = 0; i < children.length; i++) children[i].checked = false;
    }
  }

  render() {
    const {
      question,
      onQuizInputChange,
      selectedIndex,
      isFinalQuiz,
    } = this.props;

    return (
      <div className="quiz-questions">
        <div className="question-title">
          {`${isFinalQuiz ? "Final Quiz - " : ""}`}Question{" "}
          {question.questionNo}
        </div>
        <div className="question-text">
          <div
            dangerouslySetInnerHTML={{
              __html: question.question,
            }}
          />
        </div>
        {this.getQuizQuestion(
          question.questionId,
          question.questionType,
          question.options,
          onQuizInputChange,
          selectedIndex
        )}
      </div>
    );
  }
}

export default CourseQuizQuestion;
