import React, { Component } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import DataTable from "./datatable/datatable.container";

class CorporateTaxInvoice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <PageTitle title="Business Tax Invoices" />
        <ContentSection bgColor="grey-shadow mb-4" padding={2}>
          <h1>Business Tax Invoices</h1>
        </ContentSection>
        <ContentSection bgColor="" padding={2}>
          <main>
            <DataTable />
          </main>
        </ContentSection>
      </>
    );
  }
}

export default CorporateTaxInvoice;
