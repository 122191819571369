export const NswFoodSafetySupervisorCertificateRefresherThirdPartyFormTasks = [
  {
    taskId: 238,
    courseId: 8,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 239,
    courseId: 8,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 240,
    courseId: 8,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 241,
    courseId: 8,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 242,
    courseId: 8,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 243,
    courseId: 8,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 244,
    courseId: 8,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 245,
    courseId: 8,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 246,
    courseId: 8,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 247,
    courseId: 8,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 248,
    courseId: 8,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 249,
    courseId: 8,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 250,
    courseId: 8,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 251,
    courseId: 8,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 252,
    courseId: 8,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 15,
  },
  {
    taskId: 253,
    courseId: 8,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 16,
  },
  {
    taskId: 254,
    courseId: 8,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 17,
  },
  {
    taskId: 255,
    courseId: 8,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 18,
  },
  {
    taskId: 256,
    courseId: 8,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 19,
  },
  {
    taskId: 257,
    courseId: 8,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 20,
  },
  {
    taskId: 258,
    courseId: 8,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 21,
  },
  {
    taskId: 259,
    courseId: 8,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 22,
  },
  {
    taskId: 260,
    courseId: 8,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 23,
  },
  {
    taskId: 261,
    courseId: 8,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 24,
  },
  {
    taskId: 262,
    courseId: 8,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 25,
  },
  {
    taskId: 263,
    courseId: 8,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 26,
  },
  {
    taskId: 264,
    courseId: 8,
    version: 1,
    taskGroup: "Task 8: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 27,
  },
  {
    taskId: 265,
    courseId: 8,
    version: 1,
    taskGroup: "Task 8: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 28,
  },
  {
    taskId: 266,
    courseId: 8,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 29,
  },
  {
    taskId: 267,
    courseId: 8,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 30,
  },
  {
    taskId: 268,
    courseId: 8,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 31,
  },
  {
    taskId: 269,
    courseId: 8,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 32,
  },
  {
    taskId: 270,
    courseId: 8,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 33,
  },
  {
    taskId: 271,
    courseId: 8,
    version: 1,
    taskGroup: "Task 10: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 34,
  },
  {
    taskId: 272,
    courseId: 8,
    version: 1,
    taskGroup: "Task 10: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 35,
  },
  {
    taskId: 273,
    courseId: 8,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 36,
  },
  {
    taskId: 274,
    courseId: 8,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 37,
  },
  {
    taskId: 275,
    courseId: 8,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 38,
  },
  {
    taskId: 276,
    courseId: 8,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 39,
  },
  {
    taskId: 277,
    courseId: 8,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 40,
  },
  {
    taskId: 278,
    courseId: 8,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 41,
  },
  {
    taskId: 279,
    courseId: 8,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 42,
  },
  {
    taskId: 280,
    courseId: 8,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 43,
  },
  {
    taskId: 281,
    courseId: 8,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 44,
  },
  {
    taskId: 282,
    courseId: 8,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 45,
  },
  // -----------------------------------
  // Version 2
  // -----------------------------------
  {
    taskId: 385,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Storing",
    displayOrder: 1,
  },
  {
    taskId: 386,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Preparing",
    displayOrder: 2,
  },
  {
    taskId: 387,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Processing",
    displayOrder: 3,
  },
  {
    taskId: 388,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Displaying",
    displayOrder: 4,
  },
  {
    taskId: 389,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Serving",
    displayOrder: 5,
  },
  {
    taskId: 390,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Packaging",
    displayOrder: 6,
  },
  {
    taskId: 391,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Transporting",
    displayOrder: 7,
  },
  {
    taskId: 392,
    courseId: 8,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Disposing",
    displayOrder: 8,
  },
  {
    taskId: 393,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent food contamination",
    task:
      "Maintain clean clothes and use required personal protective equipment, including appropriate bandages and dressings where required.",
    displayOrder: 9,
  },
  {
    taskId: 394,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent food contamination",
    task: "Prevent food contamination from clothing and other personal items.",
    displayOrder: 10,
  },
  {
    taskId: 395,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 11,
  },
  {
    taskId: 396,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent food contamination",
    task:
      "Ensure hygienic personal contact with food and food contact surfaces.",
    displayOrder: 12,
  },
  {
    taskId: 397,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent food contamination",
    task:
      "Use hygienic cleaning and sanitising practices that prevent food-borne illnesses.",
    displayOrder: 13,
  },
  {
    taskId: 398,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent cross-contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently in accordance with the NSW guidelines.",
    displayOrder: 14,
  },
  {
    taskId: 399,
    courseId: 8,
    version: 2,
    taskGroup: "Prevent cross-contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 15,
  },
  {
    taskId: 400,
    courseId: 8,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 16,
  },
  {
    taskId: 401,
    courseId: 8,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task: "Report unsafe practices that breach hygiene procedures promptly.",
    displayOrder: 17,
  },
  {
    taskId: 402,
    courseId: 8,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self.",
    displayOrder: 18,
  },
  {
    taskId: 403,
    courseId: 8,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report as appropriate for follow-up.",
    displayOrder: 19,
  },
  {
    taskId: 404,
    courseId: 8,
    version: 2,
    taskGroup: "Report any personal health issues",
    task: "Report personal health issues likely to cause a hygiene risk.",
    displayOrder: 20,
  },
  {
    taskId: 405,
    courseId: 8,
    version: 2,
    taskGroup: "Report any personal health issues",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 21,
  },
  {
    taskId: 406,
    courseId: 8,
    version: 2,
    taskGroup: "Report any personal health issues",
    task:
      "Cease participation in food handling activities where own health issue may cause food contamination.",
    displayOrder: 22,
  },
  {
    taskId: 407,
    courseId: 8,
    version: 2,
    taskGroup: "Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 23,
  },
  {
    taskId: 408,
    courseId: 8,
    version: 2,
    taskGroup: "Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 24,
  },
  {
    taskId: 409,
    courseId: 8,
    version: 2,
    taskGroup: "Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 25,
  },
  {
    taskId: 410,
    courseId: 8,
    version: 2,
    taskGroup: "Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 26,
  },
  {
    taskId: 411,
    courseId: 8,
    version: 2,
    taskGroup: "Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 27,
  },
  {
    taskId: 412,
    courseId: 8,
    version: 2,
    taskGroup: "Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are identified.",
    displayOrder: 28,
  },
  {
    taskId: 413,
    courseId: 8,
    version: 2,
    taskGroup: "Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 29,
  },
  {
    taskId: 414,
    courseId: 8,
    version: 2,
    taskGroup: "Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 30,
  },
  {
    taskId: 415,
    courseId: 8,
    version: 2,
    taskGroup: "Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 31,
  },
  {
    taskId: 416,
    courseId: 8,
    version: 2,
    taskGroup: "Store food safely",
    task: "Ensure food is stored to avoid cross-contamination of ingredients.",
    displayOrder: 32,
  },
  {
    taskId: 417,
    courseId: 8,
    version: 2,
    taskGroup: "Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of food.",
    displayOrder: 33,
  },
  {
    taskId: 418,
    courseId: 8,
    version: 2,
    taskGroup: "Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 34,
  },
  {
    taskId: 419,
    courseId: 8,
    version: 2,
    taskGroup: "Prepare food safely",
    task: "Ensure safety of food prepared, served and sold to customers.",
    displayOrder: 35,
  },
  {
    taskId: 420,
    courseId: 8,
    version: 2,
    taskGroup: "Prepare food safely",
    task:
      "Prepare food to meet customer requirements, including actions to address allergen requests by customers.",
    displayOrder: 36,
  },
  {
    taskId: 421,
    courseId: 8,
    version: 2,
    taskGroup: "Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 37,
  },
  {
    taskId: 422,
    courseId: 8,
    version: 2,
    taskGroup: "Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 38,
  },
  {
    taskId: 423,
    courseId: 8,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 39,
  },
  {
    taskId: 424,
    courseId: 8,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 40,
  },
  {
    taskId: 425,
    courseId: 8,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 41,
  },
  {
    taskId: 426,
    courseId: 8,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Dispose of, or report damaged or unsafe eating, drinking or food handling utensils.",
    displayOrder: 42,
  },
  {
    taskId: 427,
    courseId: 8,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 43,
  },
  {
    taskId: 428,
    courseId: 8,
    version: 2,
    taskGroup: "Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 44,
  },
  {
    taskId: 429,
    courseId: 8,
    version: 2,
    taskGroup: "Dispose of food safely",
    task: "Dispose of food promptly to avoid cross-contamination.",
    displayOrder: 45,
  },
  {
    taskId: 430,
    courseId: 8,
    version: 2,
    taskGroup: "Dispose of food safely",
    task: "Use appropriate documentation to record of disposed items.",
    displayOrder: 46,
  },
  {
    taskId: 431,
    courseId: 8,
    version: 2,
    taskGroup: "Food allergen management",
    task: "Identify critical control points for food allergen management.",
    displayOrder: 47,
  },
  {
    taskId: 432,
    courseId: 8,
    version: 2,
    taskGroup: "Food allergen management",
    task:
      "Apply procedures to prevent cross contamination of food allergens in food storage, preparation and serving.",
    displayOrder: 48,
  },
  {
    taskId: 433,
    courseId: 8,
    version: 2,
    taskGroup: "Food allergen management",
    task: "Identify food allergens on packaged and unpackaged foods.",
    displayOrder: 49,
  },
  {
    taskId: 434,
    courseId: 8,
    version: 2,
    taskGroup: "Food allergen management",
    task: "Identify the most common food allergens.",
    displayOrder: 50,
  },
  {
    taskId: 435,
    courseId: 8,
    version: 2,
    taskGroup: "Food allergen management",
    task:
      "Identify tools and resources to help with implementation of food allergen management procedures.",
    displayOrder: 51,
  },
  {
    taskId: 436,
    courseId: 8,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task: "The student is aware of the NSW Food Act 2003 offences.",
    displayOrder: 52,
  },
  {
    taskId: 437,
    courseId: 8,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice.",
    displayOrder: 53,
  },
  {
    taskId: 438,
    courseId: 8,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Literacy skills to read and interpret workplace documents, including policies, procedures and flow charts that identify critical control points.",
    displayOrder: 54,
  },
  {
    taskId: 439,
    courseId: 8,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety.",
    displayOrder: 55,
  },
  {
    taskId: 440,
    courseId: 8,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings.",
    displayOrder: 56,
  },
];
