/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../redux/system/system-action-creators";
import AvetmissReportingService from "../avetmiss-reporting-service";
import uniq from "lodash/uniq";
import moment from "moment";

// ----------------------------
//  Actions
// ----------------------------

export const onLoadForm = history => {
  return dispatch => {
    dispatch(LoadFormRequest());

    const service = AvetmissReportingService();

    service
      .getStatus()
      .then(resp => {
        if (!resp.data.validToken) {
          history.push("/Admin/Avetmiss/Authorise/");
          return;
        }

        if (
          resp.status !== 204 &&
          resp.data.reports !== null &&
          resp.data.reports !== [] &&
          resp.data.reports.length > 0
        ) {
          history.push("/Admin/Avetmiss/Submission/");
          return;
        }

        service
          .getCollectionTypes()
          .then(resp => {
            const years = resp.data.map(collection => collection.year);
            const uniqueYears = uniq(years);

            dispatch(LoadFormSuccess(uniqueYears, resp.data));
          })
          .catch(err => {
            dispatch(LoadFormFailure());
            dispatch(
              addNotification(
                "Sorry, an error occurred trying to get the Avetmiss Reporting Collection Types.",
                "error"
              )
            );

            if (
              err.response.data.Message ===
              "The NCVER API returned an unauthorised response. You will need to login again."
            )
              history.push("/Admin/Avetmiss/Authorise/");
          });
      })
      .catch(err => {
        dispatch(LoadFormFailure());
        dispatch(
          addNotification(
            "Sorry, an error occurred trying to get the Avetmiss Reporting Collection Types.",
            "error"
          )
        );
      });
  };
};

const LoadFormRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_LOAD_FORM_REQUEST
});

const LoadFormSuccess = (years, collections) => ({
  type: ActionTypes.AVETMISS_REPORTING_LOAD_FORM_SUCCESS,
  payload: {
    years,
    collections
  }
});

const LoadFormFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_LOAD_FORM_FAILURE
});

// Async
export const onSubmit = (
  collectionStartDate,
  collectionEndDate,
  state,
  version,
  year,
  period,
  history
) => dispatch => {
  dispatch(onSubmitRequest());

  const service = AvetmissReportingService();

  let model = {
    collectionStartDate: moment(collectionStartDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    ),
    collectionEndDate: moment(collectionEndDate, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    ),
    state,
    year,
    period,
    version
  };

  service
    .submitRequest(model)
    .then(resp => {
      dispatch(onSubmitSuccess());
      dispatch({
        type: "AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_SUCCESS",
        payload: {
          files: resp.data.reports,
          year: resp.data.year,
          period: resp.data.period,
          version: resp.data.version,
          collectionStartDate: collectionStartDate,
          collectionEndDate: collectionEndDate,
          state: state,
        },
      });
      history.push("/Admin/Avetmiss/Submission/");
    })
    .catch(err => {
      dispatch(onSubmitFailure());
      dispatch(
        addNotification("There was an issue generating the NAT files.", "error")
      );
    });
};

const onSubmitRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMIT_REQUEST
});
const onSubmitSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMIT_SUCCESS
});
const onSubmitFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMIT_FAILURE
});

// ----------------------------
//  Form Actions
// ----------------------------

export const formInputChange = (name, value, error) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.AVETMISS_REPORTING_FORM_INPUT_CHANGE,
      payload: {
        name: name,
        value: value,
        error: error
      }
    });

    switch (name) {
      case "year":
        const periods = getState()
          .avetmissReporting.collections.filter(c => c.year === value)
          .map(collection => collection.period);

        dispatch({
          type: ActionTypes.AVETMISS_REPORTING_YEAR_CHANGE,
          payload: periods
        });
        break;

      case "period":
        if (value === "") return;

        const collections = getState()
          .avetmissReporting.collections.filter(
            c =>
              c.year === getState().avetmissReporting.fields.year &&
              c.period === value
          )
          .map(collection => ({
            collectionStartDate: moment(collection.collectionStartDate).format(
              "DD/MM/YYYY"
            ),
            collectionEndDate: moment(collection.collectionEndDate).format(
              "DD/MM/YYYY"
            ),
            version: collection.version
          }));

        dispatch({
          type: ActionTypes.AVETMISS_REPORTING_PERIOD_CHANGE,
          payload: collections[0]
        });
        break;
      default:
        break;
    }
  };
};
