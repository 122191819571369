
import axios from 'axios';
import Config from '../../../../config'
import {
    LOQATE_BASE_URL,
    LOQATE_FIND_URL,
    LOQATE_RETRIEVE_URL
} from '../constants/loqate';

const Loqate = class {
    constructor(username, password, baseUrl) {
        this.username = username;
        this.password = password;
        this.baseURL = baseUrl;
    }

    static initialise(baseUrl = LOQATE_BASE_URL) {
        if (!this.instance) {
            const username = Config.loqateUsername;
            const password = Config.loqatePassword;

            this.instance = new Loqate(username, password, baseUrl);

            this.instance.api = axios.create({
                baseURL: baseUrl,
                timeout: 60000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        }

        return this.instance;
    }

    async findAddress(query) {
        const { id, fullAddress, country, sourceOfTruth, featureOptions } = query;

        if (!this.api) {
            throw new Error('Loqate error: Initialise Loqate first.');
        }

        const params = new URLSearchParams({
            country: country,
            fullAddress: fullAddress
        });

        if (id) {
            params.set('id', id);
        }

        if (featureOptions) {
            params.set('featureOptions', featureOptions);
        }

        // Attribute is optional - for Australia and New Zealand only.Default is AUPAF for Australia and NZAD for New Zealand.
        if (sourceOfTruth) {
            params.set('sourceOfTruth', sourceOfTruth);
        }

        const findEndpointUrl = `${LOQATE_BASE_URL}/${LOQATE_FIND_URL}?${params.toString()}`;
        const apiConfig = this._getApiConfig();

        const resp = await this.api.get(findEndpointUrl, apiConfig)
            .catch(function (error) {
                if (error.response && error.response.data) {
                    throw new Error(`Loqate error: ${JSON.stringify(error.response.data)}`)
                }

                throw new Error(`Loqate error: ${JSON.stringify(error)}`)
            });

        if (resp.status !== 200) {
            throw new Error(`Loqate error: Failed to find address.`);
        }

        if (resp.data.status !== 'SUCCESS') {
            var errorMessages = ['Failed to find address.'];

            if (resp.data.messages && resp.data.messages.length) {
                errorMessages = resp.data.messages;
            }

            throw new Error(`Loqate error: ${errorMessages.join('. ')}`);
        }

        return resp.data.payload;
    }

    async retrieveAddress(id) {
        const params = new URLSearchParams({ id: id });
        const retrieveEndpointUrl = `${LOQATE_BASE_URL}/${LOQATE_RETRIEVE_URL}?${params.toString()}`;

        const apiConfig = this._getApiConfig();

        const resp = await this.api.get(retrieveEndpointUrl, apiConfig)
            .catch(function (error) {
                if (error.response && error.response.data) {
                    throw new Error(`Loqate error: ${JSON.stringify(error.response.data)}`)
                }

                throw new Error(`Loqate error: ${JSON.stringify(error)}`)
            });

        if (resp.status !== 200) {
            throw new Error(`Loqate error: Failed to retrieve address.`);
        }

        if (resp.data.status !== 'SUCCESS') {
            var errorMessages = ['Failed to retrieve address.'];

            if (resp.data.messages && resp.data.messages.length) {
                errorMessages = resp.data.messages;
            }

            throw new Error(`Loqate error: ${errorMessages.join('. ')}`);
        }

        return resp.data.payload;
    }

    _getApiConfig() {
        const config = {
            auth: {
                username: this.username,
                password: this.password
            }
        }

        return config;
    }
}

export default Loqate;