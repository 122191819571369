/**
 * Register API service
 */
import HttpClient from "../../coreLib/http/httpClient";

const RegisterService = () => {
  let httpClient = HttpClient();

  const registerUser = (data, registerType) => {
    let payload = {
      ...data,
      isCorporate: registerType === "corporate"
    };

    return httpClient.post("/v1/accounts", payload);
  };

  return {
    registerUser
  };
};

export default RegisterService;
