import React from "react";
import ContentSection from "components/Common/content-section";
import WhyTrainWithUsPoints from "features/why-train-with-us-points/why-train-with-us-points";
import "./why-train-with-us.scss";

const WhyTrainWithUs = () => {
  return (
    <ContentSection bgClass="food-bowl">
      <div className="why-train-with-us">
        <h1>So Why Train With Us?</h1>
        <WhyTrainWithUsPoints />
      </div>
    </ContentSection>
  );
};

export default WhyTrainWithUs;
