/**
 * Radio Button List
 * Adam Luck @solvable Jun 13, 2019
 * - Release notes:
 *  7.21.2019  Rcnet - Fix issues in validation (required and custom). Changed logic when doing the validation.
 */
import React, { Component } from "react";
import { Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import "./Fields.scss";

const EVENT_DEBOUNCE = 2000;

class RadioButtonList extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    showBorderError: PropTypes.bool.isRequired,
    onFieldError: PropTypes.func,
    enableFieldColorError: PropTypes.bool,
    enableFieldErrorMessage: PropTypes.bool,
    enableFieldErrorNotification: PropTypes.bool,
  };

  static defaultProps = {
    showBorderError: false,
    enableFieldColorError: true,
    enableFieldErrorMessage: false,
    enableFieldErrorNotification: true,
    onFieldError: () => {},
  };

  state = {
    value: this.props.value,
    error: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.debouncedCallback = debounce(
      (evt, error) => this.fireFieldErrorEvent(error),
      EVENT_DEBOUNCE
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    return { value: props.value };
  }

  onChange = (evt) => {
    this._detectChange(evt.target.value, false);
  };

  onBlur = (evt) => {
    let error = this._detectChange(evt.target.value, true);

    // Persist react synthetic event
    evt.persist();
    if (error) this.debouncedCallback(evt, error);
  };

  // Note:
  // 1. If we hit the tab changed event we only validate the Value of the component not the individual radio buttons. So we get the this.props.value.
  // 2. If we hit the input onChanged event we are validating the individual selected radio button list. So we are get the value from evt.target.value.
  //
  // Required field validation only triggers when hitting the onBlur event.
  _detectChange = (targetValue, isTabEvt) => {
    const name = this.props.name;
    const value = isTabEvt ? this.props.value : targetValue; //evt.target.value;
    const error = this.props.validate ? this.props.validate(value) : false;

    this.setState({ value, error });
    this.props.onChange({ name, value, error });

    return error;
  };

  fireFieldErrorEvent = (error) => this.props.onFieldError(error);

  getValues = (items) => {
    return items.map(function(item) {
      return item.value;
    });
  };

  getLabelNames = (items) => {
    return items.map(function(item) {
      return item.label ? item.label : item.value;
    });
  };

  // Note: Internal function, used by this component
  _validateField = () => {
    if (!this._isMounted) {
      return;
    }
    let error = this._detectChange(this.state.value);
    if (error) this.debouncedCallback(null, error);
    return { name: this.props.name, error: error };
  };

  _isValidComponentInstance = () => {
    return this._isMounted;
  };

  isUndefined = (value) => {
    return value === null || value === "" || value === "" || value === undefined
      ? true
      : false;
  };

  render() {
    const {
      //validate,
      items,
      enableFieldColorError,
      enableFieldErrorMessage,
      enableFieldErrorNotification,
      onFieldError,
      ...other
    } = this.props;

    let values = this.getValues(items);
    let labels = this.getLabelNames(items);

    return (
      <div style={{ display: "inline-block" }}>
        <div
          // Note: No need to use isRequired we already have validate() to do the checking from the consumer side.
          // Preventing it from coupling yo only one type of validation (isRequired).
          // className={
          //   this.props.isRequired && this.isUndefined(this.props.value)
          //     ? "form-check form-check-inline radio-list field-error"
          //     : "form-check form-check-inline"
          // }
          className={
            this.state.error ||
            (this.props.showBorderError && this.isUndefined(this.props.value))
              ? "form-check form-check-inline radio-list field-error"
              : "form-check form-check-inline"
          }
        >
          {values.map((value, i) => {
            return (
              <React.Fragment key={i}>
                <Input
                  //{...other}
                  id={this.props.name + i}
                  name={this.props.name + i}
                  type="radio"
                  placeholder={this.props.placeholder}
                  onChange={this.onChange}
                  value={value}
                  className="radio-inline-item"
                  checked={this.props.value == value}
                  onBlur={this.onBlur}
                />
                <Label
                  className="form-check-label mr-2"
                  for={this.props.name + i}
                >
                  {labels[i]}
                </Label>
              </React.Fragment>
            );
          })}
        </div>
        {this.state.error && enableFieldErrorMessage && (
          <small className="text-danger">{this.state.error}</small>
        )}
      </div>
    );
  }
}

export default RadioButtonList;
