/**
 * Register Box Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  fields: {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    isCorporate: false,
    noLastName: false,
  },
  fieldErrors: {},
};

function RegisterBoxReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.REGISTER_BOX_REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.REGISTER_BOX_REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fields: initialState.fields,
      };

    case ActionTypes.REGISTER_BOX_REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    // ----------------------------
    //  Form Action Types
    // ----------------------------

    case ActionTypes.REGISTER_BOX_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });

      return stateResult;

    case ActionTypes.REGISTER_BOX_FORM_NO_LAST_NAME:
      return {
        ...state,
        noLastName: true,
        fields: {
          ...state.fields,
          lastName: "",
        },
      };

    default:
      return state;
  }
}

export default RegisterBoxReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectRegisterBox = (state) => state.register;
