import { ActionTypes } from "./system-action-types";

const initialState = {
  loggedIn: false,
  loggedInUser: {
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    hasCourses: false,
    userType: 0,
  },
  fields: {
    username: "",
    password: "",
  },
  isLoading: false,
  urlRedirectionViaImpersonation: "",
};

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Login Action Types
    // ----------------------------

    case ActionTypes.SYS_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.SYS_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggedInUser: {
          ...state.loggedInUser,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          id: action.payload.id,
          email: action.payload.email,
          userType: action.payload.userType,
        },
        fields: {
          ...state.fields,
          username: initialState.fields.username,
          password: initialState.fields.password,
        },
        isLoading: false,
      };

    case ActionTypes.SYS_LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        fields: {
          ...state.fields,
          password: initialState.fields.password,
        },
        isLoading: false,
      };

    case ActionTypes.SYS_LOGOUT_REQUEST:
      return {
        ...state,
      };

    case ActionTypes.SYS_LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        loggedInUser: {
          ...state.loggedInUser,
          firstName: initialState.loggedInUser.firstName,
          lastName: initialState.loggedInUser.lastName,
        },
        fields: {
          ...state.fields,
          username: initialState.fields.username,
          password: initialState.fields.password,
        },
      };

    case ActionTypes.SYS_LOGOUT_FAILURE:
      return {
        ...state,
      };

    case ActionTypes.SYS_LOGIN_VALIDATION_ERROR:
      return {
        ...state,
      };

    case ActionTypes.SYS_LOGIN_USER_LOAD_IDENTITY_STATE:
      return {
        ...state,
        loggedIn: true,
        loggedInUser: {
          ...state.loggedInUser,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          id: action.payload.id,
          email: action.payload.email,
          hasCourses: action.payload.hasCourses,
          userType: action.payload.userType,
        },
      };

    case ActionTypes.SYS_LOGIN_IMPERSONATION_INIT:
      return {
        ...state,
        urlRedirectionViaImpersonation: "",
      };

    case ActionTypes.SYS_LOGIN_IMPERSONATION_REQUEST:
      return {
        ...state,
      };

    case ActionTypes.SYS_LOGIN_IMPERSONATION_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loggedInUser: {
          ...state.loggedInUser,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          id: action.payload.id,
          email: action.payload.email,
          userType: action.payload.userType,
        },
        urlRedirectionViaImpersonation: action.payload.redirectToUrl,
        isLoading: false,
      };

    case ActionTypes.SYS_LOGIN_IMPERSONATION_FAILURE:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
      };

    // ----------------------------
    //  Form Action Types
    // ----------------------------

    case ActionTypes.SYS_LOGIN_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });

      return stateResult;

    default:
      return state;
  }
}

/**
 * Selectors
 */

export const selectLoginData = (state) => state.system.login;
