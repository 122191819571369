/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../../redux/system/system-action-creators";
import NswReprintsService from "../../service";

// ----------------------------
//  Actions
// ----------------------------

export const loadData = () => {
  return dispatch => {
    dispatch(loadDataRequest());
    const service = NswReprintsService();

    service
      .loadData()
      .then(resp => {
        dispatch(loadDataSuccess(resp.data));
      })
      .catch(err => {
        dispatch(loadDataFailure(err));
        dispatch(
          addNotification("Unable to load data for this page.", "error")
        );
      });
  };
};

const loadDataRequest = () => ({
  type: ActionTypes.NSW_REPRINTS_LOAD_DATA_REQUEST
});

const loadDataSuccess = payload => ({
  type: ActionTypes.NSW_REPRINTS_LOAD_DATA_SUCCESS,
  payload: payload
});

const loadDataFailure = err => ({
  type: ActionTypes.NSW_REPRINTS_LOAD_DATA_FAILURE,
  payload: err
});

export const keepDataTableSettings = settings => ({
  type: ActionTypes.NSW_REPRINTS_DATATABLE_KEEP_SETTINGS,
  payload: {
    settings
  }
});

export const clearDataTableSearchboxFilter = () => ({
  type: ActionTypes.NSW_REPRINTS_DATATABLE_CLEAR_SEARCHBOX,
});
