import React from "react";
export const nsw = [
  {
    title: "I’m in NSW - What certificate do I need?",
    content: (
      <>
        If you are wanting to work in a food business in NSW it is a requirement
        to have a specific NSW Food Safety Supervisor Certificate. We are
        approved and registered with the NSW Food Authority – RTO Approval
        Number: 25085.
        <br />
        <br />
        Please click on the link below for your assurance.
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors/approved-training-providers/all-rtos
        </a>
      </>
    ),
  },
  {
    title: "How do I get my NSW Food Safety Supervisor Certificate?",
    content: (
      <>
        It&rsquo;s as easy as following these <strong>4 steps</strong> for the
        quickest, cheapest and most convenient way to get your NSW Food Safety
        Supervisor Certificate:
        <br />
        <br />
        <ol className="my-0">
          <li>Register</li>
          <li>
            Select the NSW $179 NSW Food Safety Supervisor Certificate course
          </li>
          <li>
            Make payment and enter the postal address where your NSW Food Safety
            Supervisor Certificate is to be posted to
          </li>
          <li>
            Start enrolment and complete online course. Third-party checklist
            and case studies submitted online
          </li>
        </ol>
        <br />
        <br />
        Upon completion of the course you will receive the NSW Food Safety
        Supervisor Certificate PLUS a Nationally Accredited Statement of
        Attainment that is recognised in every other State of Australia.
      </>
    ),
  },
  {
    title: "How long does it take to do the course?",
    content: (
      <>
        Depending on your level of understanding and comprehension it can take
        anything from three to four hours to complete. Some students have taken
        eight or nine hours and some, longer. There is no right answer to this
        question but remember, we allow you up to twelve months to complete your
        training.
      </>
    ),
  },
  {
    title:
      "How long does it take to get the NSW Food Safety Supervisor Certificate (hard copy)?",
    content: (
      <>
        We express post your NSW Food Safety Supervisor Certificate to your
        nominated postal address, within 5-7 business days of you having
        completed your online training.
        <br />
        <br />
        You will also receive the Nationally Recognised Statement of Attainment,
        which will be emailed to you automatically. This Statement of Attainment
        is also acceptable in all the other States of Australia.
      </>
    ),
  },
  {
    title:
      "I didn’t get my NSW Food Safety Supervisor Certificate in the mail?",
    content: (
      <>
        If a certificate has been deemed successfully delivered by Australia
        Post (or has been returned due to an incorrect or insufficient address
        being supplied) there will be a $45 fee charged for any reissued
        certificates. Please email or call our student support team to assist
        you further.
      </>
    ),
  },
  {
    title: "I need a reprint of my NSW Food Safety Supervisor Certificate?",
    content: (
      <>
        In your student portal you will have the option to purchase a reprint of
        your NSW certificate. You must have already completed your NSW
        certificate with Train to Gain Pty Ltd T/A Food Safety Education in
        order to obtain another certificate. Simply select the option for a
        reprint. There is a $45.00 fee. This needs to be paid online using a
        credit card at the time of your order. You MUST enter your name as you
        had previously registered with us so we can confirm that you have
        successfully completed the training. A current postal address is
        required. Your new certificate will be posted within 5-7 business days
        of payment.
      </>
    ),
  },
  {
    title: "Do I need a prerequisite for this training?",
    content: (
      <>
        No! There are no prerequisites for enrolling in our courses, so anyone
        can join.
      </>
    ),
  },
  {
    title:
      "Will I get a NSW Food Safety Supervisor Certificate if I do the 3 units from the health sector?",
    content: (
      <>
        NO!! The NSW Food Authority only recognises units from the hospitality
        and retail sector.
      </>
    ),
  },
  {
    title:
      "Why does the NSW Food Safety Supervisor Certificate course cost more than the other states?",
    content: (
      <>
        The NSW Food Authority have implemented their own Food Safety Supervisor
        Certificate that is required in NSW only.
        <br />
        <br />
        The costs are explained below:
        <br />
        <br />
        Total price for a NSW Food Safety Supervisor Certificate with us is
        $179.00. With Train to Gain Pty Ltd T/A Food Safety Education (RTO #
        22361), there are no hidden fees or charges.
        <br />
        <br />
        <strong>$139 Nationally Accredited Certificate</strong> - This can be
        downloaded automatically at the successful completion of your course.
        <br />
        <br />
        <strong>$30 NSW Food Safety Supervisor Certificate</strong> - This is
        what the NSW Food Authority charge us!
        <br />
        <br />
        <strong>
          $10 to cover Express Postage, Administration &amp; Handling
        </strong>{" "}
        - This ensures you will receive your certificate within 5 to 7 business
        days via express post after you have successfully completed the course.
      </>
    ),
  },
  {
    title:
      "What do I do when my NSW Food Safety Supervisor certification expires?",
    content: (
      <>
        The NSW Food Safety Supervisor Certificate needs to be renewed every 5
        years. You will need to ensure that the NSW Food Safety Supervisor
        Certificate is kept on the premises at all times, as council inspectors
        may request to view the certificate during an inspection.
        <br />
        <br />
        Students seeking to recertify must renew a NSW Food Safety Supervisor
        certificate that is due to expire, or has expired, within the
        immediately preceding 90 days.
        <br />
        <br />
        Please click on the link below to gain more information from the NSW
        Food Authority website.
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected
        </a>
      </>
    ),
  },
  {
    title: "How do I attain my NSW Food Safety Refresher course?",
    content: (
      <>
        We offer the NSW Food Safety Refresher course for ONLY $149. If you
        already have a NSW Food Safety Supervisor Certificate, you can renew it
        online with us today! We accept previous certificates from other
        registered training providers as long as you have your previous NSW Food
        Safety Supervisor Certificate number.
        <br />
        <br />
        Simply:
        <br />
        <br />
        <ol className="my-0">
          <li>
            Log back in (top right) of the website or re register if you have
            updated your details.
          </li>
          <li>Select the NSW Refresher Course option.</li>
        </ol>
        <br />
        Please click on the link below to gain more information from the NSW
        Food Authority website.
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.foodauthority.nsw.gov.au/rp/fss-food-safety-supervisors?utm_campaign=redirected
        </a>
      </>
    ),
  },
];
