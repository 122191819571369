export const FoodSafetySupervisorCertificateHealthAndCommunityThirdPartyFormTasks = [
  {
    taskId: 203,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task: "Handle food according to the food safety program",
    displayOrder: 1,
  },
  {
    taskId: 204,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Identify and report processes and practices which are not consistent with the food safety program",
    displayOrder: 2,
  },
  {
    taskId: 205,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Take corrective action, within level of responsibility, and according to the food safety program",
    displayOrder: 3,
  },
  {
    taskId: 206,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 1: Maintain food safety while carrying out food handling activities",
    task:
      "Report any corrective action to supervisor, and according to legislative and organisation protocols",
    displayOrder: 4,
  },
  {
    taskId: 207,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Ensure personal hygiene meets the requirements of the food safety program",
    displayOrder: 5,
  },
  {
    taskId: 208,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Report health conditions and/or illness, as required by the food safety program",
    displayOrder: 6,
  },
  {
    taskId: 209,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 2: Comply with personal hygiene standards",
    task:
      "Wear appropriate clothing and footwear as required by the food safety program",
    displayOrder: 7,
  },
  {
    taskId: 210,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Maintain the workplace in a clean and tidy order to meet workplace requirements",
    displayOrder: 8,
  },
  {
    taskId: 211,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task:
      "Comply with workplace measures to prevent pests entering food premises",
    displayOrder: 9,
  },
  {
    taskId: 212,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 3: Contribute to the cleanliness of food handling areas, according to the food safety program",
    task: "Identify and report indicators of pest presence",
    displayOrder: 10,
  },
  {
    taskId: 213,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task:
      "Keep food for disposal separate, according to food safety program requirements",
    displayOrder: 11,
  },
  {
    taskId: 214,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 4: Dispose of food according to food safety program",
    task: "Dispose of food according to the food safety program requirements",
    displayOrder: 12,
  },
  {
    taskId: 215,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task:
      "Implement food safety handling processes and procedures that meet the requirements of the food safety program",
    displayOrder: 13,
  },
  {
    taskId: 216,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task:
      "Implement processes and procedures to maintain the food handling area to meet the requirements of the food safety program",
    displayOrder: 14,
  },
  {
    taskId: 217,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task:
      "Communicate food safety requirements and procedures to others in the workplace",
    displayOrder: 15,
  },
  {
    taskId: 218,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 5: Monitor the implementation of the food safety program in the workplace",
    task: "Support people in the food area to meet food safety requirements",
    displayOrder: 16,
  },
  {
    taskId: 219,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task: "Implement procedures for responding to non-conformance",
    displayOrder: 17,
  },
  {
    taskId: 220,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task: "Identify causes of non -compliance",
    displayOrder: 18,
  },
  {
    taskId: 221,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task:
      "Implement measures to prevent the recurrence of non-conformance issues",
    displayOrder: 19,
  },
  {
    taskId: 222,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task:
      "Develop or revise procedures to support the effective control of food safety hazards",
    displayOrder: 20,
  },
  {
    taskId: 223,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 6: Take corrective action when a food safety hazard is found not to be in control",
    task:
      "Communicate all additional and/or revised processes and procedures to food handlers",
    displayOrder: 21,
  },
  {
    taskId: 224,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 7: Maintain food safety in the workplace",
    task:
      "Identify processes and procedures that could result in a breach of food safety procedures and take preventive or corrective action",
    displayOrder: 22,
  },
  {
    taskId: 225,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 7: Maintain food safety in the workplace",
    task:
      "Support food handlers to complete food safety records to meet the food safety program and legal requirements",
    displayOrder: 23,
  },
  {
    taskId: 226,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 8: Implement the food safety program",
    task:
      "Monitor and control food safety hazards as required by the food safety program",
    displayOrder: 24,
  },
  {
    taskId: 227,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 8: Implement the food safety program",
    task:
      "Promptly report non-conformance where food safety control requirements are not met and take corrective action",
    displayOrder: 25,
  },
  {
    taskId: 228,
    courseId: 1008,
    version: 1,
    taskGroup: "Task 8: Implement the food safety program",
    task:
      "Record food safety information to meet the requirements of the food safety program",
    displayOrder: 26,
  },
  {
    taskId: 229,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 9: Assist in the identification of breaches of food safety procedures",
    task:
      "Identify and report processes and conditions which could result in a breach of food safety procedures",
    displayOrder: 27,
  },
  {
    taskId: 230,
    courseId: 1008,
    version: 1,
    taskGroup:
      "Task 9: Assist in the identification of breaches of food safety procedures",
    task:
      "Take corrective action to meet the requirements of the food safety program",
    displayOrder: 28,
  },
  {
    taskId: 231,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task:
      "Apply knowledge of aspects of the food safety program relevant to the work role",
    displayOrder: 29,
  },
  {
    taskId: 232,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with personal hygiene requirements",
    displayOrder: 30,
  },
  {
    taskId: 233,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Comply with workplace cleanliness requirements",
    displayOrder: 31,
  },
  {
    taskId: 234,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Demonstrate appropriate disposal of food",
    displayOrder: 32,
  },
  {
    taskId: 235,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Apply knowledge of all aspects of the food safety program",
    displayOrder: 33,
  },
  {
    taskId: 236,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Meet reporting requirements",
    displayOrder: 34,
  },
  {
    taskId: 237,
    courseId: 1008,
    version: 1,
    taskGroup:
      "General 1: It is critical that the candidate demonstrate the ability to:",
    task: "Undertake hazard identification and management",
    displayOrder: 35,
  },
];
