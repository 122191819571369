export const FoodSafetySupervisorCertificateHospitalityAndRetailThirdPartyFormTasks = [
  {
    taskId: 113,
    courseId: 4,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 114,
    courseId: 4,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 115,
    courseId: 4,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 116,
    courseId: 4,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 117,
    courseId: 4,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 118,
    courseId: 4,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 119,
    courseId: 4,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 120,
    courseId: 4,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 121,
    courseId: 4,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 122,
    courseId: 4,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 123,
    courseId: 4,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 124,
    courseId: 4,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 125,
    courseId: 4,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 126,
    courseId: 4,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 127,
    courseId: 4,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 15,
  },
  {
    taskId: 128,
    courseId: 4,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 16,
  },
  {
    taskId: 129,
    courseId: 4,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 17,
  },
  {
    taskId: 130,
    courseId: 4,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 18,
  },
  {
    taskId: 131,
    courseId: 4,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 19,
  },
  {
    taskId: 132,
    courseId: 4,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 20,
  },
  {
    taskId: 133,
    courseId: 4,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 21,
  },
  {
    taskId: 134,
    courseId: 4,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 22,
  },
  {
    taskId: 135,
    courseId: 4,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 23,
  },
  {
    taskId: 136,
    courseId: 4,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 24,
  },
  {
    taskId: 137,
    courseId: 4,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 25,
  },
  {
    taskId: 138,
    courseId: 4,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 26,
  },
  {
    taskId: 139,
    courseId: 4,
    version: 1,
    taskGroup: "Task 8: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 27,
  },
  {
    taskId: 140,
    courseId: 4,
    version: 1,
    taskGroup: "Task 8: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 28,
  },
  {
    taskId: 141,
    courseId: 4,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 29,
  },
  {
    taskId: 142,
    courseId: 4,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 30,
  },
  {
    taskId: 143,
    courseId: 4,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 31,
  },
  {
    taskId: 144,
    courseId: 4,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 32,
  },
  {
    taskId: 145,
    courseId: 4,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 33,
  },
  {
    taskId: 146,
    courseId: 4,
    version: 1,
    taskGroup: "Task 10: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 34,
  },
  {
    taskId: 147,
    courseId: 4,
    version: 1,
    taskGroup: "Task 10: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 35,
  },
  {
    taskId: 148,
    courseId: 4,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 36,
  },
  {
    taskId: 149,
    courseId: 4,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 37,
  },
  {
    taskId: 150,
    courseId: 4,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 38,
  },
  {
    taskId: 151,
    courseId: 4,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 39,
  },
  {
    taskId: 152,
    courseId: 4,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 40,
  },
  {
    taskId: 153,
    courseId: 4,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 41,
  },
  {
    taskId: 154,
    courseId: 4,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 42,
  },
  {
    taskId: 155,
    courseId: 4,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 43,
  },
  {
    taskId: 156,
    courseId: 4,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 44,
  },
  {
    taskId: 157,
    courseId: 4,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 45,
  },
];
