import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import ContactBoxContainer from "components/Contact-Box/Contact-Box.Container";
import LinkButton from "components/link-button/link-button";
import GreenTickBalls from "features/green-tick-balls/green-tick-balls";

const ContactUs = () => {
  return (
    <ContentSection bgClass="veggies-bg off-white flex-100">
      <PageTagging title="Contact Us - Food Safety Education | Train to Gain" />
      <div className="ttg-container">
        <h1>Contact Us</h1>
        <GreenTickBalls
          items={[
            <span>
              Please read our informative{" "}
              <Link to="/FAQs/">frequently asked questions (FAQs)</Link> first
              as these may instantly assist you.
            </span>,
            <span>
              Student support is available from 9am-5pm Monday to Friday (QLD
              Time) via email and phone.
            </span>,
            <span>Email us using the email box below.</span>,
            <span>
              Call us on <a href="tel:+61400757645">0400 757 645</a>
            </span>,
          ]}
        />
        <LinkButton
          to="/online-food-safety-training-courses/"
          className="ttg-btn ttg-orange-outline-btn my-4"
          btnText="Start Your Food Safety Training Now"
        />
        <Row>
          <Col md={6}>
            <ContactBoxContainer />
          </Col>
        </Row>
      </div>
    </ContentSection>
  );
};

export default ContactUs;
