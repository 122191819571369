import SolvableDataTable from "../../../components/solvable-datatable/solvable-datatable";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import React, { Component } from "react";
import EditDialog from "./Modals/edit";
import DeleteDialog from "./Modals/delete";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {}
    };
    this.actionButtonHandler = this.actionButtonHandler.bind(this);
  }

  onSettingsChanged = settings => {
    this.setState({ settings });
  };

  columns = [
    {
      accessor: "id",
      show: false
    },
    {
      Header: "Promo Code",
      accessor: "discountCode",
      searchable: true
    },
    {
      accessor: "discountPercentage",
      show: false
    },
    {
      Header: "Discount %",
      accessor: "discountPercentage2",
      searchable: true
    },
    {
      accessor: "discountAmount",
      show: false
    },
    {
      Header: "Discount $",
      accessor: "discountAmount2",
      searchable: true
    },

    {
      accessor: "expiryDate",
      show: false
    },
    {
      Header: "Expires On",
      accessor: "expiryDate2",
      searchable: true
    },
    {
      accessor: "isOneUseOnly",
      show: false
    },
    {
      Header: "One Time Use",
      accessor: "isOneUseOnly2",
      searchable: true
    }
  ];

  actionButtonHandler = evt => {
    const {
      toggleEditDialog,
      toggleDeleteDialog,
      toggleAddDialog
    } = this.props;
    switch (evt.type) {
      case "button1":
        toggleAddDialog();
        break;
      case "button2":
        toggleEditDialog(evt.data);
        break;
      case "button3":
        toggleDeleteDialog(evt.data);
        break;
    }
  };

  render() {
    const {
      toggleEditDialog,
      toggleDeleteDialog,
      onFieldError,
      onDelete,
      onEdit,
      onAdd,
      dataTable,
      dialog,
      onSettingsChanged,
      onDialogInputChange
    } = this.props;
    const { data, isProcessing, settings } = dataTable;
    const {
      dialogs,
      fields,
      isProcessing: isDialogProcessing,
      isEdit
    } = dialog;
    return (
      <div>
        <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
          <EditDialog
            dialogs={dialogs}
            toggleEditDialog={toggleEditDialog}
            fields={fields}
            onEdit={onEdit}
            onAdd={onAdd}
            isEdit={isEdit}
            isProcessing={isDialogProcessing}
            onFieldError={onFieldError}
            onDialogInputChange={onDialogInputChange}
          />
          <DeleteDialog
            dialogs={dialogs}
            onDelete={onDelete}
            isProcessing={isDialogProcessing}
            toggleDeleteDialog={toggleDeleteDialog}
            fields={fields}
          />
          <SolvableDataTable
            defaultPageSize={50}
            columns={this.columns}
            actionButtons={{
              type: "customButton",
              containerWidth: 128,
              custom: [
                {
                  target: "button1",
                  icon: "fa fa-plus",
                  tooltip: "Add Promo Code"
                },
                {
                  target: "button2",
                  icon: "fas fa-edit",
                  tooltip: "Edit Promo Code"
                },
                {
                  target: "button3",
                  icon: "fas fa-trash-alt",
                  tooltip: "Delete Promo Code"
                }
              ]
            }}
            keepSettings={true}
            stateSettings={settings}
            onSettingsChanged={onSettingsChanged}
            onActionButtonClick={this.actionButtonHandler}
            data={data}
          />
        </BlockUiFx>
      </div>
    );
  }
}

export default DataTable;
