import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dialogs,
      toggleMarkAsPaidDialog,
      onMarkAsPaid,
      fields,
      isProcessing
    } = this.props;
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={dialogs.showMarkAsPaidDialog}>
          <ModalHeader toggle={() => toggleMarkAsPaidDialog()}>
            Mark As Paid
          </ModalHeader>
          <ModalBody>
            <section>
              Are you sure you want to mark this purchase as paid? Please note,
              this cannot be undone.
            </section>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() => onMarkAsPaid(fields.prePurchaseId)}
              className="ttg-btn"
              isLoading={isProcessing}
            >
              Save
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
