export const yesNo = [
  { value: "TRUE", label: "Yes" },
  { value: "FALSE", label: "No" },
];

export const genders = [
  { value: "F", label: "Female" },
  { value: "M", label: "Male" },
  { value: "O", label: "Other" },
];

export const levelOfSchool = [
  { value: "Year 12 or equivalent" },
  { value: "Year 11 or equivalent" },
  { value: "Year 10 or equivalent" },
  { value: "Year 9 or equivalent" },
  { value: "Year 8 or below" },
  { value: "Never attended school" },
];

export const levelOfEducation = [
  { value: "Bachelor degree or higher degree level" },
  { value: "Advanced diploma or associate degree level" },
  { value: "Diploma level" },
  { value: "Certificate IV" },
  { value: "Certificate III" },
  { value: "Certificate II" },
  { value: "Certificate I" },
  {
    value:
      "Other education (including certificates or overseas qualifications)",
  },
];

export const reasonForUndertakingCourse = [
  { value: "To get a job" },
  { value: "To develop my existing business" },
  { value: "To start my own business " },
  { value: "To try for a different career" },
  { value: "To get a better job or promotion" },
  { value: "It was a requirement of my job" },
  { value: "I wanted extra skills for my job" },
  { value: "To get into another course of study" },
  { value: "For personal interest or self-development" },
  { value: "Other reasons" },
];

export const howDidYouHear = [
  { value: "Google" },
  { value: "Twitter" },
  { value: "Facebook" },
  { value: "Word of Mouth" },
  { value: "Referral" },
  { value: "Other" },
];

export const disabilityType = [
  { value: "Hearing/deaf" },
  { value: "Physical" },
  { value: "Intellectual" },
  { value: "Learning" },
  { value: "Mental illness" },
  { value: "Acquired brain impairment" },
  { value: "Vision" },
  { value: "Medical condition" },
  { value: "Other" },
];

export const countries = [
  { value: "Afghanistan" },
  { value: "Albania" },
  { value: "Algeria" },
  { value: "Andorra" },
  { value: "Angola" },
  { value: "Antigua and Barbuda" },
  { value: "Argentina" },
  { value: "Armenia" },
  { value: "Australia" },
  { value: "Austria" },
  { value: "Azerbaijan" },
  { value: "Bahamas" },
  { value: "Bahrain" },
  { value: "Bangladesh" },
  { value: "Barbados" },
  { value: "Belarus" },
  { value: "Belgium" },
  { value: "Belize" },
  { value: "Benin" },
  { value: "Bhutan" },
  { value: "Bolivia" },
  { value: "Bosnia and Herzegovina" },
  { value: "Botswana" },
  { value: "Brazil" },
  { value: "Brunei" },
  { value: "Bulgaria" },
  { value: "Burkina Faso" },
  { value: "Burundi" },
  { value: "Côte d'Ivoire" },
  { value: "Cabo Verde" },
  { value: "Cambodia" },
  { value: "Cameroon" },
  { value: "Canada" },
  { value: "Central African Republic" },
  { value: "Chad" },
  { value: "Chile" },
  { value: "China" },
  { value: "Colombia" },
  { value: "Comoros" },
  { value: "Congo (Congo-Brazzaville)" },
  { value: "Costa Rica" },
  { value: "Croatia" },
  { value: "Cuba" },
  { value: "Cyprus" },
  { value: "Czechia" },
  { value: "Democratic Republic of the Congo" },
  { value: "Denmark" },
  { value: "Djibouti" },
  { value: "Dominica" },
  { value: "Dominican Republic" },
  { value: "Ecuador" },
  { value: "Egypt" },
  { value: "El Salvador" },
  { value: "Equatorial Guinea" },
  { value: "Eritrea" },
  { value: "Estonia" },
  { value: "Ethiopia" },
  { value: "Fiji" },
  { value: "Finland" },
  { value: "France" },
  { value: "Gabon" },
  { value: "Gambia" },
  { value: "Georgia" },
  { value: "Germany" },
  { value: "Ghana" },
  { value: "Greece" },
  { value: "Grenada" },
  { value: "Guatemala" },
  { value: "Guinea" },
  { value: "Guinea-Bissau" },
  { value: "Guyana" },
  { value: "Haiti" },
  { value: "Holy See" },
  { value: "Honduras" },
  { value: "Hungary" },
  { value: "Iceland" },
  { value: "India" },
  { value: "Indonesia" },
  { value: "Iran" },
  { value: "Iraq" },
  { value: "Ireland" },
  { value: "Israel" },
  { value: "Italy" },
  { value: "Jamaica" },
  { value: "Japan" },
  { value: "Jordan" },
  { value: "Kazakhstan" },
  { value: "Kenya" },
  { value: "Kiribati" },
  { value: "Kuwait" },
  { value: "Kyrgyzstan" },
  { value: "Laos" },
  { value: "Latvia" },
  { value: "Lebanon" },
  { value: "Lesotho" },
  { value: "Liberia" },
  { value: "Libya" },
  { value: "Liechtenstein" },
  { value: "Lithuania" },
  { value: "Luxembourg" },
  { value: "Madagascar" },
  { value: "Malawi" },
  { value: "Malaysia" },
  { value: "Maldives" },
  { value: "Mali" },
  { value: "Malta" },
  { value: "Marshall Islands" },
  { value: "Mauritania" },
  { value: "Mauritius" },
  { value: "Mexico" },
  { value: "Micronesia" },
  { value: "Moldova" },
  { value: "Monaco" },
  { value: "Mongolia" },
  { value: "Montenegro" },
  { value: "Morocco" },
  { value: "Mozambique" },
  { value: "Myanmar (formerly Burma)" },
  { value: "Namibia" },
  { value: "Nauru" },
  { value: "Nepal" },
  { value: "Netherlands" },
  { value: "New Zealand" },
  { value: "Nicaragua" },
  { value: "Niger" },
  { value: "Nigeria" },
  { value: "North Korea" },
  { value: "North Macedonia" },
  { value: "Norway" },
  { value: "Oman" },
  { value: "Pakistan" },
  { value: "Palau" },
  { value: "Palestine State" },
  { value: "Panama" },
  { value: "Papua New Guinea" },
  { value: "Paraguay" },
  { value: "Peru" },
  { value: "Philippines" },
  { value: "Poland" },
  { value: "Portugal" },
  { value: "Qatar" },
  { value: "Romania" },
  { value: "Russia" },
  { value: "Rwanda" },
  { value: "Saint Kitts and Nevis" },
  { value: "Saint Lucia" },
  { value: "Saint Vincent and the Grenadines" },
  { value: "Samoa" },
  { value: "San Marino" },
  { value: "Sao Tome and Principe" },
  { value: "Saudi Arabia" },
  { value: "Senegal" },
  { value: "Serbia" },
  { value: "Seychelles" },
  { value: "Sierra Leone" },
  { value: "Singapore" },
  { value: "Slovakia" },
  { value: "Slovenia" },
  { value: "Solomon Islands" },
  { value: "Somalia" },
  { value: "South Africa" },
  { value: "South Korea" },
  { value: "South Sudan" },
  { value: "Spain" },
  { value: "Sri Lanka" },
  { value: "Sudan" },
  { value: "Suriname" },
  { value: "Swaziland" },
  { value: "Sweden" },
  { value: "Switzerland" },
  { value: "Syria" },
  { value: "Taiwan" },
  { value: "Tajikistan" },
  { value: "Tanzania" },
  { value: "Thailand" },
  { value: "Timor-Leste" },
  { value: "Togo" },
  { value: "Tokelau" },
  { value: "Tonga" },
  { value: "Trinidad and Tobago" },
  { value: "Tunisia" },
  { value: "Turkey" },
  { value: "Turkmenistan" },
  { value: "Tuvalu" },
  { value: "Uganda" },
  { value: "Ukraine" },
  { value: "United Arab Emirates" },
  { value: "United Kingdom" },
  { value: "United States of America" },
  { value: "Uruguay" },
  { value: "Uzbekistan" },
  { value: "Vanuatu" },
  { value: "Venezuela" },
  { value: "Vietnam" },
  { value: "Yemen" },
  { value: "Zambia" },
  { value: "Zimbabwe" },
];

export const languageAtHome = [
  { value: "English" },
  { value: "Other Language" },
];

export const languages = [
  { value: "Arabic" },
  { value: "Awadhi" },
  { value: "Azerbaijani, South" },
  { value: "Bengali" },
  { value: "Bhojpuri" },
  { value: "Burmese" },
  { value: "Chinese, Gan" },
  { value: "Chinese, Hakka" },
  { value: "Chinese, Jinyu" },
  { value: "Chinese, Mandarin" },
  { value: "Chinese, Min Nan" },
  { value: "Chinese, Wu" },
  { value: "Chinese, Xiang" },
  { value: "Chinese, Yue(Cantonese)" },
  { value: "Dutch" },
  { value: "French" },
  { value: "German" },
  { value: "Gujarati" },
  { value: "Hausa" },
  { value: "Hindi" },
  { value: "Italian" },
  { value: "Japanese" },
  { value: "Javanese" },
  { value: "Kannada" },
  { value: "Korean" },
  { value: "Maithili" },
  { value: "Malayalam" },
  { value: "Marathi" },
  { value: "Oriya" },
  { value: "Panjabi, Eastern" },
  { value: "Panjabi, Western" },
  { value: "Persian" },
  { value: "Polish" },
  { value: "Portuguese" },
  { value: "Romanian" },
  { value: "Russian" },
  { value: "Sindhi" },
  { value: "Spanish" },
  { value: "Sunda" },
  { value: "Tamil" },
  { value: "Telugu" },
  { value: "Thai" },
  { value: "Turkish" },
  { value: "Ukrainian" },
  { value: "Urdu" },
  { value: "Vietnamese" },
  { value: "Yoruba" },
  { value: "Other" },
];
