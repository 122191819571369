export const relationshipToObserver = [
  { value: "Past or Present Supervisor/Employer/Manager" },
  { value: "Past or Present Volunteer Supervisor/Manager" },
];

export const currentEmploymentStatus = [
  { value: "Full-time employee" },
  { value: "Part-time employee" },
  { value: "Self employed – not employing others" },
  { value: "Self employed – employing others" },
  { value: "Employed – unpaid worker in a family business" },
  { value: "Unemployed – seeking full-time work" },
  { value: "Unemployed – seeking part-time work" },
  { value: "Not employed – not seeking employment" },
];

export const whereExperience = [{ value: "Business" }, { value: "Volunteer" }];
