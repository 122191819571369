import React, { Component } from "react";
import { Form, FormGroup } from "reactstrap";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../../components/Common/Fields/Input-Field";
import RadioButtonList from "../../components/Common/Fields/Radio-Button-List";
import ContentSection from "components/Common/content-section";
import "./third-party-form.scss";
import AdditionalInfoForm from "./sub-views/additional-info-form";
import ObserverForm from "./sub-views/observer-form";
import ConfirmEmailFormSending from "./confirm-dialog/Confirm-Email-Form-Sending";
import MessageDialog from "./message-dialog/Message-Dialog";
import ButtonFx from "./../../components/Common/Button-Fx/Button-Fx";
import BlockUiFx from "./../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import formHandler from "./../../components/Common/Fields/form-handler";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Auth from "../../coreLib/auth/auth";

class ThirdPartyForm extends Component {
  form = formHandler();

  componentDidMount() {
    const { match, onLoadForm, onLoadSampleForm } = this.props;

    if (match.params.userCourseId.toLowerCase() === "sample")
      onLoadSampleForm(2);
    else if (match.params.userCourseId.toLowerCase().startsWith("sample-")) {
      onLoadSampleForm(
        parseInt(match.params.userCourseId.toLowerCase().replace("sample-", ""))
      );
    } else onLoadForm(match.params.userCourseId);
  }

  getUnitsOfCompetency = (certificateCodes, unitOfCompetencies) => {
    let units = [];

    for (let i = 0; i < certificateCodes.length; i++) {
      units.push(
        <span key={i} className="unit-of-competency">
          {certificateCodes[i]} - {unitOfCompetencies[i]}
        </span>
      );
    }

    return units;
  };

  getTaskItems = (tasks, fieldResponses) => {
    const getData = (id) => {
      return fieldResponses[id] != null ? fieldResponses[id] : undefined;
    };

    return tasks.map((task, i) => (
      <tr key={i} className="task-row">
        <td>{task.task}</td>
        <td>
          <RadioButtonList
            name={`task-${task.taskId}`}
            items={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
            value={getData(`task-${task.taskId}`)}
            onChange={this.props.onInputChange}
          />
        </td>
        <td>
          <InputField
            name={`task-details-${task.taskId}`}
            value={getData(`task-details-${task.taskId}`)}
            onChange={this.props.onInputChange}
            className="mxw-400"
          />
        </td>
      </tr>
    ));
  };

  getTasksTable = (tasks, fieldResponses) => {
    let taskGroups = Array.from(
      new Set(
        tasks.map((x) => {
          return x.taskGroup;
        })
      )
    );

    return taskGroups.map((taskGroup, i) => (
      <table key={i} className="table table-striped third-party-tasks mt-4">
        {i === 0 && (
          <thead>
            <tr className="top-th">
              <th>
                Does the student consistently meet workplace standards in the
                following task area?
              </th>
              <th>Yes / No</th>
              {/* <th>If No, provide a brief explanation</th> */}
              <th>Comments (optional)</th>
            </tr>
          </thead>
        )}
        <tbody>
          <tr className="task-group">
            <th colSpan={3}>{taskGroup}</th>
          </tr>
          {this.getTaskItems(
            tasks.filter((x) => x.taskGroup === taskGroup),
            fieldResponses
          )}
        </tbody>
      </table>
    ));
  };

  render() {
    const {
      courseName,
      certificateCodes,
      unitOfCompetencies,
      studentFirstName,
      studentLastName,
      tasks,
      onInputChange,
      onUpdateDetails,
      onEmail,
      isLoading,
      isLoadingObserverSection,
      isLoadingObserverButton,
      isLoadingForSaveProgress,
      isLoadingForSubmit,
      saveProgress,
      submitForm,
      validate,
      onFieldError,
      isSampleForm,
      showForm,
      thirdPartyFormAdditionalContent,
      thirdPartyFormVersion,
    } = this.props;

    const {
      observerFirstName,
      observerLastName,
      observerEmail,
      additionalComments,
      studentPerformanceSatisfactory,
      confirmThirdPartyDeclaration,
      signature,
    } = this.props.fields;

    const { firstName, lastName, userType } = this.props.loginData.loggedInUser;

    let isLoggedIn = Auth.isAuthenticated();

    if (isLoggedIn === undefined) isLoggedIn = false;

    return (
      <>
        <PageTitle title="Third Party Form" />
        <ConfirmEmailFormSending {...this.props} />
        <MessageDialog {...this.props} />
        <BlockUiFx
          blocking={isLoading}
          lowerZIndex={true}
          className="veggies-bg off-white flex-100"
        >
          <Form className="ttg-form">
            <ContentSection padding={3}>
              <div className="ttg-container">
                <h1>Third Party Form</h1>
                <h5>Observer Details</h5>
                <ObserverForm
                  observerFirstName={observerFirstName}
                  observerLastName={observerLastName}
                  observerEmail={observerEmail}
                  onInputChange={onInputChange}
                  onUpdateDetails={onUpdateDetails}
                  onEmail={onEmail}
                  isLoading={isLoadingObserverSection}
                  isUpdateButtonLoading={isLoadingObserverButton}
                  validate={validate}
                  onFieldError={onFieldError}
                  form={this.form}
                  isSampleForm={isSampleForm}
                  isLoggedIn={isLoggedIn}
                  firstName={firstName}
                  lastName={lastName}
                  userType={userType}
                />
              </div>
            </ContentSection>

            {showForm && (
              <div
                className={`third-party-form${
                  isSampleForm ? " third-party-form-sample" : ""
                }`}
              >
                <ContentSection>
                  <div className="ttg-container">
                    <table className="third-party-form-info">
                      <tbody>
                        <tr>
                          <td>Student Name</td>
                          <td>
                            {studentFirstName} {studentLastName}
                          </td>
                        </tr>
                        <tr>
                          <td>Course Name</td>
                          <td>{courseName}</td>
                        </tr>
                        <tr>
                          <td>
                            Unit of Competency
                            {thirdPartyFormAdditionalContent &&
                              thirdPartyFormVersion > 1 &&
                              thirdPartyFormAdditionalContent.unitsOfCompetencyLabel && (
                                <div className="mt-3">
                                  {
                                    thirdPartyFormAdditionalContent.unitsOfCompetencyLabel
                                  }
                                </div>
                              )}
                          </td>
                          <td>
                            {this.getUnitsOfCompetency(
                              certificateCodes,
                              unitOfCompetencies
                            )}
                            {thirdPartyFormAdditionalContent &&
                              thirdPartyFormVersion > 1 &&
                              thirdPartyFormAdditionalContent.unitsOfCompetencyAdditionalPoints && (
                                <ul className="mt-3">
                                  {thirdPartyFormAdditionalContent.unitsOfCompetencyAdditionalPoints.map(
                                    (point, i) => (
                                      <li key={i}>{point}</li>
                                    )
                                  )}
                                </ul>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>Who can complete this form?</td>
                          <td>
                            <p>
                              Because we offer online training, we need to prove
                              your practical skills in Food Safety. We do this
                              by an online third-party checklist that is emailed
                              to your nominated observer. We often have students
                              who are not currently working in the food industry
                              because they are starting a new food business or
                              career in the industry, so this observation can be
                              completed in an industry - realistic simulated
                              environment.
                            </p>
                            <p>
                              If the student is the owner/CEO/most senior person
                              in the organisation, then an appropriately
                              experienced colleague can complete the report, if
                              they have the relevant experience and knowledge to
                              competently observe the student completing the
                              tasks and make the correct decisions based on the
                              student&rsquo;s performance. Your observer can be:
                            </p>
                            <p>Your observer can be:</p>
                            <ul>
                              <li>
                                Past or Present Supervisor/Employer/Manager
                              </li>
                              <li>
                                Past or Present Volunteer Supervisor/Manager
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Third Party Evidence Report Purpose</td>
                          <td>
                            In order to make a judgement about the
                            student&rsquo;s ability to complete this course, we
                            are required to collect evidence from a variety of
                            methods and over a period of time. We seek your
                            support in gathering this supplementary evidence
                            that contributes towards being able to make a valid
                            judgement about the student&rsquo;s abilities in
                            their practical skills.
                          </td>
                        </tr>
                        <tr>
                          <td>Instructions for the Third Party</td>
                          <td>
                            <p>
                              We recommend that you have a short discussion with
                              the student before starting the observation tasks,
                              to ensure you both understand what is required to
                              demonstrate accurately in the required tasks and
                              against the criteria. The observer must have
                              witnessed the student perform the required tasks
                              at least three times through a variety of methods.
                            </p>
                            <p className="mb-0">
                              After observing the student completing the
                              required tasks, use the criteria checklist to tick
                              either &lsquo;Yes&rsquo; or &lsquo;No&rsquo; to
                              confirm whether the student consistently meets
                              workplace standards on those areas. If you tick
                              &lsquo;No&rsquo; against a criteria, please
                              provide immediate feedback to the student to
                              enable them to demonstrate successfully, either
                              immediately or during their next observation
                              session with you. If you believe by the end of the
                              observation sessions that the student has
                              demonstrated satisfactorily in each criteria,
                              please complete and resubmit this supplementary
                              evidence with &lsquo;Yes&rsquo; ticked for each
                              criteria.
                            </p>
                          </td>
                        </tr>
                        <tr className="no-bg">
                          <td colSpan="2">
                            If you have any questions or concerns in regard to
                            completing this Third-Party Report, or would like to
                            gain assistance throughout this procedure, please
                            contact us on:{" "}
                            <a href="tel:0400757645">0400&nbsp;757&nbsp;645</a>{" "}
                            or email:{" "}
                            <a href="mailto:contact@traintogain.com.au">
                              contact@traintogain.com.au
                            </a>
                            . We value your contribution as this Third-Party
                            Form will support other evidence of the
                            student&rsquo;s overall understanding in this
                            course.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ContentSection>

                <ContentSection padding={3}>
                  <div className="ttg-container">
                    <h5>
                      The following day-to-day tasks were observed by the Third
                      Party:
                    </h5>
                    {this.getTasksTable(tasks, this.props.fields)}
                    <AdditionalInfoForm
                      onInputChange={onInputChange}
                      additionalComments={additionalComments}
                      studentPerformanceSatisfactory={
                        studentPerformanceSatisfactory
                      }
                      confirmThirdPartyDeclaration={
                        confirmThirdPartyDeclaration
                      }
                      signature={signature}
                    />
                    {!isSampleForm && (
                      <FormGroup className="mt-3">
                        <ButtonFx
                          type="submit"
                          className="ttg-btn mr-2"
                          isLoading={isLoadingForSaveProgress}
                          onClick={() =>
                            this.form.validate({
                              validFn: () => saveProgress(),
                            })
                          }
                          disabled={isLoadingForSubmit}
                        >
                          Save Progress
                        </ButtonFx>
                        <ButtonFx
                          className="ttg-btn mr-2"
                          isLoading={isLoadingForSubmit}
                          onClick={() =>
                            this.form.validate({
                              validFn: () => submitForm(),
                            })
                          }
                          disabled={isLoadingForSaveProgress}
                        >
                          Submit
                        </ButtonFx>
                      </FormGroup>
                    )}
                    {isSampleForm && (
                      <FormGroup className="mt-3">
                        <ButtonFx
                          type="submit"
                          className="ttg-btn mr-2"
                          isLoading={isLoadingForSaveProgress}
                          onClick={() => alert("This is just a sample form.")}
                          disabled={isLoadingForSubmit}
                        >
                          Save Progress
                        </ButtonFx>
                        <ButtonFx
                          className="ttg-btn mr-2"
                          isLoading={isLoadingForSubmit}
                          onClick={() => alert("This is just a sample form.")}
                          disabled={isLoadingForSaveProgress}
                        >
                          Submit
                        </ButtonFx>
                      </FormGroup>
                    )}
                  </div>
                </ContentSection>
                <KeyboardEventHandler
                  handleKeys={["enter"]}
                  onKeyEvent={(key, e) =>
                    this.form.validate({ validFn: () => saveProgress() })
                  }
                />
              </div>
            )}
          </Form>
        </BlockUiFx>
      </>
    );
  }
}

export default ThirdPartyForm;
