/**
 * Authentication library
 * Create singleton instance
 */

const LOCAL_STORAGE_KEY = 'ttg-app-auth';

class Auth {

    constructor() {
        this.useLocalStorage = (typeof localStorage !== 'undefined');
        this.subscribers = [];
        this.load();
    }

    load() {
        if (this.useLocalStorage) {
            let tokenObject = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (!tokenObject) return;

            let tokenObjectParsed = JSON.parse(tokenObject);
            this._populateModel(tokenObjectParsed.token, tokenObjectParsed.refreshToken, tokenObjectParsed.expiresIn);
        }
    }

    isAuthenticated() {
        // Check storage if token still in the local store
        if (this.useLocalStorage) {
            let tokenObject = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (!tokenObject) {
                // Reset token variable if token in local store is manually removed
                this._populateModel(null, null, null);
                return;
            }
        }
        return !!this.token;
    }

    setToken(token, refreshToken, expiresIn) {
        this._populateModel(token, refreshToken, expiresIn);

        if (this.useLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ token, refreshToken, expiresIn }));
        }
    }

    removeToken() {
        this._populateModel(null, null, null);

        if (this.useLocalStorage) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
    }

    getToken() {
        const tokenObj = this._fetchFullTokenObject();
        if (!tokenObj) 
            return tokenObj;

        return tokenObj.token;
    }

    getRefreshToken() {
        const tokenObj = this._fetchFullTokenObject();
        if (!tokenObj) 
            return tokenObj;

        return tokenObj.refreshToken;
    }

    subscribe(cb) {
        this.subscribers.push(cb);
    }

    notifySubscribers() {
        this.subscribers.forEach((cb) => cb(this.isAuthenticated()));
    }

    // Refresh token payload builder
    getRefreshTokenPayload(clientId) {
        const refreshTokenPayload = {
            ClientId: clientId,
            GrantType: 'refresh_token',
            RefreshToken: this.getRefreshToken()
        };

        return refreshTokenPayload;
    }

    _fetchFullTokenObject() {
        if (!this.useLocalStorage)
            return;

        let tokenObject = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!tokenObject) {
            // Reset token variable if token in local store is manually removed
            this._populateModel(null, null, null);
            return;
        }

        return JSON.parse(tokenObject);
    }

    _populateModel(token, refresh, expiresIn) {
        this.token = token;
        this.refreshToken = refresh;
        this.expiresIn = expiresIn;
    }
}

export default Auth = new Auth();
