/**
 * Action types
 */
export const ActionTypes = {
  // Actions

  AVETMISS_REPORTING_VALIDATION_LOAD_FORM_REQUEST:
    "AVETMISS_REPORTING_VALIDATION_LOAD_FORM_REQUEST",
  AVETMISS_REPORTING_VALIDATION_LOAD_FORM_SUCCESS:
    "AVETMISS_REPORTING_VALIDATION_LOAD_FORM_SUCCESS",
  AVETMISS_REPORTING_VALIDATION_LOAD_FORM_FAILURE:
    "AVETMISS_REPORTING_VALIDATION_LOAD_FORM_FAILURE",
  AVETMISS_REPORTING_VALIDATION_SUBMIT_REQUEST:
    "AVETMISS_REPORTING_VALIDATION_SUBMIT_REQUEST",
  AVETMISS_REPORTING_VALIDATION_SUBMIT_SUCCESS:
    "AVETMISS_REPORTING_VALIDATION_SUBMIT_SUCCESS",
  AVETMISS_REPORTING_VALIDATION_SUBMIT_FAILURE:
    "AVETMISS_REPORTING_VALIDATION_SUBMIT_FAILURE",
  AVETMISS_REPORTING_VALIDATION_DELETE_REQUEST:
    "AVETMISS_REPORTING_VALIDATION_DELETE_REQUEST",
  AVETMISS_REPORTING_VALIDATION_DELETE_SUCCESS:
    "AVETMISS_REPORTING_VALIDATION_DELETE_SUCCESS",
  AVETMISS_REPORTING_VALIDATION_DELETE_FAILURE:
    "AVETMISS_REPORTING_VALIDATION_DELETE_FAILURE",
  AVETMISS_REPORTING_VALIDATION_SHOW_DELETE_DIALOG:
    "AVETMISS_REPORTING_VALIDATION_SHOW_DELETE_DIALOG",
  AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST:
    "AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST",
  AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS:
    "AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS",
  AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE:
    "AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE",
  AVETMISS_REPORTING_VALIDATION_FORM_INPUT_CHANGE:
    "AVETMISS_REPORTING_VALIDATION_FORM_INPUT_CHANGE"
};
