import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../../redux/system/system-action-creators";
import HttpService from "../service";

export const onDelete = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_USERS_DELETE_REQUEST });
  service
    .deleteUser(payload.userId)
    .then(() => {
      dispatch(onDeleteSuccess(payload));
      dispatch(addNotification("User has been successfully deleted."));
    })
    .catch(() => {
      dispatch(onDeleteFailure());
      dispatch(
        addNotification("There was an error deleting the user", "warning")
      );
    });
};

const onDeleteSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_USERS_DELETE_SUCCESS,
    payload
  });
};

export const onEdit = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_USERS_EDIT_REQUEST });
  service
    .editUser(payload)
    .then(() => {
      dispatch(onEditSuccess(payload));
      dispatch(addNotification("User has been successfully edited."));
    })
    .catch(() => {
      dispatch(onEditFailure());
      dispatch(
        addNotification("There was an error updating the user", "warning")
      );
    });
};

const onEditSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_USERS_EDIT_SUCCESS,
    payload
  });
};

const onEditFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_USERS_EDIT_FAILURE
  });
};

const onDeleteFailure = () => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_USERS_DELETE_FAILURE
  });
};

export const toggleDeleteDialog = payload => ({
  type: ActionTypes.ADMIN_USERS_SHOW_DELETE_DIALOG,
  payload
});

export const toggleEditDialog = payload => ({
  type: ActionTypes.ADMIN_USERS_SHOW_EDIT_DIALOG,
  payload
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_USERS_DIALOG_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
