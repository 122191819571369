/**
 * Confirm Email Sending
 */
import React from "react";
import ConfirmModal from "../../../components/Common/Confirm-Modal/Confirm-Modal";

const ConfirmEmailFormSending = (props) => {
  return (
    <ConfirmModal title={"Email Sending Confirmation"} {...props}>
      An email will be sent to your observer requesting they complete your third
      party form. Are you ready to send it now?
    </ConfirmModal>
  );
};

export default ConfirmEmailFormSending;
