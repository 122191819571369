import React from "react";
import { Link } from "react-router-dom";

const ArrowLinkButton = ({ to, title, showAsBlue, showDownArrow }) => {
  return (
    <Link
      to={to}
      className={`ttg-arrow-btn${showAsBlue ? " ttg-blue-btn" : ""}`}
    >
      {title}
      <i
        className={`fas fa-arrow-circle-${
          showDownArrow ? "down" : "right"
        } ml-2`}
      />
    </Link>
  );
};

export default ArrowLinkButton;
