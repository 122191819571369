import React, { useEffect, useState } from "react";
import { Collapse, Card, CardHeader, CardBody } from "reactstrap";
import Header from "./header";
import Content from "./content";
import { Section, SectionLink } from "react-scroll-section";
import { PropTypes } from "prop-types";

const CollapsibleItem = ({
  id,
  title,
  icon,
  children,
  init,
  collapseState,
  toggle,
}) => {
  const [toggleFlag, setToggleFlag] = useState(false);

  useEffect(() => {
    init(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(collapseState).length === 0) return;
    setToggleFlag(collapseState[id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseState]);

  return (
    <Section id={id}>
      <Card>
        <SectionLink section={id} offset={170} smooth={true}>
          {({ onClick }) => (
            <>
              <CardHeader>
                <Header
                  title={title}
                  icon={icon}
                  expandItem={toggleFlag}
                  onClick={() => toggle(id, onClick)}
                />
              </CardHeader>
              <Collapse isOpen={collapseState[id]}>
                <Content component={children} />
              </Collapse>
            </>
          )}
        </SectionLink>
      </Card>
    </Section>
  );
};

CollapsibleItem.propTypes = {
  icon: PropTypes.string,
};

CollapsibleItem.defaultProps = {
  icon: "",
};

export default React.memo(CollapsibleItem);
