/**
 * Forgot Password API service
 */
import HttpClient from "../../coreLib/http/httpClient";

const ResetPasswordService = () => {
  let httpClient = HttpClient();

  const resetPassword = (data, resetToken) =>
    httpClient.post(`/v1/accounts/password/reset`, {
      ...data,
      resetToken
    });

  return {
    resetPassword
  };
};

export default ResetPasswordService;
