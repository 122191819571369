import { ActionTypes } from "./action-types";

const initialState = {
  addressEntry: {
    displayAutocompleteAddressField: false,
    enterAddressManually: false,
    showSearchAddress: true,
    fullAddress: "",
  },
  nswCertificate: {
    courseId: 7,
    courseTitle: "The NSW Food Safety Supervisor Certificate Reprint",
    courseCost: 45,
  },
  fields: {
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
    cardNumber: null,
    cardExpiryDate: null,
    cardName: "",
    cardCvc: null,
    email: "",
  },
  isCreditCardValid: false,
  fieldErrors: {},
  isSubmitting: false,
  isSubmitted: false,
  showConfirmPopup: true,
};

function Step1Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.NSW_REPRINT_GOOGLE_AUTOCOMPLETE_SCRIPT_REQUEST:
      return {
        ...state,
      };

    case ActionTypes.NSW_REPRINT_CLEAR_ADDRESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: "",
        },
        fields: {
          ...state.fields,
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
        },
      };
    case ActionTypes.NSW_REPRINT_INIT:
      return {
        ...state,
        addressEntry: {
          displayAutocompleteAddressField: false,
          enterAddressManually: false,
          showSearchAddress: true,
          fullAddress: "",
        },
        hasPromoCode: false,
        fields: {
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
          cardNumber: null,
          cardExpiryDate: null,
          cardName: "",
          cardCvc: null,
          email: "",
          promoCode: "",
        },
        isCreditCardValid: false,
        fieldErrors: {},
        isSubmitting: false,
        isSubmitted: false,
      };
    case ActionTypes.NSW_REPRINT_SET_CARD_VALIDITY:
      return {
        ...state,
        isCreditCardValid: action.payload,
      };

    case ActionTypes.NSW_REPRINT_AUTOCOMPLETE_ADDRESS_UPDATE:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: action.payload.fullAddress,
          enterAddressManually: false,
        },
        fields: {
          ...state.fields,
          unitNumber: `${(
            action.payload.subdwelling +
            " " +
            action.payload.buildingName
          ).trim()}`,
          streetNumber: action.payload.streetNumber,
          streetName: action.payload.street,
          city: action.payload.locality,
          state: action.payload.state,
          postcode: action.payload.postcode,
        },
      };

    case ActionTypes.NSW_REPRINT_TOGGLE_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: !state.addressEntry.enterAddressManually,
        },
      };

    case ActionTypes.NSW_REPRINT_SHOW_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: true,
        },
      };

    case ActionTypes.NSW_REPRINT_TOGGLE_PROMO_CODE_ENTRY:
      return {
        ...state,
        hasPromoCode: !state.hasPromoCode,
        fields: {
          ...state.fields,
          cardNumber: null,
          cardExpiryDate: null,
          cardCvc: null,
          cardName: "",
          promoCode: "",
        },
        isCreditCardValid: false,
      };

    case ActionTypes.NSW_REPRINT_SUBMIT_ENROLMENT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
      };

    case ActionTypes.NSW_REPRINT_SUBMIT_ENROLMENT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };

    case ActionTypes.NSW_REPRINT_SUBMIT_ENROLMENT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
      };

    case ActionTypes.NSW_REPRINT_FORM_INPUT_CHANGE:
      let stateResultInput = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });
      return stateResultInput;

    case ActionTypes.NSW_REPRINT_SHOW_CONFIRM_POPUP:
      return {
        ...state,
        showConfirmPopup: false,
      };

    default:
      return state;
  }
}

export default Step1Reducer;

export const selectNSWReprint = (state) => state.nswReprint;
