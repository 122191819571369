/**
 * DatePicker Field extension of Date-Picker component
 * - DatePicker field with built-in validator
 * rcnet@solvable
 *
 * - Added debounce to rate limit the firing of fieldError event
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Fields.scss";
import debounce from "lodash/debounce";
import DateService from "./../../../services/date-service";
import DatePicker from "react-datepicker";

const EVENT_DEBOUNCE = 2000;

class DatePickerField extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    placeholderText: PropTypes.string,
    dateFormatCalendar: PropTypes.string,
    dateFormat: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFieldError: PropTypes.func,
    enableFieldColorError: PropTypes.bool,
    enableFieldErrorMessage: PropTypes.bool,
    enableFieldErrorNotification: PropTypes.bool,
  };

  static defaultProps = {
    enableFieldColorError: true,
    enableFieldErrorMessage: false,
    enableFieldErrorNotification: true,
    placeholderText: "dd/mm/yyyy",
    dateFormatCalendar: "MMM yyyy",
    dateFormat: "dd/MM/yyyy",
    onFieldError: () => {},
  };

  state = {
    value: this.props.value,
    error: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.debouncedCallback = debounce(
      (evt, error) => this.fireFieldErrorEvent(error),
      EVENT_DEBOUNCE
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    return { value: DateService().getDateFromString(props.value) };
  }

  onChange = (val) => {
    let value = DateService().getDateFromString(val);
    this._detectChange(value);
  };

  onBlur = (evt) => {
    let value = DateService().getDateFromString(this.state.value);
    let error = this._detectChange(value);
    // Persist react synthetic event
    evt.persist();
    if (error) this.debouncedCallback(evt, error);
  };

  _detectChange = (value) => {
    const name = this.props.name;
    const error = this.props.validate ? this.props.validate(value) : false;

    this.setState({ value, error });
    this.props.onChange({ name, value, error });

    return error;
  };

  fireFieldErrorEvent = (error) => this.props.onFieldError(error);

  // Note: Internal function, used by this component
  _validateField = () => {
    if (!this._isMounted) {
      return;
    }
    let error = this._detectChange(
      DateService().getDateFromString(this.state.value)
    );
    if (error) this.debouncedCallback(null, error);
    return { name: this.props.name, error: error };
  };

  _isValidComponentInstance = () => {
    return this._isMounted;
  };

  render() {
    const {
      name,
      validate,
      enableFieldColorError,
      enableFieldErrorMessage,
      enableFieldErrorNotification,
      onFieldError,
      placeholderText,
      dateFormatCalendar,
      minDate,
      maxDate,
      dateFormat,
      ...other
    } = this.props;

    return (
      <>
        <DatePicker
          {...other}
          name={name}
          selected={DateService().getDateFromString(this.state.value)}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholderText={placeholderText}
          dateFormatCalendar={dateFormatCalendar}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={dateFormat}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className={
            this.state.error && enableFieldColorError
              ? "field-error react-date-full"
              : "react-date-full"
          }
        />
        {this.state.error && enableFieldErrorMessage && (
          <small className="text-danger">{this.state.error}</small>
        )}
      </>
    );
  }
}

export default DatePickerField;
