import React, { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import "./Fields.scss";
import debounce from "lodash/debounce";

const EVENT_DEBOUNCE = 2000;

class TextAreaField extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFieldError: PropTypes.func,
    enableFieldColorError: PropTypes.bool,
    enableFieldErrorMessage: PropTypes.bool,
    enableFieldErrorNotification: PropTypes.bool,
    rows: PropTypes.number,
  };

  static defaultProps = {
    enableFieldColorError: true,
    enableFieldErrorMessage: false,
    enableFieldErrorNotification: true,
    rows: 4,
    onFieldError: () => {},
  };

  state = {
    value: this.props.value,
    error: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.debouncedCallback = debounce(
      (evt, error) => this.fireFieldErrorEvent(error),
      EVENT_DEBOUNCE
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    return { value: props.value };
  }

  onChange = (evt) => {
    this._detectChange(evt.target.value);
  };

  onKeyPress = (evt) => {
    if (evt.key === "Enter" && this.props.onKeyPress) {
      this.props.onKeyPress();
    }
  };

  onBlur = (evt) => {
    let error = this._detectChange(evt.target.value);

    // Persist react synthetic event
    evt.persist();
    if (error) this.debouncedCallback(evt, error);
  };

  _detectChange = (value) => {
    const name = this.props.name;
    //const value = evt.target.value;
    const error = this.props.validate ? this.props.validate(value) : false;

    this.setState({ value, error });
    this.props.onChange({ name, value, error });

    return error;
  };

  fireFieldErrorEvent = (error) => this.props.onFieldError(error);

  // Note: Internal function, used by this component
  _validateField = () => {
    if (!this._isMounted) {
      return;
    }
    let error = this._detectChange(this.state.value);
    if (error) this.debouncedCallback(null, error);
    return { name: this.props.name, error: error };
  };

  _isValidComponentInstance = () => {
    return this._isMounted;
  };

  render() {
    const {
      validate,
      enableFieldColorError,
      enableFieldErrorMessage,
      enableFieldErrorNotification,
      rows,
      onFieldError,
      ...other
    } = this.props;

    return (
      <>
        <Input
          {...other}
          type="textarea"
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
          rows={rows}
          cols="3"
          className={
            this.state.error && enableFieldColorError ? "field-error" : ""
          }
        />
        {this.state.error && enableFieldErrorMessage && (
          <small className="text-danger">{this.state.error}</small>
        )}
      </>
    );
  }
}

export default TextAreaField;
