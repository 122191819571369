export const ActionTypes = {
  CORPORATE_VOUCHERS_ON_INIT: "CORPORATE_VOUCHERS_ON_INIT",
  CORPORATE_VOUCHERS_ON_LOAD_REQUEST: "CORPORATE_VOUCHERS_ON_LOAD_REQUEST",
  CORPORATE_VOUCHERS_ON_LOAD_SUCCESS: "CORPORATE_VOUCHERS_ON_LOAD_SUCCESS",
  CORPORATE_VOUCHERS_ON_LOAD_FAILURE: "CORPORATE_VOUCHERS_ON_LOAD_FAILURE",

  CORPORATE_VOUCHERS_SHOW_EMAIL_DIALOG: "CORPORATE_VOUCHERS_SHOW_EMAIL_DIALOG",
  CORPORATE_VOUCHERS_SET_DETAILS_FORM_EMAIL:
    "CORPORATE_VOUCHERS_SET_DETAILS_FORM_EMAIL",

  CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_REQUEST:
    "CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_REQUEST",
  CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_SUCCESS:
    "CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_SUCCESS",
  CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_FAILURE:
    "CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_FAILURE",

  CORPORATE_VOUCHERS_SEND_EMAIL_REQUEST:
    "CORPORATE_VOUCHERS_SEND_EMAIL_REQUEST",
  CORPORATE_VOUCHERS_SEND_EMAIL_SUCCESS:
    "CORPORATE_VOUCHERS_SEND_EMAIL_SUCCESS",
  CORPORATE_VOUCHERS_SEND_EMAIL_FAILURE:
    "CORPORATE_VOUCHERS_SEND_EMAIL_FAILURE",

  CORPORATE_VOUCHERS_DOWNLOAD_TPF_REQUEST:
    "CORPORATE_VOUCHERS_DOWNLOAD_TPF_REQUEST",
  CORPORATE_VOUCHERS_DOWNLOAD_TPF_SUCCESS:
    "CORPORATE_VOUCHERS_DOWNLOAD_TPF_SUCCESS",
  CORPORATE_VOUCHERS_DOWNLOAD_TPF_FAILURE:
    "CORPORATE_VOUCHERS_DOWNLOAD_TPF_FAILURE",
};
