import { connect } from "react-redux";
import AvetmissReportingValidation from "./avetmiss-reporting-validation";
import { selectAvetmissReportingValidation } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";

function mapStateToProps(state) {
  const avetmissReportingValidation = selectAvetmissReportingValidation(state);

  return {
    isLoading: avetmissReportingValidation.isLoading,
    isDownloading: avetmissReportingValidation.isDownloading,
    isDeleting: avetmissReportingValidation.isDeleting,
    showDeleteDialog: avetmissReportingValidation.showDeleteDialog,
    hasResult: avetmissReportingValidation.hasResult,
    avetmissReportingValidation: avetmissReportingValidation
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  toggleDeleteDialog: () => dispatch(actionCreators.toggleDeleteDialog()),
  onDownloadNatFiles: () => dispatch(actionCreators.onDownloadNatFiles()),
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadForm: () => {
    dispatchProps.dispatch(actionCreators.onLoadForm(dispatchProps.history));
  },
  onSubmit: errors => {
    dispatchProps.dispatch(
      actionCreators.onSubmit(errors, dispatchProps.history)
    );
  },
  onDelete: () => {
    dispatchProps.dispatch(actionCreators.onDelete(dispatchProps.history));
  }
});

const AvetmissReportingValidationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(AvetmissReportingValidation);

export default AvetmissReportingValidationContainer;
