import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import PriorityNav from "components/solvable-priority-nav/solvable-priority-nav";
import LoginBar from "components/Login-Bar/login-bar";
import Auth from "coreLib/auth/auth";
import UserIdentity from "coreLib/auth/userIdentity";
import { selectLoginData } from "redux/system/login-reducer";
import { loadUserIdentityState } from "../../redux/system/system-action-creators";
import * as Constants from "constants/index";
import "./header.scss";

const HeaderView = ({ loginData }) => {
  const dispatch = useDispatch();
  const identity = UserIdentity();
  const currentRole = identity.getRole();

  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    if (!Auth.isAuthenticated()) return;

    let userIdentity = UserIdentity();

    const user = {
      firstName: userIdentity.getFirstName(),
      lastName: userIdentity.getLastName(),
      id: userIdentity.getUserId(),
      email: userIdentity.getEmail(),
      hasCourses: userIdentity.hasCourses(),
      userType: userIdentity.userType(),
    };

    dispatch(loadUserIdentityState(user));
  }, []);

  useEffect(() => {
    if (loginData.loggedIn)
      setNavLinks(
        Constants.privateNavLinks.filter((o) => o.roles.includes(currentRole))
      );
    else setNavLinks(Constants.navLinks);
  }, [loginData]);

  const logo = (
    <Link to="/" className="nav-logos">
      <img
        src="https://solvable.azureedge.net/train-to-gain/images/nationally-recognised-training-logo.png"
        alt="Nationally Recognised Training"
      />
      <img
        src="https://solvable.azureedge.net/train-to-gain/images/food-safety-education-logo.png"
        alt="Food Safety Education | Train to Gain"
      />
    </Link>
  );

  return (
    <>
      <LoginBar />
      <PriorityNav
        navItems={navLinks}
        logo={logo}
        useActiveRouteStyle
        sticky
        stickyScrollTriggerValue={30}
        stickyClassNamesByRoute={{
          "/": {
            top: "header-home-top",
            sticky: "header-sticky",
            scrollTriggerValue: 30,
          },
        }}
      />
    </>
  );
};

function mapStateToProps(state) {
  const loginData = selectLoginData(state);

  return {
    loginData: loginData,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history,
});

const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderView);

export default Header;
