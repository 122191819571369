import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "./datatable";
import { onLoad, downloadTaxInvoice } from "../redux/action-creators";
import { selectCorporateTaxInvoice } from "../redux/reducers";

const mapStateToProps = state => {
  const stateData = selectCorporateTaxInvoice(state);
  return {
    data: stateData.data,
    isProcessing: stateData.isProcessing
  };
};

const DataTableContainer = withRouter(
  connect(
    mapStateToProps,
    { onLoad, downloadTaxInvoice }
  )(DataTable)
);

export default DataTableContainer;
