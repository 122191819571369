/**
 * User Claims library
 * Create singleton instance
 * by rcnet@solvable
 *
 * Claims Utility methods.
 * - Sample below expects ClaimType is equals to ClaimValue, ex. {claimType: 'CanAccessInvoice', claimValue: 'CanAccessInvoice'}.
 * - There are times that you the claimType is different than the claimValue, ex. {claimType: 'Rol', claimValue: 'admin'}. Accessing this one is pretty easy,
 *   just provide the claimType and value in the parameter separated by '==' sign. Refer to Sample Usage #2.
 * Sample usage #1:
 *
 * claimsExist() - accepts a single expected claim parameter to check against current user's claims.
 * ex.
 * button.disabled = userIdentity.claimsExist('CanAccessProfile') || userIdentity.claimsExist('CanManageProfile')
 * button.disabled = userIdentity.claimsExist('CanAccessProfile') && userIdentity.claimsExist('CanManageProfile')
 *
 * trueForAll() -  supplied parameter vs claims checking should be all truthy to say that it is a valid claim.
 * ex.
 * button.disabled = userIdentity.trueForAll(['CanAccessProfile', 'CanManageProfile'])
 *
 * any() - performs OR checking of expected claim types against user's claims. If it finds any occurence of expected claims it will considered a thruthy result.
 * ex.
 * button.disabled = userIdentity.any(['CanAccessProfile', 'CanAccessPayroll'])
 *
 * Sample usage #2: (Passing a claimType)
 *
 * trueForAll example.
 * button.disabled = userIdentity.trueForAll(['rol==admin','CanAccessProfile', 'CanManageProfile'])
 *
 * Any example.
 * button.disabled = userIdentity.any(['CanAccessProfile', 'CanAccessPayroll', 'rol==NormalUser', 'location==123'])
 */
import Auth from "../auth/auth";
import AuthUtil from "../auth/authUtil";

const UserIdentity = () => {
  // Common Claims
  const getFirstName = () => getClaimsValueByType("given_name");

  const getUserId = () => getClaimsValueByType("id");

  const getLastName = () => getClaimsValueByType("family_name");

  const getFullName = () => `${getFirstName()} ${getLastName()}`;

  const getUserName = () => getClaimsValueByType("sub");

  const getRole = () => getClaimsValueByType("rol");

  const getEmail = () => getClaimsValueByType("sub");

  const isAuthenticated = () => Auth.isAuthenticated();

  const hasCourses = () => getClaimsValueByType("has_courses");

  const userType = () => getClaimsValueByType("user_type");

  // -----------------------
  // Claims Utility methods
  // -----------------------

  /**
   * Will return a claim value based on the supply claim type.
   * @param {*} claimType
   */
  const getClaimsValueByType = (claimType) => {
    let claims = getClaims();

    return _perfGetClaimsValueByType(claimType, claims);
    // let claim = (claims && claims[claimType]);
    // return claim ? claim : '';
  };

  /**
   * Get array of claims
   */
  const getClaims = () => {
    //Todo: Perform debounce here (cache it for 3-5 secs)
    let token = Auth.getToken();
    if (!token) return null;
    return AuthUtil.parseJwt(token);
  };

  /**
   * Provide either claim type or value
   * 1. It will check if the claim is available to this user
   * 2. It will check both if the claim type and value are equal
   *
   * @param {value} value
   */
  const claimsExist = (value) => {
    let path = /==/i;
    if (value.match(path)) {
      let sValues = value.split("==");
      let claim = getClaimsValueByType(sValues[0]);
      return claim === sValues[1];
    } else {
      let claim = getClaimsValueByType(value);
      return claim === value;
    }
  };

  const trueForAll = (expectedClaims) => {
    let testResults = [];
    expectedClaims.forEach((c) => {
      testResults.push(claimsExist(c));
    });

    return testResults.every((x) => x === true);
  };

  const any = (expectedClaims) => {
    const hasAny = expectedClaims.some((c) => {
      return claimsExist(c);
    });

    return hasAny;
  };

  // internal only
  const _perfGetClaimsValueByType = (claimType, claims) => {
    let claim = claims && claims.hasOwnProperty(claimType);
    return claim ? claims[claimType] : "";
  };

  return {
    getFirstName,
    getUserId,
    getLastName,
    getFullName,
    getUserName,
    isAuthenticated,
    hasCourses,
    userType,
    getRole,
    getClaimsValueByType,
    getClaims,
    claimsExist,
    trueForAll,
    getEmail,
    any,
  };
};

export default UserIdentity;
