/**
 * Action Creator definitions
 */
import IdentityService from "../../../coreLib/auth/userIdentity";
import { addNotification } from "../../../redux/system/system-action-creators";
import CourseService from "../../../services/course-service";
import DateService from "../../../services/date-service";
import Step1Service from "../services/step1.service";
import Step3Service from "../services/step3.service";
import { ActionTypes } from "./action-types";

// ----------------------------
//  Enrolment Actions
// ----------------------------

// Async
export const onLoadCourseDetails = (courseId) => {
  return (dispatch) => {
    dispatch(onLoadCourseDetailsRequest());
    const courseService = CourseService();
    var course = courseService.getCourse(courseId);

    if (course != null) {
      dispatch(
        onLoadCourseDetailsSuccess(
          course.courseId,
          course.courseTitle,
          course.certificateCodesString,
          course.courseCost,
          course.validStates
        )
      );
    } else dispatch(onLoadCourseDetailsFailure());
  };
};

export const setEmail = () => (dispatch) => {
  let email = IdentityService().getEmail();
  dispatch(step1FormInputChange("email", email));
};

const onLoadCourseDetailsRequest = () => ({
  type: ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_REQUEST,
});

const onLoadCourseDetailsSuccess = (
  courseId,
  courseTitle,
  courseCode,
  coursePrice,
  validStates
) => ({
  type: ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_SUCCESS,
  payload: {
    courseId: courseId,
    courseTitle: courseTitle,
    courseCode: courseCode,
    coursePrice: coursePrice,
    validStates: validStates,
  },
});

const onLoadCourseDetailsFailure = () => ({
  type: ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_FAILURE,
});

export const clearStep1FormAddress = () => ({
  type: ActionTypes.STEP_1_CLEAR_ADDRESS,
});

export const step1CreditCardInputChange = (card) => (dispatch) => {
  const { field, value, meta } = card;
  dispatch(step1FormInputChange(field, value));
  let isCreditCardValid = Object.values(meta).every((m) => m === undefined);
  dispatch(setCardValidity(isCreditCardValid));
};

const setCardValidity = (value) => ({
  type: ActionTypes.STEP_1_SET_CARD_VALIDITY,
  payload: value,
});

export const updateStep1FormAutocompleteAddress = (data) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_1_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS,
      payload: data,
    });
  };
};

// ----------------------------
//  Step 1 Actions
// ----------------------------

export const toggleManualAddressEntry = () => ({
  type: ActionTypes.STEP_1_TOGGLE_MANUAL_ADDRESS_ENTRY,
});

export const togglePromoCodeEntry = () => ({
  type: ActionTypes.STEP_1_TOGGLE_PROMO_CODE_ENTRY,
});

const showManualAddressEntry = () => ({
  type: ActionTypes.STEP_1_SHOW_MANUAL_ADDRESS_ENTRY,
});

export const toggleShowConfirmNamePopup = () => ({
  type: ActionTypes.STEP_1_TOGGLE_SHOW_CONFIRM_NAME_POPUP,
});

export const step1Init = (hasCourses) => ({
  type: ActionTypes.STEP_1_INIT,
  payload: {
    hasCourses,
  },
});

// Async
export const onSubmitEnrolmentStep1 = (stateProps, fields, history) => {
  const {
    streetNumber,
    streetName,
    city,
    state,
    postcode,
    email,
    cardNumber,
    cardExpiryDate,
    cardName,
    cardCvc,
    promoCode,
    understandTerms,
  } = fields;
  return (dispatch, getState) => {
    const { hasPromoCode } = stateProps;
    // Submit Validation

    const { addressEntry } = getState().courseEnrolment.step1;

    if (!addressEntry.enterAddressManually && !addressEntry.fullAddress) {
      dispatch(addNotification("Address is required", "error"));
      return true;
    }

    if (!streetNumber || streetNumber === undefined || streetNumber === "") {
      dispatch(addNotification("Street number is required.", "warning"));
      dispatch(showManualAddressEntry());
      return;
    }

    if (!streetName || streetName === undefined || streetName === "") {
      dispatch(addNotification("Street name is required.", "warning"));
      return;
    }

    if (!city || city === undefined || city === "") {
      dispatch(addNotification("City is required.", "warning"));
      return;
    }

    if (!state || state === undefined || state === "") {
      dispatch(addNotification("State is required.", "warning"));
      return;
    }

    if (!postcode || postcode === undefined || postcode === "") {
      dispatch(addNotification("Post code is required.", "warning"));
      return;
    }

    if (!email || email === undefined || email === "") {
      dispatch(addNotification("Email address code is required.", "warning"));
      return;
    }

    if (!understandTerms) {
      dispatch(
        addNotification(
          "Please tick the student handbook acknowledgement.",
          "warning"
        )
      );
      return;
    }

    if (hasPromoCode) {
      if (!promoCode || promoCode === undefined || promoCode === "") {
        dispatch(addNotification("Promo code is required.", "warning"));
        return;
      }
    } else {
      if (!cardName || cardName === undefined || cardName === "") {
        dispatch(addNotification("Card name is required.", "warning"));
        return;
      }
      if (!cardNumber || cardNumber === undefined || cardNumber === "") {
        dispatch(addNotification("Card number is required.", "warning"));
        return;
      }
      if (
        !cardExpiryDate ||
        cardExpiryDate === undefined ||
        cardExpiryDate === ""
      ) {
        dispatch(addNotification("Card expiry date is required.", "warning"));
        return;
      }
      if (!cardCvc || cardCvc === undefined || cardCvc === "") {
        dispatch(addNotification("Card CVC is required.", "warning"));
        return;
      }
    }
    // Submit Validation

    let model = {
      ...fields,
      courseId: stateProps.courseDetails.courseId,
      cardExpiryDate: fields.cardExpiryDate.replace(/ /g, ""),
      fullAddress: addressEntry.fullAddress,
    };

    dispatch(onSubmitEnrolmentStep1Request());
    Step1Service()
      .submitStep1(model)
      .then((resp) => {
        const { enrolmentSuccessful, errorMessage, id } = resp.data;
        if (!enrolmentSuccessful) {
          dispatch(onSubmitEnrolmentStep1Failure());
          dispatch(addNotification(errorMessage, "warning"));
        } else {
          dispatch(onSubmitEnrolmentStep1Success());
          dispatch(
            addNotification(
              "Yay that worked!! Let’s continue with your enrolment.",
              "success"
            )
          );
          history.push(`/course-enrolment/${id}/step-2/`);
        }
      })
      .catch((err) => {
        dispatch(onSubmitEnrolmentStep1Failure());
        dispatch(
          addNotification(
            err.response.data.Message
              ? err.response.data.Message
              : err.response.data.title
              ? err.response.data.title
              : "There was an issue submitting the form",
            "warning"
          )
        );
      });
  };
};

const onSubmitEnrolmentStep1Request = () => ({
  type: ActionTypes.STEP_1_SUBMIT_ENROLMENT_REQUEST,
});
const onSubmitEnrolmentStep1Success = () => ({
  type: ActionTypes.STEP_1_SUBMIT_ENROLMENT_SUCCESS,
});
const onSubmitEnrolmentStep1Failure = () => ({
  type: ActionTypes.STEP_1_SUBMIT_ENROLMENT_FAILURE,
});

const onSubmitEnrolmentStep1UpdateStep2Address = (
  unitNumber,
  streetNumber,
  streetName,
  city,
  state,
  postcode
) => ({
  type: ActionTypes.STEP_2_UPDATE_ADDRESS_FROM_STEP_1_ADDRESS,
  payload: {
    unitNumber: unitNumber,
    streetNumber: streetNumber,
    streetName: streetName,
    city: city,
    state: state,
    postcode: postcode,
  },
});

// Async
export const onSubmitEnrolmentStep3 = (fields, history) => (dispatch) => {
  dispatch(onSubmitEnrolmentStep3Request());
  Step3Service()
    .submitStep3(fields)
    .then((resp) => {
      dispatch(onSubmitEnrolmentStep3Success());
      dispatch(
        addNotification("Awesome, now you can begin your course.", "success")
      );
      history.push(`/course-content/${fields.userCourseId}`);
    })
    .catch((err) => {
      dispatch(onSubmitEnrolmentStep3Failure());
      dispatch(
        addNotification("There was an issue submitting the form", "warning")
      );
    });
};

const onSubmitEnrolmentStep3Request = () => ({
  type: ActionTypes.STEP_3_SUBMIT_ENROLMENT_REQUEST,
});
const onSubmitEnrolmentStep3Success = () => ({
  type: ActionTypes.STEP_3_SUBMIT_ENROLMENT_SUCCESS,
});
const onSubmitEnrolmentStep3Failure = () => ({
  type: ActionTypes.STEP_3_SUBMIT_ENROLMENT_FAILURE,
});

// ----------------------------
//  Form Actions
// ----------------------------

export const step1FormInputChange = (name, value, error) => ({
  type: ActionTypes.STEP_1_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});

export const step3FormInputChange = (name, value, error) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.STEP_3_FORM_INPUT_CHANGE,
      payload: {
        name: name,
        value: value,
        error: error,
      },
    });

    switch (name) {
      case "whereExperience":
        dispatch({
          type: ActionTypes.STEP_3_TOGGLE_WHERE_EXPERIENCE,
          payload: value !== "Home",
        });
        break;
      default:
        break;
    }
    dispatch(validateStep3Func());
  };
};

const validateStep3Func = () => {
  return (dispatch, getState) => {
    let fieldVisibility = getState().courseEnrolment.step3.fieldVisibility;
    let fields = getState().courseEnrolment.step3.fields;
    let fieldErrors = getState().courseEnrolment.step3.fieldErrors;
    let isFormValid = !validateStep3(fields, fieldVisibility, fieldErrors);
    dispatch({
      type: ActionTypes.STEP_3_FORM_VALID,
      payload: isFormValid,
    });
  };
};

const validateStep3 = (fields, fieldVisibility, fieldErrors) => {
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
  if (!fields.whereExperience) return true;
  if (!fields.relationshipToObserver) return true;
  if (!fields.observerFirstName) return true;
  if (!fields.observerLastName) return true;
  if (!fields.observerEmail) return true;
  if (fieldVisibility.showExperience && fieldVisibility.showWorkPlaceName) {
    if (!fields.workplaceName) return true;
    if (!fields.workplaceSuburb) return true;
    if (!fields.workplacePhone) return true;
  }
  if (fieldVisibility.showExperience && !fieldVisibility.showWorkPlaceName) {
    if (!fields.workplaceSuburb) return true;
    if (!fields.workplacePhone) return true;
  }
  if (errMessages.length > 0) return true;
  return false;
};

export const step2FormDateChange = (name, value) => {
  const dateService = DateService();

  const date = dateService.formatDate(value, "YYYY-MM-DD");

  return {
    type: ActionTypes.STEP_2_FORM_DATE_CHANGE,
    payload: {
      name: name,
      value: date,
    },
  };
};

export const step3FormDateChange = (name, value) => {
  const dateService = DateService();

  const date = dateService.formatDate(value, "YYYY-MM-DD");

  return {
    type: ActionTypes.STEP_3_FORM_DATE_CHANGE,
    payload: {
      name: name,
      value: date,
    },
  };
};

export const step3OnInit = (id, history) => {
  return (dispatch) => {
    dispatch(step3OnInitStarted());
    Step3Service()
      .getStep3(id)
      .then((resp) => {
        dispatch(step3OnInitSucceeded(resp.data));
        const {
          enrolmentCompleted,
          redirectToStep2,
          courseId,
          userCourseid,
        } = resp.data;
        if (enrolmentCompleted) {
          history.push(`/course-content/`);
          return false;
        }
        if (redirectToStep2) {
          history.push(`/course-enrolment/${userCourseid}/step-2/`);
          return false;
        }
        dispatch(onLoadCourseDetails(courseId));
        dispatch(validateStep3Func());
      })
      .catch((err) => {
        dispatch(step3OnInitFailed());
        addNotification("There was an issue loading the form.", "warning");
      });
  };
};

const step3OnInitStarted = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_3_INIT,
    });
  };
};

const step3OnInitSucceeded = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_3_INIT_SUCCEEDED,
      payload,
    });
  };
};

const step3OnInitFailed = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.STEP_3_INIT_FAILED,
    });
  };
};

export const toggleDiscountCodeForm = () => (dispatch) => {
  dispatch({ type: ActionTypes.STEP_1_APPLY_DISCOUNT_TOGGLE_DISCOUNT_CODE });
};

export const applyDiscountCode = (courseId, discountCode) => (dispatch) => {
  dispatch(onApplyDiscountCodeRequest());
  Step1Service()
    .applyDiscountCode(courseId, discountCode)
    .then((resp) => {
      let { promoCodeValid, totalAfterDiscount } = resp.data;
      if (promoCodeValid) {
        dispatch(onApplyDiscountCodeSuccess(totalAfterDiscount));
        dispatch(addNotification("Promo code has been applied."));
      } else {
        dispatch(addNotification("Invalid promo code", "warning"));
        dispatch(onApplyDiscountCodeFailure());
      }
    })
    .catch(() => {
      dispatch(addNotification("Invalid promo code", "warning"));
      dispatch(onApplyDiscountCodeFailure());
    });
};

const onApplyDiscountCodeRequest = () => ({
  type: ActionTypes.STEP_1_APPLY_DISCOUNT_CODE_REQUEST,
});

const onApplyDiscountCodeSuccess = (totalAfterDiscount) => ({
  type: ActionTypes.STEP_1_APPLY_DISCOUNT_CODE_SUCCESS,
  totalAfterDiscount,
});

const onApplyDiscountCodeFailure = () => ({
  type: ActionTypes.STEP_1_APPLY_DISCOUNT_CODE_FAILURE,
});
