import { ActionTypes } from "./action-types";
import moment from "moment";
import CourseService from "../../../../../services/course-service";

const initialState = {
  data: [],
  isProcessing: false,
  fields: {
    courseIds: [],
    startDate: moment().subtract(12, "months")._d,
    endDate: moment()._d,
  },
  fieldErrors: {},
  isReset: false,
  settings: {},
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_ONLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
        data: action.payload,
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_RESET:
      return {
        ...state,
        isProcessing: true,
        isReset: true,
        fields: {
          courseIds: CourseService().getFlattenedCourses(),
          startDate: moment().subtract(12, "months")._d,
          endDate: moment()._d,
        },
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_SUCCESS:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_FAILURE:
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_ONLOAD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_ONLOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isReset: false,
        data: action.payload.map((voucher) => {
          return {
            ...voucher,
            name: `${voucher.firstName} ${voucher.lastName}`,
            purchasedDateTime: moment(voucher.purchasedDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            amountDue: "$" + voucher.amountDue.toFixed(2),
            amountPaid: "$" + voucher.amountPaid.toFixed(2),
            discountAmount: "$" + voucher.discountAmount.toFixed(2),
            isCreditCardPurchase: voucher.isCreditCardPurchase ? "Yes" : "No",
          };
        }),
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map((voucher) => {
          if (action.payload.prePurchaseId === voucher.prePurchaseId) {
            return {
              ...voucher,
              companyName: action.payload.companyName,
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              email: action.payload.email,
              phone: action.payload.phone,
            };
          } else {
            return voucher;
          }
        }),
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (voucher) => voucher.prePurchaseId !== action.payload.prePurchaseId
        ),
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS:
      return {
        ...state,
        data: state.data.map((voucher) => {
          if (action.payload === voucher.prePurchaseId) {
            return {
              ...voucher,
              amountPaid: 1,
            };
          } else {
            return voucher;
          }
        }),
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    case ActionTypes.ADMIN_PURCHASE_VOUCHERS_DATATABLE_CLEAR_SEARCHBOX:
      return {
        ...state,
        settings: {
          ...state.settings,
          searchText: "",
        },
      };
    default:
      return state;
  }
}

export default Reducer;
