import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup
} from "reactstrap";
import "../../../../components/Common/Fields/Fields.scss";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";
import formHandler from "../../../../components/Common/Fields/form-handler";
import "../../../../components/Common/Fields/Fields.scss";
import InputField from "../../../../components/Common/Fields/Input-Field";
import * as validators from "../../../../components/Common/Fields/Validators/Field-Validators";

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dialogs,
      toggleEditDialog,
      onFieldError,
      isProcessing,
      fields,
      onEdit,
      onDialogInputChange
    } = this.props;
    const { companyName, firstName, lastName, email, phone } = fields;
    const form = formHandler();
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={dialogs.showEditDialog}>
          <ModalHeader toggle={() => toggleEditDialog()}>
            Edit Voucher Purchase
          </ModalHeader>
          <ModalBody>
            <Form className="ttg-form">
              <Row form>
                <Col>
                  <FormGroup>
                    <label>Company Name</label>
                    <InputField
                      name="companyName"
                      placeholder="Company Name"
                      ref={form.add}
                      value={companyName}
                      validate={val => validators.required("Company Name", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>First Name</label>
                    <InputField
                      name="firstName"
                      placeholder="First Name"
                      ref={form.add}
                      value={firstName}
                      validate={val => validators.required("First Name", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Last Name</label>
                    <InputField
                      name="lastName"
                      placeholder="Last Name"
                      ref={form.add}
                      value={lastName}
                      validate={val => validators.required("Last Name", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Email</label>
                    <InputField
                      name="email"
                      placeholder="Email"
                      ref={form.add}
                      value={email}
                      validate={val => validators.required("Email", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Phone</label>
                    <InputField
                      name="phone"
                      placeholder="Phone"
                      ref={form.add}
                      value={phone}
                      validate={val => validators.required("Phone", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() => onEdit(fields)}
              className="ttg-btn"
              isLoading={isProcessing}
            >
              Save
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
