/**
 * Presentational component
 */
import React from "react";
import { Button, Form, FormGroup, Label, Row, Col, Input } from "reactstrap";
import InputField from "./../../../components/Common/Fields/Input-Field";
import BlockUiFx from "./../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "./../../../components/Common/Button-Fx/Button-Fx";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";

const validateEmailField = (val) => {
  if (validators.required("Email", val))
    return validators.required("Email", val);
  if (validators.email(val)) return validators.email(val);

  return false;
};

const observerNameValidator = (
  fieldName,
  value,
  observerFirstName,
  observerLastName,
  studentFirstName,
  studentLastName,
  userType
) => {
  if (userType && userType === 3) return false;

  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);

  if (
    observerFirstName !== "" &&
    observerLastName !== "" &&
    studentFirstName !== "" &&
    studentLastName !== "" &&
    observerFirstName.toLowerCase() === studentFirstName.toLowerCase() &&
    observerLastName.toLowerCase() === studentLastName.toLowerCase()
  ) {
    return "Observer Name - this cannot be your own name - your observer may be contacted for verification";
  }

  return false;
};

const ObserverForm = (props) => {
  const {
    observerFirstName,
    observerLastName,
    observerEmail,
    onInputChange,
    onUpdateDetails,
    onEmail,
    isLoading,
    isUpdateButtonLoading,
    onFieldError,
    form,
    isSampleForm,
    isLoggedIn,
    firstName,
    lastName,
    userType,
  } = props;

  return (
    <div>
      <BlockUiFx blocking={isLoading} lowerZIndex={true}>
        <FormGroup className="mb-0">
          <Label>Observer Name</Label>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <InputField
                  name="observerFirstName"
                  placeholder="First Name"
                  value={observerFirstName}
                  validate={(val) =>
                    observerNameValidator(
                      "Observer first name",
                      val,
                      val,
                      observerLastName,
                      firstName,
                      lastName,
                      userType
                    )
                  }
                  onFieldError={onFieldError}
                  onChange={onInputChange}
                  ref={form.add}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <InputField
                  name="observerLastName"
                  placeholder="Last Name"
                  value={observerLastName}
                  validate={(val) =>
                    observerNameValidator(
                      "Observer last name",
                      val,
                      observerFirstName,
                      val,
                      firstName,
                      lastName,
                      userType
                    )
                  }
                  onFieldError={onFieldError}
                  onChange={onInputChange}
                  ref={form.add}
                />
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label for="observerEmail">Observer Email</Label>
          <InputField
            name="observerEmail"
            placeholder="Email Address"
            value={observerEmail}
            type="email"
            onChange={onInputChange}
            validate={(val) => validateEmailField(val)}
            onFieldError={onFieldError}
            className="mxw-400"
            ref={form.add}
          />
        </FormGroup>
        {!isSampleForm && isLoggedIn && (
          <FormGroup>
            <ButtonFx
              type="submit"
              className="ttg-btn mr-2 mb-2"
              isLoading={isUpdateButtonLoading}
              onClick={() =>
                form.validate({
                  validFn: () => onUpdateDetails(),
                })
              }
              disabled={isUpdateButtonLoading}
            >
              Update Details
            </ButtonFx>
            <Button
              className="ttg-btn btn mb-2"
              onClick={() =>
                form.validate({
                  validFn: () => onEmail(),
                })
              }
              disabled={isUpdateButtonLoading}
            >
              Email this form to your Observer
            </Button>
          </FormGroup>
        )}
        {isSampleForm && (
          <FormGroup>
            <ButtonFx
              type="submit"
              className="ttg-btn mr-2 mb-2"
              isLoading={isUpdateButtonLoading}
              onClick={() => alert("This is just a sample form.")}
              disabled={isUpdateButtonLoading}
            >
              Update Details
            </ButtonFx>
            <Button
              className="ttg-btn btn mb-2"
              onClick={() => alert("This is just a sample form.")}
              disabled={isUpdateButtonLoading}
            >
              Email this form to your Observer
            </Button>
          </FormGroup>
        )}
      </BlockUiFx>
    </div>
  );
};

export default ObserverForm;
