/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  THIRD_PARTY_FORM_LOAD_FORM_REQUEST: "THIRD_PARTY_FORM_LOAD_FORM_REQUEST",
  THIRD_PARTY_FORM_LOAD_FORM_SUCCESS: "THIRD_PARTY_FORM_LOAD_FORM_SUCCESS",
  THIRD_PARTY_FORM_LOAD_FORM_FAILURE: "THIRD_PARTY_FORM_LOAD_FORM_FAILURE",

  // Form Actions
  THIRD_PARTY_FORM_FORM_INPUT_CHANGE: "THIRD_PARTY_FORM_FORM_INPUT_CHANGE",

  // Update third party form
  THIRD_PARTY_FORM_OBSERVER_UPDATE_REQUEST:
    "THIRD_PARTY_FORM_OBSERVER_UPDATE_REQUEST",
  THIRD_PARTY_FORM_OBSERVER_UPDATE_SUCCESS:
    "THIRD_PARTY_FORM_OBSERVER_UPDATE_SUCCESS",
  THIRD_PARTY_FORM_OBSERVER_UPDATE_FAILURE:
    "THIRD_PARTY_FORM_OBSERVER_UPDATE_FAILURE",

  // For review!!!
  // Create third party form
  THIRD_PARTY_FORM_OBSERVER_CREATE_REQUEST:
    "THIRD_PARTY_FORM_OBSERVER_CREATE_REQUEST",
  THIRD_PARTY_FORM_OBSERVER_CREATE_SUCCESS:
    "THIRD_PARTY_FORM_OBSERVER_CREATE_SUCCESS",
  THIRD_PARTY_FORM_OBSERVER_CREATE_FAILURE:
    "THIRD_PARTY_FORM_OBSERVER_CREATE_FAILURE",

  // Confirm Dialog
  THIRD_PARTY_SHOW_CONFIRM_EMAIL_OBSERVER:
    "THIRD_PARTY_SHOW_CONFIRM_EMAIL_OBSERVER",
  THIRD_PARTY_HIDE_CONFIRM_EMAIL_OBSERVER:
    "THIRD_PARTY_HIDE_CONFIRM_EMAIL_OBSERVER",

  THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_REQUEST:
    "THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_REQUEST",
  THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_SUCCESS:
    "THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_SUCCESS",
  THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_FAILURE:
    "THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_FAILURE",

  THIRD_PARTY_FORM_SAVE_PROGRESS_REQUEST:
    "THIRD_PARTY_FORM_SAVE_PROGRESS_REQUEST",
  THIRD_PARTY_FORM_SAVE_PROGRESS_SUCCESS:
    "THIRD_PARTY_FORM_SAVE_PROGRESS_SUCCESS",
  THIRD_PARTY_FORM_SAVE_PROGRESS_FAILURE:
    "THIRD_PARTY_FORM_SAVE_PROGRESS_FAILURE",

  THIRD_PARTY_FORM_SHOW_MESSAGE_DIALOG: "THIRD_PARTY_FORM_SHOW_MESSAGE_DIALOG",
  THIRD_PARTY_FORM_HIDE_MESSAGE_DIALOG: "THIRD_PARTY_FORM_HIDE_MESSAGE_DIALOG"
};
