import React from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
import ButtonFx from "../Common/Button-Fx/Button-Fx";
import * as Constants from "../../constants";
import { required, email } from "../Common/Fields/Validators/Field-Validators";
import formHandler from "./../Common/Fields/form-handler";
//import "./register-box.scss";
import KeyboardEventHandler from "react-keyboard-event-handler";

// Custom validators
const validateEmailField = val => {
  if (required("Email", val)) return required("Email", val);
  if (email(val)) return email(val);

  return false;
};

//
// Presentation Component
//
const ForgotPassword = props => {
  const { email } = props.fields;
  const {
    onInputChange,
    onFieldError,
    onResetPassword,
    validate,
    isLoading,
    isSubmitted,
    submittedTo
  } = props;

  const form = formHandler();

  return (
    <Form className="ttg-form mt-3">
      <FormGroup>
        <InputField
          name="email"
          placeholder="Email"
          type="email"
          value={email}
          validate={val => validateEmailField(val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <ButtonFx
          type="submit"
          className="ttg-btn"
          isLoading={isLoading}
          onClick={() =>
            form.validate({
              validFn: () => onResetPassword()
            })
          }
        >
          Submit
        </ButtonFx>
      </FormGroup>
      {isSubmitted ? (
        <h6>
          Reset password link has been succesfully sent to your email{" "}
          <span className="text-primary">{submittedTo}</span>.
        </h6>
      ) : null}
      <KeyboardEventHandler
        handleKeys={["enter"]}
        onKeyEvent={(key, e) =>
          form.validate({ validFn: () => onResetPassword() })
        }
      />
    </Form>
  );
};

export default ForgotPassword;
