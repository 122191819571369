import { connect } from "react-redux";
import AvetmissReportingSubmission from "./avetmiss-reporting-submission";
import { selectAvetmissReportingSubmission } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";

function mapStateToProps(state) {
  const avetmissReportingSubmission = selectAvetmissReportingSubmission(state);

  return {
    avetmissReportingSubmission: avetmissReportingSubmission,
    isLoading: avetmissReportingSubmission.isLoading,
    isDeleting: avetmissReportingSubmission.isDeleting,
    isImporting: avetmissReportingSubmission.isImporting,
    showDeleteDialog: avetmissReportingSubmission.showDeleteDialog,
    showImportDialog: avetmissReportingSubmission.showImportDialog,
    isExporting: avetmissReportingSubmission.isExporting,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  // onInputChange: ({ name, value, error }) => {
  //     dispatch(actionCreators.formInputChange(name, value, error));
  // },
  toggleDeleteDialog: () => dispatch(actionCreators.toggleDeleteDialog()),
  toggleImportDialog: () => dispatch(actionCreators.toggleImportDialog()),
  dispatch: dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadForm: () => {
    dispatchProps.dispatch(actionCreators.onLoadForm(dispatchProps.history));
  },
  onSubmit: (
    year,
    period,
    version,
    collectionStartDate,
    collectionEndDate,
    state
  ) => {
    dispatchProps.dispatch(
      actionCreators.onSubmit(
        year,
        period,
        version,
        collectionStartDate,
        collectionEndDate,
        state,
        dispatchProps.history
      )
    );
  },
  onDelete: () => {
    dispatchProps.dispatch(actionCreators.onDelete(dispatchProps.history));
  },
  onImport: async (payload) => {
    dispatchProps.dispatch(actionCreators.importNatFiles(payload));
  },
  validate: () => {
    return validate(stateProps);
  },
  exportNatFiles: () => {
    dispatchProps.dispatch(actionCreators.exportNatFiles());
  },
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

  if (!fields.year) return true;
  if (!fields.period) return true;
  if (!fields.version) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const AvetmissReportingSubmissionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(AvetmissReportingSubmission);

export default AvetmissReportingSubmissionContainer;
