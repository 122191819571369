import { connect } from "react-redux";
import ChangePassword from "./Change-Password";
import { selectChangePassword } from "./redux/reducer";
import { selectLoginData } from "../../redux/system/login-reducer";
import { onSubmit } from "./redux/action-creators";
import * as actionCreators from "./redux/action-creators";
import * as sysActionCreators from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const changePassword = selectChangePassword(state);
  const loginData = selectLoginData(state);
  return {
    changePassword: changePassword,
    fields: changePassword.fields,
    loginData: loginData,
    fieldErrors: changePassword.fieldErrors
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: error =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    // Validation code here
    if (validate(stateProps)) return;
    dispatchProps.dispatch(
      onSubmit(
        stateProps.loginData.loggedInUser.id,
        stateProps.changePassword.fields
      )
    );
  },
  validate: () => {
    return validate(stateProps);
  }
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

  if (!fields.password) return true;
  if (!fields.oldPassword) return true;
  if (!fields.confirmPassword) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const ChangePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(ChangePassword);

export default ChangePasswordContainer;
