import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import ContentSection from "components/Common/content-section";
import CourseCard from "./components/course-card/course-card";
import HelpQuestion from "./components/help-question/help-question";
import { courses } from "constants/course-content";
import "./select-a-course.scss";

const SelectACourse = ({
  title = (
    <>
      Providing Quality Online
      <br />
      Food Safety Training
    </>
  ),
}) => {
  const [showAllCourses, setShowAllCourses] = useState(false);
  const [helpCourses, setHelpCourses] = useState([]);

  const [worksInNsw, setWorksInNsw] = useState(null);
  const [nswCertificateExpired, setNswCertificateExpired] = useState(null);
  const [isFoodSafetySupervisor, setIsFoodSafetySupervisor] = useState(null);
  const [worksInHospitalityOrRetail, setWorksInHospitalityOrRetail] = useState(
    null
  );

  const handleWorksInNsw = (response) => {
    setWorksInNsw(response);
    setNswCertificateExpired(null);
    setIsFoodSafetySupervisor(null);
    setWorksInHospitalityOrRetail(null);
    setHelpCourses([]);
  };

  useEffect(() => {
    // Logic for setting helpCourseIds based on user responses
    if (worksInNsw === true) {
      if (nswCertificateExpired === false) {
        setHelpCourses(courses.filter((x) => x.courseId === 8)); // NSW Refresher Course
      } else if (nswCertificateExpired === true) {
        setHelpCourses(courses.filter((x) => x.courseId === 6)); // NSW Food Safety Supervisor
      }
    } else if (worksInNsw === false) {
      if (isFoodSafetySupervisor === true) {
        if (worksInHospitalityOrRetail === true) {
          setHelpCourses(courses.filter((x) => x.courseId === 4)); // Food Safety Supervisor Hospitality & Retail
        } else if (worksInHospitalityOrRetail === false) {
          setHelpCourses(courses.filter((x) => x.courseId === 1008)); // Food Safety Supervisor Health & Community
        }
      } else if (isFoodSafetySupervisor === false) {
        if (worksInHospitalityOrRetail === true) {
          setHelpCourses(
            courses.filter((x) => x.courseId === 2 || x.courseId === 3)
          ); // Food Handling Level 1 & 2 - Hospitality & Retail
        } else if (worksInHospitalityOrRetail === false) {
          setHelpCourses(courses.filter((x) => x.courseId === 1009)); // Food Handling Level 1 - Health & Community
        }
      }
    }
  }, [
    worksInNsw,
    nswCertificateExpired,
    isFoodSafetySupervisor,
    worksInHospitalityOrRetail,
  ]);

  /*

Do you work in NSW?
Yes/No

If Yes, then show the following question:
  Do you have a NSW Food Safety Supervisor certificate that is due to expire, or has expired, within the immediately preceding 90 days?
  Yes/No
  
  If Yes, then setHelpCourseIds(8) - NSW Refresher Course
  If No, then setHelpCourseIds(6) - NSW Food Safety Supervisor

If No, then show the following question:
  Are you a Food Safety Supervisor?
  Yes/No

  If Yes, then show the following question:
    Do you work in Hospitality or Retail?
    Yes/No

    If Yes, then setHelpCourseIds(4) - Food Safety Supervisor Hospitality & Retail
    If No, then setHelpCourseIds(1008) - Food Safety Supervisor Health & Community

  If No, then show the following question:
    Do you work in Hospitality or Retail?
    Yes/No

    If Yes, then setHelpCourseIds(2, 3) - Food Handling Level 1 & 2 - Hospitality & Retail
    If No, then setHelpCourseIds(1009) - Food Handling Level 1 - Health & Community

*/
  return (
    <>
      <div className="select-a-course">
        <h1>{title}</h1>
        <div className="text-center mt-5">
          <Button
            className="ttg-btn ttg-lg-btn ttg-orange-btn"
            onClick={() => setShowAllCourses(!showAllCourses)}
          >
            {showAllCourses ? "Help Me Choose" : "Show All Courses"}
          </Button>
        </div>

        {!showAllCourses && (
          <Row className="mt-4 justify-content-center text-center">
            <Col lg={5}>
              <HelpQuestion
                question="Do you work in NSW?"
                response={worksInNsw}
                setResponseHandler={handleWorksInNsw}
              />

              {worksInNsw === true && (
                <HelpQuestion
                  question={
                    <>
                      Do you need to complete a NSW Food Safety Supervisor
                      course because you don&rsquo;t hold a current certificate?
                    </>
                  }
                  response={nswCertificateExpired}
                  setResponseHandler={setNswCertificateExpired}
                  buttonOneText="Yes"
                  buttonTwoText="No"
                />
              )}

              {worksInNsw === false && (
                <HelpQuestion
                  question="Do you need Food Safety Supervisor certification?"
                  response={isFoodSafetySupervisor}
                  setResponseHandler={setIsFoodSafetySupervisor}
                />
              )}

              {worksInNsw === false && isFoodSafetySupervisor !== null && (
                <HelpQuestion
                  question="What industry do you work in?"
                  response={worksInHospitalityOrRetail}
                  setResponseHandler={setWorksInHospitalityOrRetail}
                  buttonOneText="Hospitality & Retail"
                  buttonTwoText="Health & Community"
                />
              )}
            </Col>

            {helpCourses.length > 0 && (
              <Col lg={helpCourses.length === 1 ? 6 : 7}>
                <Row>
                  {helpCourses.map((course, index) => (
                    <Col
                      key={index}
                      lg={12}
                      xl={helpCourses.length === 1 ? 12 : 6}
                      className="mt-5 mt-lg-3"
                    >
                      <CourseCard {...course} />
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
          </Row>
        )}

        {showAllCourses && (
          <Row className="justify-content-center">
            {courses.map((course, index) => (
              <Col key={index} md={6} xl={4} className="mt-5">
                <CourseCard {...course} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </>
  );
};

export default SelectACourse;
