import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import LinkButton from "components/link-button/link-button";
import Infographic from "features/infographic/infographic";
import { courses } from "constants/course-content";
import { getCourseContent } from "./course-content";
import "./course-info-page.scss";

const CourseInfoPage = () => {
  const { courseSlug } = useParams();

  const [course, setCourse] = useState(null);

  useEffect(() => {
    const course = courses.find(
      (course) =>
        course.courseReadMorePage === `/courses/${courseSlug.toLowerCase()}/`
    );
    setCourse(course);
  }, [courseSlug]);

  return (
    <ContentSection bgClass="veggies-bg off-white">
      {course && (
        <>
          <PageTagging
            title={course.courseInfo.pageTitle}
            description={course.courseInfo.pageDescription}
          />
          <div className="course-info">
            <div className="course-info-content text-center text-md-left">
              <h1 className="mb-3">{course.courseInfo.heading}</h1>
              <Row>
                <Col md={7}>
                  {course.courseInfo.units.map((unit, index) => (
                    <h2 key={index} className="mb-2">
                      {unit}
                    </h2>
                  ))}
                  <h3 className="my-4">{course.courseInfo.intro}</h3>
                  <div className="course-price mb-4">${course.courseCost}</div>
                  <LinkButton
                    to={`/Certificates/Enrol/${course.courseId}/`}
                    className="ttg-btn ttg-lg-btn ttg-orange-btn"
                    btnText="Enrol Now"
                  />
                  {course.courseInfo.additionalContent && (
                    <>{course.courseInfo.additionalContent}</>
                  )}
                </Col>
                <Col md={5} className="my-3 my-md-0 text-center">
                  <img
                    src={course.courseInfo.image ?? course.courseImage}
                    alt={course.courseTitle}
                    className="course-img"
                  />
                </Col>
              </Row>
            </div>
            <Infographic />
            <div className="course-info-content mt-5">
              {getCourseContent(course.courseId)}
              <LinkButton
                to={`/Certificates/Enrol/${course.courseId}/`}
                className="ttg-btn ttg-lg-btn ttg-orange-btn"
                btnText="Get Started Now"
              />
            </div>
          </div>
        </>
      )}
    </ContentSection>
  );
};

export default CourseInfoPage;
