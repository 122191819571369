import { ActionTypes } from "./action-types";
import moment from "moment";

const initialState = {
  isProcessing: false,
  fields: {
    reportType: "",
    startDate: moment().subtract(2, "month")._d,
    endDate: moment()._d
  },
  fieldErrors: {}
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        isProcessing: true
      };
    case ActionTypes.ADMIN_DOWNLOAD_REPORT_FAILURE:
    case ActionTypes.ADMIN_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        isProcessing: false
      };
    case ActionTypes.ADMIN_DOWNLOAD_REPORT_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    default:
      return state;
  }
}

export default Reducer;
