import React from "react";
import PropTypes from "prop-types";
import ContentSectionContainer from "../../content-section-container/content-section-container";
import "./intersecting-waves.scss";

function IntersectingWaves({
  id,
  children,
  bgOuterColor = "#fff",
  bgInnerColor = "#fff",
  padding = 1,
  specificClass,
  isFullWidth = false,
  showTopSvg = true,
  showBottomSvg = true,
}) {
  return (
    <div
      className={`content-section-intersecting-waves${
        specificClass ? ` ${specificClass}` : ""
      }`}
      style={{ backgroundColor: bgInnerColor }}
      {...(id ? { id } : {})}
    >
      {showTopSvg && (
        <section className="content-section-intersecting-waves-top">
          <div className="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
              style={{ backgroundColor: bgInnerColor }}
            >
              <path
                d="M0 0v99.7C62 69 122.4 48.7 205 66c83.8 17.6 160.5 20.4 240-12 54-22 110-26 173-10a392.2 392.2 0 0 0 222-5c55-17 110.3-36.9 160-27.2V0H0Z"
                opacity=".5"
                fill={bgOuterColor}
              />
              <path
                d="M0 0v74.7C62 44 122.4 28.7 205 46c83.8 17.6 160.5 25.4 240-7 54-22 110-21 173-5 76.5 19.4 146.5 23.3 222 0 55-17 110.3-31.9 160-22.2V0H0Z"
                fill={bgOuterColor}
              />
            </svg>
          </div>
        </section>
      )}
      <ContentSectionContainer
        padding={padding}
        isFullWidth={isFullWidth}
        children={children}
      />
      {showBottomSvg && (
        <section className="content-section-intersecting-waves-bottom">
          <div className="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
              style={{ backgroundColor: bgOuterColor }}
            >
              <path
                d="M0 0v99.7C62 69 122.4 48.7 205 66c83.8 17.6 160.5 20.4 240-12 54-22 110-26 173-10a392.2 392.2 0 0 0 222-5c55-17 110.3-36.9 160-27.2V0H0Z"
                opacity=".5"
                fill={bgInnerColor}
              />
              <path
                d="M0 0v74.7C62 44 122.4 28.7 205 46c83.8 17.6 160.5 25.4 240-7 54-22 110-21 173-5 76.5 19.4 146.5 23.3 222 0 55-17 110.3-31.9 160-22.2V0H0Z"
                fill={bgInnerColor}
              />
            </svg>
          </div>
        </section>
      )}
    </div>
  );
}

IntersectingWaves.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string,
  bgOuterColor: PropTypes.string,
  bgInnerColor: PropTypes.string,
  padding: PropTypes.number,
  specificClass: PropTypes.string,
  isFullWidth: PropTypes.bool,
  showTopSvg: PropTypes.bool,
  showBottomSvg: PropTypes.bool,
};

export default IntersectingWaves;
