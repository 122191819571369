import React from "react";
import ContentCollapsiblePanel from "components/Common/content-collapsible-panel";

const NswFoodSafetySupervisorCertificateRefresher = () => {
  return (
    <>
      <ContentCollapsiblePanel
        openSection={-1}
        sections={[
          {
            title: "About the NSW Refresher Course",
            content: (
              <>
                <p>
                  The NSW Food Authority Refresher Course Training is an
                  essential program for individuals in New South Wales (NSW),
                  Australia, who work in the food industry. This online training
                  ensures that food handlers and supervisors maintain up-to-date
                  knowledge of safe food handling practices and comply with
                  legal requirements.{" "}
                  <a
                    href="https://www.foodauthority.nsw.gov.au/retail/fss-food-safety-supervisors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Please click here for more information about the NSW Food
                    Safety Refresher Course.
                  </a>
                </p>
              </>
            ),
          },
          {
            title: "Purpose of the Refresher Training",
            content: (
              <>
                <ol>
                  <li>
                    Compliance with Regulations: The course aligns with the
                    requirements of the{" "}
                    <em>NSW Food Act 2003 and Food Standards Code</em>. It is
                    particularly aimed at Food Safety Supervisors (FSS) who are
                    required to renew their certification every five years.
                  </li>
                  <li>
                    Updated Knowledge: It helps participants stay informed about
                    the latest changes in food safety laws, practices, and
                    industry standards.
                  </li>
                  <li>
                    Public Health Protection: By ensuring best practices, the
                    course reduces risks of foodborne illnesses, safeguarding
                    consumer health.
                  </li>
                </ol>
              </>
            ),
          },
          {
            title: "How do I Renew?",
            content: (
              <>
                <p>
                  If you already have a NSW Food Safety Supervisor Certificate
                  you can renew it with us online today!
                </p>
                <p>
                  <strong>Simply</strong>
                </p>
                <ul>
                  <li>
                    Simply Log back in (top right) of the website or re register
                    if you have updated your details.
                  </li>
                  <li>
                    Select the NSW Refresher Course option and begin your
                    training.
                  </li>
                </ul>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default NswFoodSafetySupervisorCertificateRefresher;
