import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isSyncing: false,
  courses: [],
};

export default function SyncCoursesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SYNC_COURSES_OPERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.SYNC_COURSES_OPERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSyncing: false,
      };
    case ActionTypes.SYNC_COURSES_SYNC_REQUEST:
      return {
        ...state,
        isSyncing: true,
      };
    case ActionTypes.SYNC_COURSES_SYNC_SUCCESS:
      return {
        ...state,
        isSyncing: false,
        courses: action.payload,
      };
    case ActionTypes.SYNC_COURSES_PUBLISH_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
