import { useCallback } from "react";

const useScrollToSection = () => {
  return useCallback((sectionId, scrollOffset = 0) => {
    if (!sectionId) return;

    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = elementPosition + scrollOffset;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, []);
};

export default useScrollToSection;
