import React from "react";
import { Col, Row } from "reactstrap";
import GreenTickBalls from "features/green-tick-balls/green-tick-balls";

const WhyTrainWithUsPoints = () => {
  return (
    <Row>
      <Col md={6}>
        <GreenTickBalls
          items={[
            "We are Standard 3.2.2A Compliant",
            "Train to Gain Pty Ltd T/A Food Safety Education is a Registered Training Organisation (#22361) with over 30 years of Food Safety experience",
            "Our courses are Nationally Accredited Australia wide with NO HIDDEN ASSESSMENT or PAYMENTS",
            "NSW Food Authority approved – RTO Approval Number: 25085",
            "Have peace of mind and get the certificates that council want to see",
            "Our website is mobile friendly so you can do your course on the go",
            "You get as many times as you need to get the answer right!",
          ]}
        />
      </Col>
      <Col md={6}>
        <GreenTickBalls
          items={[
            "No paper, no printing, get your certificate today!",
            "Educational workbook included",
            "Up to date content with fun and informative videos to watch",
            "We give the best customer service in the business because we know our stuff",
            "Phone and email support from 9am-5pm Monday to Friday (QLD Time)",
            "We use industry standard SSL security & eWay to securely process your transaction",
            "Bulk orders available for businesses and student tracking facility for business clients",
            "Convenience and comfort of training in your own home or workplace, 100% online",
          ]}
        />
      </Col>
    </Row>
  );
};

export default WhyTrainWithUsPoints;
