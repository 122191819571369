Object.defineProperty(exports, "__esModule", { value: true });

require("react");
var visa = require("./visa.js");
var unionpay = require("./unionpay.js");
var placeholder = require("./placeholder.js");
var mastercard = require("./mastercard.js");
var jcb = require("./jcb.js");
var amex = require("./amex.js");
var dinersclub = require("./dinersclub.js");
var discover = require("./discover.js");
var hipercard = require("./hipercard.js");

var index = {
  amex: amex.default,
  dinersclub: dinersclub.default,
  discover: discover.default,
  hipercard: hipercard.default,
  jcb: jcb.default,
  unionpay: unionpay.default,
  mastercard: mastercard.default,
  placeholder: placeholder.default,
  visa: visa.default
};

exports.default = index;
