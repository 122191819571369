/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import * as Constants from "../../../constants";
import HttpService from "./service";

// =================
//  Contact
// =================

// Async
export const onSubmit = fields => {
  // eslint-disable-next-line no-unused-vars
  const { name, email, message } = fields;

  return dispatch => {
    if (!name || name === undefined || name === "") {
      dispatch(addNotification("Please enter your name.", "warning"));
      return;
    }

    if (!email || email === undefined || email === "") {
      dispatch(addNotification("Please enter your email address.", "warning"));
      return;
    }

    if (!Constants.emailRegex.test(email)) {
      dispatch(
        addNotification("Please enter a valid email address.", "warning")
      );
      return;
    }

    if (!message || message === undefined || message === "") {
      dispatch(addNotification("Please enter your message.", "warning"));
      return;
    }

    dispatch(onSubmitRequest());
    HttpService()
      .send(fields)
      .then(() => dispatch(onSubmitSuccess()))
      .catch(() => {
        dispatch(onSubmitFailure());
        dispatch(addNotification("Sending failed.", "warning"));
      });
  };
};

const onSubmitRequest = () => ({
  type: ActionTypes.CONTACT_BOX_SUBMIT_REQUEST
});

const onSubmitSuccess = () => ({
  type: ActionTypes.CONTACT_BOX_SUBMIT_SUCCESS
});

const onSubmitFailure = () => ({
  type: ActionTypes.CONTACT_BOX_SUBMIT_FAILURE
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.CONTACT_BOX_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
