import { ActionTypes } from "./action-types";
import moment from "moment";
import DateService from "../../../../../services/date-service";
import CourseService from "../../../../../services/course-service";

const initialState = {
  data: [],
  settings: {},
  hideFilterSection: false,
  isProcessing: false,
  fields: {
    courseIds: [],
    startDate: moment().subtract(12, "months")._d,
    endDate: moment()._d,
  },
  fieldErrors: {},
  isReset: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_USER_COURSES_ONLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
        data: action.payload,
      };
    case ActionTypes.ADMIN_USERS_COURSES_RESET:
      return {
        ...state,
        isProcessing: true,
        isReset: true,
        fields: {
          courseIds: CourseService().getFlattenedCourses(),
          startDate: moment().subtract(12, "months")._d,
          endDate: moment()._d,
        },
      };
    case ActionTypes.ADMIN_USERS_COURSES_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      };
    case ActionTypes.ADMIN_USER_COURSES_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (course) => course.userCourseId !== action.payload.userCourseId
        ),
      };
    case ActionTypes.ADMIN_USER_COURSES_ONLOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isReset: false,
        data: action.payload.map((course) => {
          return {
            ...course,
            active: course.active ? "Yes" : "No",
            enrolmentDateTime: moment(course.enrolmentDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            registeredDateTime: moment(course.registeredDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            lastLoginDateTime: moment(course.lastLoginDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            userType:
              course.userType === 1
                ? "Admin"
                : course.userType === 2
                ? "Student"
                : "Business",
          };
        }),
      };
    case ActionTypes.ADMIN_USER_COURSES_ONLOAD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.ADMIN_USERS_COURSES_HIDE_FILTER_SECTION:
      return {
        ...state,
        hideFilterSection: action.payload,
      };
    case ActionTypes.ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS:
    case ActionTypes.ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.ADMIN_USERS_COURSES_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    case ActionTypes.ADMIN_USERS_COURSES_DATATABLE_CLEAR_SEARCHBOX:
      return {
        ...state,
        settings: {
          ...state.settings,
          searchText: "",
        },
      };
    default:
      return state;
  }
}

export default Reducer;
