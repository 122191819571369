import React from "react";
import { PropTypes } from "prop-types";
import "./header-image-section.scss";

const HeaderImageSection = ({ imgSrc, title, subTitle, children }) => {
  return (
    <section className="header-image-section">
      <div
        style={{ backgroundImage: "url(" + imgSrc + ")" }}
        className="image-container d-flex flex-column justify-content-center align-items-center"
      ></div>

      {children ? (
        <div className="children-container d-flex flex-column justify-content-center align-items-center">
          {children}
        </div>
      ) : (
        <div className="text-container d-flex flex-column justify-content-center align-items-center">
          <h1>
            {Array.isArray(title) &&
              title.map((item, index) => (
                <span key={index}>
                  {item.isFeatured ? (
                    <span className="featured-text">{item.text} </span>
                  ) : (
                    <span>{item.text} </span>
                  )}
                </span>
              ))}
            {!Array.isArray(title) && title}
          </h1>
          {subTitle && <h2>{subTitle}</h2>}
        </div>
      )}
    </section>
  );
};

HeaderImageSection.propTypes = {
  imgSrc: PropTypes.any.isRequired,
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  subTitle: PropTypes.string,
  children: PropTypes.any,
};

export default HeaderImageSection;
