export const ActionTypes = {
  ADMIN_USER_COURSES_ONLOAD_REQUEST: "ADMIN_USER_COURSES_ONLOAD_REQUEST",
  ADMIN_USER_COURSES_ONLOAD_SUCCESS: "ADMIN_USER_COURSES_ONLOAD_SUCCESS",
  ADMIN_USER_COURSES_ONLOAD_FAILURE: "ADMIN_USER_COURSES_ONLOAD_FAILURE",
  ADMIN_USER_COURSES_DELETE_SUCCESS: "ADMIN_USER_COURSES_DELETE_SUCCESS",
  ADMIN_USERS_COURSES_FORM_INPUT_CHANGE:
    "ADMIN_USERS_COURSES_FORM_INPUT_CHANGE",
  ADMIN_USERS_COURSES_HIDE_FILTER_SECTION:
    "ADMIN_USERS_COURSES_HIDE_FILTER_SECTION",
  ADMIN_USERS_COURSES_RESET: "ADMIN_USERS_COURSES_RESET",
  ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST:
    "ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST",
  ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS:
    "ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS",
  ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE:
    "ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE",
  ADMIN_USERS_COURSES_DATATABLE_KEEP_SETTINGS:
    "ADMIN_USERS_COURSES_DATATABLE_KEEP_SETTINGS",
  ADMIN_USERS_COURSES_DATATABLE_CLEAR_SEARCHBOX:
    "ADMIN_USERS_COURSES_DATATABLE_CLEAR_SEARCHBOX",
};
