import React from "react";
import TextAreaField from "../../../../components/Common/Fields/Text-Area-Field";

const ValidationError = ({
  natFileNumber,
  fileId,
  recordNumber,
  errorMessage,
  fieldValue,
  onInputChange,
}) => {
  return (
    <tr>
      <td>
        {fileId} ({recordNumber})
      </td>
      <td>{errorMessage}</td>
      <td>
        <TextAreaField
          name={`${natFileNumber}-${recordNumber}`}
          value={fieldValue}
          placeholder="This entry will be deleted if left blank"
          type="text"
          onChange={onInputChange}
          rows={10}
        />
      </td>
    </tr>
  );
};

export default ValidationError;
