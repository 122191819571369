import { ActionTypes } from "./action-types";
import moment from "moment";

const initialState = {
  isModalOpen: false,
  isModalLoading: false,
  fields: {},
  fieldErrors: {}
};

function NswReprintsViewReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.NSW_CERTIFICATES_VIEW_TOGGLE_DIALOG:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
        fields: action.payload
          ? {
              ...action.payload,
              dateSent:
                action.payload.dateSent == null ||
                action.payload.dateSent === undefined
                  ? ""
                  : moment(action.payload.dateSent),
              status:
                action.payload.status === "Unactioned"
                  ? 0
                  : action.payload.status === "Underway"
                  ? 1
                  : 2
            }
          : {}
      };
    case ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_REQUEST:
      return {
        ...state,
        isModalLoading: true
      };
    case ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_SUCCESS:
    case ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_FAILURE:
      return {
        ...state,
        isModalLoading: false,
        isModalOpen: false
      };
    case ActionTypes.NSW_CERTIFICATES_VIEW_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    default:
      return state;
  }
}

export default NswReprintsViewReducer;
