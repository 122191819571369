import HttpClient from "../../../../coreLib/http/httpClient";

const ApiService = () => {
  let httpClient = HttpClient();

  const submitStep2 = (payload) => {
    let { userCourseId } = payload;

    let model = {
      ...payload,
      completedTertiary: payload.completedTertiary === "TRUE" ? true : false,
      disability: payload.disability === "TRUE" ? true : false,
      bornInAustralia: payload.bornInAustralia === "TRUE" ? true : false,
      countryOfBirth: payload.country,
      englishLanguageAtHome:
        payload.languageAtHome === "English" ? true : false,
      languageAtHomeOther: payload.languageAtHomeOther,
      goodUnderstandingOfEnglish:
        payload.goodUnderstandingOfEnglish === "TRUE" ? true : false,
      understandFalseInfo:
        payload.understandFalseInfo === "TRUE" ? true : false,
      understandTimeToComplete:
        payload.understandTimeToComplete === "TRUE" ? true : false,
    };
    return httpClient.put(`v1/enrolment/${userCourseId}/step2`, model);
  };

  const getStep2 = (id) => {
    return httpClient.get(`v1/enrolment/${id}/step2`);
  };

  return {
    getStep2,
    submitStep2,
  };
};

export default ApiService;
