import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const getStep3 = id => {
    return httpClient.get(`v1/enrolment/${id}/step3`);
  };

  const submitStep3 = payload => {
    let { userCourseId } = payload;
    let model = {
      workplaceIsHome: payload.whereExperience === "Home" ? true : false,
      workplaceName: payload.workplaceName,
      workplaceSuburb: payload.workplaceSuburb,
      workplacePhone: payload.workplacePhone,
      currentEmploymentStatus: payload.currentEmploymentStatus,
      observerFirstName: payload.observerFirstName,
      observerLastName: payload.observerLastName,
      observerEmail: payload.observerEmail,
      relationshipToObserver: payload.relationshipToObserver
    };
    return httpClient.put(`v1/enrolment/${userCourseId}/step3`, model);
  };

  return {
    getStep3,
    submitStep3
  };
};

export default Service;
