import { ActionTypes } from "./action-types";

const initialState = {
  addressEntry: {
    displayAutocompleteAddressField: false,
    enterAddressManually: false,
    showSearchAddress: true,
    fullAddress: "",
  },
  fields: {
    companyName: "",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
    phone: "",
    email: "",
    purchaseNumber: "",
    usingCreditCard: true,
    paymentMethod: "",
    cardName: "",
    cardNumber: null,
    cardExpiryDate: null,
    cardCvc: null,
    promoCode: "",
  },
  courses: [],
  totalPrice: 0,
  totalAfterDiscount: 0,
  discountAmount: 0,
  isPromoCodeApplied: false,
  isCreditCardValid: false,
  fieldErrors: {},
  isSubmitting: false,
  isSubmitted: false,
  formValid: false,
};

function PurchaseCorporateVoucherReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_VALIDATE:
      return {
        ...state,
        formValid: action.payload,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        isPromoCodeApplied: true,
        totalAfterDiscount: action.payload.totalAfterDiscount,
        isSubmitting: false,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_FAILURE:
      return {
        ...state,
        isPromoCodeApplied: false,
        fields: {
          ...state.fields,
          promoCode: "",
        },
        isSubmitting: false,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_CREDIT:
      return {
        ...state,
        fields: {
          ...state.fields,
          usingCreditCard: true,
        },
        isCreditCardValid: false,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_INVOICE:
      return {
        ...state,
        fields: {
          ...state.fields,
          usingCreditCard: false,
          cardName: "",
          cardNumber: null,
          cardExpiryDate: null,
          cardCvc: null,
        },
        isCreditCardValid: false,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_INIT:
      return {
        ...state,
        addressEntry: {
          displayAutocompleteAddressField: false,
          enterAddressManually: false,
          showSearchAddress: true,
          fullAddress: "",
        },
        fields: {
          companyName: "",
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
          phone: "",
          email: "",
          purchaseNumber: "",
          usingCreditCard: "",
          paymentMethod: "",
          cardName: "",
          cardNumber: null,
          cardExpiryDate: null,
          cardCvc: null,
          promoCode: "",
        },
        courses: [
          {
            id: 6,
            courseTitle: "NSW Food Safety Supervisor Certificate",
            courseCost: 179,
            orderPriority: 0,
            quantity: null,
            total: 0,
            courseCodes: "SITXFSA005, SITXFSA006",
          },
          {
            id: 2,
            courseTitle: "Food Handling Level 1",
            courseCost: 55,
            orderPriority: 1,
            quantity: null,
            total: 0,
            courseCodes: "SITXFSA005",
          },
          {
            id: 3,
            courseTitle: "Food Handling Level 2",
            courseCost: 89,
            orderPriority: 2,
            quantity: null,
            total: 0,
            courseCodes: "SITXFSA006",
          },
          {
            id: 4,
            courseTitle: "Food Safety Supervisor Hospitality & Retail",
            courseCost: 139,
            orderPriority: 3,
            quantity: null,
            total: 0,
            courseCodes: "SITXFSA005, SITXFSA006",
          },
          {
            id: 1008,
            courseTitle: "Food Safety Supervisor Health & Community",
            courseCost: 198,
            orderPriority: 4,
            quantity: null,
            total: 0,
            courseCodes: "HLTFSE001, HLTFSE005, HLTFSE007",
          },
          {
            id: 1009,
            courseTitle: "Food Handling Level 1 - Health & Community",
            courseCost: 50,
            orderPriority: 5,
            quantity: null,
            total: 0,
            courseCodes: "HLTFSE001",
          },
          {
            id: 8,
            courseTitle: "NSW Refresher Course",
            courseCost: 149,
            orderPriority: 6,
            quantity: null,
            total: 0,
            courseCodes: "SITXFSA005, SITXFSA006",
          },
        ],
        totalPrice: 0,
        totalAfterDiscount: 0,
        discountAmount: 0,
        isPromoCodeApplied: false,
        isCreditCardValid: false,
        fieldErrors: {},
        isSubmitting: false,
        isSubmitted: false,
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_QUANTITY_CHANGE:
      return {
        ...state,
        courses: state.courses.map((course) => {
          if (course.id == action.payload.name) {
            return {
              ...course,
              quantity: action.payload.value,
              total: parseInt(action.payload.value) * course.courseCost,
            };
          }
          return course;
        }),
        totalPrice: state.courses
          .reduce((total, currentValue) => total + currentValue.total, 0)
          .toFixed(2),
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_CLEAR_ADDRESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: "",
        },
        fields: {
          ...state.fields,
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
        },
      };
    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SET_CARD_VALIDITY:
      return {
        ...state,
        isCreditCardValid: action.payload,
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: action.payload.fullAddress,
          enterAddressManually: false,
        },
        fields: {
          ...state.fields,
          unitNumber: `${(
            action.payload.subdwelling +
            " " +
            action.payload.buildingName
          ).trim()}`,
          streetNumber: action.payload.streetNumber,
          streetName: action.payload.street,
          city: action.payload.locality,
          state: action.payload.state,
          postcode: action.payload.postcode,
        },
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_TOGGLE_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: !state.addressEntry.enterAddressManually,
        },
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SHOW_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: true,
        },
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_TOGGLE_PROMO_CODE_ENTRY:
      return {
        ...state,
        hasPromoCode: !state.hasPromoCode,
        fields: {
          ...state.fields,
          cardNumber: null,
          cardExpiryDate: null,
          cardCvc: null,
          cardName: "",
          promoCode: "",
        },
        isCreditCardValid: false,
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SUBMIT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_SUBMIT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
      };

    case ActionTypes.PURCHASE_CORPORATE_VOUCHERS_FORM_INPUT_CHANGE:
      let stateResultInput = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });

      return stateResultInput;

    default:
      return state;
  }
}

export default PurchaseCorporateVoucherReducer;

export const selectPurchaseCorporateVouchers = (state) =>
  state.purchaseCorporateVoucher;
