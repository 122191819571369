/**
 * Solvable Form :)
 * Form Handler for super Custom Field Validation requirement or if you dont want to rely on heavy weight validation libraries (open source).
 * Note: This form handler will be converted into a Form Component in the future. If I have a free time. :-)
 * This will support Pristine, Touch properties in the future. For now lets make it simple!
 *  by Rcnet@solvable 7.25.2019
 */
const formHandler = () => {
  let fieldComponents = [];
  let formFields = []; // holds form individual statuses, pristine, touch props (future scope)

  const add = comp => {
    if (comp === null) return;
    fieldComponents.push(comp);
  };

  const validate = options => {
    formFields = [];
    const opts = Object.assign(
      { validFn: () => {}, invalidFn: () => {} },
      options
    );

    // Check for unmounted components, this is to prevent dealing with a dead component.
    fieldComponents
      .filter(x => x._isValidComponentInstance())
      .forEach(c => {
        if (c.hasOwnProperty("_validateField")) {
          let valResult = c._validateField();
          formFields.push({ ...valResult });
        }
      });

    const { validFn, invalidFn } = opts;

    _isValid() ? (validFn || (() => {}))() : (invalidFn || (() => {}))();
  };

  const getFields = () => [...formFields]; // return clone instance to avoid mutation of internal object
  const getFieldErrors = () => formFields.filter(x => x.error !== false);
  const getValidFields = () => formFields.filter(x => x.error === false);
  const getErrorCount = () => formFields.filter(x => x.error !== false).length;

  /**
   * Before executing methods below, execute validate method first to get the latest validation result.
   */
  const isValid = () => _isValid();
  const invalid = () => !_isValid();
  const _isValid = () => formFields.every(f => f.error === false);

  return {
    add,
    getFields,
    getFieldErrors,
    getValidFields,
    getErrorCount,
    validate,
    isValid,
    invalid
  };
};

export default formHandler;
