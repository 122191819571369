/**
 * Action types
 */
export const ActionTypes = {
         // Actions
         AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_REQUEST:
           "AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_REQUEST",
         AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_SUCCESS:
           "AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_SUCCESS",
         AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_FAILURE:
           "AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_FAILURE",

         AVETMISS_REPORTING_SUBMISSION_SUBMIT_REQUEST:
           "AVETMISS_REPORTING_SUBMISSION_SUBMIT_REQUEST",
         AVETMISS_REPORTING_SUBMISSION_SUBMIT_SUCCESS:
           "AVETMISS_REPORTING_SUBMISSION_SUBMIT_SUCCESS",
         AVETMISS_REPORTING_SUBMISSION_SUBMIT_FAILURE:
           "AVETMISS_REPORTING_SUBMISSION_SUBMIT_FAILURE",

         AVETMISS_REPORTING_SUBMISSION_DELETE_REQUEST:
           "AVETMISS_REPORTING_SUBMISSION_DELETE_REQUEST",
         AVETMISS_REPORTING_SUBMISSION_DELETE_SUCCESS:
           "AVETMISS_REPORTING_SUBMISSION_DELETE_SUCCESS",
         AVETMISS_REPORTING_SUBMISSION_DELETE_FAILURE:
           "AVETMISS_REPORTING_SUBMISSION_DELETE_FAILURE",

         AVETMISS_REPORTING_SUBMISSION_SHOW_DELETE_DIALOG:
           "AVETMISS_REPORTING_SUBMISSION_SHOW_DELETE_DIALOG",

         AVETMISS_REPORTING_SUBMISSION_SHOW_IMPORT_DIALOG:
           "AVETMISS_REPORTING_SUBMISSION_SHOW_IMPORT_DIALOG",

         AVETMISS_REPORTING_SUBMISSION_IMPORT_REQUEST:
           "AVETMISS_REPORTING_SUBMISSION_IMPORT_REQUEST",
         AVETMISS_REPORTING_SUBMISSION_IMPORT_SUCCESS:
           "AVETMISS_REPORTING_SUBMISSION_IMPORT_SUCCESS",
         AVETMISS_REPORTING_SUBMISSION_IMPORT_FAILURE:
           "AVETMISS_REPORTING_SUBMISSION_IMPORT_FAILURE",

         AVETMISS_REPORTING_SUBMISSION_EXPORT_REQUEST:
           "AVETMISS_REPORTING_SUBMISSION_EXPORT_REQUEST",
         AVETMISS_REPORTING_SUBMISSION_EXPORT_SUCCESS:
           "AVETMISS_REPORTING_SUBMISSION_EXPORT_SUCCESS",
         AVETMISS_REPORTING_SUBMISSION_EXPORT_FAILURE:
           "AVETMISS_REPORTING_SUBMISSION_EXPORT_FAILURE",
       };
