import React from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";
import ForgotPasswordContainer from "../../components/Forgot-Password/Forgot-Password.Container";

const ForgotPasswordView = () => (
  <>
    <PageTitle title="Forgot Password" />
    <ContentSection bgColor="grey-shadow">
      <div className="row">
        <div className="col-md-6 col-12">
          <h1>Forgot Password</h1>
          <ForgotPasswordContainer />
        </div>
      </div>
    </ContentSection>
  </>
);

export default ForgotPasswordView;
