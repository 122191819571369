import { connect } from "react-redux";
import RegisterBox from "./Register-Box";
import { withRouter } from "react-router-dom";
import { selectRegisterBox } from "./redux/reducer";
import { onRegister } from "./redux/action-creators";
import * as actionCreators from "./redux/action-creators";
import { addNotification } from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const registerBox = selectRegisterBox(state);

  return {
    registerBox: registerBox,
    isLoading: registerBox.isLoading,
    noLastName: registerBox.noLastName,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: (error) => dispatch(addNotification(error, "Error")),
  toggleNoLastName: () => dispatch(actionCreators.toggleNoLastName()),
  dispatch: dispatch,
  history: ownProps.history,
  courseId: ownProps.courseId,
  registerType: ownProps.registerType,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onRegister: (courseId, registerType) => {
    if (validate(stateProps.registerBox)) return;
    dispatchProps.dispatch(
      onRegister(
        stateProps.registerBox.fields,
        courseId,
        registerType,
        dispatchProps.history
      )
    );
  },
  validate: () => {
    return validate(stateProps.registerBox);
  },
});

const validate = (stateProps) => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);
  const fieldsToCheck = stateProps.noLastName
    ? ["firstName", "email", "confirmEmail", "password", "confirmPassword"]
    : [
        "firstName",
        "lastName",
        "email",
        "confirmEmail",
        "password",
        "confirmPassword",
      ];

  for (var fld in fieldsToCheck) {
    if (!fields[fieldsToCheck[fld]]) return true;
  }

  if (!stateProps.noLastName && errMessages.length > 0) return true;
  else if (stateProps.noLastName && errMessages.length > 1) return true;

  return false;
};

const RegisterBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(RegisterBox);

export default withRouter(RegisterBoxContainer);
