/**
 * Search Stateless component
 * Functional/Controlled Component
 * by rcnet @solvable 4.22.2019
 * 
 * Css to override:
 * - datatableSearch
 */
import React from 'react';

const SearchBox = ({...props}) => (
    <input value={props.searchText}
        placeholder="Search"
        className="datatableSearch"
        onChange={props.onSearchInputChange}
    />
);

export default SearchBox;
