import { ActionTypes } from "./action-types";
import moment from "moment";

const initialState = {
  data: [],
  settings: {},
  isProcessing: false,
  fields: {
    userTypes: [],
    startDate: moment().subtract(12, "months")._d,
    endDate: moment()._d,
  },
  fieldErrors: {},
  isSubmitting: false,
  isReset: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_USERS_ONLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
        data: action.payload,
      };
    case ActionTypes.ADMIN_USERS_RESET:
      return {
        ...state,
        isProcessing: true,
        isReset: true,
        fields: {
          userTypes: [
            {
              value: 1,
              label: "Admin",
            },
            {
              value: 2,
              label: "Student",
            },
            {
              value: 3,
              label: "Business",
            },
          ],
          startDate: moment().subtract(12, "months")._d,
          endDate: moment()._d,
        },
      };
    case ActionTypes.ADMIN_USERS_DELETE_FAILURE:
    case ActionTypes.ADMIN_USERS_EDIT_FAILURE:
    case ActionTypes.ADMIN_USERS_ONLOAD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.ADMIN_USERS_ONLOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isReset: false,
        data: action.payload.map((user) => {
          return {
            ...user,
            active: user.active ? "Yes" : "No",
            registeredDateTime: moment(user.registeredDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            lastLoginDateTime: moment(user.lastLoginDateTime).format(
              "DD/MM/YYYY HH:mm"
            ),
            userType:
              user.userType === 1
                ? "Admin"
                : user.userType === 2
                ? "Student"
                : "Business",
          };
        }),
      };
    case ActionTypes.ADMIN_USERS_FILTER_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      };
    case ActionTypes.ADMIN_USERS_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(
          (user) => user.userId !== action.payload.userId
        ),
      };
    case ActionTypes.ADMIN_USERS_EDIT_SUCCESS:
      return {
        ...state,
        data: state.data.map((user) => {
          return user.userId === action.payload.userId
            ? {
                ...action.payload,
                active:
                  action.payload.active === "true" ||
                  action.payload.active === true ||
                  action.payload.active === "Yes"
                    ? "Yes"
                    : "No",
                email: action.payload.email,
                firstName: action.payload.firstName,
                lastLoginDateTime: action.payload.lastLoginDateTime,
                lastName: action.payload.lastName,
                loginAsUserToken: action.payload.loginAsUserToken,
                registeredDateTime: action.payload.registeredDateTime,
                userType:
                  action.payload.userType === 1 ||
                  action.payload.userType === "1"
                    ? "Admin"
                    : action.payload.userType === 2 ||
                      action.payload.userType === "2"
                    ? "Student"
                    : "Business",
              }
            : user;
        }),
      };
    case ActionTypes.ADMIN_USERS_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    case ActionTypes.ADMIN_USERS_DATATABLE_CLEAR_SEARCHBOX:
      return {
        ...state,
        settings: {
          ...state.settings,
          searchText: "",
        },
      };
    default:
      return state;
  }
}

export default Reducer;
