/**
 * Login Impersonation Service
 */
import HttpClient from "../coreLib/http/httpClient";
import Config from "../config";
import Auth from "../coreLib/auth/auth";
import UserIdentity from "../coreLib/auth/userIdentity";

const LoginImpersonationService = () => {
  let httpClient = HttpClient();
  let userIdentity = UserIdentity();

  /**
   * Impersonate login using token provided by server
   */
  const impersonateLogin = (userId, token) => {
    let promise = new Promise((resolve, reject) => {
      httpClient
        .post(`/v1/auth/admin/${userId}/token`, { token })
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );

          const user = {
            firstName: userIdentity.getFirstName(),
            lastName: userIdentity.getLastName(),
            id: userIdentity.getUserId(),
            email: userIdentity.getEmail(),
            userType: userIdentity.userType(),
          };
          resolve(user);
        })
        .catch((error) => reject(error));
    });

    return promise;
  };

  return {
    impersonateLogin,
  };
};

export default LoginImpersonationService;
