import { connect } from "react-redux";
import ResetPassword from "./Reset-Password";
import { withRouter } from "react-router-dom";
import { selectResetPassword } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import { addNotification } from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const stateData = selectResetPassword(state);

  return {
    fields: stateData.fields,
    fieldErrors: stateData.fieldErrors,
    isLoading: stateData.isLoading
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: error => dispatch(addNotification(error, "Error")),
  dispatch: dispatch,
  history: ownProps.history,
  resetToken: ownProps.match.params.resetToken
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onResetPassword: () => {
    if (validate(stateProps)) return;
    dispatchProps.dispatch(
      actionCreators.onResetPassword(
        stateProps.fields,
        dispatchProps.resetToken,
        dispatchProps.history
      )
    );
  },
  validate: () => {
    return validate(stateProps);
  }
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);
  //const fieldsToCheck = ["password", "confirmPassword"];

  // for (var fld in fieldsToCheck) {
  //   if (!fields[fieldsToCheck[fld]]) return true;
  // }
  if (!fields.password) return true;
  if (!fields.confirmPassword) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(ResetPassword);

export default withRouter(ResetPasswordContainer);
