import NswCertificates from "./Nsw-Certificates";
import { connect } from "react-redux";
import * as dataTableActionCreators from "./redux/dataTable/action-creators";
import * as dialogActionCreators from "./redux/dialog/action-creators";
import { withRouter } from "react-router-dom";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = state => {
  return {
    dataTable: state.nswCertificates.DataTable,
    dialog: state.nswCertificates.Dialog
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadData: () => {
    dispatch(dataTableActionCreators.loadData());
  },
  actionButtonHandler: (evt) => {
    if (evt.type === "button1") {
      dispatch(dialogActionCreators.toggleDialog(evt.data));
    }
  },
  onDialogInputChange: ({ name, value, error }) => {
    dispatch(dialogActionCreators.formInputChange(name, value, error));
  },
  update: (payload) => {
    dispatch(dialogActionCreators.update(payload));
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  onToggle: (payload) => {
    dispatch(dialogActionCreators.toggleDialog(payload));
  },
  onSettingsChanged: (settings) => {
    dispatch(dataTableActionCreators.keepDataTableSettings(settings));
  },
  clearDataTableSearchbox: () =>
    dispatch(dataTableActionCreators.clearDataTableSearchboxFilter()),
  dispatch: dispatch,
  history: ownProps.history,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NswCertificates)
);
