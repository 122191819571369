export const ActionTypes = {
  ADMIN_PROMO_CODES_SHOW_EDIT_DIALOG: "ADMIN_PROMO_CODES_SHOW_EDIT_DIALOG",
  ADMIN_PROMO_CODES_SHOW_DELETE_DIALOG: "ADMIN_PROMO_CODES_SHOW_DELETE_DIALOG",
  ADMIN_PROMO_CODES_DIALOG_FORM_INPUT_CHANGE:
    "ADMIN_PROMO_CODES_DIALOG_FORM_INPUT_CHANGE",
  ADMIN_PROMO_CODES_DELETE_REQUEST: "ADMIN_PROMO_CODES_DELETE_REQUEST",
  ADMIN_PROMO_CODES_DELETE_SUCCESS: "ADMIN_PROMO_CODES_DELETE_SUCCESS",
  ADMIN_PROMO_CODES_DELETE_FAILURE: "ADMIN_PROMO_CODES_DELETE_FAILURE",
  ADMIN_PROMO_CODES_EDIT_REQUEST: "ADMIN_PROMO_CODES_EDIT_REQUEST",
  ADMIN_PROMO_CODES_EDIT_SUCCESS: "ADMIN_PROMO_CODES_EDIT_SUCCESS",
  ADMIN_PROMO_CODES_EDIT_FAILURE: "ADMIN_PROMO_CODES_EDIT_FAILURE",
  ADMIN_PROMO_CODES_ADD_REQUEST: "ADMIN_PROMO_CODES_ADD_REQUEST",
  ADMIN_PROMO_CODES_ADD_SUCCESS: "ADMIN_PROMO_CODES_ADD_SUCCESS",
  ADMIN_PROMO_CODES_ADD_FAILURE: "ADMIN_PROMO_CODES_ADD_FAILURE"
};
