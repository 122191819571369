/**
 * Action types
 */
export const ActionTypes = {
  SURVEY_COURSE_COMPLETED_LOAD_DATA_REQUEST:
    "SURVEY_COURSE_COMPLETED_LOAD_DATA_REQUEST",
  SURVEY_COURSE_COMPLETED_LOAD_DATA_SUCCESS:
    "SURVEY_COURSE_COMPLETED_LOAD_DATA_SUCCESS",
  SURVEY_COURSE_COMPLETED_LOAD_DATA_FAILURE:
    "SURVEY_COURSE_COMPLETED_LOAD_DATA_FAILURE"
};
