export const courses = [
  {
    id: 6,
    courseTitle: "NSW Food Safety Supervisor Certificate",
    courseCost: 179,
    orderPriority: 0,
    courseCodes: "SITXFSA005, SITXFSA006",
  },
  {
    id: 2,
    courseTitle: "Food Handling Level 1",
    courseCost: 55,
    orderPriority: 1,
    courseCodes: "SITXFSA005",
  },
  {
    id: 3,
    courseTitle: "Food Handling Level 2",
    courseCost: 89,
    orderPriority: 2,
    courseCodes: "SITXFSA006",
  },
  {
    id: 4,
    courseTitle: "Food Safety Supervisor Hospitality & Retail",
    courseCost: 139,
    orderPriority: 3,
    courseCodes: "SITXFSA005, SITXFSA006",
  },
  {
    id: 1008,
    courseTitle: "Food Safety Supervisor Health & Community",
    courseCost: 198,
    orderPriority: 4,
    courseCodes: "HLTFSE001, HLTFSE005, HLTFSE007",
  },
  {
    id: 1009,
    courseTitle: "Food Handling Level 1 - Health & Community",
    courseCost: 50,
    orderPriority: 5,
    courseCodes: "HLTFSE001",
  },
  {
    id: 8,
    courseTitle: "NSW Refresher Course",
    courseCost: 149,
    orderPriority: 6,
    courseCodes: "SITXFSA005, SITXFSA006",
  },
];
