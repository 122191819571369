import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Users from "./users";
import * as dataTableActionCreators from "./redux/datatable/action-creators";
import * as dialogActionCreators from "./redux/dialog/action-creators";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = state => {
  return {
    dataTable: state.users.DataTable,
    dialog: state.users.Dialog
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: () => dispatch(dataTableActionCreators.onLoad()),
  reset: () => dispatch(dataTableActionCreators.reset()),
  toggleEditDialog: (payload) =>
    dispatch(dialogActionCreators.toggleEditDialog(payload)),
  toggleDeleteDialog: (payload) =>
    dispatch(dialogActionCreators.toggleDeleteDialog(payload)),
  onInputChange: ({ name, value, error }) => {
    dispatch(dataTableActionCreators.formInputChange(name, value, error));
  },
  onDialogInputChange: ({ name, value, error }) => {
    dispatch(dialogActionCreators.formInputChange(name, value, error));
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  onDelete: (payload) => dispatch(dialogActionCreators.onDelete(payload)),
  onEdit: (payload) => dispatch(dialogActionCreators.onEdit(payload)),
  onSettingsChanged: (settings) => {
    dispatch(dataTableActionCreators.keepDataTableSettings(settings));
  },
  clearDataTableSearchbox: () =>
    dispatch(dataTableActionCreators.clearDataTableSearchboxFilter()),
  dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  loadRequest: payload => {
    dispatchProps.dispatch(dataTableActionCreators.loadRequest(payload));
  }
});

const UsersContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(Users)
);

export default UsersContainer;
