import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import HttpService from "./service";
import YourCoursesService from "../../../views/Start/redux/service";
import IdentityService from "../../../coreLib/auth/userIdentity";
import { saveAs } from "file-saver";

export const onLoad = () => (dispatch) => {
  let httpService = HttpService();
  let identity = IdentityService();
  let userId = identity.getUserId();

  dispatch(init());
  dispatch(onLoadRequest());
  httpService
    .getVouchers(userId)
    .then((resp) => {
      dispatch(onLoadSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(onLoadFailure());
      dispatch(
        addNotification("There was an error loading the data.", "warning")
      );
    });
};

export const showEmailDialog = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_SHOW_EMAIL_DIALOG,
});

export const setEmailDetails = (payload) => ({
  type: ActionTypes.CORPORATE_VOUCHERS_SET_DETAILS_FORM_EMAIL,
  payload,
});

const init = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_ON_INIT,
});

const onLoadRequest = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_ON_LOAD_REQUEST,
});

const onLoadSuccess = (payload) => ({
  type: ActionTypes.CORPORATE_VOUCHERS_ON_LOAD_SUCCESS,
  payload,
});

export const sendVoucher = (payload) => (dispatch, getState) => {
  let service = HttpService();
  let voucherDetails = getState().corporateVouchers.emailDetails;
  let { firstName, lastName } = payload;

  dispatch(showEmailDialog());
  dispatch(onSendEmailRequest());
  service
    .sendVoucher(payload, voucherDetails)
    .then((resp) => {
      dispatch(onSendEmailSuccess(resp.data));
      dispatch(
        addNotification(
          `Thanks, we have sent the voucher to ${firstName} ${lastName}.`
        )
      );
      dispatch(onLoad());
    })
    .catch((err) => {
      dispatch(onSendEmailFailure());
      dispatch(
        addNotification("There was an issue during the submission.", "warning")
      );
    });
};

const onSendEmailRequest = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_SEND_EMAIL_REQUEST,
});

const onSendEmailSuccess = (payload) => ({
  type: ActionTypes.CORPORATE_VOUCHERS_SEND_EMAIL_SUCCESS,
  payload,
});

const onSendEmailFailure = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_SEND_EMAIL_FAILURE,
});

const onLoadFailure = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_ON_LOAD_FAILURE,
});

export const downloadCertificate = (courseId) => (dispatch) => {
  let yourCoursesService = YourCoursesService();
  dispatch(onDownloadRequest());
  yourCoursesService
    .downloadCertificate(courseId)
    .then((resp) => {
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "certificate.pdf");
      dispatch(onDownloadSuccess());
    })
    .catch((err) => {
      dispatch(onDownloadFailure());
      dispatch(
        addNotification(
          "There was an error downloading the certificate.",
          "warning"
        )
      );
    });
};

const onDownloadRequest = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_REQUEST,
});

const onDownloadSuccess = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_SUCCESS,
});

const onDownloadFailure = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_FAILURE,
});

export const downloadThirdPartyForm = (thirdPartyAccessToken) => (dispatch) => {
  let yourCoursesService = YourCoursesService();
  dispatch(onDownloadTpfRequest());
  yourCoursesService
    .downloadThirdPartyForm(thirdPartyAccessToken)
    .then((resp) => {
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "third-party-form.pdf");
      dispatch(onDownloadTpfSuccess());
    })
    .catch((err) => {
      dispatch(onDownloadTpfFailure());
      dispatch(
        addNotification(
          "There was an error downloading the third party form.",
          "warning"
        )
      );
    });
};

const onDownloadTpfRequest = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_TPF_REQUEST,
});

const onDownloadTpfSuccess = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_TPF_SUCCESS,
});

const onDownloadTpfFailure = () => ({
  type: ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_TPF_FAILURE,
});
