import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup
} from "reactstrap";
import formHandler from "../../../../components/Common/Fields/form-handler";
import "../../../../components/Common/Fields/Fields.scss";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";
import InputField from "../../../../components/Common/Fields/Input-Field";
import SelectField from "../../../../components/Common/Fields/Select-Field";
import DatePickerField from "../../../../components/Common/Fields/Date-Picker-Field";
import * as validators from "../../../../components/Common/Fields/Validators/Field-Validators";

const validateSelect = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  return false;
};

export default class EntryModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      toggleEditDialog,
      onFieldError,
      onDialogInputChange,
      onEdit,
      onAdd,
      dialogs,
      isProcessing,
      isEdit,
      fields
    } = this.props;
    const {
      discountCode,
      discountPercentage,
      discountAmount,
      expiryDate,
      isOneUseOnly
    } = fields;
    const form = formHandler();
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={dialogs.showEditDialog}>
          <ModalHeader toggle={() => toggleEditDialog()}>
            {`${isEdit ? "Edit" : "Add"} Promo Code`}
          </ModalHeader>
          <ModalBody>
            <Form className="ttg-form">
              <Row form>
                <Col xs="12">
                  <FormGroup>
                    <label>Promo Code</label>
                    <InputField
                      name="discountCode"
                      placeholder="Promo Code"
                      ref={form.add}
                      value={discountCode}
                      validate={val => validators.required("Promo Code", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Discount %</label>
                    <InputField
                      name="discountPercentage"
                      placeholder="Discount %"
                      ref={form.add}
                      value={discountPercentage}
                      validate={val => validators.required("Discount %", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Discount $</label>
                    <InputField
                      name="discountAmount"
                      placeholder="Discount $"
                      ref={form.add}
                      value={discountAmount}
                      validate={val => validators.required("Discount $", val)}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>Expires On</label>
                    <div>
                      <DatePickerField
                        name="expiryDate"
                        value={expiryDate}
                        minDate={new Date(1930, 1, 1)}
                        maxDate={new Date()}
                        onChange={onDialogInputChange}
                        onFieldError={onFieldError}
                        ref={form.add}
                      ></DatePickerField>
                    </div>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6">
                  <FormGroup>
                    <label>One Time Use</label>
                    <SelectField
                      name="isOneUseOnly"
                      ref={form.add}
                      validate={val => validateSelect("One Time Use", val)}
                      value={isOneUseOnly}
                      onFieldError={onFieldError}
                      onChange={onDialogInputChange}
                    >
                      <option value={-1}>- Select -</option>
                      <option value={true}>Yes</option>;
                      <option value={false}>No</option>;
                    </SelectField>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() =>
                form.validate({
                  validFn: () => {
                    isEdit ? onEdit(fields) : onAdd(fields);
                  }
                })
              }
              className="ttg-btn"
              isLoading={isProcessing}
            >
              Save
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
