/**
 * Course Enrolment Step 1 Container
 * Adam Luck @solvable Jun 10, 2019
 */
import { connect } from "react-redux";
import * as actionCreators from "./redux/action-creators";
import nswForm from "./nsw-reprint-page";
import { selectNSWReprint } from "./redux/reducers";
import * as sysActionCreators from "../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  const stateData = selectNSWReprint(state);

  return {
    addressEntry: stateData.addressEntry,
    fields: stateData.fields,
    fieldErrors: stateData.fieldErrors,
    isCreditCardValid: stateData.isCreditCardValid,
    isSubmitting: stateData.isSubmitting,
    isSubmitted: stateData.isSubmitted,
    nswCertificate: stateData.nswCertificate,
    showConfirmPopup: stateData.showConfirmPopup,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearAddress: () => {
    dispatch(actionCreators.clearAddress());
  },
  updateAutocompleteAddress: (address) => {
    dispatch(actionCreators.updateAutocompleteAddress(address));
  },
  toggleManualAddressEntry: () => {
    dispatch(actionCreators.toggleManualAddressEntry());
  },
  togglePromoCodeEntry: () => {
    dispatch(actionCreators.togglePromoCodeEntry());
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onCreditCardChange: (values) => {
    dispatch(actionCreators.onCreditCardInputChange(values));
  },
  setEmail: () => {
    dispatch(actionCreators.setEmail());
  },
  init: () => {
    dispatch(actionCreators.init());
  },
  toggleShowConfirmPopup: () => {
    dispatch(actionCreators.toggleShowConfirmPopup());
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match,
});

const validate = (stateProps) => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

  if (!fields.email) return true;
  if (!fields.postcode) return true;
  if (!fields.state) return true;
  if (!fields.city) return true;
  if (!fields.cardName) return true;
  if (!stateProps.isCreditCardValid) return true;
  if (!fields.streetName) return true;
  if (stateProps.addressEntry.enterAddressManually && !fields.streetNumber)
    return true;
  if (errMessages.length > 0) return true;

  return false;
};

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  toggleManualAddressEntry: () => {
    dispatchProps.dispatch(dispatchProps.toggleManualAddressEntry);
    validate(stateProps);
  },
  onSubmit: () => {
    dispatchProps.dispatch(
      actionCreators.onSubmit(
        stateProps,
        stateProps.fields,
        dispatchProps.history
      )
    );
  },
  validate: () => {
    return validate(stateProps);
  },
});

const nswFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(nswForm);

export default nswFormContainer;
