import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../../redux/system/system-action-creators";
import NswReprintsService from "../../service";

export const toggleDialog = payload => dispatch => {
  dispatch({ type: ActionTypes.NSW_CERTIFICATES_VIEW_TOGGLE_DIALOG, payload });
};

export const update = payload => dispatch => {
  let service = NswReprintsService();
  dispatch({ type: ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_REQUEST });
  service
    .update(payload)
    .then(() => {
      dispatch(updateSuccess(payload));
      dispatch(addNotification("Successfully updated."));
    })
    .catch(() => {
      dispatch(updateFailure());
      dispatch(addNotification("Unable to update.", "error"));
    });
};

const updateSuccess = payload => ({
  type: ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_SUCCESS,
  payload
});

const updateFailure = () => ({
  type: ActionTypes.NSW_CERTIFICATES_VIEW_UPDATE_FAILURE
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.NSW_CERTIFICATES_VIEW_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
