export const ActionTypes = {
         PURCHASE_CORPORATE_VOUCHERS_INIT: "PURCHASE_CORPORATE_VOUCHERS_INIT",
         PURCHASE_CORPORATE_VOUCHERS_CLEAR_ADDRESS:
           "PURCHASE_CORPORATE_VOUCHERS_CLEAR_ADDRESS",
         PURCHASE_CORPORATE_VOUCHERS_VALIDATE:
           "PURCHASE_CORPORATE_VOUCHERS_VALIDATE",
         PURCHASE_CORPORATE_VOUCHERS_QUANTITY_CHANGE:
           "PURCHASE_CORPORATE_VOUCHERS_QUANTITY_CHANGE",
         PURCHASE_CORPORATE_VOUCHERS_CREDIT:
           "PURCHASE_CORPORATE_VOUCHERS_CREDIT",
         PURCHASE_CORPORATE_VOUCHERS_INVOICE:
           "PURCHASE_CORPORATE_VOUCHERS_INVOICE",
         PURCHASE_CORPORATE_VOUCHERS_LOAD_COURSE_DETAILS_REQUEST:
           "PURCHASE_CORPORATE_VOUCHERST_LOAD_COURSE_DETAILS_REQUEST",
         PURCHASE_CORPORATE_VOUCHERS_LOAD_COURSE_DETAILS_SUCCESS:
           "PURCHASE_CORPORATE_VOUCHERS_LOAD_COURSE_DETAILS_SUCCESS",
         PURCHASE_CORPORATE_VOUCHERS_LOAD_COURSE_DETAILS_FAILURE:
           "PURCHASE_CORPORATE_VOUCHERS_LOAD_COURSE_DETAILS_FAILURE",

         PURCHASE_CORPORATE_VOUCHERS_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS:
           "PURCHASE_CORPORATE_VOUCHERS_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS",

         PURCHASE_CORPORATE_VOUCHERS_TOGGLE_MANUAL_ADDRESS_ENTRY:
           "PURCHASE_CORPORATE_VOUCHERS_TOGGLE_MANUAL_ADDRESS_ENTRY",
         PURCHASE_CORPORATE_VOUCHERS_TOGGLE_PROMO_CODE_ENTRY:
           "PURCHASE_CORPORATE_VOUCHERS_TOGGLE_PROMO_CODE_ENTRY",
         PURCHASE_CORPORATE_VOUCHERS_SUBMIT_REQUEST:
           "PURCHASE_CORPORATE_VOUCHERS_SUBMIT_REQUEST",
         PURCHASE_CORPORATE_VOUCHERS_SUBMIT_SUCCESS:
           "PURCHASE_CORPORATE_VOUCHERS_SUBMIT_SUCCESS",
         PURCHASE_CORPORATE_VOUCHERS_SUBMIT_FAILURE:
           "PURCHASE_CORPORATE_VOUCHERS_SUBMIT_FAILURE",
         PURCHASE_CORPORATE_VOUCHERS_SET_CARD_VALIDITY:
           "PURCHASE_CORPORATE_VOUCHERS_SET_CARD_VALIDITY",
         PURCHASE_CORPORATE_VOUCHERS_SHOW_MANUAL_ADDRESS_ENTRY:
           "PURCHASE_CORPORATE_VOUCHERS_SHOW_MANUAL_ADDRESS_ENTRY",
         PURCHASE_CORPORATE_VOUCHERS_FORM_INPUT_CHANGE:
           "PURCHASE_CORPORATE_VOUCHERS_FORM_INPUT_CHANGE",
         PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_REQUEST:
           "PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_REQUEST",
         PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_SUCCESS:
           "PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_SUCCESS",
         PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_FAILURE:
           "PURCHASE_CORPORATE_VOUCHERS_APPLY_PROMO_CODE_FAILURE",
       };
