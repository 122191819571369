import { combineReducers } from "redux";
import Step1Reducer from "./step-1-reducer";
import Step2Reducer from "../Step-2/redux/reducer";
import Step3Reducer from "./step-3-reducer";
import EnrolmentReducer from "./enrolment-reducer";

const CourseEnrolmentReducer = combineReducers({
  step1: Step1Reducer,
  step2: Step2Reducer,
  step3: Step3Reducer,
  enrolment: EnrolmentReducer
});

export default CourseEnrolmentReducer;
