/**
 * Avetmiss Reporting Service
 */
import HttpClient from "../../../coreLib/http/httpClient";

const AvetmissReportingService = () => {
  let httpClient = HttpClient();

  const getStatus = () => {
    return httpClient.get("/v1/avetmiss/status");
  };

  const getCollectionTypes = () => {
    return httpClient.get("/v1/avetmiss/collections");
  };

  const submitRequest = payload => {
    return httpClient.post("/v1/avetmiss/compile", payload);
  };

  return {
    getStatus,
    getCollectionTypes,
    submitRequest
  };
};

export default AvetmissReportingService;
