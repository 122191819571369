/**
 * Action types
 */
export const ActionTypes = {
  SURVEY_LOAD_DATA_REQUEST: "SURVEY_LOAD_DATA_REQUEST",
  SURVEY_LOAD_DATA_SUCCESS: "SURVEY_LOAD_DATA_SUCCESS",
  SURVEY_LOAD_DATA_FAILURE: "SURVEY_LOAD_DATA_FAILURE",

  SURVEY_FORM_INPUT_CHANGE: "SURVEY_FORM_INPUT_CHANGE",

  SURVEY_SUBMIT_REQUEST: "SURVEY_SUBMIT_REQUEST",
  SURVEY_SUBMIT_SUCCESS: "SURVEY_SUBMIT_SUCCESS",
  SURVEY_SUBMIT_FAILURE: "SURVEY_SUBMIT_FAILURE",

  SURVEY_UPDATE_REQUEST: "SURVEY_UPDATE_REQUEST",
  SURVEY_UPDATE_SUCCESS: "SURVEY_UPDATE_SUCCESS",
  SURVEY_UPDATE_FAILURE: "SURVEY_UPDATE_FAILURE"
};
