import React from "react";

export const general = [
  {
    title: "Are these courses Standard 3.2.2A Compliant?",
    content: (
      <>
        They sure are! All our courses are current and fully compliant with the
        new legislation standard 3.2.2A.
      </>
    ),
  },
  {
    title: "Are these courses Nationally Recognised?",
    content: (
      <>
        They sure are! We are a Nationally Recognised Training Organisation and
        all of the courses that Train to Gain Pty Ltd T/A Food Safety Education
        offer are Nationally accredited and recognised. We only offer the most
        current and up-to-date course codes.{" "}
        <a
          href="https://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see our Accreditation to provide Food Safety training!
        </a>
      </>
    ),
  },
  {
    title: "What is a Recognised Training Organisation (RTO)?",
    content: (
      <>
        RTO&rsquo;s deliver and assess nationally recognised training, and issue
        nationally recognised qualifications and statements of attainment.
        <br />
        <br />
        That&rsquo;s us! Nationally Recognised Training is delivered in
        association with Train to Gain Pty Ltd T/A Food Safety Education. (RTO
        provider # 22361). Look out for this symbol on our home page to support
        our authenticity.
      </>
    ),
  },
  {
    title: "How long do I have to complete my course?",
    content: (
      <>
        The length of each course will vary on the individual, depending on your
        prior knowledge and how you learn. In general, it takes approximately
        2-3 hours to complete each of the Level 1 Food Safety courses and on
        average, about 3-4 hours to complete the Food Safety Supervisor courses.
        If you want a break, your course will be exactly where you left off when
        you come back to it. No repeating anything! If you are looking to fast
        track your learning, it is not uncommon for people to sign up and
        complete their course in the same day. We allow your enrolment to be
        open for 12 months in case life gets in the way!!
      </>
    ),
  },
  {
    title: "When do I pay for the course?",
    content: (
      <>Payment for all our courses are paid after you select your course.</>
    ),
  },
  {
    title: "Are there additional costs involved?",
    content: (
      <>
        No! Payment is made up front with no additional charges. The prices
        quoted on our homepage are all inclusive of GST with no hidden fees.
      </>
    ),
  },
  {
    title: "Which payment gateway do you use? Is it secure?",
    content: (
      <>
        We use eWay as our payment gateway facility that links up to the CBA. We
        do not store your credit card numbers.
      </>
    ),
  },
  {
    title: "I don’t have a credit card. How else can I make payment?",
    content: (
      <>
        If you do not have a credit card, you cannot pay. You could ask your
        employer to buy a prepaid voucher or your employer pays with the
        business credit card and you reimburse them.
      </>
    ),
  },
  {
    title: "What do I need to have to do your courses online?",
    content: (
      <>
        <ul className="my-0">
          <li>Good understanding of written English</li>
          <li>Be able to operate a computer</li>
          <li>Have access to the internet</li>
          <li>Have access to an email account</li>
          <li>Have access to a printer</li>
          <li>
            Have an observer with Food Safety knowledge and practical skills who
            has seen you deal with food in a safe manner
          </li>
          <li>
            A Unique Student Identifier (USI). This is a requirement of the
            government for every student doing any nationally recognised
            training.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Do I have to complete my course in one sitting?",
    content: (
      <>
        No! Take your time and complete the course at your own pace. You can
        start, stop and resume your course at time intervals that are suitable
        to you. The system will remember your progress. Each time you log into
        the website you will be able to resume your course where you last left
        it. We understand that most people lead busy lives and have other
        important commitments so our online training method allows you to study
        in and around other commitments or work rosters.
      </>
    ),
  },
  {
    title: "What if I get a question wrong, what happens then?",
    content: (
      <>
        If you get any questions wrong then you just repeat the question until
        you get it right. You have as many times as you need to get the question
        right and you can always contact us for support. You also have the
        workbook available on line to assist you in your training.
        <br />
        <br />
        Other training providers may lock you out for days if you get a question
        wrong, or make you pay for the whole course again!
        <br />
        <br />
        We will NEVER do that because we understand that everyone learns
        differently, therefore, you will not be judged on how long it takes to
        learn new information.
      </>
    ),
  },
  {
    title: "What if I have problems with reading and writing?",
    content: (
      <>
        Train to Gain Pty Ltd T/A Food Safety Education T/A Food Safety
        Education provides support for participants who require assistance with
        Language Literacy and/or Numeracy (LLN). To assist in identifying any
        special learning needs, you are required to provide Train to Gain Pty
        Ltd T/A Food Safety Education Pty Ltd T/A Food Safety Education with
        information regarding your LLN requirements on your enrolment form,
        prior to the start of the training course. Conversely, if you do have
        any learning difficulties you are encouraged to discuss these with our
        student support team prior to course commencement or during the course
        enrolment.
        <br />
        <br />
        Be assured that discussions with our staff will be treated as
        confidential.
        <br />
        <br />
        Simply contact us for assistance. You are still able to complete the
        course online, but we will talk through how we can assist and support
        you in the successful completion of your chosen course.
      </>
    ),
  },
  {
    title: "How will my name appear on my certificate?",
    content: (
      <>
        Our system will print your name EXACTLY as you enter it into the
        enrolment screen. Please take care to spell your name correctly and use
        capitals correctly.
      </>
    ),
  },
  {
    title: "How do I log into my account?",
    content: (
      <>
        Go to{" "}
        <a href="https://www.foodsafetyeducation.com.au/Login/">
          https://www.foodsafetyeducation.com.au/Login/
        </a>{" "}
        Click on &lsquo;Login&rsquo; at the top right of the screen. Enter your
        email address and password you set up when you enrolled in the course.
      </>
    ),
  },
  {
    title: "I can’t remember my password. What do I do?",
    content: (
      <>
        Enter your email address in the login bar at the top right of the
        website. Then click on the &lsquo;forgot password&rsquo; tab. This will
        give you the option to create a new password.
      </>
    ),
  },
  {
    title: "I’m having trouble logging in. Why?",
    content: (
      <>
        Have you forgotten your password? Are you entering it incorrectly? Does
        your username or password contain a mixture of upper and lower-case
        letters? It should be entered exactly as you registered. Are cookies
        enabled on your browser? Try logging out of your computer and re-try. If
        you can&rsquo;t determine the reason, please contact us and we will fix
        it for you asap. Remember you receive a welcome email when you first
        register with us and this email contains your username and password.
        Please search your email inbox for your registration email.
      </>
    ),
  },
  {
    title: "Do Food Safety certificates expire?",
    content: (
      <>
        As per Standard 3.2.2A, Food Handlers and Supervisors must renew their
        training every five (5) years. The Statement of Attainment that you
        receive doesn&rsquo;t have an expiry date.
      </>
    ),
  },
  {
    title: "What is a Statement of Attainment?",
    content: (
      <>
        A Statement of Attainment forms part of an accreditation. We offer units
        of competency from the hospitality, retail and health support training
        packages. We refer to "certificates" in our marketing purely because
        this is how the "Food Safety Certificate" or the "Food Safety Supervisor
        Certificate" qualification is perceived in the community and by State
        regulators. The Statements of Attainments that you receive from Train to
        Gain Pty Ltd T/A Food Safety Education are nationally accredited units
        of competency.
      </>
    ),
  },
  {
    title: "I work in Hospitality / Retail, which course is right for me?",
    content: (
      <>
        It depends on what role you have at work. Different job roles require
        different Statements of Attainments.
        <br />
        <br />
        When it comes to the Hospitality &amp; Retail industry a unit of
        competency is transferable to the Retail sector and vice versa to the
        Hospitality sector in the Food Safety area. However, when it comes to
        the Health Food Safety units these are only accepted in those fields. We
        always strongly suggest you talk to your local council about what
        requirements they need from you as they have their own local policies
        regarding Food Safety Accreditation.
        <br />
        <br />
        Statement of Attainments that we offer for Hospitality and Retail:
        <br />
        <br />
        <strong>Food Handling Level 1</strong>
        <br />
        <br />
        SITXFSA005 Use Hygienic Practices for Food Safety
        <br />
        <br />
        This unit is for people working in a kitchen, café, restaurant, school
        canteen, hotel, supermarkets or other food retail outlets, under
        supervision. (Also known as Food Handling Level 1, Food Handlers, RSF,
        Food Hygiene Training, Safe Food Handling).
        <br />
        <br />
        <strong>Food Handling Level 2</strong>
        <br />
        <br />
        SITXFSA006 Participate in Safe Food Handling Practices
        <br />
        <br />
        This unit is for people who will be the Food Safety Supervisor and who
        have already completed Food Handling Level 1.
        <br />
        <br />
        <strong>
          Food Handling Level 1 &amp; 2 together is the Food Safety Supervisor
          Certificate for Hospitality &amp; Retail
        </strong>
        <br />
        <br />
        SITXFSA005 Use Hygienic Practices for Food Safety &amp; SITXFSA006
        Participate in Safe Food Handling Practices
        <br />
        <br />
        This certificate is Level 1 &amp; Level 2 combined into one course. This
        is for people who will be the Food Safety Supervisor.
        <br />
        <br />
        If you are unsure about what level of training you need, or the code you
        require on your certificate, please contact the Environmental Health
        Office at your local council and they will advise you of the correct
        training.
        <br />
        <br />
        We understand how confusing it can be to get the certificates you need,
        so let us help you get the Food Safety certificate that your council
        wants to see!
      </>
    ),
  },
  {
    title:
      "I work in the Health Care/Child Care/Aged Care sector. Which course is right for me?",
    content: (
      <>
        We offer three units in the one course, as shown below. Combined, they
        make up the Food Safety Supervisor Certificate within the Health sector.
        We strongly suggest you talk with your local council about what
        requirements they need from you as they have their own local policies
        regarding Food Safety Accreditation. We also offer level 1 as a
        stand-alone unit - Follow Basic Food Safety Practices. This unit is best
        for people starting out in the Health industry.
        <br />
        <br />
        <strong>Food Handling Level 1</strong>
        <br />
        <br />
        HLTFSE001 Follow Basic Food Safety Practices
        <br />
        <br />
        This course is designed for all people handling food in the Community
        and Health Service industries. (Child care, aged care, hospitals, meals
        on wheels etc). This unit of competency describes the skills and
        knowledge required for basic Food Safety practices including personal
        hygiene and conduct when working in a food service environment. It
        describes the most basic level of competence required by any person in
        the Health industry who directly handles food.
        <br />
        <br />
        <strong>
          Food Safety Supervisor Certificate for Health &amp; Community
        </strong>
        <br />
        <br />
        HLTFSE001 Follow Basic Food Safety Practices
        <br />
        <br />
        HLTFSE005 Apply and Monitor Food Safety Requirements
        <br />
        <br />
        HLTFSE007 Oversee the Day to Day Implementation of Food Safety in the
        Workplace
        <br />
        <br />
        This is for people who will be the Food Safety Supervisor. There are 3
        units of competency altogether.
        <br />
        <br />
        If you are unsure about what level of training you need, or the code you
        require on your certificate, please contact the Environmental Health
        Office at your local council and they will advise you of the correct
        training.
        <br />
        <br />
        We understand how confusing it can be to get the certificates you need,
        so let us help you get the Food Safety certificate that your council
        wants to see!
      </>
    ),
  },
  {
    title: "How do I get my certificate?",
    content: (
      <>
        <ol className="my-0">
          <li>Register your details</li>
          <li>
            Choose the course you would like to do and make your secure payment
          </li>
          <li>Complete your enrolment and start your online training</li>
          <li>Third-party checklist and assessment is submitted online</li>
        </ol>
      </>
    ),
  },
  {
    title: "When do I get my certificate?",
    content: (
      <>
        When you have successfully completed your online training assessment,
        your Statement of Attainment will be issued when your online third-party
        form has been successfully submitted. We will send you a
        &lsquo;Congratulations&rsquo; email with your Statement of Attainment
        attached so you can download it, save it and/or print your own copy. You
        can also log into the website at any time and download it yourself.
      </>
    ),
  },
  {
    title: "How do I order course vouchers for my staff?",
    content: (
      <>
        At Food Safety Education, we offer you the flexibility to purchase Food
        Safety Training for your staff so they can proceed with their Food
        Safety Training today! Simply click on the &ldquo;Business&rdquo; tab in
        the navigation bar of this website to gain further information.
      </>
    ),
  },
  {
    title: "I have lost my certificate, what should I do?",
    content: (
      <>
        If you forgot to save it to your computer and have lost your
        certificate, don&rsquo;t worry!! You can log back in to your student
        portal where you will see an option to print your Statement of
        Attainment. If you have trouble accessing that option, we will send you
        a FREE copy via email. Just let us know and we will email you a copy.
        (NSW residents please look under the NSW section of the FAQs about
        reprints for NSW).
      </>
    ),
  },
  {
    title: "Why do I need to enter my date of birth during enrolment?",
    content: (
      <>
        We are required to verify your identify. It is a government requirement
        that we capture this information.
      </>
    ),
  },
  {
    title: "What computer/technical software do I need?",
    content: (
      <>
        Any modern desktop/PC, tablet or mobile web browser with JavaScript
        enabled, a reliable internet connection and a printer so you can print
        out your certificate.
      </>
    ),
  },
  {
    title: "Train to Gain Pty Ltd T/A Food Safety Education Refund Policy",
    content: (
      <>
        SCOPE:
        <br />
        <br />
        1. This policy covers all fees payable for training services provided
        within Train to Gain Pty Ltd T/A Food Safety Education&rsquo;s scope of
        registration. Standards for Registered Training Organisations
        (RTO&rsquo;s) 2015, standard 5.3.
        <br />
        <br />
        PURPOSE:
        <br />
        <br />
        2. To provide for appropriate handling of clients&rsquo; payments and to
        facilitate refunds in the case of cancellation by either party. The
        refunds process will allow customers the option to disengage from
        training in a manner in which a negative impact may be negated or
        reduced, depending upon notification time frame and the reasoning for
        the refund request.
        <br />
        <br />
        General Rules:
        <br />
        <br />
        3. The following reflects Train to Gain Pty Ltd T/A Food Safety
        Educations refund process for students completing online training with
        Train to Gain Pty Ltd T/A Food Safety Education.
        <br />
        <br />
        <ul className="my-0">
          <li>
            Refunds must be requested in writing to the Director of Train to
            Gain via email at contact@traintogain.com.au.
          </li>
          <li>
            Once payment has been made the Director shall determine the outcome
            of any refund requests.
          </li>
          <li>
            The Director of Train to Gain Pty Ltd T/A Food Safety Education will
            process refund requests within 30 days from the day of receipt.
          </li>
          <li>
            If the online course does not work for the student all efforts will
            be made by the Train to Gain Pty Ltd T/A Food Safety Education staff
            to assist the student in completing their work.
          </li>
          <li>
            If the online course does not work due to the student&rsquo;s
            computer not supporting their requirements or the student not having
            the necessary experience as stipulated by Train to Gain Pty Ltd T/A
            Food Safety Education, a refund will not be applicable.
          </li>
          <li>
            If a student changes their mind or sources an alternate course
            elsewhere a refund will not be applicable.
          </li>
          <li>
            In some cases, a student may enrol for a wrong course. In this case
            we will refund the student only AFTER they have paid and enrolled in
            their correct course.
          </li>
          <li>
            If a refund has been granted an administration fee of{" "}
            <strong>$25.00 will incur</strong>.
          </li>
          <li>
            Under certain circumstances a course may be transferred to another
            party. If all parties agree to the transfer this request must be
            made in writing.
          </li>
          <li>
            In the case of a fraudulent fee being paid by a student, Train to
            Gain will assist the person making the complaint in every way
            possible and will provide personal details about the person to the
            Police only. The student or person who claims the fraudulent payment
            needs to take the matter up with their financial institution. Train
            to Gain accepts no responsibility for fraudulent fees being paid and
            will not be liable for refunds to the complainant. The student who
            has made the payment under another person&rsquo;s credit card will
            have their enrolment suspended pending all Train to Gain Pty Ltd T/A
            Food Safety Education investigations.
          </li>
        </ul>
        <br />
        <br />
        We abide by The Consumer Affairs policies and procedures.
        <br />
        <br />
        Please view the following information about Train to Gain Pty Ltd T/A
        Food Safety Education&rsquo;s rights and obligations regarding refunds
        below:
        <br />
        <br />
        <a
          href="http://www.consumer.vic.gov.au/shopping/refunds-and-returns/change-of-mind"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.consumer.vic.gov.au/shopping/refunds-and-returns/change-of-mind
        </a>
      </>
    ),
  },
  {
    title:
      "Train to Gain Pty Ltd T/A Food Safety Education Privacy Statement and Student Declaration",
    content: (
      <>
        Privacy Notice
        <br />
        <br />
        Under the Data Provision Requirements 2012, Train to Gain Pty Ltd T/A
        Food Safety Education Pty Ltd is required to collect personal
        information about you and to disclose that personal information to the
        National Centre for Vocational Education Research Ltd (NCVER).
        <br />
        <br />
        Your personal information (including the personal information contained
        on this enrolment form and your training activity data) may be used or
        disclosed by Train to Gain Pty Ltd T/A Food Safety Education for
        statistical, regulatory and research purposes. Train to Gain Pty Ltd T/A
        Food Safety Education Pty Ltd may disclose your personal information for
        these purposes to third parties, including:
        <br />
        <br />
        <ul className="my-0">
          <li>
            School – if you are a secondary student undertaking VET, including a
            school-based apprenticeship or traineeship;
          </li>
          <li>
            Employer – if you are enrolled in training paid by your employer;
          </li>
          <li>
            Commonwealth and State or Territory government departments and
            authorised agencies;
          </li>
          <li>NCVER;</li>
          <li>Organisations conducting student surveys; and</li>
          <li>Researchers.</li>
        </ul>
        <br />
        <br />
        Personal information disclosed to NCVER may be used or disclosed for the
        following purposes:
        <br />
        <br />
        <ul className="my-0">
          <li>
            Issuing statements of attainment or qualification, and populating
            authenticated VET transcripts;
          </li>
          <li>
            Facilitating statistics and research relating to education,
            including surveys;
          </li>
          <li>
            Understanding how the VET market operates, for policy, workforce
            planning and consumer information; and
          </li>
          <li>
            Administering VET, including programme administration, regulation,
            monitoring and evaluation.
          </li>
        </ul>
        <br />
        <br />
        You may receive an NCVER student survey which may be administered by an
        NCVER employee, agent or third party contractor. You may opt out of the
        survey at the time of being contacted. NCVER will collect, hold, use and
        disclose your personal information in accordance with the Privacy Act
        1988 (Cth), the VET Data Policy and all NCVER policies and protocols
        (including those published on NCVER&rsquo;s website at{" "}
        <a
          href="http://www.ncver.edu.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.ncver.edu.au
        </a>
        .
      </>
    ),
  },
  {
    title:
      "Train to Gain Pty Ltd T/A Food Safety Education - Complaints and Appeals Policy",
    content: (
      <>
        If you have any complaint or issue that you would like to raise with us,
        please see our complaints and appeals policy to best direct your enquiry
        at the link in our footer of{" "}
        <a
          href="/UserFiles/files/Complaints-and-Appeals-Policy-2023.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.foodsafetyeducation.com.au
        </a>
      </>
    ),
  },
  {
    title: "Accurate Representation of Self",
    content: (
      <>
        When you register &amp; enrol in a course you agree not to impersonate
        or represent any other person than the person on the Enrolment Form. All
        course assessments must be completed by the enrolled customer.
        <br />
        <br />
        Your observer will be required to complete a comprehensive Third-Party
        Form that states you have been observed dealing with the practical
        requirements of the training. Please note – there are random checks done
        on the references you provide. Should you be found to be fraudulent in
        your information, disciplinary action will be taken. Please see this
        information in our student handbook link at the footer of our website at{" "}
        <a
          href="/UserFiles/files/Student-Handbook-2024.11.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.foodsafetyeducation.com.au
        </a>
        <br />
        <br />
        Making false or misleading representation that you are another person or
        character is a criminal offence and can give rise to civil liability.
        You will also be asked to declare that the work is all yours.
      </>
    ),
  },
  {
    title: "Legal Stuff",
    content: (
      <>
        Train to Gain Pty Ltd T/A Food Safety Education has a record keeping
        system that ensures that student assessment records are collated and
        kept for a minimum of four years after the completion of a
        students&rsquo; course.
        <br />
        <br />
        This is conducted in accordance with the Principles of Assessment
        (primarily concerned with the retention, archiving, retrieval and
        transfer of records) contained in the Australian Skills Quality
        Authority Standards for Registered Training Organisations.
        <br />
        <br />
        <a
          href="http://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
          target="_blank"
          rel="noopener noreferrer"
        >
          Please click on this link to see our accreditation to do Food Safety
          training!
        </a>
      </>
    ),
  },
  {
    title: "State Food Legislation",
    content: (
      <>
        If you would like more information regarding the various State Food
        Safety legislation you can access this information at the links below.
        <br />
        <br />
        <a
          href="https://training.gov.au/Organisation/Details/22361"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see our Accreditation to provide Food Safety training!
        </a>
        <br />
        <br />
        <a
          href="http://www.foodauthority.nsw.gov.au/ip/legislation"
          target="_blank"
          rel="noopener noreferrer"
        >
          NSW Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="http://www.health.qld.gov.au/foodsafety/legislation.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Queensland Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
          target="_blank"
          rel="noopener noreferrer"
        >
          South Australian Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="http://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
          target="_blank"
          rel="noopener noreferrer"
        >
          Western Australia Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
          target="_blank"
          rel="noopener noreferrer"
        >
          Victorian Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="http://www.dhhs.tas.gov.au/publichealth/food_safety"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tasmanian Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
          target="_blank"
          rel="noopener noreferrer"
        >
          Northern Territory Food Safety Legislation
        </a>
        <br />
        <br />
        <a
          href="https://www.health.act.gov.au/businesses/food-safety-regulation"
          target="_blank"
          rel="noopener noreferrer"
        >
          ACT Food Safety Legislation
        </a>
      </>
    ),
  },
];
