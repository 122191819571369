import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const purchase = (fields, courses) => {
    let model = {
      ...fields,
      cardExpiryDate:
        fields.cardExpiryDate != null
          ? fields.cardExpiryDate.replace(/ /g, "")
          : null,
      courseQuantities: courses.map(course => {
        return {
          courseId: course.id,
          quantity: course.quantity
        };
      })
    };
    return httpClient.post("v1/corporate/purchase-vouchers", model);
  };

  const applyPromoCode = (promoCode, courses) => {
    let model = {
      promoCode,
      courseQuantities: courses.map(course => {
        return {
          courseId: course.id,
          quantity: course.quantity
        };
      })
    };
    return httpClient.post(
      "v1/corporate/purchase-vouchers/update-total",
      model
    );
  };

  return {
    purchase,
    applyPromoCode
  };
};

export default Service;
