/**
 * Contact Box Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  fields: {
    name: "",
    email: "",
    phone: "",
    message: ""
  },
  hasBeenSent: false,
  isSending: false,
  fieldErrors: {}
};

function ContactBoxReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CONTACT_BOX_SUBMIT_REQUEST:
      return {
        ...state,
        isSending: true
      };

    case ActionTypes.CONTACT_BOX_SUBMIT_SUCCESS:
      return {
        ...state,
        isSending: false,
        hasBeenSent: true
      };

    case ActionTypes.CONTACT_BOX_SUBMIT_FAILURE:
      return {
        ...state,
        isSending: false
      };

    case ActionTypes.CONTACT_BOX_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      });

      return stateResult;

    default:
      return state;
  }
}

export default ContactBoxReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectContactBox = state => state.contact;
