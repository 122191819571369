/**
 * System Reducers for UserType module
 */
import { combineReducers } from "redux";
import NotificationReducer from "./notification-reducer";
import LoginReducer from "./login-reducer";
import ErrorNotificationReducer from './error-notification-reducer';

/**
 * User Type state with reducers
 */
const SystemReducer = combineReducers({
  notification: NotificationReducer,
  errorNotification: ErrorNotificationReducer,
  login: LoginReducer
});

export default SystemReducer;
