import { connect } from "react-redux";
import AvetmissReporting from "./avetmiss-reporting";
import { selectAvetmissReporting } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import { addNotification } from "../../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const avetmissReporting = selectAvetmissReporting(state);

  return {
    avetmissReporting: avetmissReporting,
    isLoading: avetmissReporting.isLoading
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: error => dispatch(addNotification(error, "Error")),
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadForm: () => {
    dispatchProps.dispatch(actionCreators.onLoadForm(dispatchProps.history));
  },
  onSubmit: (
    collectionStartDate,
    collectionEndDate,
    state,
    version,
    year,
    period
  ) => {
    dispatchProps.dispatch(
      actionCreators.onSubmit(
        collectionStartDate,
        collectionEndDate,
        state,
        version,
        year,
        period,
        dispatchProps.history
      )
    );
  },
  validate: () => {
    return validate(stateProps);
  }
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

  if (!fields.state) return true;
  if (!fields.collectionStartDate) return true;
  if (!fields.collectionEndDate) return true;
  if (!fields.version) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const AvetmissReportingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(AvetmissReporting);

export default AvetmissReportingContainer;
