import React, { Component } from "react";
import { Row, Col, Button, Form, FormGroup } from "reactstrap";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import InputField from "../../components/Common/Fields/Input-Field";
import ContentSection from "../../components/Content-Section";
import * as validators from "../../components/Common/Fields/Validators/Field-Validators";
import "./update-profile.scss";
import BockUiFx from "../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import { Link } from "react-router-dom";

// Combination of validations
const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

class UpdateProfile extends Component {
  componentDidMount() {
    const { getUserInfo } = this.props;
    getUserInfo();
  }

  render() {
    const { firstName, lastName, email } = this.props.updateProfile.fields;
    const { onSubmit, onInputChange, onFieldError, validate } = this.props;
    const { isSubmitting } = this.props.updateProfile;
    return (
      <>
        <PageTitle title="Update Profile" />
        <div className="off-white veggies-bg flex-100">
          <div className="ttg-container">
            <ContentSection padding={4}>
              <h1>Update Profile</h1>
              <article>
                Updating your details will not change the details on your
                existing certificates, or any courses that you have already
                enrolled in. If you wish to change your existing certificate or
                a course which you are enrolled in, please{" "}
                <Link to="/Contact-Us/">contact us</Link>.
              </article>
              <main className="my-3">
                <BockUiFx blocking={isSubmitting} lowerZIndex={true}>
                  <Form className="ttg-form update-profile">
                    <FormGroup>
                      <Row>
                        <Col lg="3" md="4" sm="6" xs="12">
                          <InputField
                            name="firstName"
                            placeholder="First Name"
                            value={firstName}
                            validate={(val) =>
                              validators.required("First Name", val)
                            }
                            onFieldError={onFieldError}
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col className="last-name" lg="3" md="4" sm="6" xs="12">
                          <InputField
                            name="lastName"
                            placeholder="Last Name"
                            value={lastName}
                            validate={(val) =>
                              validators.required("Last Name", val)
                            }
                            onFieldError={onFieldError}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col lg="6" md="8" xs="12">
                          <InputField
                            name="email"
                            placeholder="Email Address"
                            value={email}
                            validate={(val) =>
                              emailValidator("Email Address", val)
                            }
                            onFieldError={onFieldError}
                            onChange={onInputChange}
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Button
                        className="ttg-btn"
                        onClick={onSubmit}
                        disabled={validate()}
                      >
                        Submit
                      </Button>
                    </FormGroup>
                  </Form>
                </BockUiFx>
              </main>
            </ContentSection>
          </div>
        </div>
      </>
    );
  }
}

export default UpdateProfile;
