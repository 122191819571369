/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../redux/system/system-action-creators";
import AvetmissReportingSubmissionService from "../avetmiss-reporting-submission-service";
import { saveAs } from "file-saver";
import { parse } from "content-disposition";

// ----------------------------
//  Actions
// ----------------------------

export const onLoadForm = (history) => {
  return (dispatch) => {
    dispatch(LoadFormRequest());

    const service = AvetmissReportingSubmissionService();

    service
      .getStatus()
      .then((resp) => {
        if (!resp.data.validToken) {
          history.push("/Admin/Avetmiss/Authorise/");
          return;
        }

        if (resp.data.reports === null || resp.data.reports === []) {
          history.push("/Admin/Avetmiss/Reporting/");
          return;
        }

        dispatch(
          LoadFormSuccess(
            resp.data.year,
            resp.data.period,
            resp.data.version,
            resp.data.reports,
            resp.data.collectionStartDate,
            resp.data.collectionEndDate,
            resp.data.state
          )
        );
      })
      .catch((err) => {
        dispatch(LoadFormFailure());
        dispatch(
          addNotification(
            "Sorry, an error occurred trying to get the Avetmiss Reporting Collection Types.",
            "error"
          )
        );
      });
  };
};

const LoadFormRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_REQUEST,
});

const LoadFormSuccess = (
  year,
  period,
  version,
  reports,
  collectionStartDate,
  collectionEndDate,
  state
) => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_SUCCESS,
  payload: {
    year,
    period,
    version,
    collectionStartDate,
    collectionEndDate,
    state,
    files: reports,
  },
});

const LoadFormFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_FAILURE,
});

// Async
export const onSubmit = (
  year,
  period,
  version,
  collectionStartDate,
  collectionEndDate,
  state,
  history
) => (dispatch) => {
  dispatch(onSubmitRequest());

  const service = AvetmissReportingSubmissionService();

  let model = {
    year,
    period,
    version,
  };

  service
    .submitRequest(model)
    .then((resp) => {
      let hasNoAddressErrors = !resp.data.addressErrors?.length;

      dispatch(onSubmitSuccess());

      if (hasNoAddressErrors) {
        dispatch({
          type: "AVETMISS_REPORTING_VALIDATION_LOAD_FORM_SUCCESS",
          payload: {
            verificationSuccessful: resp.data.verificationSuccessful,
            errorMessage: resp.data.errorMessage,
            errors: resp.data.errors,
          },
        });

        history.push("/Admin/Avetmiss/Validation/");
        return;
      }

      dispatch({
        type: "AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_SUCCESS",
        payload: {
          verificationSuccessful: resp.data.verificationSuccessful,
          errorMessage: resp.data.errorMessage,
          addressErrors: resp.data.addressErrors,
          year: year,
          period: period,
          version: version,
          collectionStartDate: collectionStartDate,
          collectionEndDate: collectionEndDate,
          state: state,
        },
      });

      history.push("/Admin/Avetmiss/Address-Validation");
    })
    .catch((err) => {
      dispatch(onSubmitFailure());
      dispatch(
        addNotification("There was an issue validating the NAT files.", "error")
      );

      if (
        err.response.data.Message ===
        "The NCVER API returned an unauthorised response. You will need to login again."
      )
        history.push("/Admin/Avetmiss/Authorise/");
    });
};

const onSubmitRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_SUBMIT_REQUEST,
});
const onSubmitSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_SUBMIT_SUCCESS,
});
const onSubmitFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_SUBMIT_FAILURE,
});

export const onDelete = (history) => (dispatch) => {
  dispatch(onDeleteRequest());

  const service = AvetmissReportingSubmissionService();

  service
    .deleteRequest()
    .then((resp) => {
      dispatch(onDeleteSuccess());
      history.push("/Admin/Avetmiss/Reporting/");
    })
    .catch((err) => {
      dispatch(onDeleteFailure());
      dispatch(
        addNotification("There was an issue deleting the NAT files.", "error")
      );
    });
};

const onDeleteRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_DELETE_REQUEST,
});
const onDeleteSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_DELETE_SUCCESS,
});
const onDeleteFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_DELETE_FAILURE,
});

export const toggleDeleteDialog = () => (dispatch) => {
  dispatch({
    type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_SHOW_DELETE_DIALOG,
  });
};

export const toggleImportDialog = () => (dispatch) => {
  dispatch({
    type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_SHOW_IMPORT_DIALOG,
  });
};

export const importNatFiles = (file) => async (dispatch) => {
  dispatch({
    type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_IMPORT_REQUEST,
  });

  const service = AvetmissReportingSubmissionService();

  await service
    .importNatFiles(file)
    .then((resp) => {
      dispatch({
        type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_IMPORT_SUCCESS,
      });

      dispatch(
        addNotification(
          "The NAT files have been successfully uploaded.",
          "info"
        )
      );
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_IMPORT_FAILURE,
      });
      dispatch(
        addNotification("There was an issue importing the NAT files.", "error")
      );
    });
};

export const exportNatFiles = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_EXPORT_REQUEST,
  });

  const service = AvetmissReportingSubmissionService();

  await service
    .exportNatFiles()
    .then((resp) => {
      dispatch({
        type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_EXPORT_SUCCESS,
      });

      const { data, headers } = resp;

      if (!data) {
        throw Error("Unable to export the NAT files.");
      }

      let file = new Blob([data], {
        type: headers["content-type"],
      });

      saveAs(file, parse(headers["content-disposition"]).parameters.filename);

      dispatch(
        addNotification(
          "The NAT files have been successfully exported.",
          "info"
        )
      );
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.AVETMISS_REPORTING_SUBMISSION_EXPORT_FAILURE,
      });
      dispatch(
        addNotification("There was an issue exporting the NAT files.", "error")
      );
    });
};
