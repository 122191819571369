/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import CourseSurveyService from "./../course-survey-service";
import { surveyQuestions } from "./../../../constants/survey-content";

// ----------------------------
//  Actions
// ----------------------------

export const loadData = (courseContentToken, history) => {
  return dispatch => {
    dispatch(loadDataRequest());

    const service = CourseSurveyService();

    service
      .getSurveys(courseContentToken)
      .then(resp => {
        const payload = {
          ...resp,
          surveyQuestions
        };

        if (!payload.status.hasCompleted) {
          history.push(`/course-content/${courseContentToken}`);
        } else {
          dispatch(loadDataSuccess(payload));
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(loadDataFailure(err));
        dispatch(addNotification("Unable to load survey.", "error"));
      });
  };
};

const loadDataRequest = () => ({
  type: ActionTypes.SURVEY_LOAD_DATA_REQUEST
});

const loadDataSuccess = payload => ({
  type: ActionTypes.SURVEY_LOAD_DATA_SUCCESS,
  payload: {
    ...payload
  }
});

const loadDataFailure = err => ({
  type: ActionTypes.SURVEY_LOAD_DATA_FAILURE,
  payload: err
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.SURVEY_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});

// =======================
// Survey Create
// =======================

export const createSurvey = (data, history, courseContentToken) => {
  return dispatch => {
    dispatch(createSurveyRequest());

    // Build questionnaires
    const answeredQuestions = sanitizeAnsweredQuestions(data.fields);
    let result = getQuestionsToSubmit(surveyQuestions, answeredQuestions);
    let payload = {
      userCourseId: data.userCourseId,
      bestAspects: data.fields.bestAspects,
      needImprovement: data.fields.needImprovement,
      undertakingApprenticeshipOrTraineeship: data.fields.uat === "1",
      rpl: data.fields.rpl === "1",
      surveyResponses: [...result]
    };

    const service = CourseSurveyService();
    service
      .createSurvey(payload)
      .then(resp => {
        dispatch(createSurveySuccess());
        history.push(`/course-completed/${courseContentToken}`);
      })
      .catch(err => {
        console.error(err);
        dispatch(createSurveyFailure(err));
        dispatch(addNotification("Unable to save survey.", "error"));
      });
  };
};

const createSurveyRequest = () => ({
  type: ActionTypes.SURVEY_SUBMIT_REQUEST
});

const createSurveySuccess = payload => ({
  type: ActionTypes.SURVEY_SUBMIT_SUCCESS,
  payload: {
    ...payload
  }
});

const createSurveyFailure = err => ({
  type: ActionTypes.SURVEY_SUBMIT_FAILURE,
  payload: err
});

const getQuestionsToSubmit = (questions, answeredQuestions) => {
  let result = questions.map(o => {
    let qr = answeredQuestions.find(x => x.questionId == o.questionId);
    return {
      questionId: o.questionId,
      response: qr ? qr.response : null
    };
  });

  return result;
};

const sanitizeAnsweredQuestions = answeredQuestions => {
  let aq = Object.keys(answeredQuestions)
    .filter(k => k.split("-")[0] == "question")
    .map(k => {
      return {
        questionId: k.split("-")[1],
        response: answeredQuestions[k]
      };
    });

  return aq;
};

// =======================
// Survey Update
// =======================

export const updateSurvey = (surveyId, data, history, courseContentToken) => {
  return dispatch => {
    dispatch(updateSurveyRequest());

    // Build questionnaires
    const answeredQuestions = sanitizeAnsweredQuestions(data.fields);
    let result = getQuestionsToSubmit(surveyQuestions, answeredQuestions);
    let payload = {
      userCourseId: data.userCourseId,
      bestAspects: data.fields.bestAspects,
      needImprovement: data.fields.needImprovement,
      uat: data.fields.uat,
      rpl: data.fields.rpl,
      surveyResponses: [...result]
    };

    const service = CourseSurveyService();
    service
      .updateSurvey(surveyId, payload)
      .then(resp => {
        dispatch(updateSurveySuccess());
        history.push(`/course-completed/${courseContentToken}`);
      })
      .catch(err => {
        console.error(err);
        dispatch(updateSurveyFailure(err));
        dispatch(addNotification("Unable to update survey.", "error"));
      });
  };
};

const updateSurveyRequest = () => ({
  type: ActionTypes.SURVEY_SUBMIT_REQUEST
});

const updateSurveySuccess = payload => ({
  type: ActionTypes.SURVEY_SUBMIT_SUCCESS,
  payload: {
    ...payload
  }
});

const updateSurveyFailure = err => ({
  type: ActionTypes.SURVEY_SUBMIT_FAILURE,
  payload: err
});
