/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
//import { addNotification } from "../../../redux/system/system-action-creators";

// ----------------------------
//  Actions
// ----------------------------

// export const toggleIsAwesome = () => {
//   return dispatch => {
//     dispatch(toggleIsAwesomeRequest());

//     let random = Math.floor(Math.random() * 10 + 1);
//     const success = random >= 5;

//     if (success) {
//       dispatch(toggleIsAwesomeSuccess());
//       dispatch(addNotification("Success!!"));
//     } else {
//       dispatch(toggleIsAwesomeFailure());
//       dispatch(addNotification("Failure!!", "error")); // error or warning
//     }
//   };
// };

// const toggleIsAwesomeRequest = () => ({
//   type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_REQUEST
// });
// const toggleIsAwesomeSuccess = () => ({
//   type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_SUCCESS
// });
// const toggleIsAwesomeFailure = () => ({
//   type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_FAILURE
// });

// // Async
// export const toggleIsAwesome = data => {
//   return dispatch => {
//     const myService = MyService();

//     dispatch(toggleIsAwesomeRequest());

//     myService.doSomething(data).then(resp => {
//       dispatch(toggleIsAwesomeSuccess(resp));
//     });
//   };
// };

// const toggleIsAwesomeRequest = () => ({
//   type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_REQUEST
// });
// const toggleIsAwesomeSuccess = data => ({
//   type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_SUCCESS,
//   payload: data
// });
// const toggleIsAwesomeFailure = () => ({
//   type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_TOGGLE_IS_AWESOME_FAILURE
// });

// ----------------------------
//  Form Actions
// ----------------------------

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.CORPORATE_VOUCHERS_REGISTER_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
