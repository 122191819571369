export const NswFoodSafetySupervisorCertificateThirdPartyFormTasks = [
  {
    taskId: 158,
    courseId: 6,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 159,
    courseId: 6,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 160,
    courseId: 6,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 161,
    courseId: 6,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 162,
    courseId: 6,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 163,
    courseId: 6,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 164,
    courseId: 6,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 165,
    courseId: 6,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 166,
    courseId: 6,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 167,
    courseId: 6,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 168,
    courseId: 6,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 169,
    courseId: 6,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 170,
    courseId: 6,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 171,
    courseId: 6,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 172,
    courseId: 6,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 15,
  },
  {
    taskId: 173,
    courseId: 6,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 16,
  },
  {
    taskId: 174,
    courseId: 6,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 17,
  },
  {
    taskId: 175,
    courseId: 6,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 18,
  },
  {
    taskId: 176,
    courseId: 6,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 19,
  },
  {
    taskId: 177,
    courseId: 6,
    version: 1,
    taskGroup: "Task 5: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 20,
  },
  {
    taskId: 178,
    courseId: 6,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 21,
  },
  {
    taskId: 179,
    courseId: 6,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 22,
  },
  {
    taskId: 180,
    courseId: 6,
    version: 1,
    taskGroup: "Task 6: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 23,
  },
  {
    taskId: 181,
    courseId: 6,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 24,
  },
  {
    taskId: 182,
    courseId: 6,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 25,
  },
  {
    taskId: 183,
    courseId: 6,
    version: 1,
    taskGroup: "Task 7: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 26,
  },
  {
    taskId: 184,
    courseId: 6,
    version: 1,
    taskGroup: "Task 8: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 27,
  },
  {
    taskId: 185,
    courseId: 6,
    version: 1,
    taskGroup: "Task 8: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 28,
  },
  {
    taskId: 186,
    courseId: 6,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 29,
  },
  {
    taskId: 187,
    courseId: 6,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 30,
  },
  {
    taskId: 188,
    courseId: 6,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 31,
  },
  {
    taskId: 189,
    courseId: 6,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 32,
  },
  {
    taskId: 190,
    courseId: 6,
    version: 1,
    taskGroup: "Task 9: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 33,
  },
  {
    taskId: 191,
    courseId: 6,
    version: 1,
    taskGroup: "Task 10: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 34,
  },
  {
    taskId: 192,
    courseId: 6,
    version: 1,
    taskGroup: "Task 10: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 35,
  },
  {
    taskId: 193,
    courseId: 6,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 36,
  },
  {
    taskId: 194,
    courseId: 6,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 37,
  },
  {
    taskId: 195,
    courseId: 6,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 38,
  },
  {
    taskId: 196,
    courseId: 6,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 39,
  },
  {
    taskId: 197,
    courseId: 6,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 40,
  },
  {
    taskId: 198,
    courseId: 6,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 41,
  },
  {
    taskId: 199,
    courseId: 6,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 42,
  },
  {
    taskId: 200,
    courseId: 6,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 43,
  },
  {
    taskId: 201,
    courseId: 6,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 44,
  },
  {
    taskId: 202,
    courseId: 6,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 45,
  },
  // -----------------------------------
  // Version 2
  // -----------------------------------
  {
    taskId: 329,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Storing",
    displayOrder: 1,
  },
  {
    taskId: 330,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Preparing",
    displayOrder: 2,
  },
  {
    taskId: 331,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Processing",
    displayOrder: 3,
  },
  {
    taskId: 332,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Displaying",
    displayOrder: 4,
  },
  {
    taskId: 333,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Serving",
    displayOrder: 5,
  },
  {
    taskId: 334,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Packaging",
    displayOrder: 6,
  },
  {
    taskId: 335,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Transporting",
    displayOrder: 7,
  },
  {
    taskId: 336,
    courseId: 6,
    version: 2,
    taskGroup:
      "Use hygienic food handling practices including the correct methods in line with organisational procedures, including each of the following on at least three occasions",
    task: "Disposing",
    displayOrder: 8,
  },
  {
    taskId: 337,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent food contamination",
    task:
      "Maintain clean clothes and use required personal protective equipment, including appropriate bandages and dressings where required.",
    displayOrder: 9,
  },
  {
    taskId: 338,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent food contamination",
    task: "Prevent food contamination from clothing and other personal items.",
    displayOrder: 10,
  },
  {
    taskId: 339,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 11,
  },
  {
    taskId: 340,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent food contamination",
    task:
      "Ensure hygienic personal contact with food and food contact surfaces.",
    displayOrder: 12,
  },
  {
    taskId: 341,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent food contamination",
    task:
      "Use hygienic cleaning and sanitising practices that prevent food-borne illnesses.",
    displayOrder: 13,
  },
  {
    taskId: 342,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent cross-contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently in accordance with the NSW guidelines.",
    displayOrder: 14,
  },
  {
    taskId: 343,
    courseId: 6,
    version: 2,
    taskGroup: "Prevent cross-contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 15,
  },
  {
    taskId: 344,
    courseId: 6,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 16,
  },
  {
    taskId: 345,
    courseId: 6,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task: "Report unsafe practices that breach hygiene procedures promptly.",
    displayOrder: 17,
  },
  {
    taskId: 346,
    courseId: 6,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self.",
    displayOrder: 18,
  },
  {
    taskId: 347,
    courseId: 6,
    version: 2,
    taskGroup: "Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report as appropriate for follow-up.",
    displayOrder: 19,
  },
  {
    taskId: 348,
    courseId: 6,
    version: 2,
    taskGroup: "Report any personal health issues",
    task: "Report personal health issues likely to cause a hygiene risk.",
    displayOrder: 20,
  },
  {
    taskId: 349,
    courseId: 6,
    version: 2,
    taskGroup: "Report any personal health issues",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 21,
  },
  {
    taskId: 350,
    courseId: 6,
    version: 2,
    taskGroup: "Report any personal health issues",
    task:
      "Cease participation in food handling activities where own health issue may cause food contamination.",
    displayOrder: 22,
  },
  {
    taskId: 351,
    courseId: 6,
    version: 2,
    taskGroup: "Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 23,
  },
  {
    taskId: 352,
    courseId: 6,
    version: 2,
    taskGroup: "Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 24,
  },
  {
    taskId: 353,
    courseId: 6,
    version: 2,
    taskGroup: "Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 25,
  },
  {
    taskId: 354,
    courseId: 6,
    version: 2,
    taskGroup: "Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 26,
  },
  {
    taskId: 355,
    courseId: 6,
    version: 2,
    taskGroup: "Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 27,
  },
  {
    taskId: 356,
    courseId: 6,
    version: 2,
    taskGroup: "Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are identified.",
    displayOrder: 28,
  },
  {
    taskId: 357,
    courseId: 6,
    version: 2,
    taskGroup: "Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 29,
  },
  {
    taskId: 358,
    courseId: 6,
    version: 2,
    taskGroup: "Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 30,
  },
  {
    taskId: 359,
    courseId: 6,
    version: 2,
    taskGroup: "Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 31,
  },
  {
    taskId: 360,
    courseId: 6,
    version: 2,
    taskGroup: "Store food safely",
    task: "Ensure food is stored to avoid cross-contamination of ingredients.",
    displayOrder: 32,
  },
  {
    taskId: 361,
    courseId: 6,
    version: 2,
    taskGroup: "Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of food.",
    displayOrder: 33,
  },
  {
    taskId: 362,
    courseId: 6,
    version: 2,
    taskGroup: "Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 34,
  },
  {
    taskId: 363,
    courseId: 6,
    version: 2,
    taskGroup: "Prepare food safely",
    task: "Ensure safety of food prepared, served and sold to customers.",
    displayOrder: 35,
  },
  {
    taskId: 364,
    courseId: 6,
    version: 2,
    taskGroup: "Prepare food safely",
    task:
      "Prepare food to meet customer requirements, including actions to address allergen requests by customers.",
    displayOrder: 36,
  },
  {
    taskId: 365,
    courseId: 6,
    version: 2,
    taskGroup: "Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 37,
  },
  {
    taskId: 366,
    courseId: 6,
    version: 2,
    taskGroup: "Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 38,
  },
  {
    taskId: 367,
    courseId: 6,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 39,
  },
  {
    taskId: 368,
    courseId: 6,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 40,
  },
  {
    taskId: 369,
    courseId: 6,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 41,
  },
  {
    taskId: 370,
    courseId: 6,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Dispose of, or report damaged or unsafe eating, drinking or food handling utensils.",
    displayOrder: 42,
  },
  {
    taskId: 371,
    courseId: 6,
    version: 2,
    taskGroup: "Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 43,
  },
  {
    taskId: 372,
    courseId: 6,
    version: 2,
    taskGroup: "Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 44,
  },
  {
    taskId: 373,
    courseId: 6,
    version: 2,
    taskGroup: "Dispose of food safely",
    task: "Dispose of food promptly to avoid cross-contamination.",
    displayOrder: 45,
  },
  {
    taskId: 374,
    courseId: 6,
    version: 2,
    taskGroup: "Dispose of food safely",
    task: "Use appropriate documentation to record of disposed items.",
    displayOrder: 46,
  },
  {
    taskId: 375,
    courseId: 6,
    version: 2,
    taskGroup: "Food allergen management",
    task: "Identify critical control points for food allergen management.",
    displayOrder: 47,
  },
  {
    taskId: 376,
    courseId: 6,
    version: 2,
    taskGroup: "Food allergen management",
    task:
      "Apply procedures to prevent cross contamination of food allergens in food storage, preparation and serving.",
    displayOrder: 48,
  },
  {
    taskId: 377,
    courseId: 6,
    version: 2,
    taskGroup: "Food allergen management",
    task: "Identify food allergens on packaged and unpackaged foods.",
    displayOrder: 49,
  },
  {
    taskId: 378,
    courseId: 6,
    version: 2,
    taskGroup: "Food allergen management",
    task: "Identify the most common food allergens.",
    displayOrder: 50,
  },
  {
    taskId: 379,
    courseId: 6,
    version: 2,
    taskGroup: "Food allergen management",
    task:
      "Identify tools and resources to help with implementation of food allergen management procedures.",
    displayOrder: 51,
  },
  {
    taskId: 380,
    courseId: 6,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task: "The student is aware of the NSW Food Act 2003 offences.",
    displayOrder: 52,
  },
  {
    taskId: 381,
    courseId: 6,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice.",
    displayOrder: 53,
  },
  {
    taskId: 382,
    courseId: 6,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Literacy skills to read and interpret workplace documents, including policies, procedures and flow charts that identify critical control points.",
    displayOrder: 54,
  },
  {
    taskId: 383,
    courseId: 6,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety.",
    displayOrder: 55,
  },
  {
    taskId: 384,
    courseId: 6,
    version: 2,
    taskGroup: "Food Safety compliance and skills",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings.",
    displayOrder: 56,
  },
];
