/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import CourseService from "../../../services/course-service";
import ThirdPartyFormService from "./../third-party-form-service";

// ----------------------------
//  Actions
// ----------------------------

export const onLoadForm = (courseContentToken, history) => {
  return (dispatch) => {
    dispatch(LoadFormRequest());
    const service = ThirdPartyFormService();
    const courseService = CourseService();

    service
      .getThirdPartyForm(courseContentToken)
      .then((resp) => {
        if (resp.status.hasCompleted && resp.status.thirdPartyFormCompleted) {
          dispatch(
            addNotification(
              "Sorry, this third party form has already been completed.",
              "error"
            )
          );

          history.push("/");
        } else if (resp.status.courseExpired) {
          dispatch(
            addNotification(
              "Sorry, this student's course has expired.",
              "error"
            )
          );

          history.push("/");
        } else {
          const course = courseService.getCourse(resp.courseId);
          const tasks = courseService.getThirdPartyFormTasks(resp.courseId);

          dispatch(
            LoadFormSuccess(
              course.courseTitle,
              course.certificateCodes,
              course.unitOfCompetencies,
              tasks,
              {
                thirdPartyFormAdditionalContent:
                  course.thirdPartyFormAdditionalContent,
                ...resp,
              }
            )
          );
        }
      })
      .catch((err) => {
        dispatch(LoadFormFailure());
        dispatch(
          addNotification(
            "Sorry, something went wrong loading your course information. Please try again. If the problem continues, please contact us for assistance.",
            "error"
          )
        );
        history.push("/your-courses/");
      });
  };
};

export const onLoadSampleForm = (courseId, history) => {
  return (dispatch) => {
    dispatch(LoadFormRequest());
    const courseService = CourseService();

    let course = courseService.getCourse(courseId);

    if (course == null) {
      courseId = 2;
      course = courseService.getCourse(courseId);
    }

    const tasks = courseService.getThirdPartyFormTasks(courseId);

    dispatch(
      LoadFormSuccess(
        course.courseTitle,
        course.certificateCodes,
        course.unitOfCompetencies,
        tasks,
        {
          observerFirstName: "Sample",
          observerLastName: "Observer",
          observerEmail: "sample@observer.com.au",
          studentPerformanceSatisfactory: null,
          additionalComments: "",
          confirmThirdPartyDeclaration: null,
          thirdPartyFormAdditionalContent:
            course.thirdPartyFormAdditionalContent,
          thirdPartyFormVersion: course.currentThirdPartyFormVersion,
          signature: "",
          taskResponses: [],
          studentFirstName: "Sample",
          studentLastName: "Student",
          isSampleForm: true,
          showForm: true,
        }
      )
    );
  };
};

const LoadFormRequest = () => ({
  type: ActionTypes.THIRD_PARTY_FORM_LOAD_FORM_REQUEST,
});

const LoadFormSuccess = (
  courseName,
  certificateCodes,
  unitOfCompetencies,
  tasks,
  thirdPartyFormResponses
) => ({
  type: ActionTypes.THIRD_PARTY_FORM_LOAD_FORM_SUCCESS,
  payload: {
    courseName: courseName,
    certificateCodes: certificateCodes,
    unitOfCompetencies: unitOfCompetencies,
    tasks: tasks,
    thirdPartyFormResponses: thirdPartyFormResponses, // server data
  },
});

const LoadFormFailure = () => ({
  type: ActionTypes.THIRD_PARTY_FORM_LOAD_FORM_FAILURE,
});

// ----------------------------
//  Form Actions
// ----------------------------

export const formInputChange = (name, value, error) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.THIRD_PARTY_FORM_FORM_INPUT_CHANGE,
      payload: {
        name: name,
        value: value,
        error: error,
      },
    });
  };
};

// ------------------------
// Update Third Party Form
// ------------------------

export const updateThirdPartyFormObserver = (accessToken, data, history) => {
  return (dispatch) => {
    dispatch(updateThirdPartyRequest());
    const service = ThirdPartyFormService();

    service
      .updateThirdPartyFormObserver(accessToken, data)
      .then((resp) => {
        dispatch(updateThirdPartySuccess());
        dispatch(addNotification("Successfully saved.", "success"));
      })
      .catch((err) => {
        console.error(err);
        dispatch(updateThirdPartyFailure());
        dispatch(addNotification("Unable to save Third Party Form.", "error"));
      });
  };
};

const updateThirdPartyRequest = () => ({
  type: ActionTypes.THIRD_PARTY_FORM_OBSERVER_UPDATE_REQUEST,
});

const updateThirdPartySuccess = () => ({
  type: ActionTypes.THIRD_PARTY_FORM_OBSERVER_UPDATE_SUCCESS,
});

const updateThirdPartyFailure = (err) => ({
  type: ActionTypes.THIRD_PARTY_FORM_OBSERVER_UPDATE_FAILURE,
  payload: {
    ...err,
  },
});

// ---------------------
//  Send Email Confirm
// ---------------------
export const showConfirmEmailObserver = () => ({
  type: ActionTypes.THIRD_PARTY_SHOW_CONFIRM_EMAIL_OBSERVER,
});

export const hideConfirmEmailObserver = () => ({
  type: ActionTypes.THIRD_PARTY_HIDE_CONFIRM_EMAIL_OBSERVER,
});

export const sendEmailAndUpdateThirdPartyForm = (
  accessToken,
  data,
  history
) => {
  return (dispatch) => {
    dispatch(sendEmailToObserverRequest());
    const service = ThirdPartyFormService();

    service
      .updateThirdPartyFormObserver(accessToken, data, true)
      .then((resp) => {
        dispatch(sendEmailToObserverSuccess());
        dispatch(
          addNotification("Third Party Form successfully sent.", "success")
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(sendEmailToObserverFailure());
        dispatch(
          addNotification(
            "Unable to send Third Party Form to Observer.",
            "error"
          )
        );
      });
  };
};

const sendEmailToObserverRequest = () => ({
  type: ActionTypes.THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_REQUEST,
});

const sendEmailToObserverSuccess = () => ({
  type: ActionTypes.THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_SUCCESS,
});

const sendEmailToObserverFailure = () => ({
  type: ActionTypes.THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_FAILURE,
});

// --------------------------
//  Save Progress
// --------------------------

export const saveProgressThirdPartyForm = (
  accessToken,
  data,
  courseId,
  thirdPartyFormVersion,
  history,
  isSubmit = false
) => {
  return (dispatch) => {
    dispatch(saveProgressThirdPartyFormRequest(isSubmit));

    const service = ThirdPartyFormService();
    const courseService = CourseService();
    const tasks = courseService
      .getThirdPartyFormTasks(courseId)
      .filter((x) => x.version === thirdPartyFormVersion);

    const taskResponses = sanitizeTaskReponses(data);
    let result = getTasksToSubmit(tasks, taskResponses);

    const {
      observerFirstName,
      observerLastName,
      observerEmail,
      studentPerformanceSatisfactory,
      additionalComments,
      confirmThirdPartyDeclaration,
      signature,
    } = data;

    let payload = {
      observerFirstName,
      observerLastName,
      observerEmail,
      studentPerformanceSatisfactory: studentPerformanceSatisfactory === "1",
      additionalComments: additionalComments == null ? "" : additionalComments,
      confirmThirdPartyDeclaration: confirmThirdPartyDeclaration === "true",
      taskResponses: [...result],
      signature: signature,
    };

    service
      .saveProgress(accessToken, payload)
      .then((resp) => {
        dispatch(saveProgressThirdPartyFormSuccess(isSubmit));
        dispatch(
          addNotification("Third Party Form successfully saved.", "success")
        );
        dispatch(showMessageDialog(resp.data.message));
      })
      .catch((err) => {
        console.error(err);
        dispatch(saveProgressThirdPartyFormFailure());
        dispatch(addNotification("Unable to save Third Party Form.", "error"));
      });
  };
};

const saveProgressThirdPartyFormRequest = (isSubmitCaller) => ({
  type: ActionTypes.THIRD_PARTY_FORM_SAVE_PROGRESS_REQUEST,
  payload: {
    submitCaller: isSubmitCaller,
  },
});

const saveProgressThirdPartyFormSuccess = (isSubmitCaller) => ({
  type: ActionTypes.THIRD_PARTY_FORM_SAVE_PROGRESS_SUCCESS,
  payload: {
    submitCaller: isSubmitCaller,
  },
});

const saveProgressThirdPartyFormFailure = (err, isSubmitCaller) => ({
  type: ActionTypes.THIRD_PARTY_FORM_SAVE_PROGRESS_FAILURE,
  payload: {
    error: err,
    submitCaller: isSubmitCaller,
  },
});

// ---------------------
//  Show Message Dialog
// ---------------------
export const showMessageDialog = (message) => ({
  type: ActionTypes.THIRD_PARTY_FORM_SHOW_MESSAGE_DIALOG,
  payload: message,
});

export const hideMessageDialog = () => ({
  type: ActionTypes.THIRD_PARTY_FORM_HIDE_MESSAGE_DIALOG,
});

// ---------------------
//  Helper methods
// ---------------------
const sanitizeTaskReponses = (taskResponses) => {
  let aq = Object.keys(taskResponses)
    .filter((k) => k.split("-")[0] === "task" && k.split("-")[1] !== "details")
    .map((k) => {
      let resp = taskResponses[k] !== null ? taskResponses[k] === "true" : null;
      return {
        taskId: k.split("-")[1],
        response: resp,
        comments: taskResponses[`task-details-${k.split("-")[1]}`],
      };
    });

  return aq;
};

const getTasksToSubmit = (tasks, taskResponses) => {
  let result = tasks.map((o) => {
    let qr = taskResponses.find((x) => x.taskId == o.taskId);
    let comments = "";
    if (qr && qr.comments) comments = qr.comments;

    return {
      taskId: o.taskId,
      response: qr ? qr.response : null,
      comments: comments,
    };
  });

  return result;
};
