import React from "react";
import CarouselItem from "../Carousel-Item";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./carousel.scss";

const View = ({ owlOptions, items, onlyImages }) => {
  return (
    <div className="row m-0">
      <div className="col">
        <OwlCarousel className="owl-theme" {...owlOptions}>
          {onlyImages &&
            items.map((item, i) => <CarouselItem key={i} imageName={item} />)}

          {!onlyImages && items.map((item, i) => item)}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default View;
