import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import Datatable from "./datatable";

class Users extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
    this.props.clearDataTableSearchbox();
  }

  render() {
    return (
      <>
        <PageTitle title="Admin - Promo Codes | Train to Gain" />
        <ContentSection isFullWidth={true} padding={3} bgColor="grey-shadow">
          <h1>Promo Codes</h1>
        </ContentSection>
        <ContentSection isFullWidth={true} padding={3}>
          <Datatable {...this.props} />
        </ContentSection>
      </>
    );
  }
}

export default Users;
