import { ActionTypes } from "./action-types";
import moment from "moment";

const initialState = {
  isProcessing: false,
  dialogs: {
    showDeleteDialog: false,
    showEditDialog: false
  },
  fieldErrors: {},
  fields: {
    discountCode: "",
    discountPercentage: "",
    discountAmount: "",
    expiryDate: "",
    isOneUseOnly: ""
  },
  isEdit: false,
  isSubmitting: false
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_PROMO_CODES_SHOW_EDIT_DIALOG:
      return {
        ...state,
        isEdit: action.payload ? true : false,
        fields: action.payload
          ? {
              ...action.payload,
              discountPercentage: action.payload.discountPercentage * 100
            }
          : {
              discountCode: "",
              discountPercentage: 0,
              discountAmount: 0,
              expiryDate: moment().set({ year: 2099, month: 0, date: 1 }),
              isOneUseOnly: ""
            },
        dialogs: {
          ...state.dialogs,
          showEditDialog: !state.dialogs.showEditDialog
        }
      };
    case ActionTypes.ADMIN_PROMO_CODES_SHOW_DELETE_DIALOG:
      return {
        ...state,
        fields: action.payload ? action.payload : {},
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        }
      };

    case ActionTypes.ADMIN_PROMO_CODES_FILTER_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    case ActionTypes.ADMIN_PROMO_CODES_DIALOG_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      };
    case ActionTypes.ADMIN_PROMO_CODES_DELETE_REQUEST:
    case ActionTypes.ADMIN_PROMO_CODES_ADD_REQUEST:
    case ActionTypes.ADMIN_PROMO_CODES_EDIT_REQUEST:
      return {
        ...state,
        isProcessing: true
      };
    case ActionTypes.ADMIN_PROMO_CODES_DELETE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        }
      };
    case ActionTypes.ADMIN_PROMO_CODES_ADD_SUCCESS:
    case ActionTypes.ADMIN_PROMO_CODES_EDIT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showEditDialog: !state.dialogs.showEditDialog
        }
      };
    default:
      return state;
  }
}

export default Reducer;
