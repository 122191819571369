import PurchaseCorporateVouchers from "./purchase-corporate-vouchers";
import { connect } from "react-redux";
import * as actionCreators from "./redux/action-creators";
import { selectPurchaseCorporateVouchers } from "./redux/reducer";
import * as sysActionCreators from "../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  const stateData = selectPurchaseCorporateVouchers(state);

  return {
    addressEntry: stateData.addressEntry,
    totalPrice: stateData.totalPrice,
    fields: stateData.fields,
    isPromoCodeApplied: stateData.isPromoCodeApplied,
    totalAfterDiscount: stateData.totalAfterDiscount,
    courses: stateData.courses,
    fieldErrors: stateData.fieldErrors,
    isCreditCardValid: stateData.isCreditCardValid,
    isSubmitting: stateData.isSubmitting,
    isSubmitted: stateData.isSubmitted,
    formValid: stateData.formValid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearAddress: () => {
    dispatch(actionCreators.clearAddress());
  },
  updateAutocompleteAddress: (fullAddress) => {
    dispatch(actionCreators.updateAutocompleteAddress(fullAddress));
  },
  toggleManualAddressEntry: () => {
    dispatch(actionCreators.toggleManualAddressEntry());
  },
  togglePromoCodeEntry: () => {
    dispatch(actionCreators.togglePromoCodeEntry());
  },
  setEmail: () => {
    dispatch(actionCreators.setEmail());
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onCreditCardChange: (values) => {
    dispatch(actionCreators.onCreditCardInputChange(values));
  },
  onInputQuantityChange: (values) => {
    if (isNaN(values.value)) {
      return;
    }
    if (
      values.value === undefined ||
      values.value === null ||
      values.value === "" ||
      values.value === ""
    ) {
      values.value = 0;
      dispatch(actionCreators.onInputQuantityChange(values));
    } else {
      dispatch(actionCreators.onInputQuantityChange(values));
    }
  },
  setEmail: () => {
    dispatch(actionCreators.setEmail());
  },
  init: () => {
    dispatch(actionCreators.init());
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match,
});

const validate = (stateProps) => {
  const fields = stateProps.fields;
  const totalPrice = stateProps.totalPrice;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

  if (!fields.companyName) return true;
  if (!fields.phone) return true;
  if (!fields.email) return true;
  if (!fields.postcode) return true;
  if (!fields.state) return true;
  if (!fields.city) return true;
  if (!fields.streetName) return true;
  if (stateProps.addressEntry.enterAddressManually && !fields.streetNumber)
    return true;
  if (!fields.paymentMethod) return true;
  if (
    fields.paymentMethod === "Credit Card" &&
    (!stateProps.isCreditCardValid || !fields.cardName)
  ) {
    return true;
  }

  if (totalPrice < 1) return true;
  if (errMessages.length > 0) return true;

  return false;
};

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  toggleManualAddressEntry: () => {
    dispatchProps.dispatch(dispatchProps.toggleManualAddressEntry);
    validate(stateProps);
  },
  applyPromoCode: () => {
    if (stateProps.fields.promoCode) {
      dispatchProps.dispatch(
        actionCreators.applyPromoCode(
          stateProps.fields.promoCode,
          stateProps.courses,
          true
        )
      );
    } else {
      dispatchProps.dispatch(
        sysActionCreators.addNotification("Please add a promo code", "warning")
      );
    }
  },
  showErrorNotification: (error) => {
    dispatchProps.dispatch(sysActionCreators.addNotification(error, "warning"));
  },
  onSubmit: () => {
    dispatchProps.dispatch(
      actionCreators.onSubmit(
        stateProps,
        stateProps.fields,
        dispatchProps.history
      )
    );
  },
  validate: () => {
    return validate(stateProps);
  },
});

const PurchaseCorporateVouchersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(PurchaseCorporateVouchers);

export default PurchaseCorporateVouchersContainer;
