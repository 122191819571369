import React from "react";
import ContentSection from "components/Common/content-section";
import SelectACourse from "features/select-a-course/select-a-course";

const CoursesContainer = () => {
  return (
    <ContentSection bgClass="off-white">
      <SelectACourse />
    </ContentSection>
  );
};

export default CoursesContainer;
