/**
 * Step 2 Reducer
 */
import { ActionTypes } from "./action-types";
import DateService from "../../../../services/date-service";
import moment from "moment";

const initialState = {
  addressEntry: {
    displayAutocompleteAddressField: false,
    enterAddressManually: false,
    showSearchAddress: true,
    fullAddress: "",
  },
  fieldVisibility: {
    showLevelOfEducation: false,
    showWhatIsUsi: false,
    showHasUsi: false,
    showDisability: false,
    showCountry: false,
    showOtherLanguage: false,
    showUnderstandingOfEnglish: false,
    showUnderstandFalseInfo: false,
    showUnderstandTimeToComplete: false,
    showGuardianAcknowledgment: false,
    showSubmit: true,
    showUsiLastName: true,
  },
  fields: {
    courseId: "",
    userCourseId: "",
    dateOfBirth: null,
    gender: "",
    levelOfSchool: "",
    stillEnrolledInSchool: "",
    completedTertiary: "",
    levelOfEducation: "",
    reasonForUndertakingCourse: "",
    phone: "",
    usiFirstName: "Adam",
    usiMiddleName: "",
    usiLastName: "Luck",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    city: "",
    state: "",
    postcode: "",
    hasUsi: "",
    usiNumber: "",
    howDidYouHear: "",
    disability: "",
    disabilityType: "",
    bornInAustralia: "",
    country: "",
    aboriginalOrTorresStraitIslandOrigin: "",
    languageAtHome: "",
    languageAtHomeOther: "",
    goodUnderstandingOfEnglish: "",
    understandFalseInfo: "",
    understandTimeToComplete: "",
    studentAcknowledgment: false,
    guardianAcknowledgment: false,
  },
  isProcessing: false,
  formValid: false,
  fieldErrors: {},
};

function Step2Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.STEP_2_INIT:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.STEP_2_INIT_SUCCEEDED:
      return {
        ...state,
        isProcessing: false,
        fields: {
          courseId: action.payload.courseId,
          userCourseId: action.payload.userCourseId,
          dateOfBirth: null,
          gender: "",
          levelOfSchool: "",
          stillEnrolledInSchool: "",
          completedTertiary: "",
          levelOfEducation: "",
          reasonForUndertakingCourse: "",
          phone: "",
          usiFirstName: action.payload.usiFirstName
            ? action.payload.usiFirstName
            : "",
          usiMiddleName: action.payload.usiMiddleName
            ? action.payload.usiMiddleName
            : "",
          usiLastName: action.payload.usiLastName
            ? action.payload.usiLastName
            : "",
          unitNumber: action.payload.unitNumber
            ? action.payload.unitNumber
            : "",
          streetNumber: action.payload.streetNumber
            ? action.payload.streetNumber
            : "",
          streetName: action.payload.streetName
            ? action.payload.streetName
            : "",
          city: action.payload.city ? action.payload.city : "",
          state: action.payload.state ? action.payload.state : "",
          postcode: action.payload.postcode ? action.payload.postcode : "",
          hasUsi: "",
          usiNumber: "",
          howDidYouHear: "",
          disability: "",
          disabilityType: "",
          bornInAustralia: "",
          country: "",
          aboriginalOrTorresStraitIslandOrigin: "",
          languageAtHome: "",
          languageAtHomeOther: "",
          goodUnderstandingOfEnglish: "",
          understandFalseInfo: "",
          understandTimeToComplete: "",
          studentAcknowledgment: false,
          guardianAcknowledgment: false,
        },
        addressEntry: {
          ...state.addressEntry,
          fullAddress: action.payload.fullAddress
            ? action.payload.fullAddress
            : "",
        },
        fieldVisibility: {
          showLevelOfEducation: action.payload.completedTertiary,
          showWhatIsUsi: false,
          showHasUsi: false,
          showDisability: false,
          showCountry: false,
          showOtherLanguage: false,
          showUnderstandingOfEnglish: false,
          showUnderstandFalseInfo: false,
          showUnderstandTimeToComplete: false,
          showGuardianAcknowledgment: false,
          showSubmit: false,
          showUsiLastName:
            action.payload.usiFirstName !== action.payload.usiLastName,
        },
      };
    case ActionTypes.STEP_2_INIT_FAILED:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.STEP_2_TOGGLE_TERTIARY:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showLevelOfEducation: action.payload,
        },
        fields: {
          ...state.fields,
          levelOfEducation: action.payload ? state.fields.levelOfEducation : "",
        },
      };
    case ActionTypes.STEP_2_FORM_VALID:
      return {
        ...state,
        formValid: action.payload,
      };
    case ActionTypes.STEP_2_UPDATE_DOB_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          dateOfBirth: action.payload,
        },
        fieldVisibility: {
          ...state.fieldVisibility,
          showGuardianAcknowledgment:
            DateService().getCurrentAge(moment(action.payload)) < 18
              ? true
              : false,
        },
      };
    case ActionTypes.STEP_2_TOGGLE_WHAT_IS_USI:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showWhatIsUsi: !state.showWhatIsUsi,
        },
      };

    case ActionTypes.STEP_2_TOGGLE_HAS_USI:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showHasUsi: action.payload,
          showSubmit: action.payload,
        },
        fields: {
          ...state.fields,
          usiNumber: action.payload ? state.fields.usiNumber : "",
        },
      };

    case ActionTypes.STEP_2_TOGGLE_DISABILITY:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showDisability: action.payload,
        },
        fields: {
          ...state.fields,
          disabilityType: action.payload ? state.fields.disabilityType : "",
        },
      };

    case ActionTypes.STEP_2_TOGGLE_COUNTRY:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showCountry: action.payload,
        },
        fields: {
          ...state.fields,
          country: action.payload ? state.fields.country : "",
        },
      };

    case ActionTypes.STEP_2_TOGGLE_OTHER_LANGUAGE:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showOtherLanguage: action.payload,
        },
        fields: {
          ...state.fields,
          languageAtHome: action.payload ? "Other Language" : "English",
          languageAtHomeOther: action.payload
            ? state.fields.languageAtHomeOther
            : "",
        },
      };

    case ActionTypes.STEP_2_TOGGLE_UNDERSTANDING_OF_ENGLISH:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showUnderstandingOfEnglish: action.payload,
        },
      };

    case ActionTypes.STEP_2_TOGGLE_UNDERSTAND_FALSE_INFO:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showUnderstandFalseInfo: action.payload,
        },
      };

    case ActionTypes.STEP_2_TOGGLE_UNDERSTAND_TIME_TO_COMPLETE:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showUnderstandTimeToComplete: action.payload,
        },
      };

    case ActionTypes.STEP_2_TOGGLE_GUARDIAN_ACKNOWLEDGEMENT:
      return {
        ...state,
        fieldVisibility: {
          ...state.fieldVisibility,
          showGuardianAcknowledgment: action.payload,
        },
        fields: {
          ...state.fields,
          guardianAcknowledgment: action.payload
            ? state.fields.guardianAcknowledgment
            : false,
        },
      };

    case ActionTypes.STEP_2_UPDATE_ADDRESS_FROM_STEP_1_ADDRESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          unitNumber: action.payload.unitNumber,
          streetNumber: action.payload.streetNumber,
          streetName: action.payload.streetName,
          city: action.payload.city,
          state: action.payload.state,
          postcode: action.payload.postcode,
        },
      };

    case ActionTypes.STEP_2_SUBMIT_ENROLMENT_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };

    case ActionTypes.STEP_2_SUBMIT_ENROLMENT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };

    case ActionTypes.STEP_2_SUBMIT_ENROLMENT_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };

    case ActionTypes.STEP_2_FORM_INPUT_CHANGE:
      let stateResultInput = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });
      return stateResultInput;
    case ActionTypes.STEP_2_FORM_DATE_CHANGE:
      let stateResultDate = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
      });

      return stateResultDate;

    // ADDRESS
    case ActionTypes.STEP_2_CLEAR_ADDRESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: "",
        },
        fields: {
          ...state.fields,
          unitNumber: "",
          streetNumber: "",
          streetName: "",
          city: "",
          state: "",
          postcode: "",
        },
      };
    case ActionTypes.STEP_2_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          fullAddress: action.payload.fullAddress,
        },
        fields: {
          ...state.fields,
          unitNumber: `${(
            action.payload.subdwelling +
            " " +
            action.payload.buildingName
          ).trim()}`,
          streetNumber: action.payload.streetNumber,
          streetName: action.payload.street,
          city: action.payload.locality,
          state: action.payload.state,
          postcode: action.payload.postcode,
        },
      };
    case ActionTypes.STEP_2_TOGGLE_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: !state.addressEntry.enterAddressManually,
        },
      };
    case ActionTypes.STEP_2_SHOW_MANUAL_ADDRESS_ENTRY:
      return {
        ...state,
        addressEntry: {
          ...state.addressEntry,
          enterAddressManually: true,
        },
      };

    default:
      return state;
  }
}

export default Step2Reducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectStep2 = (state) => state.courseEnrolment.step2;
