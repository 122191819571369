import React from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import CourseService from "services/course-service";
import "./new-enrolment.scss";

const NewEnrolment = () => {
  const courseService = CourseService();

  return (
    <>
      <PageTagging title="Enrol in a Course" />
      <ContentSection bgClass="veggies-bg off-white flex-100">
        <div className="ttg-container">
          <h1>Enrol in a Course!</h1>
          <Link to="/your-courses" className="btn ttg-btn">
            Your Courses
          </Link>
          <Table className="new-enrolment-table mt-3">
            <thead>
              <tr>
                <th>Course</th>
                <th>Price</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {courseService.getCourses().map((course, i) => (
                <tr key={i}>
                  <td className="course-val">
                    {course.courseId !== 6 ? (
                      course.courseTitle
                    ) : (
                      <>
                        Food Safety Supervisor Hospitality &amp; Retail{" "}
                        <span style={{ color: "#1e2b5f", fontWeight: "700" }}>
                          (The NSW Food Safety Supervisor Certificate)
                        </span>
                      </>
                    )}
                    <br />({course.certificateCodesString})
                  </td>
                  <td className="cost-val">${course.courseCost}.00</td>
                  <td className="action-btns">
                    <Link
                      to={`/course-enrolment/${course.courseId}/step-1`}
                      className="btn ttg-btn"
                    >
                      Enrol Now
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </ContentSection>
    </>
  );
};

export default NewEnrolment;
