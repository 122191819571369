import React from "react";
import PropTypes from "prop-types";
import View from "./View";

const Carousel = ({ owlOptions, items, onlyImages }) => {
  const defaultOwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    margin: 10,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 5,
      },
    },
  };

  return (
    <View
      owlOptions={owlOptions ?? defaultOwlOptions}
      items={items}
      onlyImages={onlyImages}
    />
  );
};
Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  owlOptions: PropTypes.object,
  onlyImages: PropTypes.bool,
};

export default Carousel;
