import { connect } from "react-redux";
import AuthoriseAvs from "./authorise-avs";
import { selectAuthoriseAvs } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";

function mapStateToProps(state) {
  const authoriseAvs = selectAuthoriseAvs(state);

  return {
    authoriseAvs: authoriseAvs,
    isLoading: authoriseAvs.isLoading
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadForm: () => {
    dispatchProps.dispatch(actionCreators.onLoadForm(dispatchProps.history));
  },
  onSubmit: deviceCode => {
    dispatchProps.dispatch(
      actionCreators.onSubmit(deviceCode, dispatchProps.history)
    );
  }
});

const AuthoriseAvsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(AuthoriseAvs);

export default AuthoriseAvsContainer;
