/**
 * HttpInterceptor abstraction
 * To encapsulate http provider details like FETCH Api or Axios.
 *
 * Provider: Axios
 * - rcnet @solvable
 */

import axios from "axios";
import Config from "../../config";
import Auth from "../auth/auth";

const AUTH_LOGIN_URL = Config.authLoginUrl;
const AUTH_TOKEN_URL = Config.authTokenUrl;
const MAINTENANCE_PAGE_URL = Config.maintenancePageUrl;
const FORBIDDEN_PAGE_URL = Config.forbiddenPageUrl;

const HttpInterceptor = () => {
  const api = axios.create({ baseURL: Config.apiUrl });
  let authTokenRequestPromise;

  // By default interceptors were enabled, this will impact setAuthToken rendering it no effect at all.
  // Todo: Provide a way to enable /disable interceptor specially the auth alteration when setAuthToken method is called.
  const interceptorReqAuth = config => {
    const token = Auth.getToken();
    if (token != null) config.headers.Authorization = `Bearer ${token}`;

    return config;
  };

  // AuthTokenRequestPromise plays a very important role in this code base, it handles concurrent/parallel requests which puts into queue.
  // To prevent multiple refreshtoken request to server.
  const interceptorRespError = error => {
    const err = error.response;

    console.log(err);

    // Check if api server is down
    if (error.message === "Network Error" && err === undefined) {
      window.location.assign(MAINTENANCE_PAGE_URL);
      return Promise.reject(error);
    }

    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      console.log("Requesting new access token...");
      return _getAuthToken()
        .then(resp => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );
          err.config.__isRetryRequest = true;
          err.config.headers.Authorization = `Bearer ${resp.data.access_token}`;

          console.log("Successfully requested access token...");
          return axios(err.config);
        })
        .catch(e => {
          let ex = e.response;
          if (ex.status === 400 && ex.data && ex.data.invalid_refreshtoken) {
            console.log("Invalid refresh token!");
            console.log("Redirect to login.");

            Auth.removeToken();
            //Note: Bad practice to use location
            //To be fix in the future
            window.location.assign(AUTH_LOGIN_URL);

            //history.push({ pathname: '/auth/login' });
            //history.replace('/auth/login');
          }
          return Promise.reject(e);
        });
    } else if (err.status === 403) {
      window.location.assign(FORBIDDEN_PAGE_URL);
    } else {
      return Promise.reject(error);
    }
  };

  const _getAuthToken = () => {
    if (!authTokenRequestPromise) {
      const refreshTokenPayload = Auth.getRefreshTokenPayload(Config.clientId);

      authTokenRequestPromise = api.post(AUTH_TOKEN_URL, refreshTokenPayload);
      authTokenRequestPromise
        .then(_resetAuthTokenRequestPromise)
        .catch(_resetAuthTokenRequestPromise);
    }

    return authTokenRequestPromise;
  };

  const _resetAuthTokenRequestPromise = () => (authTokenRequestPromise = null);

  return {
    interceptorReqAuth,
    interceptorRespError
  };
};

export default HttpInterceptor;
