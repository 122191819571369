/**
 * Custom hook to observe and return the width of a referenced element.
 *
 * Uses ResizeObserver to detect changes in size and updates the width state accordingly.
 *
 * Dependencies: react (for state and layout effects), resize-observer-polyfill.
 * Adam Luck - Solvable - 2024-06-29
 */

import { useState, useLayoutEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export const useResizeObserve = (ref) => {
  const [width, setWidth] = useState(
    ref.current ? ref.current.clientWidth : window ? window.innerWidth : 0
  );

  useLayoutEffect(() => {
    const handleWidth = () => {
      if (ref.current) setWidth(ref.current.clientWidth);
    };

    const resizeObserver = new ResizeObserver(handleWidth);

    const currentRef = ref.current;

    if (currentRef) resizeObserver.observe(currentRef);

    return () => {
      if (currentRef) resizeObserver.unobserve(currentRef);
    };
  }, [ref]);

  return width;
};
