import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonFx from "../../../../components/Common/Button-Fx/Button-Fx";

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dialogs,
      toggleDeleteDialog,
      fields,
      onDelete,
      isProcessing
    } = this.props;
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={dialogs.showDeleteDialog}>
          <ModalHeader toggle={() => toggleDeleteDialog()}>
            Delete Use
          </ModalHeader>
          <ModalBody>
            <section>
              Are you sure you want to delete this user? Please note, this will
              delete any courses or vouchers attached to this user. This cannot
              be undone.
            </section>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() => {
                onDelete(fields);
              }}
              className="ttg-btn"
              isLoading={isProcessing}
            >
              Delete
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
