import HttpClient from "../../../coreLib/http/httpClient";
import DateService from "../../../services/date-service";

const NswReprintsService = () => {
  let httpClient = HttpClient();
  let dateService = DateService();

  const loadData = () => {
    return httpClient.get("/v1/admin/nsw/reprints");
  };

  const update = payload => {
    return httpClient.put(`/v1/admin/nsw/reprints/${payload.id}`, {
      nswCertificateNumber: payload.nswCertificateNumber,
      expressPostNumber: payload.expressPostNumber,
      dateSent: dateService.toIsoDate(payload.dateSent),
      status: payload.status
    });
  };

  return {
    loadData,
    update
  };
};

export default NswReprintsService;
