import { connect } from 'react-redux';
import ErrorModal from './Error-Modal';
import * as actionCreators from '../../../redux/system/system-action-creators';
import { selectErrorNotification } from '../../../redux/system/error-notification-reducer';

function mapStateToProps(state) {
    const stateData = selectErrorNotification(state);

    return {
        title: stateData.title,
        message: stateData.message,
        isModalOpen: stateData.isModalOpen,
    };
}

const mapDispatchToProps = (dispatch) => (
    {
        onButtonClick: (e) => {
            dispatch(actionCreators.hideErrorForm());
        },
    });

const ErrorModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorModal);

export default ErrorModalContainer;