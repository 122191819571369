/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import IdentityService from "../../../coreLib/auth/userIdentity";
import HttpService from "./service";
import { saveAs } from "file-saver";

// ----------------------------
//  Actions
// ----------------------------

export const toggleIsAwesome = () => {
  return (dispatch) => {
    dispatch(toggleIsAwesomeRequest());

    let random = Math.floor(Math.random() * 10 + 1);
    const success = random >= 5;

    if (success) {
      dispatch(toggleIsAwesomeSuccess());
      dispatch(addNotification("Success!!"));
    } else {
      dispatch(toggleIsAwesomeFailure());
      dispatch(addNotification("Failure!!", "error")); // error or warning
    }
  };
};

export const loadCourses = () => (dispatch) => {
  let userId = IdentityService().getUserId();
  dispatch(loadCoursesRequest());
  HttpService()
    .getCourses(userId)
    .then((resp) => {
      dispatch(loadCoursesRequestSuccess(resp.data));
    })
    .catch((err) => {
      dispatch(loadCoursesRequestFailure());
      dispatch(
        addNotification("There was an issue loading your courses.", "warning")
      );
    });
};

export const downloadTaxInvoice = (invoiceId) => (dispatch) => {
  dispatch(downloadTaxInvoiceRequest());
  HttpService()
    .downloadTaxInvoice(invoiceId)
    .then((resp) => {
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "taxinvoice.pdf");
      dispatch(downloadTaxInvoiceSuccess());
      dispatch(addNotification("Your tax invoice has been downloaded."));
    })
    .catch(() => {
      dispatch(downloadTaxInvoiceFailure());
      dispatch(
        addNotification(
          "There was an issue downloading your tax invoice.",
          "warning"
        )
      );
    });
};

const downloadTaxInvoiceRequest = () => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST,
});

const downloadTaxInvoiceSuccess = (payload) => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS,
});

const downloadTaxInvoiceFailure = () => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE,
});

export const downloadCertificate = (courseId) => (dispatch) => {
  dispatch(downloadCertificateRequest());
  HttpService()
    .downloadCertificate(courseId)
    .then((resp) => {
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "certificate.pdf");
      dispatch(downloadCertificateSuccess());
      dispatch(addNotification("Your certificate has been downloaded."));
    })
    .catch(() => {
      dispatch(downloadCertificateFailure());
      dispatch(
        addNotification(
          "There was an issue downloading your certificate.",
          "warning"
        )
      );
    });
};

export const downloadThirdPartyForm = (courseId) => (dispatch) => {
  dispatch(downloadTpfRequest());
  HttpService()
    .downloadThirdPartyForm(courseId)
    .then((resp) => {
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "third-party-form.pdf");
      dispatch(downloadTpfSuccess());
      dispatch(addNotification("Your third party form has been downloaded."));
    })
    .catch(() => {
      dispatch(downloadTpfFailure());
      dispatch(
        addNotification(
          "There was an issue downloading your third party form.",
          "warning"
        )
      );
    });
};

const downloadCertificateRequest = () => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_CERTIFICATE_REQUEST,
});

const downloadCertificateSuccess = (payload) => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_CERTIFICATE_SUCCESS,
});

const downloadCertificateFailure = () => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_CERTIFICATE_FAILURE,
});

const downloadTpfRequest = () => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_TPF_REQUEST,
});

const downloadTpfSuccess = (payload) => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_TPF_SUCCESS,
});

const downloadTpfFailure = () => ({
  type: ActionTypes.YOUR_COURSES_DOWNLOAD_TPF_FAILURE,
});

const loadCoursesRequest = () => ({
  type: ActionTypes.YOUR_COURSES_LOAD_REQUEST,
});

const loadCoursesRequestSuccess = (payload) => ({
  type: ActionTypes.YOUR_COURSES_LOAD_SUCCESS,
  payload,
});

const loadCoursesRequestFailure = () => ({
  type: ActionTypes.YOUR_COURSES_LOAD_FAILURE,
});

const toggleIsAwesomeRequest = () => ({
  type: ActionTypes.YOUR_COURSES_TOGGLE_IS_AWESOME_REQUEST,
});
const toggleIsAwesomeSuccess = () => ({
  type: ActionTypes.YOUR_COURSES_TOGGLE_IS_AWESOME_SUCCESS,
});
const toggleIsAwesomeFailure = () => ({
  type: ActionTypes.YOUR_COURSES_TOGGLE_IS_AWESOME_FAILURE,
});

// // Async
// export const toggleIsAwesome = data => {
//   return dispatch => {
//     const myService = MyService();

//     dispatch(toggleIsAwesomeRequest());

//     myService.doSomething(data).then(resp => {
//       dispatch(toggleIsAwesomeSuccess(resp));
//     });
//   };
// };

// const toggleIsAwesomeRequest = () => ({
//   type: ActionTypes.YOUR_COURSES_TOGGLE_IS_AWESOME_REQUEST
// });
// const toggleIsAwesomeSuccess = data => ({
//   type: ActionTypes.YOUR_COURSES_TOGGLE_IS_AWESOME_SUCCESS,
//   payload: data
// });
// const toggleIsAwesomeFailure = () => ({
//   type: ActionTypes.YOUR_COURSES_TOGGLE_IS_AWESOME_FAILURE
// });

// ----------------------------
//  Form Actions
// ----------------------------

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.YOUR_COURSES_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});
