import { connect } from "react-redux";
import ForgotPassword from "./Forgot-Password";
import { withRouter } from "react-router-dom";
import { selectForgotPassword } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import { addNotification } from "../../redux/system/system-action-creators";

function mapStateToProps(state) {
  const stateData = selectForgotPassword(state);

  return {
    fields: stateData.fields,
    fieldErrors: stateData.fieldErrors,
    isLoading: stateData.isLoading,
    isSubmitted: stateData.isSubmitted,
    submittedTo: stateData.submittedTo
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: error => dispatch(addNotification(error, "Error")),
  dispatch: dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onResetPassword: () => {
    if (validate(stateProps)) return;
    dispatchProps.dispatch(
      actionCreators.onRequestResetPassword(stateProps.fields.email)
    );
  },
  validate: () => {
    return validate(stateProps);
  }
});

const validate = stateProps => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);
  const fieldsToCheck = ["email"];

  for (var fld in fieldsToCheck) {
    if (!fields[fieldsToCheck[fld]]) return true;
  }

  if (errMessages.length > 0) return true;

  return false;
};

const ForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(ForgotPassword);

export default withRouter(ForgotPasswordContainer);
