import React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "components/Common/content-section";
import LinkButton from "components/link-button/link-button";
import CertificateInfo from "../certificate-info/certificate-info";
import "./business-info.scss";

const BusinessInfo = () => {
  return (
    <ContentSection bgClass="veggies-bg alt-off-white">
      <CertificateInfo />
      <div className="business-info">
        <Row className="d-flex align-items-center">
          <Col md={6}>
            <h1>Are you a Business or Organisation?</h1>

            <h2 className="mt-4">Buy vouchers for your staff...</h2>
            <p>
              We take the hassle out of organising your staff Food Safety
              training. Our online Business portal makes it quick and easy for
              you to purchase Food Safety Courses for your staff or volunteers.
            </p>

            <LinkButton
              to="/online-food-safety-training-courses-for-your-business/"
              className="ttg-btn ttg-lg-btn ttg-orange-btn"
              btnText="Get Started"
            />
          </Col>
          <Col md={6}>
            <img
              src="https://solvable.azureedge.net/train-to-gain/images/fruit-bowl.webp"
              alt=""
            />
          </Col>
        </Row>
      </div>
    </ContentSection>
  );
};

export default BusinessInfo;
