import SolvableDataTable from "../../../components/solvable-datatable/solvable-datatable";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import React, { Component } from "react";
import EditDialog from "./Modals/edit";
import DeleteDialog from "./Modals/delete";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
    };
    this.actionButtonHandler = this.actionButtonHandler.bind(this);
  }

  onSettingsChanged = (settings) => {
    this.setState({ settings });
  };

  columns = [
    {
      Header: "First Name",
      accessor: "firstName",
      searchable: true,
    },
    {
      accessor: "userId",
      show: false,
    },
    {
      accessor: "loginAsUserToken",
      show: false,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      searchable: true,
    },
    {
      Header: "Email",
      accessor: "email",
      searchable: true,
    },
    {
      Header: "Active",
      accessor: "active",
      searchable: true,
    },
    {
      Header: "User Type",
      accessor: "userType",
      searchable: true,
    },
    {
      Header: "Date Registered",
      accessor: "registeredDateTime",
      searchable: true,
    },
    {
      Header: "Last Login",
      accessor: "lastLoginDateTime",
      searchable: true,
    },
  ];

  actionButtonHandler = (evt) => {
    const { toggleEditDialog, toggleDeleteDialog } = this.props;
    switch (evt.type) {
      case "button1":
        toggleEditDialog(evt.data);
        break;
      case "button2":
        toggleDeleteDialog(evt.data);
        break;
      case "button3":
        this.props.history.push(`/Admin/User-Courses/${evt.data.userId}`);
        break;
    }
  };

  render() {
    const {
      toggleEditDialog,
      toggleDeleteDialog,
      onFieldError,
      onDelete,
      onEdit,
      dataTable,
      dialog,
      onSettingsChanged,
      onDialogInputChange,
    } = this.props;
    const { data, isProcessing, settings } = dataTable;
    const { dialogs, fields, isProcessing: isDialogProcessing } = dialog;
    return (
      <div>
        <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
          <EditDialog
            dialogs={dialogs}
            toggleEditDialog={toggleEditDialog}
            fields={fields}
            onEdit={onEdit}
            isProcessing={isDialogProcessing}
            onFieldError={onFieldError}
            onDialogInputChange={onDialogInputChange}
          />
          <DeleteDialog
            dialogs={dialogs}
            onDelete={onDelete}
            isProcessing={isDialogProcessing}
            toggleDeleteDialog={toggleDeleteDialog}
            fields={fields}
          />
          <SolvableDataTable
            defaultPageSize={50}
            columns={this.columns}
            actionButtons={{
              type: "customButton",
              containerWidth: 170,
              custom: [
                {
                  target: "button1",
                  icon: "fas fa-edit",
                  tooltip: "Edit User",
                },
                {
                  target: "button2",
                  icon: "fas fa-trash-alt",
                  tooltip: "Delete User",
                },
                {
                  target: "button3",
                  icon: "fas fa-list-alt",
                  tooltip: "View Course Enrolments",
                },
                {
                  target: "button4",
                  icon: "fas fa-address-card",
                  tooltip:
                    "You can right click “Open link in incognito window” to avoid having to log out and in again",
                  to: (row) => {
                    return `/login/${row.userId}/${row.loginAsUserToken}`;
                  },
                },
              ],
            }}
            keepSettings={true}
            stateSettings={settings}
            onSettingsChanged={onSettingsChanged}
            onActionButtonClick={this.actionButtonHandler}
            data={data}
          />
        </BlockUiFx>
      </div>
    );
  }
}

export default DataTable;
