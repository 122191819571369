import HttpClient from "../../../../coreLib//http/httpClient";
import moment from "moment";

const Service = () => {
  let httpClient = HttpClient();

  const loadCourses = payload => {
    let model = {
      courseIds: payload.courseIds.map(course =>
        typeof course === "object" ? course.value : course
      ),
      startDate: moment(payload.startDate),
      endDate: moment(payload.endDate)
    };
    return httpClient.post("v1/admin/users/courses", model);
  };

  const loadSpecificUserCourses = id => {
    return httpClient.get(`v1/admin/users/courses/${id}`);
  };

  const deleteCourse = id => {
    return httpClient.delete(`v1/admin/users/course/${id}`);
  };

  const downloadTaxInvoice = invoiceId => {
    return httpClient.get(`v1/userCourse/pdf/tax-invoice/${invoiceId}`, {
      responseType: "blob"
    });
  };

  return {
    loadCourses,
    loadSpecificUserCourses,
    deleteCourse,
    downloadTaxInvoice
  };
};

export default Service;
