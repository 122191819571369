import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Row,
  Col,
  Collapse,
  Form,
  FormGroup
} from "reactstrap";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import { reportTypes } from "../../../constants";
import DatePickerField from "../../../components/Common/Fields/Date-Picker-Field";
import formHandler from "../../../components/Common/Fields/form-handler";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import SelectField from "../../../components/Common/Fields/Select-Field";
import KeyboardEventHandler from "react-keyboard-event-handler";
import orderBy from "lodash/orderBy";

const validateSelect = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  return false;
};

class FilterSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { reportType, startDate, endDate } = this.props.fields;
    const {
      onInputChange,
      onFieldError,
      loadRequest,
      isProcessing
    } = this.props;
    const form = formHandler();
    return (
      <div className="mb-4">
        <Card>
          <CardHeader onClick={this.toggle}>
            <div className="clearfix">
              <span className="float-left">Filter Reports</span>
            </div>
          </CardHeader>
          <Collapse isOpen={true}>
            <CardBody>
              <Form className="ttg-form">
                <FormGroup>
                  <Row>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <label>Report Types</label>
                        <SelectField
                          name="reportType"
                          ref={form.add}
                          validate={val => validateSelect("Report type", val)}
                          value={reportType}
                          onFieldError={onFieldError}
                          onChange={onInputChange}
                        >
                          <option value={-1}>- Select -</option>;
                          {orderBy(reportTypes, ["order"], ["asc"]).map(
                            state => {
                              return (
                                <option key={state.value} value={state.value}>
                                  {state.label}
                                </option>
                              );
                            }
                          )}
                        </SelectField>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <FormGroup>
                        <label>Start Date</label>
                        <div>
                          <DatePickerField
                            name="startDate"
                            value={startDate}
                            minDate={new Date(1930, 1, 1)}
                            maxDate={new Date()}
                            onChange={onInputChange}
                            validate={val =>
                              validators.required("Start Date", val)
                            }
                            onFieldError={onFieldError}
                            ref={form.add}
                          ></DatePickerField>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <FormGroup>
                        <label>End Date</label>
                        <div>
                          <DatePickerField
                            name="endDate"
                            value={endDate}
                            minDate={new Date(1930, 1, 1)}
                            maxDate={new Date()}
                            onChange={onInputChange}
                            validate={val =>
                              validators.required("End Date", val)
                            }
                            onFieldError={onFieldError}
                            ref={form.add}
                          ></DatePickerField>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
            <CardFooter>
              <ButtonFx
                type="submit"
                onClick={() => {
                  form.validate({
                    validFn: () => loadRequest(this.props.fields)
                  });
                }}
                className="ttg-btn"
                isLoading={isProcessing}
              >
                Filter
              </ButtonFx>
            </CardFooter>
          </Collapse>
        </Card>
        <KeyboardEventHandler
          handleKeys={["enter"]}
          onKeyEvent={() =>
            form.validate({
              validFn: () => loadRequest(this.props.fields)
            })
          }
        />
      </div>
    );
  }
}

export default FilterSection;
