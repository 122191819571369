import HttpClient from "../../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const load = () => {
    return httpClient.get("v1/admin/promocodes");
  };

  const deletePromoCode = id => {
    return httpClient.delete(`v1/admin/promocodes/${id}`);
  };

  const addPromoCode = payload => {
    let model = {
      discountCode: payload.discountCode,
      discountPercentage: payload.discountPercentage / 100,
      discountAmount: payload.discountAmount,
      expiryDate: payload.expiryDate,
      isOneUseOnly: payload.isOneUseOnly
    };
    return httpClient.post(`v1/admin/promocodes/`, model);
  };

  const editPromoCode = payload => {
    let model = {
      discountCode: payload.discountCode,
      discountPercentage: payload.discountPercentage / 100,
      discountAmount: payload.discountAmount,
      expiryDate: payload.expiryDate,
      isOneUseOnly:
        payload.isOneUseOnly === true || payload.isOneUseOnly === "true"
          ? true
          : false
    };
    return httpClient.put(`v1/admin/promocodes/${payload.id}`, model);
  };

  return {
    load,
    deletePromoCode,
    addPromoCode,
    editPromoCode
  };
};

export default Service;
