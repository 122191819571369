/**
 * Third Party Form Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  courseId: "",
  courseName: "",
  certificateCodes: [],
  unitOfCompetencies: [],
  studentFirstName: "",
  studentLastName: "",
  tasks: [],
  accessToken: "",
  showForm: false,
  thirdPartyFormAdditionalContent: null,
  thirdPartyFormVersion: 0,

  fields: {
    observerFirstName: "",
    observerLastName: "",
    observerEmail: "",
    studentPerformanceSatisfactory: "",
    additionalComments: "",
    confirmThirdPartyDeclaration: "false",
    signature: "",
  },
  fieldErrors: {},

  // For confirmation
  showConfirmModal: false,

  // For messaging Dialog
  showMessageDialog: false,
  showMessageDialogMessage: "",

  // For the Sample Form link
  isSampleForm: false,

  isLoading: false,
  isLoadingObserverButton: false, // Update details button loading
  isLoadingObserverSection: false, // Observer section
  isLoadingForSaveProgress: false, // For save progress
  isLoadingForSubmit: false, // For submit button
  error: null,
};

function ThirdPartyFormReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.THIRD_PARTY_FORM_LOAD_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.THIRD_PARTY_FORM_LOAD_FORM_SUCCESS:
      let taskResProps = {};
      // Load responses
      if (action.payload.thirdPartyFormResponses.taskResponses) {
        const { taskResponses } = action.payload.thirdPartyFormResponses;

        action.payload.tasks.forEach((o) => {
          let responseFindResult = taskResponses.find(
            (f) => f.taskId == o.taskId
          );

          let response = null;
          if (responseFindResult && responseFindResult.response !== null)
            response = responseFindResult.response ? "true" : "false";

          taskResProps[`task-${o.taskId}`] = response;
          taskResProps[`task-details-${o.taskId}`] = responseFindResult
            ? responseFindResult.comments
            : null;
        });
      }

      const {
        courseId,
        studentFirstName,
        studentLastName,
        accessToken,
        observerFirstName,
        observerLastName,
        observerEmail,
        studentPerformanceSatisfactory,
        additionalComments,
        confirmThirdPartyDeclaration,
        signature,
        thirdPartyFormVersion,
        isSampleForm,
        showForm,
        thirdPartyFormAdditionalContent,
      } = action.payload.thirdPartyFormResponses;

      return {
        ...state,
        courseId: courseId,
        courseName: action.payload.courseName,
        certificateCodes: action.payload.certificateCodes,
        unitOfCompetencies: action.payload.unitOfCompetencies,
        tasks: action.payload.tasks.filter(
          (x) => x.version === thirdPartyFormVersion
        ),
        studentFirstName: studentFirstName,
        studentLastName: studentLastName,
        accessToken: accessToken,
        isLoading: false,
        isSampleForm: isSampleForm,
        showForm: showForm,
        thirdPartyFormAdditionalContent: thirdPartyFormAdditionalContent,
        thirdPartyFormVersion: thirdPartyFormVersion,
        fields: {
          ...state.fields,
          observerFirstName: observerFirstName,
          observerLastName: observerLastName,
          observerEmail: observerEmail,
          studentPerformanceSatisfactory:
            studentPerformanceSatisfactory === null
              ? null
              : studentPerformanceSatisfactory
              ? "1"
              : "0",
          additionalComments: additionalComments,
          confirmThirdPartyDeclaration: "" + confirmThirdPartyDeclaration, // the "" + is required to set the value as text
          signature: signature,
          ...taskResProps,
        },
      };

    case ActionTypes.THIRD_PARTY_FORM_LOAD_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.THIRD_PARTY_FORM_OBSERVER_UPDATE_REQUEST:
      return {
        ...state,
        isLoadingObserverButton: true,
      };

    case ActionTypes.THIRD_PARTY_FORM_OBSERVER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingObserverButton: false,
      };

    case ActionTypes.THIRD_PARTY_FORM_OBSERVER_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingObserverButton: false,
        error: action.payload,
      };

    case ActionTypes.THIRD_PARTY_SHOW_CONFIRM_EMAIL_OBSERVER:
      return {
        ...state,
        showConfirmModal: true,
      };

    case ActionTypes.THIRD_PARTY_HIDE_CONFIRM_EMAIL_OBSERVER:
      return {
        ...state,
        showConfirmModal: false,
      };

    case ActionTypes.THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_REQUEST:
      return {
        ...state,
        isLoadingObserverSection: true,
        showConfirmModal: false,
      };

    case ActionTypes.THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_SUCCESS:
      return {
        ...state,
        isLoadingObserverSection: false,
      };

    case ActionTypes.THIRD_PARTY_SEND_EMAIL_TO_OBSERVER_FAILURE:
      return {
        ...state,
        isLoadingObserverSection: false,
        error: action.payload,
      };

    case ActionTypes.THIRD_PARTY_FORM_SHOW_MESSAGE_DIALOG:
      return {
        ...state,
        showMessageDialogMessage: action.payload,
        showMessageDialog: true,
      };

    case ActionTypes.THIRD_PARTY_FORM_HIDE_MESSAGE_DIALOG:
      return {
        ...state,
        showMessageDialog: false,
      };

    case ActionTypes.THIRD_PARTY_FORM_SAVE_PROGRESS_REQUEST:
      if (!action.payload.submitCaller) {
        return {
          ...state,
          isLoadingForSaveProgress: true,
        };
      }

      return {
        ...state,
        isLoadingForSubmit: true,
      };

    case ActionTypes.THIRD_PARTY_FORM_SAVE_PROGRESS_SUCCESS:
      if (!action.payload.submitCaller) {
        return {
          ...state,
          isLoadingForSaveProgress: false,
        };
      }
      return {
        ...state,
        isLoadingForSubmit: false,
      };

    case ActionTypes.THIRD_PARTY_FORM_SAVE_PROGRESS_FAILURE:
      if (!action.payload.submitCaller) {
        return {
          ...state,
          isLoadingForSaveProgress: false,
          error: action.payload.error,
        };
      }

      return {
        ...state,
        isLoadingForSubmit: false,
        error: action.payload.error,
      };
    // ----------------------------
    //  Form Action Types
    // ----------------------------

    case ActionTypes.THIRD_PARTY_FORM_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });

      return stateResult;

    default:
      return state;
  }
}

export default ThirdPartyFormReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectThirdPartyForm = (state) => state.thirdPartyForm;
