import React from "react";
import { Input, Label } from "reactstrap";

const CourseQuizQuestionDropDown = props => {
  const { options, onInputChange } = props;
  return (
    <div className="my-3">
      <Label className="form-label" for="quizQuestion">
        {options.title}
      </Label>
      <Input
        type="select"
        name="quizQuestion"
        onChange={e =>
          onInputChange({
            title: options.title,
            selectedValue: e.target.value,
            selectedIndex: options.items.findIndex(o => o === e.target.value)
          })
        }
      >
        <option value="-1">- Select -</option>
        {options.items.map((item, i) => (
          <option key={i} value={item}>
            {item}
          </option>
        ))}
      </Input>
    </div>
  );
};

export default CourseQuizQuestionDropDown;
