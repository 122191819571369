import React from "react";
import { Link } from "react-router-dom";
export const thirdParty = [
  {
    title: "Why do I need a third-party observer?",
    content: (
      <>
        Because we offer online training, we need to prove your practical skills
        in Food Safety and we do this by an online third-party checklist form
        that is emailed to your nominated observer. There are some things we
        can&rsquo;t see you doing through your computer – such as washing your
        hands. This is a short form with a checklist.
        <br />
        <br />
        Observer reports are a fundamental part of the assessment process at
        Food Safety Education. It&rsquo;s a mandatory requirement for our
        accredited Food Safety courses that students are observed performing
        certain tasks.
      </>
    ),
  },
  {
    title: "Who can be my third-party observer?",
    content: (
      <>
        Your observer checklist can be submitted by anyone who works in the food
        industry who has observed you working with food over a period of time.
        It could also be someone you work with currently, have worked with in
        the past, or someone from a community organisation where you have
        volunteered your time. Your observer must have sufficient skills &amp;
        knowledge and is in a current supervisory role. Many places are happy to
        have volunteers help out in return for observing you do the tasks listed
        on the observer report. Your Observer can be:
        <br />
        <br />
        <ul className="my-0">
          <li>Past or Present Supervisor/Employer/Manager</li>
          <li>Past or Present Volunteer Supervisor/Manager</li>
        </ul>
      </>
    ),
  },
  {
    title: "What happens after I nominate my observer?",
    content: (
      <>
        Once you have entered your observers email into the enrolment form, a
        system generated email will be sent to your observer with the
        third-party checklist. The third-party checklist needs to be correctly
        filled out and then submitted back to us online.
        <br />
        <br />
        It&rsquo;s important to be in contact with your observer straight away
        to let them know that you have nominated them for this task and request
        that they return the completed checklist asap.
      </>
    ),
  },
  {
    title: "Here is an example of a Third Party Form",
    content: (
      <>
        <p style={{ fontSize: "1.2em" }}>
          <Link to="/Third-Party-Form/Sample-6/">
            <strong>
              Click here to see an example of the Third Party Form
            </strong>
          </Link>
        </p>
      </>
    ),
  },
  {
    title: "What are the assessments?",
    content: (
      <>
        There are multiple choice questions and open text answers that are
        required to be completed to gain competency. Case studies are included
        for the NSW courses only.
      </>
    ),
  },
];
