import { connect } from "react-redux";
import { selectLoginData } from "../../../redux/system/login-reducer";
import AppWrapper from "./App-Wrapper";

function mapStateToProps(state) {
  const loginData = selectLoginData(state);

  return {
    loginData: loginData
  };
}

const AppWrapperContainer = connect(mapStateToProps)(AppWrapper);

export default AppWrapperContainer;
