/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  CONTACT_BOX_SUBMIT_REQUEST: "CONTACT_BOX_SUBMIT_REQUEST",
  CONTACT_BOX_SUBMIT_SUCCESS: "CONTACT_BOX_SUBMIT_SUCCESS",
  CONTACT_BOX_SUBMIT_FAILURE: "CONTACT_BOX_SUBMIT_FAILURE",

  // Form Actions
  CONTACT_BOX_FORM_INPUT_CHANGE: "CONTACT_BOX_FORM_INPUT_CHANGE"
};
