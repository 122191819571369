import React, { Component } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import "./Fields.scss";
import debounce from "lodash/debounce";

const EVENT_DEBOUNCE = 2000;

class ReactSelectField extends Component {
  static propTypes = {
    keepSettings: PropTypes.bool,
    name: PropTypes.string.isRequired,
    showSelectAll: PropTypes.bool,
    selectAllByDefault: PropTypes.bool,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFieldError: PropTypes.func,
    enableFieldColorError: PropTypes.bool,
    enableFieldErrorMessage: PropTypes.bool,
    enableFieldErrorNotification: PropTypes.bool
  };

  static defaultProps = {
    keepSettings: false,
    showSelectAll: false,
    selectAllByDefault: false,
    enableFieldColorError: true,
    enableFieldErrorMessage: false,
    enableFieldErrorNotification: true,
    onFieldError: () => {}
  };

  state = {
    error: false,
    options: this.props.options,
    value: this.props.keepSettings
      ? this.props.value.length > 0
        ? this.props.value
        : this.props.options
      : this.props.selectAllByDefault
      ? this.props.options
      : null
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.debouncedCallback = debounce(
      error => this.fireFieldErrorEvent(error),
      EVENT_DEBOUNCE
    );
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.selectAllByDefault && !this.props.keepSettings) {
      this.onChange(this.state.options);
    } else {
      this.onChange(this.state.value);
    }

    //Option to add 'Select All' option item.
    if (this.props.showSelectAll) {
      let additionalOption = { value: "*", label: "Select All" };
      this.setState({
        ...this.state,
        options: [additionalOption, ...this.state.options]
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    return { defaultValue: props.defaultValue };
  }

  onChange = value => {
    this._detectChange(value);
  };

  onKeyPress = evt => {
    if (evt.key === "Enter" && this.props.onKeyPress) {
      this.props.onKeyPress();
    }
  };

  onBlur = () => {
    this._validateField();
  };

  _detectChange = value => {
    const name = this.props.name;
    const error = this.props.validate ? this.props.validate(value) : false;

    if (error) this.debouncedCallback(value, error);

    if (this.props.showSelectAll && value) {
      value = value.find(v => v.value === "*")
        ? this.state.options.filter(v => v.value !== "*")
        : value;
    }

    this.setState({ value, error });
    this.props.onChange({ name, value, error });
    return error;
  };

  fireFieldErrorEvent = error => this.props.onFieldError(error);

  // Note: Internal function, used by this component
  _validateField = () => {
    if (!this._isMounted) {
      return;
    }
    let error = this._detectChange(this.state.value);
    if (error) this.debouncedCallback(null, error);
    return { name: this.props.name, error: error };
  };

  _isValidComponentInstance = () => {
    return this._isMounted;
  };

  render() {
    const {
      validate,
      enableFieldColorError,
      enableFieldErrorMessage,
      enableFieldErrorNotification,
      onFieldError,
      ...other
    } = this.props;

    return (
      <div className={this.state.error ? "field-error-react-select" : ""}>
        <Select
          {...other}
          value={this.state.value}
          options={this.state.options}
          onChange={this.onChange}
          isMulti
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
        />
      </div>
    );
  }
}

export default ReactSelectField;
