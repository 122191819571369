import React from "react";
import ContentCollapsiblePanel from "components/Common/content-collapsible-panel";

const FoodSafetySupervisorCertificateHealthAndCommunity = () => {
  return (
    <>
      <ContentCollapsiblePanel
        openSection={-1}
        sections={[
          {
            title: "About the Food Safety Supervisor",
            content: (
              <>
                <p>
                  When you enrol with us, you have access to everything you need
                  to be accredited as a qualified Food Safety Supervisor (FSS)
                  in Australia.
                </p>
                <p>
                  <em>
                    *If you are in{" "}
                    <strong>
                      <u>Childcare</u>
                    </strong>{" "}
                    in NSW, please do the NSW specific training. Not this one!
                  </em>
                </p>
                <p>
                  <em>
                    As NSW Aged Care providers are <u>licensed</u>, the required
                    training comes under their Food Safety Plan. If you reside
                    in NSW and work with vulnerable persons, we strongly suggest
                    talking to your Food Safety auditor or ring the NSW Food
                    Authority before you begin your training. Ph:{" "}
                    <a href="tel:1300552406">1300&nbsp;552&nbsp;406</a>
                  </em>
                </p>
                <p>
                  Under FSANZ Standard 3.2.2A, all Australian businesses that
                  serve food must have one or more qualified Food Safety
                  Supervisors. A Food Safety Supervisor is someone who has the
                  appropriate Food Safety training to supervise Food Safety in a
                  business. Food Safety Supervisors are mainly responsible for:
                </p>
                <ul>
                  <li>Supervising Food Handling Staff</li>
                  <li>Maintaining the Food Safety Program</li>
                  <li>
                    Ensuring Safe Food Handling Practices across the business
                  </li>
                </ul>
              </>
            ),
          },
          {
            title: "Is this course for me?",
            content: (
              <>
                <p>
                  Either way, it is a requirement that a Food Safety Supervisor
                  is appointed to the food business. Depending on how large the
                  food operation is, or how available the FSS can be, will vary
                  on how many FSS there will be.
                </p>
                <p>This qualification needs to be renewed every 5 years.</p>
                <p>
                  Under Standard 3.2.2A - category one and two businesses must:
                </p>
                <ul>
                  <li>
                    Appoint a certified Food Safety Supervisor (FSS) before
                    engaging in a &lsquo;prescribed activity&rsquo;
                  </li>
                  <li>
                    The certificate must be from either a registered training
                    organisation or an organisation recognised by the relevant
                    food regulator, THAT&rsquo;S US!
                  </li>
                  <li>
                    The certificate must have been obtained within the past 5
                    years
                  </li>
                  <li>
                    Ensure that the FSS is reasonably available to advise and
                    supervise each food handler engaged in that prescribed
                    activity.
                  </li>
                </ul>
                <p>
                  Prescribed activities involve handling unpackaged, potentially
                  hazardous foods that are ready to eat, which are high risk.
                </p>
              </>
            ),
          },
          {
            title: "Who should be a FSS?",
            content: (
              <>
                <ul>
                  <li>
                    The FSS should be in a position to oversee food handling and
                    be involved in the day-to-day food handling operations of
                    the food business.
                  </li>
                  <li>
                    They must be &lsquo;reasonably available&rsquo; as a point
                    of contact for food handlers and authorised officers.
                  </li>
                  <li>
                    &lsquo;Reasonably available&rsquo; means the FSS works
                    onsite and oversees food handling of high-risk unpackaged
                    foods, or can be easily contacted (e.g. by phone).
                  </li>
                  <li>
                    It is not considered reasonable for a business to appoint
                    someone as a FSS who does not regularly handle food as part
                    of their normal duties or is never onsite at a food
                    business.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title: "Role of the FSS",
            content: (
              <>
                <p>
                  The FSS makes sure the business is handling food safely, so if
                  something goes wrong, food that could be unsafe to eat is not
                  processed further or served to consumers.
                </p>
                <p>The role of the FSS is to:</p>
                <ul>
                  <li>
                    Ensure all your food handling staff complete the necessary
                    Food Safety training
                  </li>
                  <li>
                    Make sure Food Safety risks are managed and issues are
                    prevented or corrected
                  </li>
                  <li>
                    Instruct staff, review and update business procedures, and
                    inspect premises and food handling operations
                  </li>
                  <li>
                    Share their Food Safety knowledge with other food handlers
                  </li>
                  <li>
                    Foster a positive Food Safety culture, so Food Safety is a
                    top priority in &lsquo;the way we do things here&rsquo;
                  </li>
                </ul>
                <p>
                  The business needs to make sure the FSS is able to fulfil
                  these duties.
                </p>
                <p>
                  <img
                    src="/images/food-safety-supervisor-hospitality-and-retail-3-tools.png"
                    alt="Standard 3.2.2A of the Australia New Zealand Food Standards Code introduces 3 mandatory Food Safety management tools for food businesses, depending on their food handling activities"
                  />
                </p>
              </>
            ),
          },
          {
            title: "Course Summary",
            content: (
              <>
                <p>
                  These three units make up the Food Safety Supervisor
                  Certificate for the Health and Communities sector. This course
                  is designed for all people handling and supervising food in
                  the Community and Health Service industries.
                </p>
                <p>
                  With this Food Safety training, you can better understand Food
                  Safety principles and regulations and ensure the lowest
                  possible risk to this vulnerable population.
                </p>
                <p>
                  Our course is designed to be as flexible and straightforward
                  as possible! We pride ourselves on our learning platform which
                  is filled with many short DVD's and visual images making this
                  training course a fast and effective way to obtain your
                  Nationally Accredited Certificate.
                </p>
                <p>
                  All our training courses allow students to complete their
                  course in their own time. That's right, there are no lockouts
                  and no penalties if you get any answers wrong!
                </p>
                <p>
                  On completion of your Food Safety training, we also offer you
                  a downloadable course workbook. This online course material is
                  always updated so we encourage you to log back in any time
                  after your training and obtain any new Food Safety
                  information. This is especially helpful when training up new
                  staff and when council come around!
                </p>
                <p>
                  This Food Safety Supervisor course prepares you with an
                  extensive understanding of the role a Food Safety Supervisor
                  must play within the workplace.
                </p>
              </>
            ),
          },
          {
            title: "State Food Safety Legislation",
            content: (
              <>
                <p>
                  <strong>
                    Please click for more information on the link below for your
                    States or Territories Food Safety Legislation
                  </strong>
                </p>
                <p>
                  <a
                    href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Queensland Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Victorian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.health.act.gov.au/businesses/food-safety-regulation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ACT Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    South Australian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.foodauthority.nsw.gov.au/ip/legislation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NSW Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Western Australia Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tasmanian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Northern Territory Food Safety Legislation
                  </a>
                </p>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FoodSafetySupervisorCertificateHealthAndCommunity;
