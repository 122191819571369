import HttpClient from "../../../../coreLib/http/httpClient";

const ApiService = () => {
  let httpClient = HttpClient();

  const submitChanges = (payload) => {
    return httpClient.post("/v1/avetmiss/validation/address-fixes", payload);
  };

  return {
    submitChanges,
  };
};

export default ApiService;
