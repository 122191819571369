import React from "react";
import LinkButton from "components/link-button/link-button";
import WhyTrainWithUsPoints from "features/why-train-with-us-points/why-train-with-us-points";
import ContentSection from "components/Common/content-section";

export const aboutPages = [
  {
    pageName: "about-train-to-gain",
    title:
      "Food Safety Training with Train to Gain Pty Ltd T/A Food Safety Education",
    description: "",
    noContentSection: true,
    content: (
      <div className="off-white flex-100">
        <ContentSection bgClass="veggies-bg">
          <div className="ttg-container">
            <h1>
              Food Safety Training with Train to Gain Pty Ltd T/A Food Safety
              Education
            </h1>
            <p className="fsb">
              Train to Gain Pty Ltd T/A Food Safety Education is a Nationally
              Accredited Registered Training Organisation (RTO provider #
              22361). We have over 30 years of Hospitality and Retail experience
              and are 100% Aussie trained and owned.
            </p>
            <p></p>
            <p>
              Our mission is to make your training experience as convenient and
              straightforward as possible! Our Food Safety training covers
              industries, in the Child Care, Aged Care, Hospitality and Retail
              sector. This can all be achieved with the convenience and comfort
              of completing your training in your own home or workplace, 100%
              online.
            </p>
            <p>
              We have scrubbed floors, shaken margaritas and flipped burgers! So
              we understand how time poor one can be in the food service
              industry. This is why we moved our face to face training model to
              online training so that we can provide accredited training to
              personnel like you, anywhere, anytime!
            </p>
            <p>
              We are a preferred registered training provider with companies
              such as Nando&#39;s, Mission Australia, Crust Pizza, United
              Petroleum, Sodexo, Costco and Spud Bar just to name a few.
            </p>

            <p>
              <LinkButton
                to="/online-food-safety-training-courses/"
                className="ttg-btn ttg-orange-outline-btn"
                btnText="Start Your Food Safety Training Now!"
              />
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://training.gov.au/Organisation/Details/043f189e-b557-4308-bf62-fa8656f30437"
              >
                Click here to see our Accreditation to Provide Food Safety
                Training
              </a>
            </p>
          </div>
        </ContentSection>
        <ContentSection bgClass="food-bowl">
          <div className="certificates-why-train-with-us">
            <h1>So Why Train With Us?</h1>
            <WhyTrainWithUsPoints />
          </div>
        </ContentSection>
      </div>
    ),
  },
];
