/**
 * Default field validators
 * by rcnet@solvable
 */
import validator from "validator";

export const required = (fieldName = "Field", value) => {
  if (value == null || validator.isEmpty(value.toString())) {
    return `${fieldName} is required.`;
  }
  return false;
};

export const email = (value) => {
  if (!validator.isEmail(value)) {
    return `${value} is not a valid email.`;
  }
  return false;
};

export const phone = (value) => {
  if (
    !validator.isNumeric(value.replace(/ /g, "")) ||
    value.replace(/ /g, "").length !== 10 ||
    value[0] !== "0" ||
    (value[1] !== "2" &&
      value[1] !== "3" &&
      value[1] !== "4" &&
      value[1] !== "5" &&
      value[1] !== "7" &&
      value[1] !== "8")
  )
    return `${value} is not a valid phone number. Please enter a 10 digit number starting with 0 (i.e. 0412 345 678 or 03 9123 4567)`;

  return false;
};

export const min = (value, field, min) => {
  if (value.length < min) {
    return `${field} must be at least ${min} characters.`;
  }
  return false;
};

export const max = (value, field, max) => {
  if (value.length > max) {
    return `${field} must not br more than ${max} characters.`;
  }
  return false;
};

export const strongPassword = (value) => {
  const passwordStrengthRegex = /^(?=.*[!@#$&*0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,50}$/m;

  if (!passwordStrengthRegex.test(value))
    return "Sorry, your password is not secure enough. It must be at least 8 characters with at least 1 number or special character.";

  return false;
};

export const meessage = (message) => {
  return `${message}`;
};
