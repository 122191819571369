import HttpClient from "coreLib/http/httpClient";
import CryptoJS from "crypto-js";
import Config from "config";

const ApiService = () => {
  const httpClient = HttpClient();

  const getCourseContentGuide = async (courseContentToken) => {
    return await httpClient
      .get(`/v1/usercourse/${courseContentToken}/content-guides`)
      .then((resp) => {
        var data = decryptPayload(
          resp.data,
          Config.crypto_key,
          Config.crypto_vector
        );
        return data;
      });
  };

  const decryptPayload = (encryptedPayload, secretKey, vector) => {
    try {
      const ciphertext = CryptoJS.enc.Base64.parse(encryptedPayload);
      const key = CryptoJS.enc.Utf8.parse(secretKey);
      const initializationVector = CryptoJS.enc.Utf8.parse(vector);

      const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, {
        iv: initializationVector,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      const decryptedPayload = decrypted.toString(CryptoJS.enc.Utf8);
      const json = JSON.parse(decryptedPayload);

      return json;
    } catch (error) {
      console.error("Error decrypting payload:", error.message);
    }
  };

  return { getCourseContentGuide };
};

export default ApiService;
