import { ActionTypes } from "./action-types";
import moment from "moment";

const initialState = {
  data: [],
  settings: {},
  isProcessing: false,
  fields: {
    userTypes: "",
    startDate: moment().subtract(12, "months")._d,
    endDate: moment()._d
  },
  fieldErrors: {},
  isSubmitting: false
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_PROMO_CODES_ONLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
        data: action.payload,
      };
    case ActionTypes.ADMIN_PROMO_CODES_DELETE_FAILURE:
    case ActionTypes.ADMIN_PROMO_CODES_EDIT_FAILURE:
    case ActionTypes.ADMIN_PROMO_CODES_ONLOAD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.ADMIN_PROMO_CODES_ONLOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isReset: false,
        data: action.payload.map((code) => {
          return {
            ...code,
            discountPercentage2: `${code.discountPercentage * 100}%`,
            discountAmount2: "$" + `${code.discountAmount.toFixed(2)}`,
            isOneUseOnly2: code.isOneUseOnly ? "Yes" : "No",
            expiryDate: moment(code.expiryDate),
            expiryDate2: moment(code.expiryDate).format("DD/MM/YYYY"),
          };
        }),
      };
    case ActionTypes.ADMIN_PROMO_CODES_FILTER_FORM_INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      };
    case ActionTypes.ADMIN_PROMO_CODES_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((code) => code.id !== action.payload.id),
      };
    case ActionTypes.ADMIN_PROMO_CODES_ADD_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          {
            ...action.payload,
            discountPercentage2: `${parseFloat(
              action.payload.discountPercentage
            )}%`,
            discountAmount2:
              "$" + `${parseFloat(action.payload.discountAmount).toFixed(2)}`,
            isOneUseOnly2:
              action.payload.isOneUseOnly === "true" ||
              action.payload.isOneUseOnly === true
                ? "Yes"
                : "No",
            expiryDate: moment(action.payload.expiryDate),
            expiryDate2: moment(action.payload.expiryDate).format("DD/MM/YYYY"),
          },
        ],
      };
    case ActionTypes.ADMIN_PROMO_CODES_EDIT_SUCCESS:
      return {
        ...state,
        data: state.data.map((code) => {
          return code.id === action.payload.id
            ? {
                ...action.payload,
                discountPercentage2: `${parseFloat(
                  action.payload.discountPercentage
                )}%`,
                discountAmount2:
                  "$" +
                  `${parseFloat(action.payload.discountAmount).toFixed(2)}`,
                isOneUseOnly2:
                  action.payload.isOneUseOnly === "true" ||
                  action.payload.isOneUseOnly === true
                    ? "Yes"
                    : "No",
                expiryDate: moment(action.payload.expiryDate),
                expiryDate2: moment(action.payload.expiryDate).format(
                  "DD/MM/YYYY"
                ),
              }
            : code;
        }),
      };
    case ActionTypes.ADMIN_PROMO_CODES_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    case ActionTypes.ADMIN_PROMO_CODES_DATATABLE_CLEAR_SEARCHBOX:
       return {
         ...state,
         settings: {
           ...state.settings,
           searchText: "",
         },
       };
    default:
      return state;
  }
}

export default Reducer;
