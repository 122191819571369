const ActionTypes = {
  SYNC_COURSES_OPERATE_REQUEST: "SYNC_COURSES_OPERATE_REQUEST",
  SYNC_COURSES_OPERATE_FAILURE: "SYNC_COURSES_OPERATE_FAILURE",

  SYNC_COURSES_SYNC_REQUEST: "SYNC_COURSES_SYNC_REQUEST",
  SYNC_COURSES_SYNC_SUCCESS: "SYNC_COURSES_SYNC_SUCCESS",

  SYNC_COURSES_PUBLISH_SUCCESS: "SYNC_COURSES_PUBLISH_SUCCESS",
};

export default ActionTypes;
