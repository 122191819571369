import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import ContentService from "services/content-service";

const ContentPage = () => {
  const location = useLocation();
  const [page, setPage] = useState(null);

  useEffect(() => {
    const pageName = location.pathname
      .replace(new RegExp("^[/]+"), "")
      .replace(new RegExp("[/]+$"), "")
      .toLowerCase();

    setPage(ContentService().getContentPage(pageName));

    return () => {
      setPage(null);
    };
  }, [location]);

  return (
    <>
      {page !== null && (
        <>
          <PageTagging title={page.title} description={page.description} />
          {!page.noContentSection && (
            <ContentSection
              bgClass={page.bgClass ?? "veggies-bg off-white flex-100"}
            >
              <div className="ttg-container">{page.content}</div>
            </ContentSection>
          )}
          {page.noContentSection && page.content}
        </>
      )}
    </>
  );
};

export default ContentPage;
