/**
 * User Course Service
 */
import HttpClient from "./../coreLib/http/httpClient";

const UserCourseService = () => {
  let httpClient = HttpClient();

  const getCourseId = (courseContentToken) =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/courseDetails`);

  const getStatus = (courseContentToken) =>
    httpClient.get(`/v1/userCourse/${courseContentToken}/status`);

  const getCourseDetailsAndStatus = async (courseContentToken) => {
    const promise1 = getCourseId(courseContentToken);
    const promise2 = getStatus(courseContentToken);

    return await Promise.all([promise1, promise2]).then((values) => {
      const result = {
        data: {
          id: values[0].data.id,
          status: values[1].data,
        },
      };

      return result;
    });
  };

  const updateRedirectToQuiz = (courseContentToken) =>
    httpClient.patch(`/v1/userCourse/${courseContentToken}/quiz/redirect`);

  return {
    getCourseId,
    getStatus,
    getCourseDetailsAndStatus,
    updateRedirectToQuiz,
  };
};

export default UserCourseService;
