import React from "react";
import "./copyright.css";

const Copyright = () => {
  return (
    <div className="copyright-bar">
      <div className="container">
        <span className="copyright-content">
          Copyright &copy; {new Date().getFullYear()} TTG - All Rights Reserved.
          Train to Gain Pty Ltd T/A Food Safety Education,
          ABN&nbsp;17&nbsp;134&nbsp;929&nbsp;221 RTO&nbsp;#&nbsp;22361.
        </span>
        <span className="copyright-content">
          Website Developed by{" "}
          <a
            href="https://www.solvable.com.au"
            target="_blank"
            rel="noopener noreferrer"
            title="Solvable drives business efficiency and delivers exceptional client value by creating cost-effective, innovative solutions using cutting-edge technologies, guided by a philosophy that &ldquo;Anything is Possible, Everything is Solvable.&rdquo;"
          >
            Solvable
          </a>
        </span>
      </div>
    </div>
  );
};

export default Copyright;
