export const ActionTypes = {
  ADMIN_PURCHASE_VOUCHERS_SHOW_EDIT_DIALOG:
    "ADMIN_PURCHASE_VOUCHERS_SHOW_EDIT_DIALOG",
  ADMIN_PURCHASE_VOUCHERS_SHOW_VOUCHERS_DIALOG:
    "ADMIN_PURCHASE_VOUCHERS_SHOW_VOUCHERS_DIALOG",
  ADMIN_PURCHASE_VOUCHERS_SHOW_DELETE_DIALOG:
    "ADMIN_PURCHASE_VOUCHERS_SHOW_DELETE_DIALOG",
  ADMIN_PURCHASE_VOUCHERS_SHOW_MARK_AS_PAID_DIALOG:
    "ADMIN_PURCHASE_VOUCHERS_SHOW_MARK_AS_PAID_DIALOG",
  ADMIN_PURCHASE_VOUCHERS_DIALOG_FORM_INPUT_CHANGE:
    "ADMIN_PURCHASE_VOUCHERS_DIALOG_FORM_INPUT_CHANGE",
  ADMIN_PURCHASE_VOUCHERS_UPDATE_REQUEST:
    "ADMIN_PURCHASE_VOUCHERS_UPDATE_REQUEST",
  ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS:
    "ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_UPDATE_FAILURE:
    "ADMIN_PURCHASE_VOUCHERS_UPDATE_FAILURE",
  ADMIN_PURCHASE_VOUCHERS_DELETE_REQUEST:
    "ADMIN_PURCHASE_VOUCHERS_DELETE_REQUEST",
  ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS:
    "ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_DELETE_FAILURE:
    "ADMIN_PURCHASE_VOUCHERS_DELETE_FAILURE",
  ADMIN_PURCHASE_VOUCHERS_PAID_REQUEST: "ADMIN_PURCHASE_VOUCHERS_PAID_REQUEST",
  ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS: "ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_PAID_FAILURE: "ADMIN_PURCHASE_VOUCHERS_PAID_FAILURE"
};
