/**
 * Certificate Register Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {};

function CertificateRegisterReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    // case ActionTypes.CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_REQUEST:
    //   return {
    //     ...state
    //   };

    // case ActionTypes.CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_SUCCESS:
    //   return {
    //     ...state,
    //     isAwesome: !state.isAwesome
    //   };

    // case ActionTypes.CERTIFICATE_REGISTER_TOGGLE_IS_AWESOME_FAILURE:
    //   return {
    //     ...state
    //   };

    // ----------------------------
    //  Form Action Types
    // ----------------------------

    case ActionTypes.CERTIFICATE_REGISTER_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      });

      return stateResult;

    default:
      return state;
  }
}

export default CertificateRegisterReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectCertificateRegister = state => state.certificateRegister;
