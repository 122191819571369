export const ActionTypes = {
  ADMIN_USERS_SHOW_EDIT_DIALOG: "ADMIN_USERS_SHOW_EDIT_DIALOG",
  ADMIN_USERS_SHOW_DELETE_DIALOG: "ADMIN_USERS_SHOW_DELETE_DIALOG",
  ADMIN_USERS_DIALOG_FORM_INPUT_CHANGE: "ADMIN_USERS_DIALOG_FORM_INPUT_CHANGE",
  ADMIN_USERS_DELETE_REQUEST: "ADMIN_USERS_DELETE_REQUEST",
  ADMIN_USERS_DELETE_SUCCESS: "ADMIN_USERS_DELETE_SUCCESS",
  ADMIN_USERS_DELETE_FAILURE: "ADMIN_USERS_DELETE_FAILURE",
  ADMIN_USERS_EDIT_REQUEST: "ADMIN_USERS_EDIT_REQUEST",
  ADMIN_USERS_EDIT_SUCCESS: "ADMIN_USERS_EDIT_SUCCESS",
  ADMIN_USERS_EDIT_FAILURE: "ADMIN_USERS_EDIT_FAILURE"
};
