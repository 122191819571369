import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
import "./login-box.scss";

class LoginBox extends Component {
  render() {
    const { username, password } = this.props.loginData.fields;
    const { onLogin, onInputChange } = this.props;

    return (
      <Form className="ttg-form login-box" onSubmit={onLogin}>
        <FormGroup>
          <InputField
            name="username"
            placeholder="Email"
            type="email"
            value={username}
            onChange={onInputChange}
          />
        </FormGroup>
        <FormGroup>
          <InputField
            name="password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={onInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Button className="ttg-btn mb-2" type="submit">
            Log In
          </Button>
          &nbsp;&nbsp;
          <Link to="/forgot-password/" className="btn ttg-btn mb-2">
            Forgot your Password?
          </Link>
        </FormGroup>
      </Form>
    );
  }
}

export default LoginBox;
