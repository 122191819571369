import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const getTaxInvoices = id => {
    return httpClient.get(`v1/corporate/tax-invoices/${id}`);
  };

  return {
    getTaxInvoices
  };
};

export default Service;
