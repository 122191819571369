import { connect } from "react-redux";
import CourseQuiz from "./Course-Quiz";
import { selectCourseQuiz } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import isObject from "lodash/isObject";

function mapStateToProps(state) {
  const courseQuiz = selectCourseQuiz(state);

  return {
    courseQuiz: courseQuiz
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onQuizInputChange: selectedIndex => {
    if (!isObject(selectedIndex))
      dispatch(actionCreators.onQuizInputChange(selectedIndex));
    else dispatch(actionCreators.onQuizDropdownInputChange(selectedIndex));
  },
  onRedirectToContent: courseContentToken => {
    dispatch(
      actionCreators.onRedirectToCourseContent(
        courseContentToken,
        ownProps.history
      )
    );
  },
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadSection: courseContentToken => {
    dispatchProps.dispatch(
      actionCreators.onLoadSection(courseContentToken, dispatchProps.history)
    );
  },
  onSubmitAnswer: courseContentToken => {
    dispatchProps.dispatch(
      actionCreators.onSubmitAnswer(
        courseContentToken,
        stateProps.courseQuiz.courseId,
        stateProps.courseQuiz.currentQuestion,
        stateProps.courseQuiz.selectedIndex,
        stateProps.courseQuiz.selectedDropdownIndexes,
        dispatchProps.history
      )
    );
  }
});

const CourseQuizContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(CourseQuiz);

export default CourseQuizContainer;
