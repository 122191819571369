/**
 * Date Service
 */

import format from "date-fns/format";
import differenceInYears from "date-fns/difference_in_years";
import addDays from "date-fns/add_days";
import moment from "moment";

const DateService = () => {
  const today = new Date();
  const formatDate = (date, formatString) => {
    return format(date, formatString);
  };
  const getCurrentAge = date => {
    return differenceInYears(new Date(), addDays(date, -1));
  };
  const getDateFromString = dateString => {
    if (!dateString || dateString === undefined || dateString === "")
      return null;
    return new Date(dateString);
  };
  const formatDateFromString = (dateString, formatString) => {
    const date = getDateFromString(dateString);
    return formatDate(date, formatString);
  };
  const getFirstDayOfTheMonth = () =>
    new Date(today.getFullYear(), today.getMonth(), 1);
  const getLastDayOfTheMonth = () =>
    new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const toIsoDate = date => {
    const isoDateFormat = "YYYY-MM-DD HH:mm:ss";
    const minDate = "01 JAN 1900";

    let dt = moment.isMoment(date) ? date.toDate() : date;

    if (
      dt === null ||
      moment(dt)
        .format(isoDateFormat)
        .toUpperCase() == minDate
    ) {
      return "";
    }
    return moment(dt)
      .format(isoDateFormat)
      .toUpperCase();
  };

  return {
    formatDate,
    getCurrentAge,
    getDateFromString,
    formatDateFromString,
    getFirstDayOfTheMonth,
    getLastDayOfTheMonth,
    toIsoDate
  };
};

export default DateService;
