import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const getVouchers = userId => {
    return httpClient.get(`v1/corporate/purchase-vouchers/${userId}`);
  };

  const sendVoucher = (payload, voucher) => {
    let model = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      voucherCode: voucher.voucher,
      courseId: voucher.courseId
    };
    return httpClient.post(`v1/corporate/purchase-vouchers/send`, model);
  };

  return {
    getVouchers,
    sendVoucher
  };
};

export default Service;
