import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import React from "react";

const ResponsiveHtml = ({ html }) => {
  const sanitizeHtml = (htmlString) => {
    return removeFigureTags(htmlString.replace(/\\/g, ""));
  };

  const removeFigureTags = (htmlString) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlString, "text/html");

    var figures = doc.querySelectorAll("figure.table");

    figures.forEach((figure) => {
      var table = figure.querySelector("table");

      if (table) {
        let tableStyle = table.getAttribute("style") || "";

        // Handle width conversion if width is in percentage
        if (figure.style.width) {
          const widthInStyle = figure.style.width;

          if (widthInStyle.includes("%")) {
            const percentage = parseFloat(widthInStyle);

            const widthInPx =
              percentage > 99 ? "100%" : `${(percentage / 100) * 1350}px`;

            tableStyle += `width: ${widthInPx}; `;
          } else tableStyle += `width: ${widthInStyle}; `;
        }

        // Handle float and margin styles
        if (figure.style.float) {
          tableStyle += `float: ${figure.style.float}; `;
          if (figure.style.float === "right")
            tableStyle += "margin-left: 16px; ";
          else if (figure.style.float === "left")
            tableStyle += "margin-right: 16px; ";
        }
        // Center the table if there is no float style
        else tableStyle += "margin-left: auto; margin-right: auto; ";

        // Set updated style attribute on table
        table.setAttribute("style", tableStyle.trim());
      }

      // Replace figure with table or remove the figure if no table is found
      if (table) figure.parentNode.replaceChild(table, figure);
      else figure.parentNode.removeChild(figure);
    });

    return doc.body.innerHTML;
  };

  function extractTextFromStyle(htmlString) {
    // Extract the style attribute value
    var styleValue = htmlString.match(/style="([^"]*)"/);

    if (styleValue && styleValue.length > 1) {
      var styleText = styleValue[1]
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&amp;/g, "&")
        .trim();

      return styleText;
    }

    return "";
  }

  const measureTextWidth = (htmlString, styleString) => {
    const dummyDiv = document.createElement("div");
    dummyDiv.style.position = "absolute";
    dummyDiv.style.visibility = "hidden";
    dummyDiv.style.height = "auto";
    dummyDiv.style.width = "auto";
    dummyDiv.style.whiteSpace = "nowrap";

    // Explicitly set font-related styles
    dummyDiv.style.fontSize = "16px";
    dummyDiv.style.fontWeight = "bold";

    // Apply additional styles from the style string
    if (styleString) {
      styleString.split(";").forEach((style) => {
        const [key, value] = style.split(":");
        if (key && value) {
          dummyDiv.style[key.trim()] = value.trim();
        }
      });
    }

    // Set the innerHTML to the HTML string
    dummyDiv.innerHTML = htmlString;

    document.body.appendChild(dummyDiv);

    const width = dummyDiv.clientWidth;
    document.body.removeChild(dummyDiv);

    return width + 25; // add 25px for buffer
  };

  const generateDivHTML = (
    headers,
    tableData,
    headerStyles,
    tableStyleData
  ) => {
    // Measure the width of each header
    const headerWidths = headers.map((header, index) =>
      measureTextWidth(header, headerStyles[index])
    );

    // Find the maximum width (limited to 200px)
    const maxWidth = Math.min(Math.max(...headerWidths), 200);

    const divString = `<div class="cms-responsive-table">
        ${tableData
          .map(
            (row, tableDataIndex) => `<div class="cms-responsive-tr">
                ${headers
                  .map(
                    (header, index) => `<div class="cms-responsive-tbody">
                        <div class="cms-responsive-th" style="${headerStyles[index]} width: ${maxWidth}px;">${header}</div>
                        <div class="cms-responsive-td" style="${tableStyleData[tableDataIndex][index]} width: calc(100% - ${maxWidth}px)">${tableData[tableDataIndex][index]}</div>
                    </div>`
                  )
                  .join("")}
            </div>`
          )
          .join("")}
        </div>`;

    return divString;
  };

  const removeStyleProperty = (styleString) => {
    return styleString
      .split(";")
      .filter((styleProp) => !styleProp.includes("border"))
      .join(";");
  };

  const parseHTMLTable = (htmlString) => {
    const parser = new DOMParser();

    const doc = parser.parseFromString(htmlString, "text/html");

    const tableData = [];
    const tableStyleData = [];

    const table = doc.querySelector("table");
    const rows = table.querySelectorAll("tr");

    for (let i = 0; i < rows.length; i++) {
      let cells = rows[i].querySelectorAll("th");

      if (cells.length === 0) cells = rows[i].querySelectorAll("td");

      const rowData = [];
      let rowStyleData = [];

      for (let j = 0; j < cells.length; j++) {
        rowStyleData.push(
          removeStyleProperty(extractTextFromStyle(cells[j].outerHTML))
        );
        rowData.push(cells[j].innerHTML);
      }

      tableData.push(rowData);
      tableStyleData.push(rowStyleData);
    }

    let headers = tableData.shift();

    // make the heading bold if there is no <strong> tag
    headers.forEach((header, index) => {
      if (!header.includes("<strong>"))
        headers[index] = `<strong>${header}</strong>`;
    });

    const headerStyles = tableStyleData.shift();

    return {
      headers,
      data: tableData,
      headerStyles,
      dataStyles: tableStyleData,
    };
  };

  // Helper function to parse HTML string and select elements
  const parseAndSelectElements = (htmlString, elementSelector) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const elements = doc.querySelectorAll(elementSelector);

    return {
      doc,
      elements,
    };
  };

  const parseAndSelectImageElements = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const allElements = Array.from(doc.querySelectorAll("*"));

    const selectedElements = allElements.filter((el) => {
      if (el.tagName === "IMG") {
        let parent = el.parentNode;
        while (parent) {
          if (parent.tagName === "FIGURE" && parent.classList.contains("image"))
            return false;

          parent = parent.parentNode;
        }

        return true;
      }

      return el.tagName === "FIGURE" && el.classList.contains("image");
    });

    return {
      doc,
      selectedElements,
    };
  };

  // Function to replace tables
  const parseAndReplaceTables = (htmlString, isBigScreen) => {
    let { doc, elements: tables } = parseAndSelectElements(htmlString, "table");

    for (let i = 0; i < tables.length; i++) {
      const parsedTable = parseHTMLTable(tables[i].outerHTML);

      const newTable = generateDivHTML(
        parsedTable.headers,
        parsedTable.data,
        parsedTable.headerStyles,
        parsedTable.dataStyles
      );

      const newTableElement = new DOMParser().parseFromString(
        newTable,
        "text/html"
      ).body.firstChild;

      tables[i].parentNode.replaceChild(newTableElement, tables[i]);
    }

    return doc.documentElement.outerHTML;
  };

  const replaceOembedWithIFrame = (htmlString) => {
    const videoUrlRegEx = /(\/|%3D|vi*=)([0-9A-z-_]{11})([%#?&]|$)/;

    let { doc, elements: oembeds } = parseAndSelectElements(
      htmlString,
      "oembed"
    );

    oembeds.forEach((oembedElement) => {
      let url = oembedElement.getAttribute("url");
      let match = url.match(videoUrlRegEx);

      let iframe = document.createElement("iframe");
      iframe.src = match ? `https://www.youtube.com/embed/${match[2]}` : url;
      iframe.width = "560";
      iframe.height = "315";
      iframe.frameborder = "0";
      iframe.allowFullscreen = true;
      iframe.allow =
        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture";

      oembedElement.parentNode.replaceChild(iframe, oembedElement);
    });

    return doc.documentElement.outerHTML;
  };

  // Function to replace images
  const parseAndReplaceImages = (htmlString) => {
    let { doc, selectedElements: images } = parseAndSelectImageElements(
      htmlString
    );

    images.forEach((image) => {
      if (image.tagName === "IMG") {
        let src = image.getAttribute("src");
        let className = image.getAttribute("class");
        let style = image.getAttribute("style");

        let widthInStyle;

        if (style) {
          const widthMatch = style.match(/width:\s*([^;]+);/);

          if (widthMatch) {
            widthInStyle = widthMatch[1];
            style = style.replace(widthMatch[0], "");
          }
        }

        let classAttribute = className ? ` class="${className}"` : "";
        let styleAttribute = style ? ` style="${style}"` : "";

        let widthAttribute = "";

        if (widthInStyle) {
          let percentage = parseFloat(widthInStyle);

          widthAttribute = widthInStyle.includes("%")
            ? ` width="${
                percentage > 99 ? "100%" : `${(percentage / 100) * 1350}px`
              }"`
            : ` width="${widthInStyle}"`;
        }

        const imgElement = `<img src="${src}"${classAttribute}${styleAttribute}${widthAttribute}/>`;

        image.outerHTML = imgElement;
      } else {
        let extractedImageFromFigure = image.querySelector("img");
        let src = extractedImageFromFigure.getAttribute("src");
        let className = image.getAttribute("class");

        if (className) {
          if (className === "image") className = "image-style-align-center";
          else
            className = className.replace(
              "image ",
              "image-style-align-center "
            );
        }

        let style = image.getAttribute("style");

        let widthInStyle;

        if (style) {
          const widthMatch = style.match(/width:\s*([^;]+);/);

          if (widthMatch) {
            widthInStyle = widthMatch[1];
            style = style.replace(widthMatch[0], "");
          }
        }

        let classAttribute = className ? ` class="${className}"` : "";
        let styleAttribute = style ? ` style="${style}"` : "";

        let widthAttribute = "";

        if (widthInStyle) {
          let percentage = parseFloat(widthInStyle);

          widthAttribute = widthInStyle.includes("%")
            ? ` width="${
                percentage > 99 ? "100%" : `${(percentage / 100) * 1350}px`
              }"`
            : ` width="${widthInStyle}"`;
        }

        const imgElement = `<img src="${src}"${classAttribute}${styleAttribute}${widthAttribute}/>`;

        image.outerHTML = imgElement;
      }
    });

    return doc.documentElement.outerHTML;
  };

  const sanitisedHtml = sanitizeHtml(html);

  const isBigScreen = useMediaQuery({
    minWidth: 768,
  });

  let newHtml = isBigScreen
    ? parseAndReplaceImages(sanitisedHtml)
    : parseAndReplaceImages(parseAndReplaceTables(sanitisedHtml));

  newHtml = replaceOembedWithIFrame(newHtml);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div
      dangerouslySetInnerHTML={{
        __html: newHtml,
      }}
    />
  );
};

ResponsiveHtml.propTypes = {
  html: PropTypes.string.isRequired,
};

export default ResponsiveHtml;
