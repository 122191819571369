export const FoodHandlingLevel2CertificateHospitalityAndRetailThirdPartyFormTasks = [
  {
    taskId: 1,
    courseId: 3,
    version: 1,
    taskGroup: "Task 1: Follow food safety program",
    task:
      "Access and use relevant information from organisational food safety program.",
    displayOrder: 1,
  },
  {
    taskId: 2,
    courseId: 3,
    version: 1,
    taskGroup: "Task 1: Follow food safety program",
    task: "Follow policies and procedures in food safety program.",
    displayOrder: 2,
  },
  {
    taskId: 3,
    courseId: 3,
    version: 1,
    taskGroup: "Task 1: Follow food safety program",
    task: "Control food hazards at critical control points.",
    displayOrder: 3,
  },
  {
    taskId: 4,
    courseId: 3,
    version: 1,
    taskGroup: "Task 1: Follow food safety program",
    task:
      "Complete food safety monitoring processes and complete documents as required.",
    displayOrder: 4,
  },
  {
    taskId: 5,
    courseId: 3,
    version: 1,
    taskGroup: "Task 1: Follow food safety program",
    task: "Identify and report non-conforming practices.",
    displayOrder: 5,
  },
  {
    taskId: 6,
    courseId: 3,
    version: 1,
    taskGroup: "Task 1: Follow food safety program",
    task:
      "Take corrective actions within scope of job responsibility for incidents where food hazards are not controlled.",
    displayOrder: 6,
  },
  {
    taskId: 7,
    courseId: 3,
    version: 1,
    taskGroup: "Task 2: Store food safely",
    task: "Select food storage conditions for specific food type.",
    displayOrder: 7,
  },
  {
    taskId: 8,
    courseId: 3,
    version: 1,
    taskGroup: "Task 2: Store food safely",
    task:
      "Store food in environmental conditions that protect against contamination and maximise freshness, quality and appearance.",
    displayOrder: 8,
  },
  {
    taskId: 9,
    courseId: 3,
    version: 1,
    taskGroup: "Task 2: Store food safely",
    task:
      "Store food at controlled temperatures and ensure that frozen items remain frozen during storage.",
    displayOrder: 9,
  },
  {
    taskId: 10,
    courseId: 3,
    version: 1,
    taskGroup: "Task 3: Prepare food safely",
    task:
      "Use cooling and heating processes that support microbiological safety of the food.",
    displayOrder: 10,
  },
  {
    taskId: 11,
    courseId: 3,
    version: 1,
    taskGroup: "Task 3: Prepare food safely",
    task:
      "Monitor food temperature during preparation using required temperature measuring device to achieve microbiological safety.",
    displayOrder: 11,
  },
  {
    taskId: 12,
    courseId: 3,
    version: 1,
    taskGroup: "Task 3: Prepare food safely",
    task:
      "Ensure safety of food prepared, served and sold to customers under other conditions.",
    displayOrder: 12,
  },
  {
    taskId: 13,
    courseId: 3,
    version: 1,
    taskGroup: "Task 4: Provide safe single use items",
    task:
      "Store, display and provide single use items so they are protected from damage and contamination.",
    displayOrder: 13,
  },
  {
    taskId: 14,
    courseId: 3,
    version: 1,
    taskGroup: "Task 4: Provide safe single use items",
    task: "Follow instructions for items intended for single use.",
    displayOrder: 14,
  },
  {
    taskId: 15,
    courseId: 3,
    version: 1,
    taskGroup: "Task 5: Maintain a clean environment",
    task: "Clean and sanitise equipment, surfaces and utensils.",
    displayOrder: 15,
  },
  {
    taskId: 16,
    courseId: 3,
    version: 1,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Use appropriate containers and prevent accumulation of garbage and recycled matter.",
    displayOrder: 16,
  },
  {
    taskId: 17,
    courseId: 3,
    version: 1,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Identify and report cleaning, sanitising and maintenance requirements.",
    displayOrder: 17,
  },
  {
    taskId: 18,
    courseId: 3,
    version: 1,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Dispose of or report chipped, broken or cracked eating, drinking or food handling utensils.",
    displayOrder: 18,
  },
  {
    taskId: 19,
    courseId: 3,
    version: 1,
    taskGroup: "Task 5: Maintain a clean environment",
    task:
      "Take measures within scope of responsibility to ensure food handling areas are free from animals and pests and report incidents of animal or pest infestation.",
    displayOrder: 19,
  },
  {
    taskId: 20,
    courseId: 3,
    version: 1,
    taskGroup: "Task 6: Dispose of food safely",
    task:
      "Mark and keep separate from other foodstuffs any food identified for disposal until disposal is complete.",
    displayOrder: 20,
  },
  {
    taskId: 21,
    courseId: 3,
    version: 1,
    taskGroup: "Task 6: Dispose of food safely",
    task: "Dispose of food promptly to avoid cross contamination.",
    displayOrder: 21,
  },
  {
    taskId: 22,
    courseId: 3,
    version: 1,
    taskGroup:
      "General 1: Communication skills – Verbal communication of hazards",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 22,
  },
  {
    taskId: 23,
    courseId: 3,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to read and interpret food safety program, including policies, procedures and flow charts that identify critical control points",
    displayOrder: 23,
  },
  {
    taskId: 24,
    courseId: 3,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend and complete documentation for monitoring food safety",
    displayOrder: 24,
  },
  {
    taskId: 25,
    courseId: 3,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 25,
  },
  {
    taskId: 26,
    courseId: 3,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Numeracy skills to calibrate and use a temperature probe and calculate timings",
    displayOrder: 26,
  },
  {
    taskId: 27,
    courseId: 3,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task:
      "Problem-solving skills to identify and respond to routine food safety issues",
    displayOrder: 27,
  },
];
