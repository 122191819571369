/**
 * ForwardRefDiv component to create a div element with forwarded ref and custom className.
 * Adam Luck - Solvable - 2024-06-29
 */

import React, { forwardRef } from "react";

const ForwardRefDiv = forwardRef((props, ref) => (
  <div ref={ref} {...props} className={props.className} />
));

export default React.memo(ForwardRefDiv);
