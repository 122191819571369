import React from "react";
import PropTypes from "prop-types";
import Angle from "./angle/angle";
import Arc from "./arc/arc";
import WaveCurve from "./wave-curve/wave-curve";
import IntersectingWaves from "./intersecting-waves/intersecting-waves";
import IntersectingWavesScattered from "./intersecting-waves-scattered/intersecting-waves-scattered";
import CustomSvgs from "./custom-svgs/custom-svgs";

function ContentBackgroundFactory({ id, type = "", options = {}, ...rest }) {
  switch (type) {
    case "angle":
      return (
        <Angle
          {...rest}
          id={id}
          bgInnerColor={options.bgInnerColor}
          bgOuterColor={options.bgOuterColor}
          padding={options.padding}
          specificClass={options.specificClass}
          isFullWidth={options.isFullWidth}
          showTopSvg={options.showTopSvg}
          showBottomSvg={options.showBottomSvg}
        />
      );

    case "arc":
      return (
        <Arc
          {...rest}
          id={id}
          bgInnerColor={options.bgInnerColor}
          bgOuterColor={options.bgOuterColor}
          padding={options.padding}
          specificClass={options.specificClass}
          isFullWidth={options.isFullWidth}
          showTopSvg={options.showTopSvg}
          showBottomSvg={options.showBottomSvg}
        />
      );

    case "waveCurve":
      return (
        <WaveCurve
          {...rest}
          id={id}
          bgInnerColor={options.bgInnerColor}
          bgOuterColor={options.bgOuterColor}
          padding={options.padding}
          specificClass={options.specificClass}
          isFullWidth={options.isFullWidth}
          showTopSvg={options.showTopSvg}
          showBottomSvg={options.showBottomSvg}
        />
      );

    case "intersectingWaves":
      return (
        <IntersectingWaves
          {...rest}
          id={id}
          bgInnerColor={options.bgInnerColor}
          bgOuterColor={options.bgOuterColor}
          padding={options.padding}
          specificClass={options.specificClass}
          isFullWidth={options.isFullWidth}
          showTopSvg={options.showTopSvg}
          showBottomSvg={options.showBottomSvg}
        />
      );

    case "intersectingWavesScattered":
      return (
        <IntersectingWavesScattered
          {...rest}
          id={id}
          bgInnerColor={options.bgInnerColor}
          bgOuterColor={options.bgOuterColor}
          padding={options.padding}
          specificClass={options.specificClass}
          isFullWidth={options.isFullWidth}
          showTopSvg={options.showTopSvg}
          showBottomSvg={options.showBottomSvg}
        />
      );

    case "customSvgs":
      return (
        <CustomSvgs
          {...rest}
          id={id}
          bgInnerColor={options.bgInnerColor}
          bgOuterColor={options.bgOuterColor}
          padding={options.padding}
          specificClass={options.specificClass}
          isFullWidth={options.isFullWidth}
          showTopSvg={options.showTopSvg}
          showBottomSvg={options.showBottomSvg}
          customSvgTop={options.customSvgTop}
          customSvgBottom={options.customSvgBottom}
        />
      );

    default:
      return (
        <div
          className={`container-fluid py-${options.padding} ${
            options.isFullWidth ? "content-full-width" : "content-section"
          }${options.specificClass ? ` ${options.specificClass}` : ""}`}
          {...(id ? { id } : {})}
        >
          {rest.children}
        </div>
      );
  }
}

ContentBackgroundFactory.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.shape({
    bgOuterColor: PropTypes.string,
    bgInnerColor: PropTypes.string,
    padding: PropTypes.number,
    isFullWidth: PropTypes.bool,
    specificClass: PropTypes.string,
    showTopSvg: PropTypes.bool,
    showBottomSvg: PropTypes.bool,
    customSvgTop: PropTypes.element,
    customSvgBottom: PropTypes.element,
  }),
};

export default ContentBackgroundFactory;
