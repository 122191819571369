/**
 * Priority navigation component with responsive design.
 * Creates a navigation bar with priority items and a dropdown menu for overflow items.
 *
 * Uses refs, custom hooks, and reducers for handling dynamic resizing and state management.
 *
 * Props include offset, debounceMs, navItems, logo, logoUrl, logoAltText, priorityNavClassName, toggleButtonIcon, useActiveRouteStyle, wrapInsideHeaderElement, sticky and stickyScrollTriggerValue.
 *
 * Dependencies: React, react-router-dom, prop-types, local utility functions and components, and a SCSS file for styling.
 * Adam Luck - Solvable - 2024-06-29
 * Adam Luck - Solvable - Updated 2024-08-13 to add useActiveRouteStyle prop and functionality.
 * Adam Luck - Solvable - Updated 2024-10-12 to add smooth scrolling # link functionality.
 * Adam Luck - Solvable - Updated 2024-10-13 to add sticky functionality.
 */

import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { reducer } from "./reducers/reducer";
import { useResizeObserve } from "./hooks/use-resize-observe";
import useScrollToSection from "./hooks/use-scroll-to-section";
import { renderNavItem } from "./utils/render-nav-item";
import { doesItFit, getClassName } from "./utils/priority-nav-utils";
import ForwardRefDiv from "./components/forward-ref-div";
import ToggleButton from "./components/toggle-button";
import "./solvable-priority-nav.scss";

const initialState = {
  children: [],
  dropdownItems: [],
  lastItemWidth: [],
};

const PriorityNav = ({
  children,
  offset = 0,
  debounceMs = 0,
  navItems = [],
  logo,
  logoUrl,
  logoAltText = "",
  priorityNavClassName,
  toggleButtonIcon,
  useActiveRouteStyle = false,
  wrapInsideHeaderElement = true,
  sticky = false,
  stickyScrollTriggerValue = 0,
  stickyClassNamesByRoute = {},
}) => {
  const location = useLocation();
  const history = useHistory();

  const priorityNavRef = useRef(null);
  const outerNav = useRef(null);
  const nav = useRef(null);
  const dropDownNav = useRef(null);
  const items = useRef(new Map()).current;

  const outerNavWidth = useResizeObserve(outerNav);

  const getStickyScrollTriggerValue = useCallback(() => {
    const currentRoute = location.pathname;
    const routeClasses = stickyClassNamesByRoute[currentRoute] || {};
    const stickyOffset =
      routeClasses.scrollTriggerValue || stickyScrollTriggerValue;

    return stickyOffset;
  }, [location.pathname, stickyClassNamesByRoute, stickyScrollTriggerValue]);

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(
    window.scrollY >= getStickyScrollTriggerValue()
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const scrollToSection = useScrollToSection();

  const handleToggleClick = useCallback(() => {
    setIsDropDownOpen(!isDropDownOpen);
  }, [isDropDownOpen]);

  const handleLinkClick = useCallback(() => {
    setIsDropDownOpen(false);
  }, []);

  const handleHashNavigation = useCallback(
    (link) => {
      if (link.startsWith("/") && link.includes("#")) {
        const [pathname, hash] = link.split("#");

        const scrollOffset = sticky
          ? -priorityNavRef.current?.clientHeight ?? 0
          : 0;

        if (location.pathname === pathname) {
          // If already on the target page, just scroll
          scrollToSection(hash, scrollOffset);
        } else {
          // Navigate to the page, and then scroll once there
          history.push(pathname);

          // Delay scrolling to allow for page navigation
          setTimeout(() => {
            scrollToSection(hash, scrollOffset);
          }, 100);
        }
      } else if (link.startsWith("#")) {
        // Handle in-page anchor links
        const scrollOffset = sticky
          ? -priorityNavRef.current?.clientHeight ?? 0
          : 0;

        scrollToSection(link.substring(1), scrollOffset);
      } else {
        // Handle normal navigation without a hash
        history.push(link);
      }
      setIsDropDownOpen(false);
    },
    [history, location.pathname, scrollToSection, sticky]
  );

  const handleClickOutsideDropDown = useCallback((event) => {
    if (
      outerNav.current &&
      !outerNav.current.contains(event.target) &&
      dropDownNav.current &&
      !dropDownNav.current.contains(event.target)
    ) {
      setIsDropDownOpen(false);
    }
  }, []);

  const childrenFromNavItems = useMemo(() => {
    if (navItems.length === 0) return null;

    return navItems.map((item, i) =>
      renderNavItem(item, i, handleLinkClick, handleHashNavigation)
    );
  }, [handleLinkClick, handleHashNavigation, navItems]);

  const memoizedChildren = useMemo(() => {
    return children !== undefined ? children : childrenFromNavItems;
  }, [children, childrenFromNavItems]);

  const logoObj = useMemo(() => {
    if (!logoUrl && logo) return logo;
    if (logoUrl)
      return (
        <Link to="/">
          <img src={logoUrl} alt={logoAltText} className="priority-nav-logo" />
        </Link>
      );
  }, [logo, logoUrl, logoAltText]);

  useEffect(() => {
    const fitHandler = doesItFit(
      nav,
      outerNav,
      state,
      items,
      dispatch,
      offset,
      debounceMs
    );
    fitHandler();
  }, [
    state.children,
    state.dropdownItems,
    outerNavWidth,
    state,
    items,
    offset,
    debounceMs,
  ]);

  useEffect(() => {
    if (isDropDownOpen)
      document.addEventListener("mousedown", handleClickOutsideDropDown);
    else document.removeEventListener("mousedown", handleClickOutsideDropDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropDown);
    };
  }, [handleClickOutsideDropDown, isDropDownOpen]);

  useEffect(() => {
    // Scroll event listener to track if the page is scrolled

    const handleScroll = () => {
      setIsScrolled(window.scrollY > getStickyScrollTriggerValue());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [getStickyScrollTriggerValue]);

  const locationDependency = useActiveRouteStyle ? location.pathname : null;

  useEffect(() => {
    dispatch({
      type: "update",
      payload: {
        initialChildren: memoizedChildren,
      },
    });
  }, [children, childrenFromNavItems, memoizedChildren, locationDependency]);

  const trimTrailingSlash = (str) => {
    return str.endsWith("/") && str.length > 1 ? str.slice(0, -1) : str;
  };

  const normalizePath = (str) => {
    return trimTrailingSlash(str).toLowerCase();
  };

  const getHeaderClassNames = () => {
    const currentRoute = location.pathname;
    const routeClasses = stickyClassNamesByRoute[currentRoute] || {};
    const topClass = routeClasses.top || "header-top";
    const stickyClass = routeClasses.sticky || "header-sticky";

    return isScrolled ? stickyClass : topClass;
  };

  const priorityNavObj = useMemo(() => {
    return (
      <div
        ref={priorityNavRef}
        className={getClassName("priority-nav", priorityNavClassName)}
      >
        {logoObj}
        <div className="priority-nav-bar-wrap">
          <ForwardRefDiv ref={outerNav} className="priority-nav-bar">
            <ForwardRefDiv
              ref={nav}
              className={getClassName(
                "priority-nav-items",
                logoUrl || logo ? "priority-nav-items-mt" : null
              )}
            >
              {state.children.map((child, i) => {
                const isActiveRoute =
                  useActiveRouteStyle &&
                  child.props &&
                  normalizePath(child.props.to) ===
                    normalizePath(location.pathname);

                return (
                  <ForwardRefDiv
                    ref={(e) => {
                      if (e) items.set(i, e);
                    }}
                    className={getClassName(
                      "priority-nav-item",
                      isActiveRoute ? "priority-nav-item-active" : null
                    )}
                    key={i}
                  >
                    {child}
                  </ForwardRefDiv>
                );
              })}

              {state.dropdownItems.length > 0 &&
                (toggleButtonIcon ? (
                  <ToggleButton onClick={handleToggleClick}>
                    {toggleButtonIcon}
                  </ToggleButton>
                ) : (
                  <ToggleButton onClick={handleToggleClick} />
                ))}
            </ForwardRefDiv>
          </ForwardRefDiv>
          {state.dropdownItems.length > 0 && isDropDownOpen && (
            <ForwardRefDiv ref={dropDownNav} className="priority-nav-menu">
              <ul>
                {state.dropdownItems.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </ForwardRefDiv>
          )}
        </div>
      </div>
    );
  }, [
    handleToggleClick,
    isDropDownOpen,
    items,
    logo,
    logoObj,
    logoUrl,
    priorityNavClassName,
    state.children,
    state.dropdownItems,
    toggleButtonIcon,
    useActiveRouteStyle,
  ]);

  const priorityNav = useMemo(() => {
    if (wrapInsideHeaderElement && sticky) {
      const headerClassNames = getHeaderClassNames();
      return <header className={headerClassNames}>{priorityNavObj}</header>;
    }

    if (wrapInsideHeaderElement) return <header>{priorityNavObj}</header>;

    return priorityNavObj;
  }, [
    handleToggleClick,
    isDropDownOpen,
    items,
    logo,
    logoObj,
    logoUrl,
    priorityNavClassName,
    state.children,
    state.dropdownItems,
    toggleButtonIcon,
    useActiveRouteStyle,
    priorityNavObj,
    wrapInsideHeaderElement,
    sticky,
    isScrolled,
    getHeaderClassNames,
  ]);

  return priorityNav;
};

PriorityNav.propTypes = {
  children: PropTypes.node,
  offset: PropTypes.number, // Extra width to trigger show/hide nav item
  debounceMs: PropTypes.number,
  navItems: PropTypes.array,
  logo: PropTypes.element, // Custom logo component
  logoUrl: PropTypes.string,
  logoAltText: PropTypes.string,
  priorityNavClassName: PropTypes.string,
  toggleButtonIcon: PropTypes.element, // Custom toggle button component
  useActiveRouteStyle: PropTypes.bool, // Highlight active route
  wrapInsideHeaderElement: PropTypes.bool, // Wrap inside header
  sticky: PropTypes.bool, // Sticky nav
  stickyScrollTriggerValue: PropTypes.number, // Scroll value to trigger sticky
  stickyClassNamesByRoute: PropTypes.object, // Sticky class names by route
};

export default React.memo(PriorityNav);

// /**
//  * Priority navigation component with responsive design.
//  * Creates a navigation bar with priority items and a dropdown menu for overflow items.
//  *
//  * Uses refs, custom hooks, and reducers for handling dynamic resizing and state management.
//  *
//  * Props include offset, debounceMs, navItems, logo, logoUrl, logoAltText, priorityNavClassName, toggleButtonIcon, useActiveRouteStyle, wrapInsideHeaderElement, sticky and stickyScrollTriggerValue.
//  *
//  * Dependencies: React, react-router-dom, prop-types, local utility functions and components, and a SCSS file for styling.
//  * Adam Luck - Solvable - 2024-06-29
//  * Adam Luck - Solvable - Updated 2024-08-13 to add useActiveRouteStyle prop and functionality.
//  * Adam Luck - Solvable - Updated 2024-10-12 to add smooth scrolling # link functionality.
//  * Adam Luck - Solvable - Updated 2024-10-13 to add sticky functionality.
//  */

// import React, {
//   useEffect,
//   useState,
//   useRef,
//   useReducer,
//   useCallback,
//   useMemo,
// } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { useHistory } from "react-router";
// import PropTypes from "prop-types";
// import { reducer } from "./reducers/reducer";
// import { useResizeObserve } from "./hooks/use-resize-observe";
// import useScrollToSection from "./hooks/use-scroll-to-section";
// import { renderNavItem } from "./utils/render-nav-item";
// import { doesItFit, getClassName } from "./utils/priority-nav-utils";
// import ForwardRefDiv from "./components/forward-ref-div";
// import ToggleButton from "./components/toggle-button";
// import "./solvable-priority-nav.scss";

// const initialState = {
//   children: [],
//   dropdownItems: [],
//   lastItemWidth: [],
// };

// const PriorityNav = ({
//   children,
//   offset = 0,
//   debounceMs = 0,
//   navItems = [],
//   logo,
//   logoUrl,
//   logoAltText = "",
//   priorityNavClassName,
//   toggleButtonIcon,
//   useActiveRouteStyle = false,
//   wrapInsideHeaderElement = true,
//   sticky = false,
//   stickyScrollTriggerValue = 0,
// }) => {
//   const location = useLocation();
//   const history = useHistory();

//   const priorityNavRef = useRef(null);
//   const outerNav = useRef(null);
//   const nav = useRef(null);
//   const dropDownNav = useRef(null);
//   const items = useRef(new Map()).current;

//   const outerNavWidth = useResizeObserve(outerNav);

//   const [isDropDownOpen, setIsDropDownOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(
//     window.scrollY >= stickyScrollTriggerValue
//   );
//   const [state, dispatch] = useReducer(reducer, initialState);

//   const scrollToSection = useScrollToSection();

//   const handleToggleClick = useCallback(() => {
//     setIsDropDownOpen(!isDropDownOpen);
//   }, [isDropDownOpen]);

//   const handleLinkClick = useCallback(() => {
//     setIsDropDownOpen(false);
//   }, []);

//   const handleHashNavigation = useCallback(
//     (link) => {
//       if (link.startsWith("/") && link.includes("#")) {
//         const [pathname, hash] = link.split("#");

//         const scrollOffset = sticky
//           ? -priorityNavRef.current?.clientHeight ?? 0
//           : 0;

//         if (location.pathname === pathname) {
//           // If already on the target page, just scroll
//           scrollToSection(hash, scrollOffset);
//         } else {
//           // Navigate to the page, and then scroll once there
//           history.push(pathname);

//           // Delay scrolling to allow for page navigation
//           setTimeout(() => {
//             scrollToSection(hash, scrollOffset);
//           }, 100);
//         }
//       } else if (link.startsWith("#")) {
//         // Handle in-page anchor links
//         const scrollOffset = sticky
//           ? -priorityNavRef.current?.clientHeight ?? 0
//           : 0;

//         scrollToSection(link.substring(1), scrollOffset);
//       } else {
//         // Handle normal navigation without a hash
//         history.push(link);
//       }
//       setIsDropDownOpen(false);
//     },
//     [navigate, location.pathname, scrollToSection, sticky]
//   );

//   const handleClickOutsideDropDown = useCallback((event) => {
//     if (
//       outerNav.current &&
//       !outerNav.current.contains(event.target) &&
//       dropDownNav.current &&
//       !dropDownNav.current.contains(event.target)
//     ) {
//       setIsDropDownOpen(false);
//     }
//   }, []);

//   const childrenFromNavItems = useMemo(() => {
//     if (navItems.length === 0) return null;

//     return navItems.map((item, i) =>
//       renderNavItem(item, i, handleLinkClick, handleHashNavigation)
//     );
//   }, [handleLinkClick, handleHashNavigation, navItems]);

//   const memoizedChildren = useMemo(() => {
//     return children !== undefined ? children : childrenFromNavItems;
//   }, [children, childrenFromNavItems]);

//   const logoObj = useMemo(() => {
//     if (!logoUrl && logo) return logo;
//     if (logoUrl)
//       return (
//         <Link to="/">
//           <img src={logoUrl} alt={logoAltText} className="priority-nav-logo" />
//         </Link>
//       );
//   }, [logo, logoUrl, logoAltText]);

//   useEffect(() => {
//     const fitHandler = doesItFit(
//       nav,
//       outerNav,
//       state,
//       items,
//       dispatch,
//       offset,
//       debounceMs
//     );
//     fitHandler();
//   }, [
//     state.children,
//     state.dropdownItems,
//     outerNavWidth,
//     state,
//     items,
//     offset,
//     debounceMs,
//   ]);

//   useEffect(() => {
//     if (isDropDownOpen)
//       document.addEventListener("mousedown", handleClickOutsideDropDown);
//     else document.removeEventListener("mousedown", handleClickOutsideDropDown);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutsideDropDown);
//     };
//   }, [handleClickOutsideDropDown, isDropDownOpen]);

//   useEffect(() => {
//     // Scroll event listener to track if the page is scrolled

//     const handleScroll = () => {
//       setIsScrolled(window.scrollY >= stickyScrollTriggerValue);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [stickyScrollTriggerValue]);

//   const locationDependency = useActiveRouteStyle ? location.pathname : null;

//   useEffect(() => {
//     dispatch({
//       type: "update",
//       payload: {
//         initialChildren: memoizedChildren,
//       },
//     });
//   }, [children, childrenFromNavItems, memoizedChildren, locationDependency]);

//   const trimTrailingSlash = (str) => {
//     return str.endsWith("/") && str.length > 1 ? str.slice(0, -1) : str;
//   };

//   const normalizePath = (str) => {
//     return trimTrailingSlash(str).toLowerCase();
//   };

//   const priorityNavObj = useMemo(() => {
//     return (
//       <div
//         ref={priorityNavRef}
//         className={getClassName("priority-nav", priorityNavClassName)}
//       >
//         {logoObj}
//         <div className="priority-nav-bar-wrap">
//           <ForwardRefDiv ref={outerNav} className="priority-nav-bar">
//             <ForwardRefDiv
//               ref={nav}
//               className={getClassName(
//                 "priority-nav-items",
//                 logoUrl || logo ? "priority-nav-items-mt" : null
//               )}
//             >
//               {state.children.map((child, i) => {
//                 const isActiveRoute =
//                   useActiveRouteStyle &&
//                   child.props &&
//                   normalizePath(child.props.to) ===
//                     normalizePath(location.pathname);

//                 return (
//                   <ForwardRefDiv
//                     ref={(e) => {
//                       if (e) items.set(i, e);
//                     }}
//                     className={getClassName(
//                       "priority-nav-item",
//                       isActiveRoute ? "priority-nav-item-active" : null
//                     )}
//                     key={i}
//                   >
//                     {child}
//                   </ForwardRefDiv>
//                 );
//               })}

//               {state.dropdownItems.length > 0 &&
//                 (toggleButtonIcon ? (
//                   <ToggleButton onClick={handleToggleClick}>
//                     {toggleButtonIcon}
//                   </ToggleButton>
//                 ) : (
//                   <ToggleButton onClick={handleToggleClick} />
//                 ))}
//             </ForwardRefDiv>
//           </ForwardRefDiv>
//           {state.dropdownItems.length > 0 && isDropDownOpen && (
//             <ForwardRefDiv ref={dropDownNav} className="priority-nav-menu">
//               <ul>
//                 {state.dropdownItems.map((item, i) => (
//                   <li key={i}>{item}</li>
//                 ))}
//               </ul>
//             </ForwardRefDiv>
//           )}
//         </div>
//       </div>
//     );
//   }, [
//     handleToggleClick,
//     isDropDownOpen,
//     items,
//     logo,
//     logoObj,
//     logoUrl,
//     priorityNavClassName,
//     state.children,
//     state.dropdownItems,
//     toggleButtonIcon,
//     useActiveRouteStyle,
//   ]);

//   const priorityNav = useMemo(() => {
//     if (wrapInsideHeaderElement && sticky)
//       return (
//         <header className={isScrolled ? "header-sticky" : "header-top"}>
//           {priorityNavObj}
//         </header>
//       );

//     if (wrapInsideHeaderElement) return <header>{priorityNavObj}</header>;

//     return priorityNavObj;
//   }, [
//     handleToggleClick,
//     isDropDownOpen,
//     items,
//     logo,
//     logoObj,
//     logoUrl,
//     priorityNavClassName,
//     state.children,
//     state.dropdownItems,
//     toggleButtonIcon,
//     useActiveRouteStyle,
//     priorityNavObj,
//     wrapInsideHeaderElement,
//     sticky,
//     isScrolled,
//   ]);

//   return priorityNav;
// };

// PriorityNav.propTypes = {
//   children: PropTypes.node,
//   offset: PropTypes.number, // Extra width to trigger show/hide nav item
//   debounceMs: PropTypes.number,
//   navItems: PropTypes.array,
//   logo: PropTypes.element, // Custom logo component
//   logoUrl: PropTypes.string,
//   logoAltText: PropTypes.string,
//   priorityNavClassName: PropTypes.string,
//   toggleButtonIcon: PropTypes.element, // Custom toggle button component
//   useActiveRouteStyle: PropTypes.bool, // Highlight active route
//   wrapInsideHeaderElement: PropTypes.bool, // Wrap inside header
//   sticky: PropTypes.bool, // Sticky nav
//   stickyScrollTriggerValue: PropTypes.number, // Scroll value to trigger sticky
// };

// export default React.memo(PriorityNav);
