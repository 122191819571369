/**
 * Avetmiss Reporting Service
 */
import HttpClient from "../../../coreLib/http/httpClient";

const AvetmissReportingSubmissionService = () => {
  let httpClient = HttpClient();

  const getStatus = () => {
    return httpClient.get("/v1/avetmiss/status");
  };

  const submitRequest = payload => {
    return httpClient.post("/v1/avetmiss/submit", payload);
  };

  const deleteRequest = payload => {
    return httpClient.delete("/v1/avetmiss");
  };

  const importNatFiles = (file) => {
    let payload = {
      file: file,
    };

    return httpClient.post("/v1/avetmiss/nat-files", payload);
  };

  const exportNatFiles = async () => {
    const resp = await HttpClient().get(`/v1/avetmiss/download`, {
      responseType: "blob",
    });

    if (resp && resp.status === 200) {
      return { data: resp.data, headers: resp.headers };
    }

    return null;
  };

  return {
    getStatus,
    submitRequest,
    deleteRequest,
    importNatFiles,
    exportNatFiles,
  };
};

export default AvetmissReportingSubmissionService;
