/**
 * Application wide settings
 * 7.04.2019
 */

const Config = {
  /** URLs Api configurations **/
  // apiUrl: "https://localhost:44313/api",
  //apiUrl: "https://api.foodsafetyeducation.com.au/api",
  apiUrl: process.env.REACT_APP_API_URL,

  /** Keys */

  // Auth Urls
  authLoginUrl: "/v1/auth/login", // BE endpoint
  authTokenUrl: "/v1/auth/token", // BE endpoint
  maintenancePageUrl: "/pages/maintenance",
  forbiddenPageUrl: "/pages/Error-403",

  // Redirects
  loginPageUrlRedirect: "/login",
  logoutRedirectUrl: "/",

  // Local sign-in
  clientId: "CB11B32330884911B13D4961D326EFBA",
  authStorageKey: "ident",

  // Google sign-in
  googleClientId:
    "287026400610-gettkeic6lrhuu63sgie5v8dmqenhn92.apps.googleusercontent.com",

  /** Feature or Functionality Settings **/
  enableGoogleLogin: false,

  // Loqate
  loqateUsername: process.env.REACT_APP_LOQATE_USERNAME,
  loqatePassword: process.env.REACT_APP_LOQATE_PASSWORD,

  crypto_key: process.env.REACT_APP_CRYPTO_KEY,
  crypto_vector: process.env.REACT_APP_CRYPTO_VECTOR,
};

export default Config;
