/**
 * Forbidden page (HTTP 403)
 */
import React, { Component } from "react";
import ContentSection from "../../../components/Content-Section";
import ArrowLinkButton from "../../../components/Arrow-Link-Button/Arrow-Link-Button";

class Error403 extends Component {
  state = {};
  render() {
    return (
      <>
        <ContentSection bgColor="grey-shadow">
          <h1 className="mb-4">403 - Access Forbidden...</h1>
          <h5 className="mb-0 text-muted font-medium">
            You do not have enough permission to access the page.
          </h5>
          <h5 className="text-muted font-medium">
            Contact your system administrator.
          </h5>
          <ArrowLinkButton
            to="/Login/"
            title="Start Your Food Safety Training Now!"
          />
        </ContentSection>
      </>
    );
  }
}

export default Error403;
