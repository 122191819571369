/**
 * Presentational Component
 *
 */
import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import InputField from "../../../components/Common/Fields/Input-Field";
import RadioButtonList from "../../../components/Common/Fields/Radio-Button-List";
import SignaturePad from "../../../components/Common/signature-pad/signature-pad";

const AdditionalInfoForm = (props) => {
  const {
    onInputChange,
    additionalComments,
    studentPerformanceSatisfactory,
    confirmThirdPartyDeclaration,
    signature,
  } = props;

  return (
    <div className="mt-3">
      <FormGroup>
        <Label className="field-label" for="overallPerf">
          Overall the student’s performance was
        </Label>
        <div>
          <RadioButtonList
            id="overallPerf"
            name="studentPerformanceSatisfactory"
            items={[
              { label: "Satisfactory", value: "1" },
              { label: "Not Satisfactory", value: "0" },
            ]}
            value={studentPerformanceSatisfactory}
            onChange={onInputChange}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Label className="field-label" for="additionalInfo">
          Any additional comments/information
        </Label>
        <InputField
          id="additionalInfo"
          name="additionalComments"
          type="textarea"
          value={additionalComments}
          onChange={onInputChange}
        />
      </FormGroup>
      <FormGroup check className="mb-3">
        <Input
          type="checkbox"
          name="confirmThirdPartyDeclaration"
          id="confirmation"
          checked={confirmThirdPartyDeclaration === "true"}
          onChange={(e) =>
            onInputChange({
              name: e.target.name,
              value: e.target.checked.toString(),
              error: false,
            })
          }
        />
        <Label for="confirmation" check>
          I confirm that the information provided in this report, is to the best
          of my knowledge, an accurate reflection of the student&rsquo;s
          satisfactory practical skills in the related tasks. I have read this
          document in full and understand what is required and I agree to be
          contacted if further verification of this report is required.
        </Label>
      </FormGroup>
      {confirmThirdPartyDeclaration === "true" && (
        <FormGroup className="mb-3">
          <Label className="field-label" for="signature">
            Signature
          </Label>
          <SignaturePad
            id={`signature`}
            initialValue={signature}
            isReadonly={false}
            onChange={(value) =>
              onInputChange({
                name: "signature",
                value: value,
                error: false,
              })
            }
          />
        </FormGroup>
      )}

      <h5>
        You can save your changes at any time, and come back at a later date to
        complete this form.
      </h5>
    </div>
  );
};

export default AdditionalInfoForm;
