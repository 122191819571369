/**
 * Checkbox
 * Adam Luck @solvable Jun 13, 2019
 */
import React, { Component } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import "./Fields.scss";

const EVENT_DEBOUNCE = 2000;

class CheckboxField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    label: PropTypes.string.isRequired,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFieldError: PropTypes.func,
    enableFieldColorError: PropTypes.bool,
    enableFieldErrorMessage: PropTypes.bool,
    enableFieldErrorNotification: PropTypes.bool
  };

  static defaultProps = {
    enableFieldColorError: true,
    enableFieldErrorMessage: false,
    enableFieldErrorNotification: true,
    onFieldError: () => {}
  };

  state = {
    value: this.props.value,
    error: false
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.debouncedCallback = debounce(
      (evt, error) => this.fireFieldErrorEvent(error),
      EVENT_DEBOUNCE
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  fireFieldErrorEvent = error => this.props.onFieldError(error);

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    return { value: props.value };
  }

  onChange = evt => {
    this._detectChange(evt.target.checked ? "on" : false);
  };

  onBlur = evt => {
    let error = this._detectChange(evt.target.checked ? "on" : false);

    // Persist react synthetic event
    evt.persist();
    if (error) this.debouncedCallback(evt, error);
  };

  _detectChange = value => {
    const name = this.props.name;
    //const value = evt.target.checked ? "on" : false;
    const error = this.props.validate ? this.props.validate(value) : false;
    this.setState({ value, error });
    this.props.onChange({ name, value, error });

    return error;
  };

  // Note: Internal function, used by this component
  _validateField = () => {
    if (!this._isMounted) {
      return;
    }
    let error = this._detectChange(this.state.value);
    if (error) this.debouncedCallback(null, error);
    return { name: this.props.name, error: error };
  };

  _isValidComponentInstance = () => {
    return this._isMounted;
  };

  render() {
    const {
      validate,
      label,
      value,
      enableFieldColorError,
      enableFieldErrorMessage,
      enableFieldErrorNotification,
      onFieldError,
      ...other
    } = this.props;

    return (
      <div className="inline-block">
        <div className={this.state.error ? "field-error-checkbox" : ""}>
          <FormGroup check>
            <div>
              <div>
                <Label check>
                  <Input
                    {...other}
                    type="checkbox"
                    defaultChecked={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                  />
                  {label}
                </Label>
              </div>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default CheckboxField;
