/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  YOUR_COURSES_LOAD_REQUEST: "YOUR_COURSES_LOAD_REQUEST",
  YOUR_COURSES_LOAD_SUCCESS: "YOUR_COURSES_LOAD_SUCCESS",
  YOUR_COURSES_LOAD_FAILURE: "YOUR_COURSES_LOAD_FAILURE",
  YOUR_COURSES_TOGGLE_IS_AWESOME_REQUEST:
    "YOUR_COURSES_TOGGLE_IS_AWESOME_REQUEST",
  YOUR_COURSES_TOGGLE_IS_AWESOME_SUCCESS:
    "YOUR_COURSES_TOGGLE_IS_AWESOME_SUCCESS",
  YOUR_COURSES_TOGGLE_IS_AWESOME_FAILURE:
    "YOUR_COURSES_TOGGLE_IS_AWESOME_FAILURE",

  // Form Actions
  YOUR_COURSES_FORM_INPUT_CHANGE: "YOUR_COURSES_FORM_INPUT_CHANGE",

  YOUR_COURSES_DOWNLOAD_CERTIFICATE_REQUEST:
    "YOUR_COURSES_DOWNLOAD_CERTIFICATE_REQUEST",
  YOUR_COURSES_DOWNLOAD_CERTIFICATE_SUCCESS:
    "YOUR_COURSES_DOWNLOAD_CERTIFICATE_SUCCESS",
  YOUR_COURSES_DOWNLOAD_CERTIFICATE_FAILURE:
    "YOUR_COURSES_DOWNLOAD_CERTIFICATE_FAILURE",

  YOUR_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST:
    "YOUR_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST",
  YOUR_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS:
    "YOUR_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS",
  YOUR_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE:
    "YOUR_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE",

  YOUR_COURSES_DOWNLOAD_TPF_REQUEST: "YOUR_COURSES_DOWNLOAD_TPF_REQUEST",
  YOUR_COURSES_DOWNLOAD_TPF_SUCCESS: "YOUR_COURSES_DOWNLOAD_TPF_SUCCESS",
  YOUR_COURSES_DOWNLOAD_TPF_FAILURE: "YOUR_COURSES_DOWNLOAD_TPF_FAILURE",
};
