import React, { Component } from "react";

class AppWrapper extends Component {
  render() {
    const { children } = this.props;
    const { loggedIn } = this.props.loginData;

    return (
      <div className={loggedIn ? "ttg-app ttg-app-pv" : "ttg-app"}>
        {children}
      </div>
    );
  }
}

export default AppWrapper;
