import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const send = payload => {
    return httpClient.post("v1/contactus/send", payload);
  };

  return {
    send
  };
};

export default Service;
