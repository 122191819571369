import React from "react";
import PropTypes from "prop-types";
import ContentSectionContainer from "../../content-section-container/content-section-container";
import "./intersecting-waves-scattered.scss";

function IntersectingWavesScattered({
  id,
  children,
  bgOuterColor = "#fff",
  bgInnerColor = "#fff",
  padding = 4,
  specificClass,
  isFullWidth = false,
  showTopSvg = true,
}) {
  return (
    <div
      className={`content-section-intersecting-waves-scattered${
        specificClass ? ` ${specificClass}` : ""
      }`}
      style={{ backgroundColor: bgInnerColor }}
      {...(id ? { id } : {})}
    >
      {showTopSvg && (
        <section className="content-section-intersecting-waves-scattered-top">
          <div className="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
              style={{ backgroundColor: bgInnerColor }}
            >
              <path
                d="M0 1v99c134.3 0 153.7-99 296-99H0Z"
                opacity=".5"
                fill={bgOuterColor}
              />
              <path
                d="M1000 4v86C833.3 90 833.3 3.6 666.7 3.6S500 90 333.3 90 166.7 4 0 4h1000Z"
                opacity=".5"
                fill={bgOuterColor}
              />
              <path
                d="M617 1v86C372 119 384 1 196 1h421Z"
                opacity=".5"
                fill={bgOuterColor}
              />
              <path
                d="M1000 0H0v52C62.5 28 125 4 250 4c250 0 250 96 500 96 125 0 187.5-24 250-48V0Z"
                fill={bgOuterColor}
              />
            </svg>
          </div>
        </section>
      )}
      <ContentSectionContainer
        padding={padding}
        isFullWidth={isFullWidth}
        children={children}
      />
    </div>
  );
}

IntersectingWavesScattered.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string,
  bgOuterColor: PropTypes.string,
  bgInnerColor: PropTypes.string,
  padding: PropTypes.number,
  specificClass: PropTypes.string,
  isFullWidth: PropTypes.bool,
  showTopSvg: PropTypes.bool,
};

export default IntersectingWavesScattered;
