Object.defineProperty(exports, "__esModule", { value: true });

function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = _interopDefault(require("react"));

var dinersclub = React.createElement(
  "g",
  {
    id: "319",
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  },
  React.createElement(
    "g",
    {
      id: "New-Icons",
      transform: "translate(-320.000000, -280.000000)",
      fillRule: "nonzero"
    },
    React.createElement(
      "g",
      {
        id: "Card-Brands",
        transform: "translate(40.000000, 200.000000)"
      },
      React.createElement(
        "g",
        {
          id: "Color",
          transform: "translate(0.000000, 80.000000)"
        },
        React.createElement(
          "g",
          {
            id: "Diners-Club",
            transform: "translate(280.000000, 0.000000)"
          },
          React.createElement("path", {
            d:
              "M21.9972414,15.749927 L21.999381,15.7499362 C22.9544683,15.7581106 23.73806,14.9772525 23.75,14.0041555 L23.7500083,2.00630219 C23.7461702,1.53568921 23.5588633,1.08617106 23.2297297,0.756801782 C22.9014319,0.428268884 22.4589161,0.246148853 21.9972414,0.250070854 L2.00063,0.250061791 C1.54108393,0.246148853 1.09856813,0.428268884 0.77027028,0.756801782 C0.441136651,1.08617106 0.253829819,1.53568921 0.25,2.00426336 L0.249991686,13.9936957 C0.253829819,14.4643086 0.441136651,14.9138268 0.77027028,15.2431961 C1.09856813,15.571729 1.54108393,15.753849 2.00275862,15.749927 L21.9972414,15.749927 Z M21.996203,16.249927 C21.9958359,16.249924 21.9954688,16.249921 21.9951018,16.2499178 L21.9972414,16.249927 L21.996203,16.249927 Z",
            id: "shape",
            strokeOpacity: "0.2",
            stroke: "#000000",
            strokeWidth: "0.5",
            fill: "#FFFFFF"
          }),
          React.createElement("path", {
            d:
              "M10.0021142,2.05179033 L10.0021142,2.03579033 L14.0021142,2.03579033 L14.0021142,2.05179033 C17.1375481,2.28122918 19.5642283,4.89197286 19.5642283,8.03579033 C19.5642283,11.1796078 17.1375481,13.7903515 14.0021142,14.0197903 L14.0021142,14.0357903 L10.0021142,14.0357903 L10.0021142,14.0197903 C6.86668021,13.7903515 4.44,11.1796078 4.44,8.03579033 C4.44,4.89197286 6.86668021,2.28122918 10.0021142,2.05179033 Z",
            id: "shape",
            fill: "#0165AC"
          }),
          React.createElement("path", {
            d:
              "M11.6021142,11.4277903 C13.0374002,10.9175027 13.9961556,9.55908923 13.9961556,8.03579033 C13.9961556,6.51249143 13.0374002,5.15407792 11.6021142,4.64379033 L11.6021142,11.4277903 L11.6021142,11.4277903 Z M9.20211417,4.64379033 C7.76682809,5.15407792 6.80807271,6.51249143 6.80807271,8.03579033 C6.80807271,9.55908923 7.76682809,10.9175027 9.20211417,11.4277903 L9.20211417,4.64379033 L9.20211417,4.64379033 Z M10.4021142,13.2357903 C7.53023347,13.2357903 5.20211417,10.907671 5.20211417,8.03579033 C5.20211417,5.16390963 7.53023347,2.83579033 10.4021142,2.83579033 C13.2739949,2.83579033 15.6021142,5.16390963 15.6021142,8.03579033 C15.6021142,10.907671 13.2739949,13.2357903 10.4021142,13.2357903 Z",
            id: "shape",
            fill: "#FFFFFF"
          })
        )
      )
    )
  )
);

exports.default = dinersclub;
