import { connect } from "react-redux";
import Footer from "./Footer";
import { selectLoginData } from "../../redux/system/login-reducer";

function mapStateToProps(state) {
  const stateLogin = selectLoginData(state);

  return {
    loginData: stateLogin
  };
}

const FooterContainer = connect(mapStateToProps)(Footer);

export default FooterContainer;
