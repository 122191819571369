/**
 * Confirm Modal
 * Another flavor of Modal dialog
 *
 * Propeties exposed:
 * - title
 * - isModalOpen
 * - onToggle
 *
 * Requires to pass objectTag value (id, object to delete etc.)
 */
import React from "react";
import BasicModal from "./../Basic-Modal/Basic-Modal";

const ConfirmModal = (props) => (
  <BasicModal
    title={props.title}
    className={props.className}
    isOpen={props.isModalOpen}
    isLoading={props.isLoading}
    toggle={(evt) => props.onToggle({ type: evt, objectTag: props.objectTag })}
    okButtonText={"Yes"}
    cancelButtonText={"No"}
  >
    {props.children}
  </BasicModal>
);

export default ConfirmModal;
