import { ActionTypes } from "./action-types";
import HttpService from "../service";
import { addNotification } from "../../../../../redux/system/system-action-creators";
import CourseService from "../../../../../services/course-service";
import moment from "moment";
import { saveAs } from "file-saver";

export const onLoad = () => (dispatch, getState) => {
  let fieldValues = getState().userCourses.DataTable.fields;
  let params = fieldValues ? fieldValues : getInitialParams();
  dispatch(loadRequest(params));
};

export const loadRequest = (params) => (dispatch) => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_USER_COURSES_ONLOAD_REQUEST });
  service
    .loadCourses(params)
    .then((resp) => {
      dispatch(onLoadSuccess(resp.data));
    })
    .catch(() => {
      dispatch(onLoadFailure());
    });
};

export const resetData = () => (dispatch) => {
  let params = getInitialParams();
  dispatch(loadRequest(params));
};

export const onLoadSpecificUserCourse = (id) => (dispatch) => {
  let service = HttpService();

  service
    .loadSpecificUserCourses(id)
    .then((resp) => {
      dispatch(onLoadSuccess(resp.data));
    })
    .catch(() => {
      dispatch(addNotification("Error loading Course Enrolments.", "warning"));
    });
};

export const reset = () => (dispatch) => {
  dispatch({ type: ActionTypes.ADMIN_USERS_COURSES_RESET });
  dispatch(resetData());
};

const onLoadFailure = () => (dispatch) => {
  dispatch({
    type: ActionTypes.ADMIN_USER_COURSES_ONLOAD_FAILURE,
  });
};

const getInitialParams = () => {
  return {
    courseIds: CourseService()
      .getCourses()
      .map((course) => course.courseId),
    startDate: moment().subtract(12, "months")._d,
    endDate: moment()._d,
  };
};

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_USERS_COURSES_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error,
  },
});

const onLoadSuccess = (payload) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADMIN_USER_COURSES_ONLOAD_SUCCESS,
    payload,
  });
};

export const hideFilterSection = (payload) => ({
  type: ActionTypes.ADMIN_USERS_COURSES_HIDE_FILTER_SECTION,
  payload,
});

export const downloadTaxInvoice = (invoidGuid) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST,
  });
  let service = HttpService();
  service
    .downloadTaxInvoice(invoidGuid)
    .then((resp) => {
      dispatch(onDownloadTaxInvoiceSuccess());
      const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
      saveAs(pdfBlob, "taxinvoice.pdf");
    })
    .catch(() => {
      dispatch(onDownloadTaxInvoiceFailure());
      dispatch(addNotification("Unable to download tax invoice.", "warning"));
    });
};

const onDownloadTaxInvoiceSuccess = () => ({
  type: ActionTypes.ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS,
});

const onDownloadTaxInvoiceFailure = () => ({
  type: ActionTypes.ADMIN_USERS_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE,
});

export const keepDataTableSettings = (settings) => ({
  type: ActionTypes.ADMIN_USERS_COURSES_DATATABLE_KEEP_SETTINGS,
  payload: {
    settings,
  },
});

export const clearDataTableSearchboxFilter = () => ({
  type: ActionTypes.ADMIN_USERS_COURSES_DATATABLE_CLEAR_SEARCHBOX,
});
