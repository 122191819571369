import HttpClient from "../../../../coreLib//http/httpClient";
import moment from "moment";

const Service = () => {
  let httpClient = HttpClient();

  const load = payload => {
    return httpClient.post(
      "v1/admin/reports/excel",
      {
        startDate: moment(payload.startDate),
        endDate: moment(payload.endDate),
        reportType: payload.reportType
      },
      {
        responseType: "arraybuffer"
      }
    );
  };

  return {
    load
  };
};

export default Service;
