/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import CourseCompletedService from "./../course-completed-service";
import { surveyQuestions } from "./../../../constants/survey-content";

// ----------------------------
//  Actions
// ----------------------------

export const loadData = (courseContentToken, history) => {
  return dispatch => {
    dispatch(loadDataRequest());

    const service = CourseCompletedService();

    service
      .getStatus(courseContentToken)
      .then(resp => {
        const { hasCompleted, thirdPartyFormCompleted } = resp.data.status;
        const { courseTitle, courseId } = resp.data.courseInfo;

        //if (!hasCompleted || (hasCompleted && thirdPartyFormCompleted)) {
        if (!hasCompleted) {
          history.push(`/course-content/${courseContentToken}`);
        } else {
          let payload = {
            hasBeenCompleted: hasCompleted,
            thirdPartyFormCompleted: thirdPartyFormCompleted,
            courseTitle: courseTitle,
            courseId: courseId,
            courseContentToken: courseContentToken
          };

          dispatch(loadDataSuccess(payload));
        }
      })
      .catch(err => {
        console.error(err);
        dispatch(loadDataFailure(err));
        dispatch(
          addNotification("Unable to load data for this page.", "error")
        );
      });
  };
};

const loadDataRequest = () => ({
  type: ActionTypes.SURVEY_COURSE_COMPLETED_LOAD_DATA_REQUEST
});

const loadDataSuccess = payload => ({
  type: ActionTypes.SURVEY_COURSE_COMPLETED_LOAD_DATA_SUCCESS,
  payload: {
    ...payload
  }
});

const loadDataFailure = err => ({
  type: ActionTypes.SURVEY_COURSE_COMPLETED_LOAD_DATA_FAILURE,
  payload: err
});
