/**
 * Course Completed Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  hasBeenCompleted: false,
  thirdPartyFormCompleted: false,
  courseTitle: "",
  courseId: 0,
  isLoading: false,
  courseContentToken: ""
};

function CourseCompletedReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SURVEY_COURSE_COMPLETED_LOAD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.SURVEY_COURSE_COMPLETED_LOAD_DATA_SUCCESS:
      return {
        ...state,
        hasBeenCompleted: action.payload.hasBeenCompleted,
        thirdPartyFormCompleted: action.payload.thirdPartyFormCompleted,
        courseTitle: action.payload.courseTitle,
        courseId: action.payload.courseId,
        courseContentToken: action.payload.courseContentToken,
        isLoading: false
      };

    case ActionTypes.SURVEY_COURSE_COMPLETED_LOAD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}

export default CourseCompletedReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectCourseCompleted = state => state.courseCompleted;
