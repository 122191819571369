export const surveyQuestions = [
  {
    questionId: 1,
    question: "I developed the skills expected from this training."
  },
  {
    questionId: 2,
    question: "I identified ways to build on my current knowledge and skills."
  },
  {
    questionId: 3,
    question: "The training focused on relevant skills."
  },
  {
    questionId: 4,
    question: "I developed the knowledge expected from this training."
  },
  {
    questionId: 5,
    question: "The training prepared me well for work."
  },
  {
    questionId: 6,
    question: "I set high standards for myself in this training."
  },
  {
    questionId: 7,
    question: "The training had a good mix of theory and practice."
  },
  {
    questionId: 8,
    question: "I looked for my own resources to help me learn."
  },
  {
    questionId: 9,
    question: "Overall, I am satisfied with the training."
  },
  {
    questionId: 10,
    question: "I would recommend the training organisation to others."
  },
  {
    questionId: 11,
    question: "Training organisation staff respected my background and needs."
  },
  {
    questionId: 12,
    question: "I pushed myself to understand things I found confusing."
  },
  {
    questionId: 13,
    question: "Trainers had an excellent knowledge of the subject content."
  },
  {
    questionId: 14,
    question: "I received useful feedback on my assessments."
  },
  {
    questionId: 15,
    question:
      "The way I was assessed was a fair test of my skills and knowledge."
  },
  {
    questionId: 16,
    question: "I learned to work with people."
  },
  {
    questionId: 17,
    question: "The training was at the right level of difficulty for me."
  },
  {
    questionId: 18,
    question: "The amount of work I had to do was reasonable."
  },
  {
    questionId: 19,
    question: "Assessments were based on realistic activities."
  },
  {
    questionId: 20,
    question: "It was always easy to know the standards expected."
  },
  {
    questionId: 21,
    question: "Training facilities and materials were in good condition."
  },
  {
    questionId: 22,
    question: "I usually had a clear idea of what was expected of me."
  },
  {
    questionId: 23,
    question: "Trainers explained things clearly."
  },
  {
    questionId: 24,
    question:
      "The training organisation had a range of services to support learners."
  },
  {
    questionId: 25,
    question: "I learned to plan and manage my work."
  },
  {
    questionId: 26,
    question:
      "The training used up-to-date equipment, facilities and materials."
  },
  {
    questionId: 27,
    question: "I approached trainers if I needed help."
  },
  {
    questionId: 28,
    question: "Trainers made the subject as interesting as possible."
  },
  {
    questionId: 29,
    question: "I would recommend the training to others."
  },
  {
    questionId: 30,
    question:
      "The training organisation gave appropriate recognition of existing knowledge and skills."
  },
  {
    questionId: 31,
    question: "Training resources were available when I needed them."
  },
  {
    questionId: 32,
    question: "I was given enough material to keep up my interest."
  },
  {
    questionId: 33,
    question: "The training was flexible enough to meet my needs."
  },
  {
    questionId: 34,
    question: "Trainers encouraged learners to ask questions."
  },
  {
    questionId: 35,
    question:
      "Trainers made it clear right from the start what they expected from me."
  }
];
