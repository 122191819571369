export const ActionTypes = {
  ADMIN_PURCHASE_VOUCHERS_ONLOAD_REQUEST:
    "ADMIN_PURCHASE_VOUCHERS_ONLOAD_REQUEST",
  ADMIN_PURCHASE_VOUCHERS_ONLOAD_SUCCESS:
    "ADMIN_PURCHASE_VOUCHERS_ONLOAD_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_ONLOAD_FAILURE:
    "ADMIN_PURCHASE_VOUCHERS_ONLOAD_FAILURE",
  ADMIN_PURCHASE_VOUCHERS_FORM_INPUT_CHANGE:
    "ADMIN_PURCHASE_VOUCHERS_FORM_INPUT_CHANGE",
  ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS:
    "ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS:
    "ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS: "ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_REQUEST:
    "ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_REQUEST",
  ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_SUCCESS:
    "ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_SUCCESS",
  ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_FAILURE:
    "ADMIN_PURCHASE_VOUCHERS_DOWNLOAD_TAX_INVOICE_FAILURE",
  ADMIN_PURCHASE_VOUCHERS_DATATABLE_KEEP_SETTINGS:
    "ADMIN_PURCHASE_VOUCHERS_DATATABLE_KEEP_SETTINGS",
  ADMIN_PURCHASE_VOUCHERS_RESET: "ADMIN_PURCHASE_VOUCHERS_RESET",
  ADMIN_PURCHASE_VOUCHERS_DATATABLE_CLEAR_SEARCHBOX:
    "ADMIN_PURCHASE_VOUCHERS_DATATABLE_CLEAR_SEARCHBOX",
};
