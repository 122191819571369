/**
 * Authorise Avs Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  isDeleting: false,
  showDeleteDialog: false,
  showImportDialog: false,
  isImporting: false,
  isExporting: false,
  files: [],
  year: "",
  period: "",
  version: "",
  collectionStartDate: "",
  collectionEndDate: "",
  state: "",
};

function AvetmissReportingSubmissionReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        files: action.payload.files,
        year: action.payload.year,
        period: action.payload.period,
        version: action.payload.version,
        collectionStartDate: action.payload.collectionStartDate,
        collectionEndDate: action.payload.collectionEndDate,
        state: action.payload.state,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        showDeleteDialog: false,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        showDeleteDialog: false,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_SHOW_DELETE_DIALOG:
      return {
        ...state,
        showDeleteDialog: !state.showDeleteDialog,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_SHOW_IMPORT_DIALOG:
      return {
        ...state,
        showImportDialog: !state.showImportDialog,
      };
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_IMPORT_REQUEST:
      return {
        ...state,
        isImporting: true,
      };
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_IMPORT_SUCCESS:
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_IMPORT_FAILURE:
      return {
        ...state,
        isImporting: false,
        showImportDialog: false,
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_EXPORT_REQUEST:
      return {
        ...state,
        isExporting: true,
      };
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_EXPORT_SUCCESS:
    case ActionTypes.AVETMISS_REPORTING_SUBMISSION_EXPORT_FAILURE:
      return {
        ...state,
        isExporting: false,
      };

    default:
      return state;
  }
}

export default AvetmissReportingSubmissionReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectAvetmissReportingSubmission = state =>
  state.avetmissReportingSubmission;
