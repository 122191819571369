/**
 * Reducer function to manage the state of the priority navigation component.
 * Handles actions for moving items between the main nav and dropdown (move, return).
 *
 * State includes children (nav items), dropdownItems, and lastItemWidth.
 *
 * Adam Luck - Solvable - 2024-06-29
 */

export function reducer(state, action) {
  switch (action.type) {
    case "update":
      const initialChildren = action.payload.initialChildren ?? [];

      return {
        ...state,
        children: [...initialChildren],
        dropdownItems: [],
        lastItemWidth: [],
      };

    case "move":
      const lastChild = state.children[state.children.length - 1];
      const children = state.children.slice(0, -1);

      return {
        ...state,
        children,
        dropdownItems: [lastChild, ...state.dropdownItems],
        ...(action.payload.lastItem && {
          lastItemWidth: [
            ...state.lastItemWidth,
            action.payload.lastItem.clientWidth,
          ],
        }),
      };

    case "return":
      const [firstItemFromList, ...dropdownItems] = state.dropdownItems;
      const lastItemWidth =
        state.lastItemWidth.length > 0
          ? [...state.lastItemWidth].slice(0, -1)
          : [];

      return {
        children: [...state.children, firstItemFromList],
        dropdownItems,
        lastItemWidth,
      };

    default:
      return state;
  }
}
