/**
 * ButtonFx
 * - Progress spinner feature
 * rcnet @Solvable 5.5.2019
 *
 * props exposed:
 *   - onClick = event onClick
 *   - isLoading = true or false (spinner hide/show)
 *   - name = button name
 *   - targetName = name of button to trigger loading spinner
 *   - color  = refer to reacstrap button colors
 */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 5px;
`;

const ButtonFx = props => {
  const {
    isLoading,
    targetName,
    //color,
    name,
    onClick,
    size,
    round,
    icon,
    disabled
  } = props;
  const loadSpinner =
    (targetName === props.name || targetName === "") && isLoading;

  return (
    // <Button {...props} color={props.color} name={props.name} onClick={props.onClick} size={props.size} round={props.round} icon={props.icon} disabled={props.isLoading || props.disabled}>
    <Button
      //{...props}
      //color={""}
      type={props.type}
      className={props.className}
      name={name}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      size={size}
      round={round}
      icon={icon}
      disabled={isLoading || disabled}
    >
      {loadSpinner ? (
        <BeatLoader
          css={override}
          sizeUnit={"px"}
          size={10}
          color={"#fff"}
          loading={true}
        />
      ) : (
        <span>{props.children}</span>
      )}
    </Button>
  );
};

ButtonFx.propTypes = {
  //color: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  targetName: PropTypes.string,
  size: PropTypes.string,
  round: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func
};

ButtonFx.defaultProps = {
  //color: "primary",
  type: "button",
  name: "fxButton",
  targetName: "",
  isLoading: false,
  size: "md",
  round: "true",
  icon: "false"
};

export default ButtonFx;
