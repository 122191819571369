import React from "react";
import ContentSection from "components/Common/content-section";
import Carousel from "components/Carousel";
import * as Constants from "constants/index";
import "./happy-customers.scss";

const HappyCustomers = () => {
  const getTestimonials = () => {
    return Constants.customerTestimonials.map((testimonial, i) => (
      <div className="customer-testimonial">
        &ldquo;{testimonial.testimonial}&rdquo;
        <div className="customer-name">{testimonial.name}</div>
      </div>
    ));
  };

  return (
    <ContentSection bgClass="green-chef customer-testimonials">
      <Carousel
        items={getTestimonials()}
        owlOptions={{
          loop: true,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          margin: 10,
          nav: false,
          responsive: {
            0: {
              items: 1,
            },
          },
        }}
      />
    </ContentSection>
  );
};

export default HappyCustomers;
