import isNil from "lodash/isNil";
import React, { Component } from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ToggleDisplay from "react-toggle-display";
import { Button, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import BlockUiFx from "../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "../../components/Common/Button-Fx/Button-Fx";
import formHandler from "../../components/Common/Fields/form-handler";
import InputField from "../../components/Common/Fields/Input-Field";
import MaskedInputField from "../../components/Common/Fields/Masked-Input-Field";
import PaymentInputs from "../../components/Common/Fields/Payment-Inputs";
import RadioButtonList from "../../components/Common/Fields/Radio-Button-List";
import * as validators from "../../components/Common/Fields/Validators/Field-Validators";
import LoqateAddressField from "../../components/Common/Loqate-Single-Address-Field";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../components/Content-Section";

import "../Start/corporate-start.scss";

// Combination of validations
const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

const phoneValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);

  if (validators.phone(value)) return validators.phone(value);

  return false;
};

const getPhoneMask = (value) => {
  if (
    value.startsWith("02") ||
    value.startsWith("03") ||
    value.startsWith("07") ||
    value.startsWith("08")
  )
    return "99 9999 9999";

  return "9999 999 999";
};

class CorporatePurchaseVouchers extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.init();
    this.props.setEmail();
  }

  onAutoCompleteAddressSelect = (address) => {
    this.props.updateAutocompleteAddress(address);
  };

  onChange(value) {
    if (!value) {
      this.props.clearAddress();
    }
  }

  render() {
    const form = formHandler();

    const {
      onInputChange,
      onCreditCardChange,
      onInputQuantityChange,
      totalPrice,
      onFieldError,
      applyPromoCode,
      courses,
      onSubmit,
      isPromoCodeApplied,
      totalAfterDiscount,
      isSubmitting,
      clearAddress,
      toggleManualAddressEntry,
    } = this.props;
    const { fullAddress } = this.props.addressEntry;
    const {
      companyName,
      phone,
      email,
      purchaseNumber,
      cardName,
      promoCode,
      paymentMethod,
      cardNumber,
      cardExpiryDate,
      cardCvc,
    } = this.props.fields;

    return (
      <>
        <PageTitle title="Purchase New Vouchers" />
        <BlockUiFx blocking={isSubmitting} lowerZIndex={true}>
          <Form className="ttg-form">
            <ContentSection bgColor="grey-shadow" padding={4}>
              <h1>Purchase New Vouchers</h1>
            </ContentSection>
            <ContentSection padding={3}>
              <FormGroup>
                <Label for="companyName">Company Name</Label>
                <InputField
                  name="companyName"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={onInputChange}
                  ref={form.add}
                  validate={(val) => validators.required("Company Name", val)}
                  onFieldError={onFieldError}
                />
              </FormGroup>

              <FormGroup>
                <LoqateAddressField
                  name={"postalAddress"}
                  placeholder="Enter your address"
                  form={form}
                  initialValue={fullAddress}
                  onInputChange={onInputChange}
                  onAddressSelect={this.onAutoCompleteAddressSelect}
                  fields={this.props.fields}
                  onFieldError={onFieldError}
                  validate={(val) => validators.required("Address", val)}
                  ref={form.add}
                  clearAddress={clearAddress}
                  toggleManualEntry={toggleManualAddressEntry}
                />
              </FormGroup>

              <FormGroup>
                <Label for="phone">Telephone Number</Label>
                <MaskedInputField
                  name="phone"
                  placeholder="Telephone Number"
                  mask={getPhoneMask(phone)}
                  value={phone}
                  ref={form.add}
                  onChange={onInputChange}
                  onFieldError={onFieldError}
                  validate={(val) => phoneValidator("Telephone Number", val)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email Invoice To</Label>
                <InputField
                  name="email"
                  placeholder="Email Address"
                  ref={form.add}
                  value={email}
                  onChange={onInputChange}
                  validate={(val) => emailValidator("Email Address", val)}
                  onFieldError={onFieldError}
                />
              </FormGroup>
              <FormGroup>
                <Label for="purchaseNumber">
                  Purchase Order Number (optional)
                </Label>
                <InputField
                  name="purchaseNumber"
                  placeholder="Purchase Order Number"
                  ref={form.add}
                  value={purchaseNumber}
                  onChange={onInputChange}
                />
              </FormGroup>

              <Table className="new-enrolment-table">
                <thead>
                  <tr>
                    <th>Course</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((course, i) => (
                    <tr key={i}>
                      <td className="course-val">
                        {course.id === 6 ? (
                          <>
                            Food Safety Supervisor Hospitality &amp; Retail{" "}
                            <span
                              style={{ color: "#1e2b5f", fontWeight: "700" }}
                            >
                              (The NSW Food Safety Supervisor Certificate)
                            </span>
                            <br />
                            (SITXFSA005, SITXFSA006)
                          </>
                        ) : (
                          <>
                            {course.courseTitle}
                            <br />
                            {"(" + course.courseCodes + ")"}
                          </>
                        )}
                      </td>
                      <td className="cost-val">${course.courseCost}.00</td>
                      <td className="action-btns" style={{ maxWidth: "80px" }}>
                        <InputField
                          name={`${course.id}`}
                          placeholder="0"
                          value={`${course.quantity ?? 0}`}
                          onChange={(c) => onInputQuantityChange(c)}
                          className="mxw-400"
                        />
                      </td>
                      <td>${course.total}.00</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <ToggleDisplay if={totalPrice > 0}>
                        <strong>Total : </strong>
                      </ToggleDisplay>
                    </td>
                    <td>
                      <ToggleDisplay if={totalPrice > 0}>
                        <Row>
                          <Col>
                            <ToggleDisplay if={isPromoCodeApplied}>
                              <strong>
                                <del>
                                  <span style={{ color: "grey" }}>
                                    {totalPrice && (
                                      <span>
                                        ${Number(totalPrice).toFixed(2)}
                                      </span>
                                    )}
                                  </span>
                                </del>
                              </strong>
                            </ToggleDisplay>
                            <ToggleDisplay if={!isPromoCodeApplied}>
                              <strong>
                                {totalPrice && <span>${totalPrice}</span>}
                              </strong>
                            </ToggleDisplay>
                          </Col>
                        </Row>
                        <ToggleDisplay if={isPromoCodeApplied}>
                          <Row>
                            <Col>
                              <strong>
                                {totalPrice && (
                                  <span>
                                    ${Number(totalAfterDiscount).toFixed(2)}
                                  </span>
                                )}
                              </strong>
                            </Col>
                          </Row>
                        </ToggleDisplay>
                      </ToggleDisplay>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <div>
                <ToggleDisplay if={!isPromoCodeApplied}>
                  <FormGroup>
                    <Row>
                      <Col xs="auto">
                        <div className="mb-2">
                          <Label for="promoCode">Promo Code</Label>
                          <InputField
                            name="promoCode"
                            placeholder="Promo Code"
                            type="text"
                            value={promoCode}
                            onKeyPress={applyPromoCode}
                            onChange={onInputChange}
                            className="mxw-400"
                            style={{ maxWidth: "250px" }}
                          />
                        </div>
                      </Col>
                      <Col xs="auto">
                        <Button
                          style={{ marginTop: "30px" }}
                          onClick={applyPromoCode}
                          className="ttg-btn"
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </ToggleDisplay>

                <div className="mb-4">
                  <label>Payment Method</label>
                  <div>
                    <RadioButtonList
                      name="paymentMethod"
                      showBorderError={false}
                      value={paymentMethod}
                      ref={form.add}
                      validate={(val) =>
                        val === "-1" ||
                        val === "" ||
                        val === undefined ||
                        val === null
                          ? "Payment method is required"
                          : false
                      }
                      onFieldError={onFieldError}
                      items={[{ value: "Credit Card" }, { value: "Invoice" }]}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                {paymentMethod === "Credit Card" && (
                  <FormGroup>
                    <div>
                      <Label>Credit Card Details</Label>
                    </div>

                    <PaymentInputs
                      onInputChange={onInputChange}
                      onFieldError={onFieldError}
                      cardName={cardName}
                      form={form}
                      cardNumber={cardNumber}
                      cardExpiryDate={cardExpiryDate}
                      cardCvc={cardCvc}
                      onChange={onCreditCardChange}
                    />
                  </FormGroup>
                )}
                <h2 className="mt-2">
                  $
                  {isPromoCodeApplied
                    ? totalAfterDiscount.toFixed(2)
                    : totalPrice}
                </h2>

                <FormGroup>
                  <ButtonFx
                    type="submit"
                    className="ttg-btn"
                    isLoading={isSubmitting}
                    onClick={() => {
                      if (paymentMethod !== "Invoice") {
                        if (isNil(cardNumber) || !cardNumber) {
                          onInputChange({
                            name: "cardNumber",
                            value: "",
                            error: "Card number is required",
                          });
                          onFieldError("Card number is required.");
                        }

                        if (isNil(cardExpiryDate) || !cardExpiryDate) {
                          onInputChange({
                            name: "cardExpiryDate",
                            value: "",
                            error: "Card expiry date is required.",
                          });
                          onFieldError("Card expiry date is required.");
                        }

                        if (isNil(cardCvc) || !cardCvc) {
                          onInputChange({
                            name: "cardCvc",
                            value: "",
                            error: "Card CVC is required.",
                          });
                          onFieldError("Card CVC is required.");
                        }
                      }

                      form.validate({
                        validFn: () => {
                          onSubmit();
                        },
                        invalidFn: () => console.log(form.getFieldErrors()),
                      });
                    }}
                  >
                    {paymentMethod === "Credit Card"
                      ? "Purchase Now"
                      : "Order Now"}
                  </ButtonFx>
                </FormGroup>
              </div>
            </ContentSection>
            <KeyboardEventHandler
              handleKeys={["enter"]}
              onKeyEvent={(key, e) =>
                form.validate({
                  validFn: () => onSubmit(),
                })
              }
            />
          </Form>
        </BlockUiFx>
      </>
    );
  }
}

export default CorporatePurchaseVouchers;
