import { ActionTypes } from "./action-types";
import DateService from "../../../services/date-service";

const initialState = {
  data: [],
  isProcessing: false
};

function UpdateProfileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CORPORATE_TAX_INVOICE_ON_INIT:
      return {
        ...state,
        data: [],
        isProcessing: false
      };
    case ActionTypes.CORPORATE_TAX_INVOICE_DOWNLOAD_REQUEST:
    case ActionTypes.CORPORATE_TAX_INVOICE_ON_LOAD_REQUEST:
      return {
        ...state,
        isProcessing: true
      };
    case ActionTypes.CORPORATE_TAX_INVOICE_ON_LOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        data: action.payload.map(tax => {
          return {
            ...tax,
            invoiceDate: DateService().formatDateFromString(
              tax.invoiceDate,
              "DD/MM/YYYY"
            ),
            total: `$${tax.total}.00`
          };
        })
      };
    case ActionTypes.CORPORATE_TAX_INVOICE_DOWNLOAD_SUCCESS:
    case ActionTypes.CORPORATE_TAX_INVOICE_DOWNLOAD_FAILURE:
    case ActionTypes.CORPORATE_TAX_INVOICE_ON_LOAD_FAILURE:
      return {
        ...state,
        isProcessing: false
      };
    default:
      return state;
  }
}

export default UpdateProfileReducer;

export const selectCorporateTaxInvoice = state => state.corporateTaxInvoice;
