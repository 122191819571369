/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  REGISTER_BOX_REGISTER_REQUEST: "REGISTER_BOX_REGISTER_REQUEST",
  REGISTER_BOX_REGISTER_SUCCESS: "REGISTER_BOX_REGISTER_SUCCESS",
  REGISTER_BOX_REGISTER_FAILURE: "REGISTER_BOX_REGISTER_FAILURE",

  // Form Actions
  REGISTER_BOX_FORM_INPUT_CHANGE: "REGISTER_BOX_FORM_INPUT_CHANGE",

  REGISTER_BOX_FORM_NO_LAST_NAME: "REGISTER_BOX_FORM_NO_LAST_NAME",
};
