/**
 * Avetmiss Reporting Validation Service
 */
import HttpClient from "../../../coreLib/http/httpClient";

const AvetmissReportingValidationService = () => {
  let httpClient = HttpClient();

  const getStatus = () => {
    return httpClient.get("/v1/avetmiss/status");
  };

  const submitRequest = payload => {
    return httpClient.put("/v1/avetmiss/validation/update", payload);
  };

  const deleteRequest = payload => {
    return httpClient.delete("/v1/avetmiss");
  };

  const downloadNatFiles = () => {
    return httpClient.get("/v1/avetmiss/download", {
      responseType: "blob"
    });
  };

  return {
    getStatus,
    submitRequest,
    deleteRequest,
    downloadNatFiles
  };
};

export default AvetmissReportingValidationService;
