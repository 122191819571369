import { connect } from "react-redux";
import CourseContent from "./Course-Content";
import { selectCourseContent } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";

function mapStateToProps(state) {
  const courseContent = selectCourseContent(state);

  return {
    courseContent: courseContent
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onNextPage: () => {
    dispatch(actionCreators.onNextPage());
  },
  onPreviousPage: () => {
    dispatch(actionCreators.onPreviousPage());
  },
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadSection: courseContentToken => {
    dispatchProps.dispatch(
      actionCreators.onLoadSection(
        courseContentToken,
        stateProps.courseContent.allowRedirect,
        dispatchProps.history
      )
    );
  },
  onLoadQuiz: () => {
    dispatchProps.dispatch(
      actionCreators.onLoadQuiz(
        dispatchProps.match.params.userCourseId,
        dispatchProps.history
      )
    );
  }
});

const CourseContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(CourseContent);

export default CourseContentContainer;
