/**
 * Course Enrolment Step 1 Container
 * Adam Luck @solvable Jun 10, 2019
 */
import { connect } from "react-redux";
import * as actionCreators from "../redux/action-creators";
import Step1Form from "./Step-1-Form";
import { selectEnrolment } from "../redux/enrolment-reducer";
import { selectStep1 } from "../redux/step-1-reducer";
import { selectLoginData } from "../../../redux/system/login-reducer";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  const step1Data = selectStep1(state);
  const loginData = selectLoginData(state);
  const enrolmentData = selectEnrolment(state);

  return {
    addressEntry: step1Data.addressEntry,
    fields: step1Data.fields,
    fieldErrors: step1Data.fieldErrors,
    isCreditCardValid: step1Data.isCreditCardValid,
    loginData: loginData,
    hasPromoCode: step1Data.hasPromoCode,
    isSubmitting: step1Data.isSubmitting,
    showConfirmNamePopup: step1Data.showConfirmNamePopup,
    discountedPrice: step1Data.discountedPrice,
    isApplyingDiscountCode: step1Data.isApplyingDiscountCode,
    isDiscountCodeApplied: step1Data.isDiscountCodeApplied,
    showDiscountCodeForm: step1Data.showDiscountCodeForm,
    discountCode: step1Data.discountCode,
    courseDetails: enrolmentData.courseDetails,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadCourseDetails: (courseId) => {
    dispatch(actionCreators.onLoadCourseDetails(courseId));
  },
  updateAutocompleteAddress: (address) => {
    dispatch(actionCreators.updateStep1FormAutocompleteAddress(address));
  },
  toggleManualAddressEntry: () => {
    dispatch(actionCreators.toggleManualAddressEntry());
  },
  togglePromoCodeEntry: () => {
    dispatch(actionCreators.togglePromoCodeEntry());
  },
  toggleDiscountCodeForm: () => {
    dispatch(actionCreators.toggleDiscountCodeForm());
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.step1FormInputChange(name, value, error));
  },
  onCreditCardChange: (values) => {
    dispatch(actionCreators.step1CreditCardInputChange(values));
  },
  setEmail: () => {
    dispatch(actionCreators.setEmail());
  },
  init: (hasCourses) => {
    dispatch(actionCreators.step1Init(hasCourses));
  },
  clearAddress: () => {
    dispatch(actionCreators.clearStep1FormAddress());
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  toggleShowConfirmNamePopup: () => {
    dispatch(actionCreators.toggleShowConfirmNamePopup());
  },
  onLogoutAndRegister: (courseId) => {
    dispatch(
      sysActionCreators.onLogout(
        `/Certificates/Enrol/${courseId}/`,
        ownProps.history
      )
    );
  },
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match,
});

const validate = (stateProps) => {
  const fields = stateProps.fields;
  const fieldErrors = stateProps.fieldErrors;
  const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

  if (!fields.email) return true;
  if (!fields.postcode) return true;
  if (!fields.state) return true;
  if (!fields.city) return true;
  if (!fields.streetName) return true;
  if (errMessages.length > 0) return true;

  if (stateProps.hasPromoCode) {
    if (!fields.promoCode) return true;
  } else {
    if (!fields.cardName) return true;
    if (!stateProps.isCreditCardValid) return true;
  }

  if (stateProps.addressEntry.enterAddressManually && !fields.streetNumber)
    return true;

  return false;
};

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  toggleManualAddressEntry: () => {
    dispatchProps.dispatch(dispatchProps.toggleManualAddressEntry);
    validate(stateProps);
  },
  onSubmitEnrolment: () => {
    dispatchProps.dispatch(
      actionCreators.onSubmitEnrolmentStep1(
        stateProps,
        stateProps.fields,
        dispatchProps.history
      )
    );
  },
  applyDiscountCode: () => {
    dispatchProps.dispatch(
      actionCreators.applyDiscountCode(
        stateProps.courseDetails.courseId,
        stateProps.fields.discountCode
      )
    );
  },
  validate: () => {
    return validate(stateProps);
  },
});

const Step1FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(Step1Form);

export default Step1FormContainer;
