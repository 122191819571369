/**
 * NSW Reprints Data table
 */
import React, { Component } from "react";
import BlockUiFx from "./../../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import SolvableDataTable from "./../../../../components/solvable-datatable/solvable-datatable";
import ViewDetailsNswCertificate from "./../Modals/View-Details";
import "../nsw-reprint.scss";

class NswReprintsDataTable extends Component {
  constructor(props) {
    super(props);
    this.columns = this.setupDataTable();
  }

  setupDataTable = () => {
    return [
      {
        Header: "First Name",
        accessor: "firstName",
        searchable: true
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        searchable: true
      },
      {
        Header: "Email",
        accessor: "email",
        searchable: true
      },
      {
        Header: "Phone",
        accessor: "phone",
        searchable: true
      },
      {
        Header: "Purchased Date/Time",
        accessor: "purchasedDateTime",
        searchable: true,
        width: 200
      },
      {
        Header: "Status",
        accessor: "status",
        searchable: true
      }
    ];
  };

  render() {
    const { isLoading, data, settings } = this.props.dataTable;
    const {
      handleError,
      onSettingsChanged,
      onActionButtonHandler
    } = this.props;
    return (
      <div>
        <BlockUiFx blocking={isLoading} lowerZIndex={true}>
          <ViewDetailsNswCertificate {...this.props} />
          <SolvableDataTable
            columns={this.columns}
            data={data}
            defaultPageSize={50}
            onError={handleError}
            keepSettings={true}
            stateSettings={settings}
            onSettingsChanged={onSettingsChanged}
            actionButtons={{
              type: "customButton",
              containerWidth: 100,
              custom: [
                {
                  target: "button1",
                  icon: "fas fa-list-ul"
                }
              ]
            }}
            onActionButtonClick={onActionButtonHandler}
          />
        </BlockUiFx>
      </div>
    );
  }
}

export default NswReprintsDataTable;
