import moment from "moment";
import { addNotification } from "../../../../redux/system/system-action-creators";
import ApiService from "../services/api-service";
import { ActionTypes } from "./action-types";

export const formInputChange = (name, value, error) => (dispatch) => {
  const natFileNumber = parseInt(name.match(/(\d+)-/im)[1]);
  const recordNumber = parseInt(name.match(/-(\d+)/im)[1]);

  dispatch({
    type: ActionTypes.AVETMISS_ADDRESS_VALIDATION_FORM_INPUT_CHANGE,
    payload: {
      natFileNumber: natFileNumber,
      recordNumber: recordNumber,
      fieldValue: value,
    },
  });
};

export const submitChanges = (
  collectionStartDate,
  collectionEndDate,
  state,
  version,
  year,
  period,
  errors,
  history
) => (dispatch) => {
  dispatch(submitChangesRequest());

  const service = ApiService();

  const fixes = errors
    .filter((x) => x.updated)
    .map((error) => ({
      natFileNumber: error.natFileNumber,
      version: error.version,
      fileId: error.fileId,
      recordNumber: error.recordNumber,
      fieldValue: error.fieldValue,
    }));

  const formatDateToYYYYMMDD = (date) => {
    const isDDMMYYYY = /^(\d{2})\/(\d{2})\/(\d{4})$/.test(date);

    return isDDMMYYYY ? moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") : date;
  };

  const payload = {
    collectionStartDate: formatDateToYYYYMMDD(collectionStartDate),
    collectionEndDate: formatDateToYYYYMMDD(collectionEndDate),
    state: state,
    version: version,
    year: year,
    period: period,
    fixes: fixes,
  };

  if (fixes.length > 0) {
    service
      .submitChanges(payload)
      .then((resp) => {
        dispatch(submitChangesSuccess());
        dispatch(addNotification("Changes saved.", "info"));

        dispatch({
          type: "AVETMISS_REPORTING_SUBMISSION_LOAD_FORM_SUCCESS",
          payload: {
            files: resp.data.reports,
            year: resp.data.year,
            period: resp.data.period,
            version: resp.data.version,
            collectionStartDate: collectionStartDate,
            collectionEndDate: collectionEndDate,
            state: state,
          },
        });

        history.push("/Admin/Avetmiss/Submission/");
      })
      .catch((err) => {
        dispatch(submitChangesFailure());
        dispatch(
          addNotification(
            "There was an issue applying address validation error fixes.",
            "error"
          )
        );
      });

    return;
  }

  dispatch(submitChangesFailure());
  dispatch(addNotification("No changes have been detected.", "error"));
};

const submitChangesRequest = () => ({
  type: ActionTypes.AVETMISS_ADDRESS_VALIDATION_SUBMIT_REQUEST,
});
const submitChangesSuccess = () => ({
  type: ActionTypes.AVETMISS_ADDRESS_VALIDATION_SUBMIT_SUCCESS,
});
const submitChangesFailure = () => ({
  type: ActionTypes.AVETMISS_ADDRESS_VALIDATION_SUBMIT_FAILURE,
});
