import React, { Component } from "react";
import PropTypes from "prop-types";
import "./section.scss";
import FruitBg from "../../assets/images/fruit-bg.jpg";
import FoodBg from "../../assets/images/food-bg.jpg";
import withSizes from "react-sizes";

class ContentSection extends Component {
  state = {};

  static defaultProps = {
    padding: 3,
    bgColor: "",
    isFullWidth: false,
  };

  renderBackgroundImage(bgColor) {
    switch (bgColor) {
      case "fruit":
        return (
          <>
            <img className="fruit-img" src={FruitBg} alt="" />
            <div className="top-shadow" />
          </>
        );
      case "food":
        return <img className="food-img" src={FoodBg} alt="" />;
      default:
        return "";
    }
  }

  render() {
    const padding = this.props.padding ? this.props.padding : 5;
    const isFullWidth = this.props.isFullWidth ? this.props.isFullWidth : false;
    const { isDeviceSmall } = this.props;

    return (
      <div
        className={
          this.props.bgColor ? "container-bg-" + this.props.bgColor : ""
        }
      >
        {this.renderBackgroundImage(this.props.bgColor)}

        <div
          className={
            isDeviceSmall || isFullWidth
              ? "container-fluid py-" + padding + " content-section"
              : "container py-" + padding + " content-section"
          }
        >
          {/* <div
          className={
            isDeviceSmall || isFullWidth
              ? "px-2 py-" + padding + " content-section"
              : "container py-" + padding + " content-section"
          }
        > */}

          <div className="row">
            <div className="col">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

ContentSection.propTypes = {
  bgColor: PropTypes.string,
};

const mapSizesToProps = ({ width }) => ({
  isDeviceSmall: width < 1300,
});

export default withSizes(mapSizesToProps)(ContentSection);
