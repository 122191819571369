import React from "react";
import ContentCollapsiblePanel from "components/Common/content-collapsible-panel";

const FoodSafetySupervisorCertificateHospitalityAndRetail = () => {
  return (
    <>
      <ContentCollapsiblePanel
        openSection={-1}
        sections={[
          {
            title: "About the Food Safety Supervisor",
            content: (
              <>
                <p>
                  When you enrol with us, you have access to everything you need
                  to be accredited as a qualified Food Safety Supervisor (FSS)
                  in Australia.{" "}
                  <em>
                    If you are a business in NSW please do the NSW specific
                    training. Not this one!
                  </em>
                </p>
                <p>
                  Under FSANZ Standard 3.2.2A, all Australian businesses that
                  serve food must have one or more qualified Food Safety
                  Supervisor. A Food Safety Supervisor is someone who has the
                  appropriate Food Safety training to supervise Food Safety in a
                  business. Food Safety Supervisors are mainly responsible for:
                </p>
                <ul>
                  <li>Supervising Food Handling Staff</li>
                  <li>Maintaining the Food Safety Program</li>
                  <li>
                    Ensuring Safe Food Handling Practices across the business
                  </li>
                </ul>
                <p>
                  The new requirements outlined in FSANZ Standard 3.2.2A demand
                  a higher level of compliance compared to previous regulations.
                  Failure to comply can have serious consequences. Enforcement
                  begins in December 2023 and is overseen by local governments.
                </p>
                <p>
                  Penalties for non-compliance vary based on the severity of
                  violations and potentially leading to fines, license
                  suspension or even revocation.
                </p>
                <p>
                  Owners of registered food businesses must ensure those who
                  handle food within their business handle food safely. The
                  owner must nominate a Food Safety Supervisor, who has the
                  responsibility of recognising, preventing and alleviating the
                  hazards associated with food handling in your business.
                </p>
              </>
            ),
          },
          {
            title: "Is this course for me?",
            content: (
              <>
                <p>
                  Either way, it is a requirement that a Food Safety Supervisor
                  is appointed to the food business. Depending on how large the
                  food operation is, or how available the FSS can be, will vary
                  on how many FSS there will be.
                </p>
                <p>This qualification needs to be renewed every 5 years.</p>
                <p>
                  Under Standard 3.2.2A -{" "}
                  <u>category one and two businesses must:</u>
                </p>
                <ul>
                  <li>
                    appoint a certified Food Safety Supervisor (FSS) before
                    engaging in a ‘prescribed activity’
                  </li>
                  <li>
                    the certificate must be from either a registered training
                    organisation or an organisation recognised by the relevant
                    food regulator, THAT’S US!
                  </li>
                  <li>
                    the certificate must have been obtained within the past 5
                    years
                  </li>
                  <li>
                    ensure that the FSS is reasonably available to advise and
                    supervise each food handler engaged in that prescribed
                    activity.
                  </li>
                </ul>
                <p>
                  Prescribed activities involve handling unpackaged potentially
                  hazardous foods that are ready to eat, which are high risk.
                </p>
              </>
            ),
          },
          {
            title: "Who should be a FSS?",
            content: (
              <>
                <ul>
                  <li>
                    The FSS should be in a position to oversee food handling and
                    be involved in the day-to-day food handling operations of
                    the food business.
                  </li>
                  <li>
                    They must be ‘reasonably available’ as a point of contact
                    for food handlers and authorised officers.
                  </li>
                  <li>
                    ‘Reasonably available’ means the FSS works onsite and
                    oversees food handling of high-risk unpackaged foods, or can
                    be easily contacted (e.g. by phone).
                  </li>
                  <li>
                    It is not considered reasonable for a business to appoint
                    someone as a FSS who does not regularly handle food as part
                    of their normal duties, or is never onsite at a food
                    business.
                  </li>
                </ul>
              </>
            ),
          },
          {
            title: "Role of the FSS",
            content: (
              <>
                <p>
                  The FSS makes sure the business is handling food safely, so if
                  something goes wrong, food that could be unsafe to eat is not
                  processed further or served to consumers.
                </p>
                <p>The role of the FSS is to:</p>
                <ul>
                  <li>
                    make sure Food Safety risks are managed and issues are
                    prevented or corrected
                  </li>
                  <li>
                    instruct staff, review and update business procedures, and
                    inspect premises and food handling operations
                  </li>
                  <li>
                    share their Food Safety knowledge with other food handlers
                  </li>
                  <li>
                    foster a positive Food Safety culture, so Food Safety is a
                    top priority in &lsquo;the way we do things here&rsquo;
                  </li>
                </ul>
                <p>
                  The business needs to make sure the FSS is able to fulfil
                  these duties.
                </p>
                <p>
                  <img
                    src="/images/food-safety-supervisor-hospitality-and-retail-3-tools.png"
                    alt="Standard 3.2.2A of the Australia New Zealand Food Standards Code introduces 3 mandatory Food Safety management tools for food businesses, depending on their food handling activities"
                  />
                </p>
                <p>
                  This Food Safety Supervisor training applies to: hospitality,
                  retail and tourism enterprises. It is particularly relevant to
                  staff working in these industries, such as kitchen hands,
                  cooks, chefs, catering staff, bar staff, food and beverage
                  attendants, housekeeping and laundry staff, sandwich hands,
                  cafe and fast-food outlet’s, cooking crew, salespeople,
                  owner-operators of small business catering operations or any
                  retail food outlets involving the preparation of food.
                </p>
                <p>
                  This Food Safety training will also apply to any venue that
                  operates a permanent or temporary kitchen or smaller food
                  preparation area, such as restaurants, cafes, clubs, hotels,
                  attractions, events and conference venues, fast- food
                  restaurants, retail food outlets such as sandwich shops and
                  food court outlets. It would also apply to tour operators
                  involved in the preparation and service of food at temporary
                  sites.
                </p>
              </>
            ),
          },
          {
            title: "Course Summary",
            content: (
              <>
                <p>
                  Our course is designed to be as flexible and straightforward
                  as possible! We pride ourselves on our learning platform which
                  is filled with many short DVD's and visual images making this
                  training course a fast and effective way to obtain your
                  Nationally Accredited Certificate.
                </p>
                <p>
                  All our training courses allow students to complete their
                  course in their own time. That's right, there are no lock outs
                  and no penalties if you get any answers wrong!
                </p>
                <p>
                  On completion of your Food Safety training, we also offer you
                  a downloadable course workbook. This online course material is
                  always updated so we encourage you to log back in any time
                  after your training and obtain any new Food Safety
                  information, this is especially helpful when training up new
                  staff and when council come around!
                </p>
                <p>
                  This Food Safety Supervisor course prepares you with an
                  extensive understanding of the role a Food Safety Supervisor
                  must play within the workplace.
                </p>
              </>
            ),
          },
          {
            title: "State Food Safety Legislation",
            content: (
              <>
                <p>
                  <strong>
                    Please click for more information on the link below for your
                    States or Territories Food Safety Legislation
                  </strong>
                </p>
                <p>
                  <a
                    href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Queensland Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Victorian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.health.act.gov.au/businesses/food-safety-regulation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ACT Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    South Australian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.foodauthority.nsw.gov.au/ip/legislation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NSW Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Western Australia Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tasmanian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Northern Territory Food Safety Legislation
                  </a>
                </p>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FoodSafetySupervisorCertificateHospitalityAndRetail;
