export const FoodHandlingLevel1CertificateHospitalityAndRetailThirdPartyFormTasks = [
  {
    taskId: 28,
    courseId: 2,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Follow organisational hygiene procedures.",
    displayOrder: 1,
  },
  {
    taskId: 29,
    courseId: 2,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task: "Promptly report unsafe practices that breach hygiene procedures.",
    displayOrder: 2,
  },
  {
    taskId: 30,
    courseId: 2,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Identify food hazards that may affect the health and safety of customers, colleagues and self",
    displayOrder: 3,
  },
  {
    taskId: 31,
    courseId: 2,
    version: 1,
    taskGroup: "Task 1: Follow hygiene procedures and identify food hazards",
    task:
      "Remove or minimise the hygiene hazard and report to appropriate person for follow up.",
    displayOrder: 4,
  },
  {
    taskId: 32,
    courseId: 2,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task: "Report any personal health issues likely to cause a hygiene risk.",
    displayOrder: 5,
  },
  {
    taskId: 33,
    courseId: 2,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Report incidents of food contamination resulting from personal health issues.",
    displayOrder: 6,
  },
  {
    taskId: 34,
    courseId: 2,
    version: 1,
    taskGroup: "Task 2: Report any personal health issues.",
    task:
      "Cease participation in food handling activities where a health issue may cause food contamination.",
    displayOrder: 7,
  },
  {
    taskId: 35,
    courseId: 2,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Maintain clean clothes, wear required personal protective clothing and only use organisation-approved bandages and dressings.",
    displayOrder: 8,
  },
  {
    taskId: 36,
    courseId: 2,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent food contamination from clothing and other items worn.",
    displayOrder: 9,
  },
  {
    taskId: 37,
    courseId: 2,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task: "Prevent unnecessary direct contact with ready to eat food.",
    displayOrder: 10,
  },
  {
    taskId: 38,
    courseId: 2,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic personal contact with food or food contact surfaces.",
    displayOrder: 11,
  },
  {
    taskId: 39,
    courseId: 2,
    version: 1,
    taskGroup: "Task 3: Prevent food contamination",
    task:
      "Avoid unhygienic cleaning practices that may cause food-borne illnesses.",
    displayOrder: 12,
  },
  {
    taskId: 40,
    courseId: 2,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task:
      "Wash hands at appropriate times and follow hand washing procedures consistently.",
    displayOrder: 13,
  },
  {
    taskId: 41,
    courseId: 2,
    version: 1,
    taskGroup: "Task 4: Prevent cross contamination by washing hands",
    task: "Wash hands using appropriate facilities.",
    displayOrder: 14,
  },
  {
    taskId: 42,
    courseId: 2,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task: "Verbal communication of hazards",
    displayOrder: 15,
  },
  {
    taskId: 43,
    courseId: 2,
    version: 1,
    taskGroup: "General 1: Communication skills",
    task:
      "Communication skills to verbally report hygiene hazards and poor organisational practice",
    displayOrder: 16,
  },
  {
    taskId: 44,
    courseId: 2,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the organisational food safety program",
    displayOrder: 17,
  },
  {
    taskId: 45,
    courseId: 2,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the hygiene and food safety procedures",
    displayOrder: 18,
  },
  {
    taskId: 46,
    courseId: 2,
    version: 1,
    taskGroup: "General 2: Literacy skills - Understanding workplace documents",
    task:
      "Literacy skills to comprehend workplace documents or diagrams that interpret the content of the Hazard Analysis and Critical Control Points (HACCP) practices",
    displayOrder: 19,
  },
  {
    taskId: 47,
    courseId: 2,
    version: 1,
    taskGroup:
      "General 3: Problem solving skills – Identifying and reporting hazards",
    task: "Problem-solving skills to identify and report hygiene hazards",
    displayOrder: 20,
  },
];
