import React from "react";
import "./carousel-item.css";

const View = ({ imageName }) => {
  return (
    <div
      className="home-testimonial"
      style={{
        backgroundImage:
          "url('/userfiles/images/client-logos/" + imageName + "')"
      }}
    >
      {/* <img src={"/userfiles/images/client-logos/" + imageName} alt="" /> */}
    </div>
  );
};

export default View;
