import React from "react";
import { Col, Row } from "reactstrap";

const ContentSectionContainer = ({
  padding,
  isFullWidth,
  specificClass,
  imageSection,
  children,
}) => {
  const getInnerContainer = () => {
    if (!imageSection || !imageSection.imgSrc) return children;

    const imageXlColSize = imageSection.xLargeColSize || 6;
    const imageLgColSize = imageSection.largeColSize || 6;
    const imageMdColSize = imageSection.mediumColSize || 6;
    const childrenXlColSize =
      imageSection.xLargeColSize === 12
        ? 12
        : 12 - imageSection.xLargeColSize || 6;
    const childrenLgColSize =
      imageSection.largeColSize === 12
        ? 12
        : 12 - imageSection.largeColSize || 6;
    const childrenMdColSize =
      imageSection.mediumColSize === 12
        ? 12
        : 12 - imageSection.mediumColSize || 6;
    const position = imageSection.position || "right";
    const smallSizePosition = imageSection.smallSizePosition || "bottom";
    const headingStyle = imageSection.headingStyle || "h2";
    const smallColSizeImageMargin = imageSection.smallColSizeImageMargin || 3;
    const isVerticallyCentered = imageSection.isVerticallyCentered || true;

    return (
      <Row
        className={
          smallSizePosition === "top"
            ? `flex-column-reverse flex-md-row${
                isVerticallyCentered ? " align-items-center" : ""
              }`
            : `flex-column flex-md-row${
                isVerticallyCentered ? " align-items-center" : ""
              }`
        }
      >
        <Col
          xl={{ size: childrenXlColSize, order: position === "left" ? 2 : 1 }}
          lg={{ size: childrenLgColSize, order: position === "left" ? 2 : 1 }}
          md={{ size: childrenMdColSize, order: position === "left" ? 2 : 1 }}
          sm={12}
        >
          {imageSection.headingText &&
            React.createElement(
              headingStyle,
              { className: "d-none d-md-block" },
              imageSection.headingText
            )}
          {children}
        </Col>
        <Col
          xl={{ size: imageXlColSize, order: position === "left" ? 1 : 2 }}
          lg={{ size: imageLgColSize, order: position === "left" ? 1 : 2 }}
          md={{ size: imageMdColSize, order: position === "left" ? 1 : 2 }}
          sm={12}
        >
          {imageSection.headingText &&
            React.createElement(
              headingStyle,
              { className: "d-block d-md-none" },
              imageSection.headingText
            )}
          <img
            src={imageSection.imgSrc}
            alt={imageSection.imgAltText}
            className={`section-img ${
              smallSizePosition === "top"
                ? `mb-md-0 mb-${smallColSizeImageMargin}`
                : `mt-md-0 mt-${smallColSizeImageMargin}`
            }`}
          />
        </Col>
      </Row>
    );
  };

  return (
    <div
      className={`container-fluid py-${padding} ${
        isFullWidth ? "content-full-width" : "content-section"
      }${specificClass ? ` ${specificClass}` : ""}`}
    >
      {getInnerContainer()}
    </div>
  );
};

export default ContentSectionContainer;
