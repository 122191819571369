import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTagging from "components/Common/page-tagging/page-tagging";
import ContentSection from "components/Common/content-section";
import ContentCollapsiblePanel from "components/Common/content-collapsible-panel";
import LinkButton from "components/link-button/link-button";

import { general } from "constants/faqs-content/faqs-general";
import { usi } from "constants/faqs-content/faqs-usi";
import { thirdParty } from "constants/faqs-content/faqs-third-party";
import { nsw } from "constants/faqs-content/faqs-nsw";

import "./faqs.scss";

const FAQs = () => {
  const { faqPage } = useParams();

  const [faqContent, setFaqContent] = useState(null);

  useEffect(() => {
    switch (faqPage) {
      case "usi":
        setFaqContent(usi);
        break;
      case "third-party-observer-assessment":
        setFaqContent(thirdParty);
        break;
      case "nsw-students":
        setFaqContent(nsw);
        break;
      default:
        setFaqContent(general);
        break;
    }
  }, [faqPage]);

  return (
    <ContentSection bgClass="veggies-bg off-white">
      <PageTagging title="Frequently Asked Questions | Food Safety Education" />
      <div className="faqs">
        <h1>Frequently Asked Questions</h1>
        <div className="my-4">
          {" "}
          <LinkButton
            to="/faqs/"
            className={`ttg-btn ${
              faqPage === undefined
                ? "ttg-orange-btn"
                : "ttg-orange-outline-btn"
            } mr-2 my-2`}
            btnText="General"
          />
          <LinkButton
            to="/faqs/third-party-observer-assessment/"
            className={`ttg-btn ${
              faqPage === "third-party-observer-assessment"
                ? "ttg-orange-btn"
                : "ttg-orange-outline-btn"
            } mr-2 my-2`}
            btnText="Third Party Observer/Assessment"
          />
          <LinkButton
            to="/faqs/nsw-students/"
            className={`ttg-btn ${
              faqPage === "nsw-students"
                ? "ttg-orange-btn"
                : "ttg-orange-outline-btn"
            } mr-2 my-2`}
            btnText="NSW Students"
          />
          <LinkButton
            to="/faqs/usi/"
            className={`ttg-btn ${
              faqPage === "usi" ? "ttg-orange-btn" : "ttg-orange-outline-btn"
            } mr-2 my-2`}
            btnText="USI - Unique Student Identifier"
          />
        </div>
        {faqContent && (
          <ContentCollapsiblePanel openSection={-1} sections={faqContent} />
        )}
      </div>
    </ContentSection>
  );
};

export default FAQs;
