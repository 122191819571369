/**
 * Authorise Avs Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  fields: {
    state: "All",
    year: "",
    period: ""
  },
  fieldErrors: {},
  years: [],
  periods: [],
  collections: [],
  collectionStartDate: "",
  collectionEndDate: "",
  version: "",

  isLoading: false
};

function AvetmissReportingReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.AVETMISS_REPORTING_LOAD_FORM_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.AVETMISS_REPORTING_LOAD_FORM_SUCCESS:
      return {
        ...state,
        years: action.payload.years,
        collections: action.payload.collections,
        isLoading: false
      };

    case ActionTypes.AVETMISS_REPORTING_LOAD_FORM_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.AVETMISS_REPORTING_YEAR_CHANGE:
      return {
        ...state,
        periods: action.payload
      };

    case ActionTypes.AVETMISS_REPORTING_PERIOD_CHANGE:
      return {
        ...state,
        collectionStartDate: action.payload.collectionStartDate,
        collectionEndDate: action.payload.collectionEndDate,
        version: action.payload.version
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.AVETMISS_REPORTING_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    // ----------------------------
    //  Form Action Types
    // ----------------------------

    case ActionTypes.AVETMISS_REPORTING_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      });

      return stateResult;

    default:
      return state;
  }
}

export default AvetmissReportingReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectAvetmissReporting = state => state.avetmissReporting;
