/**
 * Avetmiss Reporting Authorise AVS Service
 */
import HttpClient from "../../../coreLib/http/httpClient";

const AuthoriseAvsService = () => {
  let httpClient = HttpClient();

  const getStatus = () => {
    return httpClient.get("/v1/avetmiss/status");
  };

  const getAuthorisation = () => {
    return httpClient.get("/v1/avetmiss/authorise");
  };

  const getVerification = deviceCode => {
    return httpClient.get(`/v1/avetmiss/verify/${deviceCode}`);
  };

  return {
    getStatus,
    getAuthorisation,
    getVerification
  };
};

export default AuthoriseAvsService;
