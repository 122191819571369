import React from "react";
import { Col, Row } from "reactstrap";
import hltfse001 from "../../../../assets/svgs/hltfse001.svg";
import hltfse005 from "../../../../assets/svgs/hltfse005.svg";
import hltfse007 from "../../../../assets/svgs/hltfse007.svg";
import sitxfsa005 from "../../../../assets/svgs/sitxfsa005.svg";
import sitxfsa006 from "../../../../assets/svgs/sitxfsa006.svg";
import "./certificate-info.scss";

const CertificateInfo = () => {
  const getCertificateInfo = (svg, colorClass, code, unit) => {
    return (
      <div className="certificate-details">
        <div className={`certificate-details-icon ${colorClass}`}>
          <img src={svg} alt={`${code} - ${unit}`} />
        </div>
        <div>
          <h2>{code}</h2>
          <h3>{unit}</h3>
        </div>
      </div>
    );
  };

  return (
    <div className="certificate-info">
      <Row>
        <Col md={6}>
          <div className="hr-line"></div>
          <h1>
            Food Safety Supervisor Certificate for Hospitality or Retail
            Industries
          </h1>
          {getCertificateInfo(
            sitxfsa005,
            "orange-icon",
            "SITXFSA005",
            "Use Hygienic Practices for Food Safety"
          )}
          {getCertificateInfo(
            sitxfsa006,
            "orange-icon",
            "SITXFSA006",
            "Participate in Safe Food Handling Practices"
          )}
        </Col>
        <Col md={6}>
          <div className="hc-line"></div>
          <h1>
            Food Safety Supervisor Certificates for Health or Community
            Industries
          </h1>
          {getCertificateInfo(
            hltfse001,
            "green-icon",
            "HLTFSE001",
            "Follow Basic Food Safety Practices"
          )}
          {getCertificateInfo(
            hltfse005,
            "green-icon",
            "HLTFSE005",
            "Apply and Monitor Food Safety Requirements"
          )}
          {getCertificateInfo(
            hltfse007,
            "green-icon",
            "HLTFSE007",
            "Oversee the Day to Day Implementation of Food Safety in the Workplace"
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CertificateInfo;
