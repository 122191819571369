import React, { Component } from "react";
import PageTitle from "../../../components/Common/Page-Title/Page-Title";
import ContentSection from "../../../components/Content-Section";
import { Row, Col, Form, FormGroup, Button } from "reactstrap";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";
import Textarea from "../../../components/Common/Fields/Text-Area-Field";
import formHandler from "../../../components/Common/Fields/form-handler";
import AddressEntry from "../../../components/Address-Entry/Address-Entry";
import BlockUiFx from "../../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import InputField from "../../../components/Common/Fields/Input-Field";
import MaskedInputField from "../../../components/Common/Fields/Masked-Input-Field";
import CheckboxField from "../../../components/Common/Fields/Checkbox-Field";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import ToggleDisplay from "react-toggle-display";
import KeyboardEventHandler from "react-keyboard-event-handler";
import DeleteDialog from "./delete-dialog";
import ConfirmModal from "./../../../components/Common/Confirm-Modal/Confirm-Modal";

const validateUsiNumber = (fieldName, value) => {
  if (validators.required(fieldName, value) || value === "-1") {
    return `${fieldName} is required.`;
  }
  if (!/^[2-9A-HJ-NP-Za-hj-np-z]{10}$/.test(value) && value !== "INDIV") {
    return `${value} is not a valid USI number.`;
  }
  return false;
};

const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

const phoneValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);

  if (validators.phone(value)) return validators.phone(value);

  return false;
};

const getPhoneMask = (value) => {
  if (
    value.startsWith("02") ||
    value.startsWith("03") ||
    value.startsWith("07") ||
    value.startsWith("08")
  )
    return "99 9999 9999";

  return "9999 999 999";
};

class Users extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { onLoad, match } = this.props;
    onLoad(match.params.userCourseId);
  }

  render() {
    const {
      isProcessing,
      fields,
      onInputChange,
      onFieldError,
      updateCourse,
      toggleDeleteDialog,
      isDeleting,
      deleteCourse,
      showDeleteDialog,
      showCourseTransferConfirmation,
      onCourseTransferConfirmation,
      toggleCourseTransferConfirmation,
    } = this.props;
    const {
      firstName,
      lastName,
      usiNumber,
      email,
      phone,
      unitNumber,
      streetNumber,
      streetName,
      city,
      state,
      postcode,
      enrolmentStep2Completed,
      enrolmentStep3Completed,
      thirdPartyFormCompleted,
      surveyCompleted,
      courseCompleted,
      redirectToQuiz,
      setCount,
      lastSectionCompleted,
      lastQuestionCompleted,
      previousCertificateNumber,
      nswCertificateDetails,
      comments,
    } = fields;
    const form = formHandler();
    return (
      <>
        <BlockUiFx blocking={isProcessing} lowerZIndex={true}>
          <PageTitle title="Admin - Edit User Course | Train to Gain" />
          <ContentSection padding={3} bgColor="grey-shadow">
            <h1>Edit User Course</h1>
          </ContentSection>
          <ContentSection padding={3}>
            <Form className="ttg-form">
              <Row>
                <Col>
                  <h4>Personal Information</h4>
                </Col>
              </Row>
              <Row className="mt-3" form>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>First Name</label>
                    <InputField
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) => validators.required("First Name", val)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>Last Name</label>
                    <InputField
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) => validators.required("Last Name", val)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col sm="12" md="8">
                  <FormGroup>
                    <label>USI Number</label>
                    <InputField
                      name="usiNumber"
                      placeholder="USI Number"
                      value={usiNumber}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) => validateUsiNumber("USI Number", val)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4>Contact Information</h4>
                </Col>
              </Row>
              <Row className="mt-3" form>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>Email</label>
                    <InputField
                      name="email"
                      placeholder="Email"
                      value={email}
                      type="email"
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) => emailValidator("Email", val)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>Phone Number</label>
                    <MaskedInputField
                      name="phone"
                      placeholder="Phone Number"
                      mask={getPhoneMask(phone)}
                      value={phone}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) => phoneValidator("Phone Number", val)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <h4>Address</h4>
                </Col>
              </Row>

              <Row form>
                <Col sm="12" md="8">
                  <FormGroup>
                    <AddressEntry
                      unitNumber={unitNumber}
                      streetNumber={streetNumber}
                      streetName={streetName}
                      city={city}
                      state={state}
                      postcode={postcode}
                      onFieldError={onFieldError}
                      onInputChange={onInputChange}
                      form={form}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <h4>Course Status</h4>
                </Col>
              </Row>

              <div
                style={{
                  position: "relative",
                  right: "20px",
                }}
              >
                <Row className="mt-3" form>
                  <Col sm="12" md="4">
                    <FormGroup check>
                      <CheckboxField
                        ref={form.add}
                        checked={enrolmentStep2Completed}
                        name="enrolmentStep2Completed"
                        label="Enrolment Step 2 Completed"
                        value={enrolmentStep2Completed}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm="12" md="4">
                    <FormGroup check>
                      <CheckboxField
                        ref={form.add}
                        checked={enrolmentStep3Completed}
                        name="enrolmentStep3Completed"
                        label="Enrolment Step 3 Completed"
                        value={enrolmentStep3Completed}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm="12" md="4">
                    <FormGroup check>
                      <CheckboxField
                        ref={form.add}
                        checked={thirdPartyFormCompleted}
                        name="thirdPartyFormCompleted"
                        label="Third Party Form Completed"
                        value={thirdPartyFormCompleted}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="4">
                    <FormGroup check>
                      <CheckboxField
                        ref={form.add}
                        checked={surveyCompleted}
                        name="surveyCompleted"
                        label="Survey Completed"
                        value={surveyCompleted}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="4">
                    <FormGroup check>
                      <CheckboxField
                        ref={form.add}
                        checked={courseCompleted}
                        name="courseCompleted"
                        label="Course Completed"
                        value={courseCompleted}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="4">
                    <FormGroup check>
                      <CheckboxField
                        ref={form.add}
                        checked={redirectToQuiz}
                        name="redirectToQuiz"
                        label="Redirect to Quiz"
                        value={redirectToQuiz}
                        onFieldError={onFieldError}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <Row className="mt-3" form>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>Content Set Count</label>
                    <InputField
                      name="setCount"
                      placeholder="Content Set Count"
                      value={setCount}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>Last Section Completed</label>
                    <InputField
                      name="lastSectionCompleted"
                      placeholder="Last Section Completed"
                      value={lastSectionCompleted}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="4">
                  <FormGroup>
                    <label>Last Question Completed</label>
                    <InputField
                      name="lastQuestionCompleted"
                      placeholder="Last Question Completed"
                      value={lastQuestionCompleted}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col sm="12" md="8">
                  <FormGroup>
                    <label>Previous Certificate Number</label>
                    <InputField
                      name="previousCertificateNumber"
                      placeholder="Previous Certificate Number"
                      value={previousCertificateNumber}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <h4>Comment</h4>
                </Col>
              </Row>

              <Row className="mb-4" form>
                <Col sm="12" md="8">
                  <FormGroup>
                    <Textarea
                      name="comments"
                      placeholder="Comment"
                      value={comments}
                      ref={form.add}
                      onFieldError={onFieldError}
                      onChange={onInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <ToggleDisplay if={nswCertificateDetails !== null}>
                <Row form>
                  <Col>
                    <h4>NSW Certificate Details</h4>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col sm="12" md="9">
                    <InputField
                      name="nswCertificateDetails"
                      placeholder="NSW Certificate Details"
                      value={nswCertificateDetails}
                      ref={form.add}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                    />
                  </Col>
                </Row>
              </ToggleDisplay>

              <div>
                <ButtonFx
                  onClick={() =>
                    form.validate({
                      validFn: () => updateCourse(fields),
                    })
                  }
                  isLoading={isProcessing}
                  className="ttg-btn mr-2"
                >
                  Save
                </ButtonFx>

                <Button
                  className="ttg-btn ttg-red-btn mr-5"
                  color="secondary"
                  onClick={() => toggleDeleteDialog()}
                  disabled={isProcessing}
                >
                  Delete
                </Button>

                <Button
                  className="ttg-btn ttg-blue-green-btn"
                  color="secondary"
                  onClick={() => toggleCourseTransferConfirmation()}
                  disabled={isProcessing}
                >
                  Transfer to new user
                </Button>
              </div>
            </Form>
          </ContentSection>

          <DeleteDialog
            deleteCourse={deleteCourse}
            toggleDeleteDialog={toggleDeleteDialog}
            isDeleting={isDeleting}
            fields={fields}
            showDeleteDialog={showDeleteDialog}
          />

          <ConfirmModal
            title={"Transfer User Course"}
            isModalOpen={showCourseTransferConfirmation}
            onToggle={(evt) =>
              onCourseTransferConfirmation(evt, fields.userCourseId)
            }
          >
            Are you sure you want to transfer this user course to a new user
            matching the details of the user course? Note: this cannot be
            undone.
          </ConfirmModal>

          <KeyboardEventHandler
            handleKeys={["enter"]}
            onKeyEvent={() =>
              form.validate({
                validFn: () => updateCourse(fields),
              })
            }
          />
        </BlockUiFx>
      </>
    );
  }
}

export default Users;
