import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTable from "./datatable";
import * as actionCreators from "../redux/action-creators";

const mapStateToProps = (state) => state.corporateVouchers;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: () => dispatch(actionCreators.onLoad()),
  downloadCertificate: (userCourseId) =>
    dispatch(actionCreators.downloadCertificate(userCourseId)),
  downloadThirdPartyForm: (thirdPartyAccessToken) =>
    dispatch(actionCreators.downloadThirdPartyForm(thirdPartyAccessToken)),
  setEmailDetails: (data) => dispatch(actionCreators.setEmailDetails(data)),
  showEmailDialog: () => dispatch(actionCreators.showEmailDialog()),
  sendVoucher: (values) => dispatch(actionCreators.sendVoucher(values)),
  dispatch,
  history: ownProps.history,
});

const DataTableContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DataTable)
);

export default DataTableContainer;
