/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../redux/system/system-action-creators";
import AuthoriseAvsService from "../authorise-avs-service";

// ----------------------------
//  Actions
// ----------------------------
export const onLoadForm = history => {
  return dispatch => {
    dispatch(LoadFormRequest());

    const service = AuthoriseAvsService();

    service
      .getStatus()
      .then(resp => {
        if (resp.data.validToken) {
          history.push("/Admin/Avetmiss/Reporting/");
          return;
        }

        service
          .getAuthorisation()
          .then(resp => {
            dispatch(
              LoadFormSuccess(
                resp.data.userCode,
                resp.data.deviceCode,
                resp.data.verificationUrl,
                resp.data.errorMessage
              )
            );
          })
          .catch(err => {
            dispatch(LoadFormFailure());
            dispatch(
              addNotification(
                "Sorry, an error occurred trying to get the Avetmiss Reporting Authorisation Details.",
                "error"
              )
            );
          });
      })
      .catch(err => {
        dispatch(LoadFormFailure());
        dispatch(
          addNotification(
            "Sorry, an error occurred trying to get the Avetmiss Reporting Authorisation Details.",
            "error"
          )
        );
      });
  };
};

const LoadFormRequest = () => ({
  type: ActionTypes.AVETMISS_AUTHORISE_AVS_LOAD_FORM_REQUEST
});

const LoadFormSuccess = (
  userCode,
  deviceCode,
  verificationUrl,
  errorMessage
) => ({
  type: ActionTypes.AVETMISS_AUTHORISE_AVS_LOAD_FORM_SUCCESS,
  payload: {
    userCode,
    deviceCode,
    verificationUrl,
    errorMessage
  }
});

const LoadFormFailure = () => ({
  type: ActionTypes.AVETMISS_AUTHORISE_AVS_LOAD_FORM_FAILURE
});

// Async
export const onSubmit = (deviceCode, history) => dispatch => {
  dispatch(onSubmitRequest());

  const service = AuthoriseAvsService();

  service
    .getVerification(deviceCode)
    .then(resp => {
      if (resp.data.verificationSuccessful) {
        dispatch(onSubmitSuccess());
        dispatch(
          addNotification(
            "Awesome, that worked, we are set for another year.",
            "info"
          )
        );
        history.push("/Admin/Avetmiss/Reporting/");
      }
    })
    .catch(err => {
      dispatch(onSubmitFailure());
      dispatch(
        addNotification(
          "Hmm, that did not work. Try again, and if you keep getting this error, you might need to call support.",
          "error"
        )
      );
    });
};

const onSubmitRequest = () => ({
  type: ActionTypes.AVETMISS_AUTHORISE_AVS_SUBMIT_REQUEST
});
const onSubmitSuccess = () => ({
  type: ActionTypes.AVETMISS_AUTHORISE_AVS_SUBMIT_SUCCESS
});
const onSubmitFailure = () => ({
  type: ActionTypes.AVETMISS_AUTHORISE_AVS_SUBMIT_FAILURE
});
