/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  FORGOT_PASSWORD_APPLY_RESET_REQUEST: "FORGOT_PASSWORD_APPLY_RESET_REQUEST",
  FORGOT_PASSWORD_APPLY_RESET_SUCCESS: "FORGOT_PASSWORD_APPLY_RESET_SUCCESS",
  FORGOT_PASSWORD_APPLY_RESET_FAILURE: "FORGOT_PASSWORD_APPLY_RESET_FAILURE",

  // Form Actions
  FORGOT_PASSWORD_APPLY_RESET_INPUT_CHANGE:
    "FORGOT_PASSWORD_APPLY_RESET_INPUT_CHANGE"
};
