import DownloadReports from "./download-reports";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionCreators from "./redux/action-creators";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = state => state.adminDownloadReport;

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  onFieldError: error =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch,
  history: ownProps.history
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  loadRequest: () => {
    dispatchProps.dispatch(actionCreators.loadRequest(stateProps.fields));
  }
});

const DownloadReportsContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeFormProps
  )(DownloadReports)
);

export default DownloadReportsContainer;
