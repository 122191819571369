export const ActionTypes = {
         NSW_REPRINT_LOAD_COURSE_DETAILS_REQUEST:
           "NSW_REPRINTT_LOAD_COURSE_DETAILS_REQUEST",
         NSW_REPRINT_LOAD_COURSE_DETAILS_SUCCESS:
           "ENSW_REPRINT_LOAD_COURSE_DETAILS_SUCCESS",
         NSW_REPRINT_LOAD_COURSE_DETAILS_FAILURE:
           "NSW_REPRINT_LOAD_COURSE_DETAILS_FAILURE",

         NSW_REPRINT_AUTOCOMPLETE_ADDRESS_UPDATE:
           "NSW_REPRINT_AUTOCOMPLETE_ADDRESS_UPDATE",

         NSW_REPRINT_INIT: "NSW_REPRINT_INIT",
         NSW_REPRINT_CLEAR_ADDRESS: "NSW_REPRINT_CLEAR_ADDRESS",
         NSW_REPRINT_TOGGLE_MANUAL_ADDRESS_ENTRY:
           "NSW_REPRINT_TOGGLE_MANUAL_ADDRESS_ENTRY",
         NSW_REPRINT_TOGGLE_PROMO_CODE_ENTRY:
           "NSW_REPRINT_TOGGLE_PROMO_CODE_ENTRY",
         NSW_REPRINT_SUBMIT_ENROLMENT_REQUEST:
           "NSW_REPRINT_SUBMIT_ENROLMENT_REQUEST",
         NSW_REPRINT_SUBMIT_ENROLMENT_SUCCESS:
           "NSW_REPRINT_SUBMIT_ENROLMENT_SUCCESS",
         NSW_REPRINT_SUBMIT_ENROLMENT_FAILURE:
           "NSW_REPRINT_SUBMIT_ENROLMENT_FAILURE",
         NSW_REPRINT_SET_CARD_VALIDITY: "NSW_REPRINT_SET_CARD_VALIDITY",
         NSW_REPRINT_SHOW_MANUAL_ADDRESS_ENTRY:
           "NSW_REPRINT_SHOW_MANUAL_ADDRESS_ENTRY",
         NSW_REPRINT_FORM_INPUT_CHANGE: "NSW_REPRINT_FORM_INPUT_CHANGE",
         NSW_REPRINT_SHOW_CONFIRM_POPUP: "NSW_REPRINT_SHOW_CONFIRM_POPUP",
       };
