import HttpClient from "../../../../coreLib//http/httpClient";
import moment from "moment";

const Service = () => {
  let httpClient = HttpClient();

  const load = (payload) => {
    return httpClient.post("v1/admin/users", {
      ...payload,
      startDate: moment(payload.startDate),
      endDate: moment(payload.endDate),
      userTypes: payload.userTypes.map((u) => {
        return typeof u === "object" ? u.value : u;
      }),
    });
  };

  const editUser = (payload) => {
    let model = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      active:
        payload.active === "Yes" ||
        payload.active === true ||
        payload.active === "true"
          ? true
          : false,
      userType: parseInt(payload.userType),
    };
    return httpClient.put(`v1/admin/users/${payload.userId}`, model);
  };

  const deleteUser = (userId) => {
    return httpClient.delete(`v1/admin/users/${userId}`);
  };

  return {
    load,
    editUser,
    deleteUser,
  };
};

export default Service;
