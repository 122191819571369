/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  TEMPLATE_COMPONENT_TOGGLE_IS_AWESOME_REQUEST:
    "TEMPLATE_COMPONENT_TOGGLE_IS_AWESOME_REQUEST",
  TEMPLATE_COMPONENT_TOGGLE_IS_AWESOME_SUCCESS:
    "TEMPLATE_COMPONENT_TOGGLE_IS_AWESOME_SUCCESS",
  TEMPLATE_COMPONENT_TOGGLE_IS_AWESOME_FAILURE:
    "TEMPLATE_COMPONENT_TOGGLE_IS_AWESOME_FAILURE",

  // Form Actions
  TEMPLATE_COMPONENT_FORM_INPUT_CHANGE: "TEMPLATE_COMPONENT_FORM_INPUT_CHANGE"
};
