import React from "react";
import PropTypes from "prop-types";
import HeaderImageSection from "../header-image-section/header-image-section";
import ContentBackgroundFactory from "./components/fancy-backgrounds/content-background-factory";
import ContentSectionContainer from "./components/content-section-container/content-section-container";
import BlockUiFx from "../Block-Ui-Fx/Block-Ui-Fx";
import PageTagging from "../page-tagging/page-tagging";

const ContentSection = ({
  id,
  bgType = "plain",
  bgOuterColor,
  bgInnerColor,
  bgClass,
  padding = undefined,
  specificClass,
  isFullWidth = false,
  showTopSvg = true,
  showBottomSvg = true,
  customSvgTop,
  customSvgBottom,
  pageTitle,
  pageDescription,
  includeLoader = false,
  isLoading = false,
  headerImageSection,
  imageSection,
  children,
}) => {
  const getContainerClass = () => {
    return (
      <>
        {headerImageSection && headerImageSection.imgSrc && (
          <HeaderImageSection {...headerImageSection} />
        )}
        <ContentSectionContainer
          padding={padding !== undefined ? padding : 4}
          isFullWidth={isFullWidth}
          specificClass={specificClass}
          imageSection={imageSection}
          children={children}
        />
      </>
    );
  };

  const getContentSection = () => {
    if (!bgType || bgType === "plain")
      return bgClass ? (
        <>
          {pageTitle && (
            <PageTagging title={pageTitle} description={pageDescription} />
          )}
          <div className={bgClass} {...(id ? { id } : {})}>
            {getContainerClass()}
          </div>
        </>
      ) : (
        <>
          {pageTitle && (
            <PageTagging title={pageTitle} description={pageDescription} />
          )}
          {!id && getContainerClass()}
          {id && <div id={id}>{getContainerClass()}</div>}
        </>
      );

    return (
      <>
        {pageTitle && (
          <PageTagging title={pageTitle} description={pageDescription} />
        )}
        <ContentBackgroundFactory
          id={id}
          type={bgType}
          children={children}
          options={{
            bgOuterColor: bgOuterColor,
            bgInnerColor: bgInnerColor,
            padding: padding,
            isFullWidth: isFullWidth || false,
            specificClass: specificClass,
            showTopSvg: showTopSvg,
            showBottomSvg: showBottomSvg,
            customSvgTop: customSvgTop,
            customSvgBottom: customSvgBottom,
          }}
        />
      </>
    );
  };

  if (includeLoader)
    return <BlockUiFx blocking={isLoading}>{getContentSection()}</BlockUiFx>;

  return getContentSection();
};

ContentSection.propTypes = {
  id: PropTypes.string,
  bgType: PropTypes.string,
  bgOuterColor: PropTypes.string,
  bgInnerColor: PropTypes.string,
  bgClass: PropTypes.string,
  padding: PropTypes.number,
  specificClass: PropTypes.string,
  isFullWidth: PropTypes.bool,
  showTopSvg: PropTypes.bool,
  showBottomSvg: PropTypes.bool,
  customSvgTop: PropTypes.element,
  customSvgBottom: PropTypes.element,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  includeLoader: PropTypes.bool,
  isLoading: PropTypes.bool,
  headerImageSection: PropTypes.shape({
    imgSrc: PropTypes.any.isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.any,
  }),
  imageSection: PropTypes.shape({
    imgSrc: PropTypes.any.isRequired,
    imgAltText: PropTypes.string,
    xLargeColSize: PropTypes.number,
    largeColSize: PropTypes.number,
    mediumColSize: PropTypes.number,
    position: PropTypes.string, // left, right
    smallSizePosition: PropTypes.string, // top, bottom
    headingText: PropTypes.string,
    headingStyle: PropTypes.string,
    smallColSizeImageMargin: PropTypes.number,
    isVerticallyCentered: PropTypes.bool,
  }),
};

export default ContentSection;
