/**
 * Action types
 */
export const ActionTypes = {
  ENROLMENT_LOAD_COURSE_DETAILS_REQUEST:
    "ENROLMENT_LOAD_COURSE_DETAILS_REQUEST",
  ENROLMENT_LOAD_COURSE_DETAILS_SUCCESS:
    "ENROLMENT_LOAD_COURSE_DETAILS_SUCCESS",
  ENROLMENT_LOAD_COURSE_DETAILS_FAILURE:
    "ENROLMENT_LOAD_COURSE_DETAILS_FAILURE",

  STEP_1_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS:
    "STEP_1_AUTOCOMPLETE_ADDRESS_UPDATE_SUCCESS",

  STEP_1_INIT: "STEP_1_INIT",
  STEP_1_CLEAR_ADDRESS: "STEP_1_CLEAR_ADDRESS",
  STEP_1_TOGGLE_MANUAL_ADDRESS_ENTRY: "STEP_1_TOGGLE_MANUAL_ADDRESS_ENTRY",
  STEP_1_TOGGLE_PROMO_CODE_ENTRY: "STEP_1_TOGGLE_PROMO_CODE_ENTRY",

  STEP_1_SUBMIT_ENROLMENT_REQUEST: "STEP_1_SUBMIT_ENROLMENT_REQUEST",
  STEP_1_SUBMIT_ENROLMENT_SUCCESS: "STEP_1_SUBMIT_ENROLMENT_SUCCESS",
  STEP_1_SUBMIT_ENROLMENT_FAILURE: "STEP_1_SUBMIT_ENROLMENT_FAILURE",
  STEP_1_SET_CARD_VALIDITY: "STEP_1_SET_CARD_VALIDITY",
  STEP_1_SHOW_MANUAL_ADDRESS_ENTRY: "STEP_1_SHOW_MANUAL_ADDRESS_ENTRY",

  STEP_1_APPLY_DISCOUNT_CODE_REQUEST: "STEP_1_APPLY_DISCOUNT_CODE_REQUEST",
  STEP_1_APPLY_DISCOUNT_CODE_SUCCESS: "STEP_1_APPLY_DISCOUNT_CODE_SUCCESS",
  STEP_1_APPLY_DISCOUNT_CODE_FAILURE: "STEP_1_APPLY_DISCOUNT_CODE_FAILURE",
  STEP_1_APPLY_DISCOUNT_TOGGLE_DISCOUNT_CODE:
    "STEP_1_APPLY_DISCOUNT_TOGGLE_DISCOUNT_CODE",

  STEP_1_TOGGLE_SHOW_CONFIRM_NAME_POPUP:
    "STEP_1_TOGGLE_SHOW_CONFIRM_NAME_POPUP",

  STEP_3_TOGGLE_WHERE_EXPERIENCE: "STEP_3_TOGGLE_WHERE_EXPERIENCE",

  STEP_3_SUBMIT_ENROLMENT_REQUEST: "STEP_3_SUBMIT_ENROLMENT_REQUEST",
  STEP_3_SUBMIT_ENROLMENT_SUCCESS: "STEP_3_SUBMIT_ENROLMENT_SUCCESS",
  STEP_3_SUBMIT_ENROLMENT_FAILURE: "STEP_3_SUBMIT_ENROLMENT_FAILURE",
  STEP_3_FORM_VALID: "STEP_3_FORM_VALID",

  STEP_3_INIT: "STEP_3_INIT",
  STEP_3_INIT_SUCCEEDED: "STEP_3_INIT_SUCCEEDED",
  STEP_3_INIT_FAILED: "STEP_3_INIT_FAILED",

  // Form Actions
  STEP_1_FORM_INPUT_CHANGE: "STEP_1_FORM_INPUT_CHANGE",

  STEP_3_FORM_INPUT_CHANGE: "STEP_3_FORM_INPUT_CHANGE",
  STEP_3_FORM_DATE_CHANGE: "STEP_2_FORM_DATE_CHANGE",
};
