import { ActionTypes } from "./action-types";
import HttpService from "../service";
import { addNotification } from "../../../../../redux/system/system-action-creators";

export const toggleDeleteDialog = payload => ({
  type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_DELETE_DIALOG,
  payload
});

export const toggleEditDialog = payload => ({
  type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_EDIT_DIALOG,
  payload
});

export const toggleVouchersDialog = payload => ({
  type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_VOUCHERS_DIALOG,
  payload
});

export const toggleMarkAsPaidDialog = payload => ({
  type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_SHOW_MARK_AS_PAID_DIALOG,
  payload
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_DIALOG_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});

export const updateVoucher = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_REQUEST });
  service
    .updateVouchers(payload)
    .then(() => {
      dispatch(updateVoucherSuccess(payload));
      dispatch(addNotification("Voucher has been updated."));
    })
    .catch(() => {
      dispatch(updateVoucherFailure());
      dispatch(addNotification("Unable to update the voucher.", "warning"));
    });
};

const updateVoucherSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_SUCCESS,
    payload
  });
};

const updateVoucherFailure = () => dispatch => {
  dispatch({ type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_UPDATE_FAILURE });
};

export const deleteVoucher = payload => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_REQUEST });
  service
    .deleteVoucher(payload)
    .then(() => {
      dispatch(deleteVoucherSuccess(payload));
      dispatch(addNotification("Voucher has been deleted."));
    })
    .catch(() => {
      dispatch(deleteVoucherFailure());
      dispatch(addNotification("Unable to delete the voucher.", "warning"));
    });
};

const deleteVoucherSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_SUCCESS,
    payload
  });
};

const deleteVoucherFailure = () => dispatch => {
  dispatch({ type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_DELETE_FAILURE });
};

export const markAsPaid = prePurchaseId => dispatch => {
  let service = HttpService();
  dispatch({ type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_REQUEST });
  service
    .markAsPaid(prePurchaseId)
    .then(() => {
      dispatch(markAsPaidSuccess(prePurchaseId));
      dispatch(addNotification("Successfully mark as paid."));
    })
    .catch(() => {
      dispatch(markAsPaidFailure());
      dispatch(addNotification("Unable to mark as paid", "warning"));
    });
};

const markAsPaidSuccess = payload => dispatch => {
  dispatch({
    type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_SUCCESS,
    payload
  });
};

const markAsPaidFailure = () => dispatch => {
  dispatch({ type: ActionTypes.ADMIN_PURCHASE_VOUCHERS_PAID_FAILURE });
};
