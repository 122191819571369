import React from "react";
import { connect } from "react-redux";
import { selectCorporateVouchersRegister } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import PageTagging from "components/Common/page-tagging/page-tagging";
import ContentSection from "components/Common/content-section";
import "./corporate-vouchers-register.scss";
import GreenTickBalls from "features/green-tick-balls/green-tick-balls";
import { Col, Row } from "reactstrap";
import RegisterBoxContainer from "components/Register-Box/Register-Box.Container";

const CorporateVouchersRegisterView = () => {
  return (
    <>
      <PageTagging title="Business Vouchers for Staff Training" />
      <ContentSection bgClass="veggies-bg off-white flex-100">
        <div className="business-training">
          <h1>
            Simplify Your Food Safety Training by Ordering Your Course Vouchers
            Here
          </h1>
          <p>
            Managing your team&rsquo;s Food Safety training has never been
            easier. Our Business Training Vouchers are designed to take the
            hassle out of compliance, offering a streamlined solution for
            business owners and managers to ensure their staff is trained and
            up-to-date with Food Safety standards.
          </p>

          <p className="mb-5">
            <strong>To order course vouchers for your staff</strong>, simply
            enter your details below to register for access our business portal.
            Your vouchers are generated instantly and can be emailed directly to
            your staff, allowing them to begin their Food Safety training
            immediately!
          </p>

          <Row>
            <Col lg={6}>
              <div className="heading-text mb-3">
                Key Benefits of Training With Us
              </div>
              <GreenTickBalls
                items={[
                  "Streamlined Training Management",
                  "Real-Time Staff Progress & Access to Certificates",
                  "Convenient Voucher Assignment",
                  "Stay Compliance-Ready",
                  "Flexible Payment Options",
                  "Easy Reordering",
                  "Long-Lasting Vouchers",
                ]}
              />
            </Col>
            <Col lg={6}>
              <div className="heading-text mb-3 mt-3 mt-lg-0">
                Register for a Business Account
              </div>
              <RegisterBoxContainer registerType={"corporate"} />
            </Col>
          </Row>
          <div className="heading-text mb-3">
            How do my staff access their training?
          </div>
          <GreenTickBalls
            items={[
              "Your staff receive an email with a link to begin their training",
              "They click the link in the email or select their assigned course & click the 'Start Now' button",
              "Your staff enter their personal details to register",
              "In the payment section, they choose the 'Voucher' option and click 'Apply'",
              "Once the voucher is applied, your staff complete the enrolment and start their online training",
            ]}
          />
        </div>
      </ContentSection>
    </>
  );
};

function mapStateToProps(state) {
  const corporateVouchersRegister = selectCorporateVouchersRegister(state);

  return {
    corporateVouchersRegister: corporateVouchersRegister,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.formInputChange(name, value, error));
  },
  dispatch: dispatch,
  history: ownProps.history,
});

const CorporateVouchersRegister = connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateVouchersRegisterView);

export default CorporateVouchersRegister;
