import { ActionTypes } from "./action-types";

const initialState = {
  fields: {
    password: null,
    oldPassword: null,
    confirmPassword: null
  },
  isSubmitting: false,
  isSubmitted: false,
  fieldErrors: {}
};

function ChangePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CHANGE_PASSWORD_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error
        }
      });
      return stateResult;
    case ActionTypes.CHANGE_PASSWORD_ON_SUBMIT:
      return {
        ...state,
        isSubmitting: true
      };
    case ActionTypes.CHANGE_PASSWORD_ON_SUCCESS:
      return {
        ...state,
        isSubmitted: true,
        isSubmitting: false
      };
    case ActionTypes.CHANGE_PASSWORD_ON_FAILURE:
      return {
        ...state,
        isSubmitting: false
      };
    default:
      return state;
  }
}

export default ChangePasswordReducer;

export const selectChangePassword = state => state.changePassword;
