import React from "react";
import BasicModal from "./../../../components/Common/Basic-Modal/Basic-Modal";

const MessageDialog = props => {
  return (
    <BasicModal
      title={"Food Safety Education"}
      isOpen={props.showMessageDialog}
      isLoading={props.isLoading}
      toggle={evt =>
        props.onMessageToggle({ type: evt, objectTag: props.objectTag })
      }
      okButtonText={"Ok"}
      showCancel={false}
    >
      {props.showMessageDialogMessage}
    </BasicModal>
  );
};

export default MessageDialog;
