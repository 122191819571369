/**
 * Your Courses Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  hasNswCertificate: false,
  courses: [],
  isProcessing: false,
};

function YourCoursesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TEMPLATE_COMPONENT_FORM_INPUT_CHANGE:
      let stateResult = Object.assign({}, state, {
        fields: {
          ...state.fields,
          [action.payload.name]: action.payload.value,
        },
        fieldErrors: {
          ...state.fieldErrors,
          [action.payload.name]: action.payload.error,
        },
      });
      return stateResult;
    case ActionTypes.YOUR_COURSES_DOWNLOAD_TAX_INVOICE_REQUEST:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_CERTIFICATE_REQUEST:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_TPF_REQUEST:
    case ActionTypes.YOUR_COURSES_LOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.YOUR_COURSES_DOWNLOAD_CERTIFICATE_FAILURE:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_TAX_INVOICE_SUCCESS:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_TAX_INVOICE_FAILURE:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_CERTIFICATE_SUCCESS:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_TPF_SUCCESS:
    case ActionTypes.YOUR_COURSES_DOWNLOAD_TPF_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.YOUR_COURSES_LOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        hasNswCertificate: action.payload.some(
          (course) => course.hasNswCertificate
        ),
        courses: action.payload,
      };
    default:
      return state;
  }
}

export default YourCoursesReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectYourCourses = (state) => state.yourCourses;
