import React from "react";
import PropTypes from "prop-types";
import ContentSectionContainer from "../../content-section-container/content-section-container";
import "./angle.scss";

function Angle({
  id,
  children,
  bgOuterColor = "#fff",
  bgInnerColor = "#fff",
  padding = 1,
  specificClass,
  isFullWidth = false,
  showTopSvg = true,
  showBottomSvg = true,
}) {
  return (
    <div
      className={`content-section-angle${
        specificClass ? ` ${specificClass}` : ""
      }`}
      style={{ backgroundColor: bgInnerColor }}
      {...(id ? { id } : {})}
    >
      {showTopSvg && (
        <section className="content-section-angle-top">
          <div className="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 34"
              preserveAspectRatio="none"
              style={{ backgroundColor: bgOuterColor }}
            >
              <polygon points="0 35 100 0 100 35" fill={bgInnerColor}></polygon>
            </svg>
          </div>
        </section>
      )}
      <ContentSectionContainer
        padding={padding}
        isFullWidth={isFullWidth}
        children={children}
      />
      {showBottomSvg && (
        <section className="content-section-angle-bottom">
          <div className="svg-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 35"
              preserveAspectRatio="none"
              style={{ backgroundColor: bgInnerColor }}
            >
              <polygon points="0 35 100 0 100 35" fill={bgOuterColor}></polygon>
            </svg>
          </div>
        </section>
      )}
    </div>
  );
}

Angle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  id: PropTypes.string,
  bgOuterColor: PropTypes.string,
  bgInnerColor: PropTypes.string,
  padding: PropTypes.number,
  specificClass: PropTypes.string,
  isFullWidth: PropTypes.bool,
  showTopSvg: PropTypes.bool,
  showBottomSvg: PropTypes.bool,
};

export default Angle;
