import React from "react";
import "./hero.scss";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="home-hero-wrap">
      <div className="home-hero">
        <div>
          <div className="home-hero-welcome">Welcome to</div>
          <h1>Food Safety Education</h1>
          <h2>
            We are Australia&rsquo;s number one Registered Training Organisation
            for quality customer service and offer fast and effective Food
            Safety training that you can trust.
          </h2>
          <div className="home-hero-btn">
            <Link
              to="/online-food-safety-training-courses/"
              className="ttg-btn ttg-lg-btn"
            >
              Explore Courses
            </Link>
          </div>
        </div>
        <img
          src="https://solvable.azureedge.net/train-to-gain/images/love-food-bowl.webp"
          alt=""
        />
      </div>
    </div>
  );
};

export default Hero;
