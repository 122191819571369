import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Row,
  Col,
  Collapse,
  Form,
  FormGroup,
} from "reactstrap";
import CourseService from "../../../services/course-service";
import ReactSelectField from "../../../components/Common/Fields/React-Select-Field";
import DatePickerField from "../../../components/Common/Fields/Date-Picker-Field";
import formHandler from "../../../components/Common/Fields/form-handler";
import * as validators from "../../../components/Common/Fields/Validators/Field-Validators";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";

class FilterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
    };
  }

  toggle = () => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  render() {
    const { showFilters } = this.state;
    const form = formHandler();
    const {
      dataTable,
      onInputChange,
      onFieldError,
      onLoad,
      reset,
    } = this.props;
    const { isProcessing } = dataTable;
    const { courseIds, startDate, endDate } = dataTable.fields;

    const courses = CourseService()
      .getCourses()
      .map((course) => {
        return {
          label: course.courseTitle,
          value: course.courseId,
        };
      });
    return (
      <div className="mb-4">
        <Card>
          <CardHeader onClick={this.toggle}>
            <div className="clearfix">
              <span className="float-left">Filter Course Enrolments</span>
              <span className="float-right">
                <i
                  className={
                    showFilters ? "fas fa-caret-up" : "fas fa-caret-down"
                  }
                />
              </span>
            </div>
          </CardHeader>
          <Collapse isOpen={showFilters}>
            <CardBody>
              <Form className="ttg-form">
                <FormGroup>
                  <Row>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <label>Courses</label>
                        <ReactSelectField
                          name="courseIds"
                          validate={(val) => validators.required("Course", val)}
                          showSelectAll={true}
                          selectAllByDefault={true}
                          ref={form.add}
                          onChange={onInputChange}
                          onFieldError={onFieldError}
                          options={courses}
                          keepSettings={true}
                          value={courseIds}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <FormGroup>
                        <label>Enrolment Start Date</label>
                        <div>
                          <DatePickerField
                            name="startDate"
                            value={startDate}
                            minDate={new Date(1930, 1, 1)}
                            maxDate={new Date()}
                            onChange={onInputChange}
                            validate={(val) =>
                              validators.required("Start Date", val)
                            }
                            onFieldError={onFieldError}
                            ref={form.add}
                          ></DatePickerField>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <FormGroup>
                        <label>Enrolment End Date</label>
                        <div>
                          <DatePickerField
                            name="endDate"
                            value={endDate}
                            minDate={new Date(1930, 1, 1)}
                            maxDate={new Date()}
                            onChange={onInputChange}
                            validate={(val) =>
                              validators.required("End Date", val)
                            }
                            onFieldError={onFieldError}
                            ref={form.add}
                          ></DatePickerField>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </CardBody>
            <CardFooter>
              <ButtonFx
                onClick={() => {
                  form.validate({
                    validFn: () => onLoad(dataTable.fields),
                  });
                }}
                type="submit"
                className="ttg-btn"
                isLoading={false}
              >
                Filter
              </ButtonFx>

              <ButtonFx
                type="submit"
                onClick={() => reset()}
                className="ttg-blue-btn ml-2"
                isLoading={isProcessing}
              >
                Reset
              </ButtonFx>
            </CardFooter>
          </Collapse>
        </Card>
        <KeyboardEventHandler
          handleKeys={["enter"]}
          onKeyEvent={() =>
            form.validate({
              validFn: () => onLoad(dataTable.fields),
            })
          }
        />
      </div>
    );
  }
}

export default FilterSection;
