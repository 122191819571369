import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ButtonFx from "../../../components/Common/Button-Fx/Button-Fx";

export default class EditUserModal extends Component {
  render() {
    const {
      toggleDeleteDialog,
      showDeleteDialog,
      isDeleting,
      onDelete
    } = this.props;
    return (
      <div>
        <Modal unmountOnClose={true} isOpen={showDeleteDialog}>
          <ModalHeader toggle={() => toggleDeleteDialog()}>
            Delete NAT Files
          </ModalHeader>
          <ModalBody>
            <section>
              Are you sure you are ready to delete the NAT files?
            </section>
          </ModalBody>
          <ModalFooter>
            <ButtonFx
              type="submit"
              onClick={() => onDelete()}
              className="ttg-btn"
              isLoading={isDeleting}
            >
              Delete
            </ButtonFx>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
