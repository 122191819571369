export const ActionTypes = {
         AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_REQUEST:
           "AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_REQUEST",
         AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_SUCCESS:
           "AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_SUCCESS",
         AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_FAILURE:
           "AVETMISS_ADDRESS_VALIDATION_LOAD_FORM_FAILURE",

         AVETMISS_ADDRESS_VALIDATION_SUBMIT_REQUEST:
           "AVETMISS_ADDRESS_VALIDATION_SUBMIT_REQUEST",
         AVETMISS_ADDRESS_VALIDATION_SUBMIT_SUCCESS:
           "AVETMISS_ADDRESS_VALIDATION_SUBMIT_SUCCESS",
         AVETMISS_ADDRESS_VALIDATION_SUBMIT_FAILURE:
           "AVETMISS_ADDRESS_VALIDATION_SUBMIT_FAILURE",

         AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST:
           "AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST",
         AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS:
           "AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS",
         AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE:
           "AVETMISS_ADDRESS_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE",

         AVETMISS_ADDRESS_VALIDATION_FORM_INPUT_CHANGE:
           "AVETMISS_ADDRESS_VALIDATION_FORM_INPUT_CHANGE",
       };
