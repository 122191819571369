/**
 * Error Modal
 * Another flavor of Modal dialog
 * 
 * Properties exposed:
 * - title
 * - isModalOpen
 * - onButtonClick
 * - isModelStateError
 * 
 */
import React from 'react';
import { Row, Col } from 'reactstrap';
import BasicModal from '../Basic-Modal/Basic-Modal';

const ErrorModal = (props) => (
    <BasicModal title={props.title}
        isOpen={props.isModalOpen}
        isLoading={false}
        isModelStateError={props.isModelStateError}
        showCancel={false}
        toggle={props.onButtonClick}>
        <Row>
            <Col md="1">
                <h2>
                    <i className="fas fa-exclamation-circle"></i>
                </h2>
            </Col>
            <Col>
                {props.message}
                {/* {props.children} */}
            </Col>
        </Row>
    </BasicModal>
);

export default ErrorModal;