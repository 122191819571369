import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import YourCoursesContainer from "components/Your-Courses/Your-Courses.Container";

const StartView = ({ isProcessing, hasNswCertificate }) => {
  return (
    <>
      <PageTagging title="Your Courses" />
      <ContentSection bgClass="veggies-bg off-white flex-100">
        <div className="ttg-container">
          <h1>Your Courses</h1>
          {!isProcessing && (
            <div className="mb-3">
              <Link
                to="/course-enrolment/new/"
                className="btn ttg-btn mt-2 mr-2"
              >
                Enrol in a New Course
              </Link>

              {hasNswCertificate && (
                <Link
                  to="/course-enrolment/replacement-nsw-certificate/"
                  className="btn ttg-btn mt-2"
                >
                  Order a Duplicate Copy NSW FoodSafety Supervisor Certificate
                </Link>
              )}
            </div>
          )}
          <YourCoursesContainer />
        </div>
      </ContentSection>
    </>
  );
};

function mapStateToProps(state) {
  const stateData = state.yourCourses;
  return {
    isProcessing: stateData.isProcessing,
    hasNswCertificate: stateData.hasNswCertificate,
  };
}

const Start = connect(mapStateToProps)(StartView);

export default Start;
