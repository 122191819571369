import React from "react";
import { ErrorMessage } from 'formik';
import "./validation-error-message.scss";

const ValidationErrorMessage = ({name}) => (
  <ErrorMessage name={name} component="div">
    {msg => <div className="small error-text mt-2">{msg}</div>}
  </ErrorMessage>
);

export default ValidationErrorMessage;