import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import ContentSection from "components/Common/content-section";
import PageTagging from "components/Common/page-tagging/page-tagging";
import { selectExternalLearningCourse } from "./redux/reducer";
import * as actionCreators from "./redux/action-creators";
import ButtonFx from "components/Common/Button-Fx/Button-Fx";

const ExternalLearningCourseView = ({
  externalLearningCourse,
  onLoadSection,
  onLoadSso,
}) => {
  const { courseName, isLoading, isLoadingSso } = externalLearningCourse;

  const { id } = useParams();

  useEffect(() => {
    onLoadSection(id);
  }, [id]);

  return (
    <ContentSection bgClass="veggies-bg off-white flex-100">
      <PageTagging title="eLearning Course | Food Safety Education" />
      <div className="ttg-container">
        {!isLoading && (
          <>
            <h1>{courseName}</h1>
            <h5>
              Your course is ready for you on eLearnd, our learning platform.
            </h5>
            <h5>Simply click the button below to get access your course.</h5>
            <ButtonFx
              type="submit"
              className="ttg-btn"
              isLoading={isLoadingSso}
              onClick={() => onLoadSso(id)}
            >
              Continue Course
            </ButtonFx>
          </>
        )}
      </div>
    </ContentSection>
  );
};

function mapStateToProps(state) {
  const externalLearningCourse = selectExternalLearningCourse(state);

  return {
    externalLearningCourse: externalLearningCourse,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  history: ownProps.history,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onLoadSection: (courseContentToken) => {
    dispatchProps.dispatch(
      actionCreators.onLoadSection(courseContentToken, dispatchProps.history)
    );
  },
  onLoadSso: (courseContentToken) => {
    dispatchProps.dispatch(actionCreators.onLoadSso(courseContentToken));
  },
});

const ExternalLearningCourse = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(ExternalLearningCourseView);

export default ExternalLearningCourse;
