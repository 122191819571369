import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import { Button } from "reactstrap";
import "./course-completed.scss";
import ContentSection from "../../components/Content-Section/index";
import BlockUiFx from "./../../components/Common/Block-Ui-Fx/Block-Ui-Fx";

const NswCertificateMessage = (props) => {
  const { courseId } = props;
  if (parseInt(courseId) === 6)
    return (
      <p>
        Your NSW Food Safety Supervisor Certificate will be posted to you within
        7 business days
      </p>
    );

  return null;
};

const CourseCompleted = (props) => {
  const {
    courseTitle,
    hasBeenCompleted,
    thirdPartyFormCompleted,
    courseId,
    loadData,
    isLoading,
    onRedirectToThirdParty,
    courseContentToken,
  } = props;

  useEffect(() => {
    loadData();
  }, []);

  return (
    <BlockUiFx blocking={isLoading} lowerZIndex={true}>
      <PageTitle title="Course Completed" />
      <ContentSection bgColor="grey-shadow">
        <div id="courseContent">
          {hasBeenCompleted && !thirdPartyFormCompleted && (
            <>
              <h1>Well done, almost there...</h1>
              <p>
                We are still waiting for your observer to complete your third
                party form.
              </p>
              <p>
                Please contact your observer and ask them to complete your third
                party form. If they have lost or deleted their email with your
                third party form link, you can resend it by clicking the 'View
                Third Party Form' button below, then click the 'Email this form
                to your Observer' button on your third party form.
              </p>
              <Button className="ttg-btn btn" onClick={onRedirectToThirdParty}>
                View Third Party Form
              </Button>
            </>
          )}
          {hasBeenCompleted && thirdPartyFormCompleted && (
            <>
              <h1>Congratulations!</h1>
              <p className="mt-3">
                You have successfully completed the test for:
              </p>
              <h5>{courseTitle}</h5>
              <NswCertificateMessage courseId={courseId} />
              <a
                href={`https://api.foodsafetyeducation.com.au/api/v1/userCourse/pdf/certificate/${courseContentToken}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn ttg-btn"
              >
                Download Certificate
              </a>
            </>
          )}
        </div>
      </ContentSection>
    </BlockUiFx>
  );
};

export default CourseCompleted;
