import React from "react";
import Hero from "./components/hero/hero";
import InfographicContainer from "./components/infographic-container/infographic-container";
import WhatsNext from "./components/whats-next/whats-next";
import CoursesContainer from "./components/courses-container/courses-container";
import HappyCustomers from "./components/happy-customers/happy-customers";
import WhyTrainWithUs from "./components/why-train-with-us/why-train-with-us";
import BusinessInfo from "./components/business-info/business-info";
import HappyClients from "./components/happy-clients/happy-clients";
import PageTagging from "components/Common/page-tagging/page-tagging";

const Home = () => {
  return (
    <>
      <PageTagging title="Food Safety Education | Food Safety Training that you can trust" />
      <Hero />
      <InfographicContainer />
      <WhatsNext />
      <HappyCustomers />
      <CoursesContainer />
      <WhyTrainWithUs />
      <BusinessInfo />
      <HappyClients />
    </>
  );
};

export default Home;
