/**
 * Enrolment Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  courseDetails: {
    courseId: 0,
    courseTitle: "",
    courseCode: "",
    coursePrice: 0,
    validStates: "",
  },
};

function EnrolmentReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_REQUEST:
      return {
        ...state,
      };

    case ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          courseId: action.payload.courseId,
          courseTitle: action.payload.courseTitle,
          courseCode: action.payload.courseCode,
          coursePrice: action.payload.coursePrice,
          validStates: action.payload.validStates,
        },
      };

    case ActionTypes.ENROLMENT_LOAD_COURSE_DETAILS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default EnrolmentReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectEnrolment = (state) => state.courseEnrolment.enrolment;
