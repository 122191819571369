/**
 * Configure additional middleware here
 */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./root-reducer";

const ConfigureStore = () => {
  let middleware = [thunk];
  let enhancers = [applyMiddleware(...middleware)];

  if (
    process.env.NODE_ENV === "development" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    enhancers.push(
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }

  return createStore(RootReducer, compose(...enhancers));
};

export default ConfigureStore;
