/**
 * Authorise Avs Reducer
 */
import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  userCode: "",
  deviceCode: "",
  verificationUrl: "",
  errorMessage: ""
};

function AuthoriseAvsReducer(state = initialState, action) {
  switch (action.type) {
    // ----------------------------
    //  Action Types
    // ----------------------------

    case ActionTypes.AVETMISS_AUTHORISE_AVS_LOAD_FORM_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.AVETMISS_AUTHORISE_AVS_LOAD_FORM_SUCCESS:
      return {
        ...state,
        userCode: action.payload.userCode,
        deviceCode: action.payload.deviceCode,
        verificationUrl: action.payload.verificationUrl,
        errorMessage: action.payload.errorMessage,
        isLoading: false
      };

    case ActionTypes.AVETMISS_AUTHORISE_AVS_LOAD_FORM_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.AVETMISS_AUTHORISE_AVS_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.AVETMISS_AUTHORISE_AVS_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.AVETMISS_AUTHORISE_AVS_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}

export default AuthoriseAvsReducer;

/**
 * =================
 *  Selectors
 * =================
 */

export const selectAuthoriseAvs = state => state.authoriseAvs;
