import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Copyright from "../Copyright/Copyright";
import "./footer.scss";

const Footer = (props) => {
  const { loggedIn } = props.loginData;

  return (
    <footer>
      <Row>
        <Col lg={4} className="text-center text-lg-left">
          <img
            src="https://solvable.azureedge.net/train-to-gain/images/food-safety-education-white-text-logo.webp"
            alt="Train to Gain"
          />
          <p>
            We are Australia&rsquo;s number one Registered Training Organisation
            for quality customer service and offer fast and effective Food
            Safety training that you can trust.
          </p>
        </Col>
        <Col lg={8}>
          <Row className="justify-content-center text-center text-md-left">
            <Col md={4}>
              <div className="footer-heading">Information</div>
              <ul>
                {!loggedIn && (
                  <li>
                    <Link to="/online-food-safety-training-courses/">
                      Courses
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/FAQs/">FAQs</Link>
                </li>
                {!loggedIn && (
                  <li>
                    <Link to="/online-food-safety-training-courses-for-your-business/">
                      Business
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/About-Train-to-Gain/">About Us</Link>
                </li>
                <li>
                  <Link to="/Contact-Us/">Contact Us</Link>
                </li>
                <li>
                  <a
                    href="/UserFiles/files/Student-Handbook-2024.11.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Student Handbook
                  </a>
                </li>
              </ul>
            </Col>
            {!loggedIn && (
              <Col md={4}>
                <div className="footer-heading">All Courses</div>
                <ul>
                  <li>
                    <Link to="/courses/nsw-food-safety-supervisor-certificate/">
                      NSW Food Safety Supervisor
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses/nsw-food-safety-supervisor-certificate-refresher/">
                      NSW Food Safety Supervisor
                      <br />
                      <span>Refresher</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses/food-safety-supervisor-certificate-hospitality-and-retail/">
                      Food Safety Supervisor
                      <br />
                      <span>Hospitality &amp; Retail</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses/food-handling-level-1-certificate-hospitality-and-retail/">
                      Food Handling Level 1
                      <br />
                      <span>Hospitality &amp; Retail</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses/food-handling-level-2-certificate-hospitality-and-retail/">
                      Food Handling Level 2
                      <br />
                      <span>Hospitality &amp; Retail</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses/food-handling-level-1-certificate-health-and-community/">
                      Food Handling Level 1
                      <br />
                      <span>Health &amp; Community</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/courses/food-safety-supervisor-certificate-health-and-community/">
                      Food Safety Supervisor
                      <br />
                      <span>Health &amp; Community</span>
                    </Link>
                  </li>
                </ul>
              </Col>
            )}
            <Col md={4}>
              <div className="footer-heading">Opening Hours</div>
              <ul>
                <li>MON-FRI: 9AM - 5PM (AEST)</li>
              </ul>
              <div className="footer-heading">Call Us On</div>
              <ul>
                <li>
                  <a href="tel:0400757645">0400 757 645</a>
                </li>
              </ul>
              <div className="footer-heading">Email Us</div>
              <ul>
                <li>
                  <a href="mailto:contact@traintogain.com.au">
                    contact@traintogain.com.au
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Copyright />
    </footer>
  );
};

export default Footer;
