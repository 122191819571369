/**
 * Course Enrolment Step 3 Container
 * Adam Luck @solvable Jun 14, 2019
 */
import { connect } from "react-redux";
import * as actionCreators from "../redux/action-creators";
import Step3Form from "./Step-3-Form";
import { selectEnrolment } from "../redux/enrolment-reducer";
import { selectStep3 } from "../redux/step-3-reducer";
import { selectLoginData } from "../../../redux/system/login-reducer";
import * as sysActionCreators from "../../../redux/system/system-action-creators";

const mapStateToProps = state => {
  const step3Data = selectStep3(state);
  const loginData = selectLoginData(state);
  const enrolmentData = selectEnrolment(state);

  return {
    fields: step3Data.fields,
    isProcessing: step3Data.isProcessing,
    loginData: loginData,
    formValid: step3Data.formValid,
    courseDetails: enrolmentData.courseDetails,
    fieldVisibility: step3Data.fieldVisibility
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadCourseDetails: (courseId) => {
    dispatch(actionCreators.onLoadCourseDetails(courseId));
  },
  onInputChange: ({ name, value, error }) => {
    dispatch(actionCreators.step3FormInputChange(name, value, error));
  },
  onFieldError: (error) =>
    dispatch(sysActionCreators.addNotification(error, "warning")),
  dispatch: dispatch,
  history: ownProps.history,
  match: ownProps.match,
});

const mergeFormProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  step3OnInit: courseId => {
    dispatchProps.dispatch(
      actionCreators.step3OnInit(courseId, dispatchProps.history)
    );
  },
  onSubmitEnrolment: () => {
    dispatchProps.dispatch(
      actionCreators.onSubmitEnrolmentStep3(
        stateProps.fields,
        dispatchProps.history
      )
    );
  }
});

const Step3FormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeFormProps
)(Step3Form);

export default Step3FormContainer;
