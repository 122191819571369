import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withFormik, Form } from "formik";
import FileUploader from "./../../../../../../components/Common/file-uploader/file-uploader";
import ValidationErrorMessage from "./../../../../../../components/Common/validation-error-message/validation-error-message";
import * as Yup from "yup";

const ImportNatFileDialog = ({
  isOpen,
  isImporting,
  close,
  resetForm,
  handleSubmit,
  values,
  data,
  setValues,
  setFieldValue,
}) => {
  useEffect(() => {
    if (data) {
      setValues({
        id: data.id,
        title: data.title,
        displayOrder: data.displayOrder,
        files: [
          {
            ...data.file,
            size: data.file.size,
          },
        ],
      });
    }
  }, [data, setValues]);

  const closeDialog = () => {
    if (!isImporting) {
      close();
      resetForm();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={closeDialog} size="md">
      <ModalHeader toggle={closeDialog}>Import NAT files</ModalHeader>
      <Form>
        <ModalBody>
          <FileUploader
            maxFileSize={5242880}
            maxFiles={1}
            text="Click me or drag a file to upload."
            dropText="Drop it like it's hot!"
            fileTypes={[".zip"]}
            onChange={(files) => {
              setFieldValue(
                "files",
                files.map(({ name, data, size }) => {
                  return {
                    name,
                    data,
                    size,
                  };
                })
              );
            }}
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          />

          <ValidationErrorMessage name="files" />
        </ModalBody>
        {!isImporting && (
          <ModalFooter>
            <Button
              className="react-button app-link-primary btn btn-secondary btn-md"
              onClick={handleSubmit}
            >
              Upload
            </Button>
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                closeDialog();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

const ImportNatFileFormDialog = withFormik({
  mapPropsToValues: () => ({ files: "" }),
  validationSchema: Yup.object().shape({
    files: Yup.array()
      .required("Please add a file.")
      .min(1, "Please add a file."),
  }),
  handleSubmit: async (values, { props, resetForm, setSubmitting }) => {
    try {
      setSubmitting(true);
      await props.import(values.files[0].data);
      resetForm();
    } catch (error) {
      setSubmitting(false);
    }
  },
})(ImportNatFileDialog);

export default ImportNatFileFormDialog;
