import React from "react";
import ContentCollapsiblePanel from "components/Common/content-collapsible-panel";

const FoodHandlingLevel2CertificateHospitalityAndRetail = () => {
  return (
    <>
      <ContentCollapsiblePanel
        openSection={-1}
        sections={[
          {
            title: "About the Food Safety Handler",
            content: (
              <>
                <p>
                  All Food Handlers in Australia must be trained in Food Safety.
                  Our Food Handling Certificate course (Level 2) is designed to
                  meet these legal requirements and helps to protect customers
                  from food-related illnesses and incidents.
                </p>
                <p>
                  This course is designed for all people supervising food
                  operations in the Hospitality or Retail food industries. This
                  course is also known as Level 2 of the Food Safety Supervisor
                  qualification.
                </p>
                <p>
                  People at many levels use this skill in the workplace during
                  their daily activities, including cooks, chefs, caterers,
                  kitchen stewards, kitchen hands, bar staff, food attendants,
                  and room attendants in the hospitality and retail industries.
                </p>
              </>
            ),
          },
          {
            title: "Is this course for me?",
            content: (
              <>
                <p>
                  This course is perfect for people who have completed Level 1 -
                  Food Handling in Hospitality & Retail and wish to further
                  their Food Safety skills and responsibilities.
                </p>
                <p>
                  The two completed units (Level 1 & 2) make up the Food Safety
                  Supervisor qualification.
                </p>
                <p>
                  Under the Standard 3.2.2A, all staff who handle unpackaged,
                  potentially hazardous food must be trained in Food Safety and
                  be able to show they have appropriate skills and knowledge.
                  This is different from the Food Safety Supervisor requirement.
                </p>
                <p>Food Handlers are required to:</p>
                <ul>
                  <li>
                    Have Food Safety skills and knowledge as per Standard 3.2.2A
                  </li>
                  <li>Be able to demonstrate this to a Health Inspector</li>
                  <li>Handle food in a safe and hygienic manner</li>
                </ul>
                <p>
                  Businesses can choose how Food Handlers are trained, but the
                  training must cover:
                </p>
                <p>
                  <img
                    src="/images/food-handling-level-1-covered-training.png"
                    alt="Businesses can choose how Food Handlers are trained, but the training must cover safe handling of food, food contamination, cleaning and sanitising, and personal hygiene"
                  />
                </p>
                <p>
                  <strong>
                    Rest assured, when you enrol with us, you have access to
                    everything you need to be accredited as a qualified Food
                    Handler in Australia.
                  </strong>
                </p>
              </>
            ),
          },
          {
            title: "Course Summary",
            content: (
              <>
                <p>
                  This unit applies to all tourism, hospitality, retail, and
                  catering organisations with permanent or temporary kitchen
                  premises or smaller food preparation areas.
                </p>
                <p>Critical aspects of this training include:</p>
                <ul>
                  <li>
                    Consistently use safe Food Handling practices in a range of
                    different Food Handling circumstances
                  </li>
                  <li>
                    Demonstrate knowledge of a Food Safety program and
                    requirements, including critical control points and methods
                    of food hazard control for each critical control point
                  </li>
                  <li>
                    Take corrective actions within the scope of job
                    responsibility for incidents where food hazards are not
                    controlled
                  </li>
                </ul>
                <p>
                  Our Food Handler (Level 2) course is designed to be as
                  flexible and straightforward as possible! We pride ourselves
                  on our learning platform which is filled with many short DVD's
                  and visual images, making this training course a fast and
                  effective way to obtain your Nationally Accredited
                  Certificate.
                </p>
                <p>
                  All our training courses allow students to complete their
                  course in their own time. That's right, there are no lockouts
                  and no penalties if you get any answers wrong!
                </p>
                <p>
                  On completion of your Food Handling training, we also offer
                  you a downloadable course workbook. This online course
                  material is always updated so we encourage you to log back in
                  any time after your training and obtain any new Food Safety
                  information. This is especially helpful when you need to
                  refresh your Food Safety skills and when council comes around!
                </p>
              </>
            ),
          },
          {
            title: "State Food Safety Legislation",
            content: (
              <>
                <p>
                  <strong>
                    Please click for more information on the link below for your
                    States or Territories Food Safety Legislation
                  </strong>
                </p>
                <p>
                  <a
                    href="https://www.health.qld.gov.au/foodsafety/legislation.asp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Queensland Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.health.vic.gov.au/food-safety/food-safety-laws-and-regulations"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Victorian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.health.act.gov.au/businesses/food-safety-regulation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ACT Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.sahealth.sa.gov.au/wps/wcm/connect/public+content/sa+health+internet/public+health/food+safety+for+businesses/food+safety+for+businesses"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    South Australian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.foodauthority.nsw.gov.au/ip/legislation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NSW Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.foodstandards.gov.au/foodstandards/foodenforcementcontacts/westernaustralia.cfm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Western Australia Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.dhhs.tas.gov.au/publichealth/food_safety"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tasmanian Food Safety Legislation
                  </a>
                </p>
                <p>
                  <a
                    href="https://nt.gov.au/industry/hospitality/accommodation-and-food-businesses/food-safety-and-regulations/food-safety-standards"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Northern Territory Food Safety Legislation
                  </a>
                </p>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FoodHandlingLevel2CertificateHospitalityAndRetail;
