import React from "react";
import ContentSection from "components/Common/content-section";
import Carousel from "components/Carousel";
import * as Constants from "constants/index";
import "./happy-clients.scss";

const HappyClients = () => {
  return (
    <ContentSection>
      <div className="happy-clients">
        <h1>Our Happy Clients</h1>

        <div className="mb-4">
          <Carousel items={Constants.testimonials} onlyImages />
        </div>
      </div>
    </ContentSection>
  );
};

export default HappyClients;
