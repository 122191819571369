/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import ForgotPasswordService from "../Forgot-Password-Service";

// =================
//  Reset Password
// =================

// Async
export const onRequestResetPassword = email => {
  return dispatch => {
    const service = ForgotPasswordService();
    dispatch(onRequestResetPasswordRequest());

    service
      .requestResetPassword(email)
      .then(resp => {
        dispatch(onRequestResetPasswordSuccess(resp));
      })
      .catch(err => {
        // Todo: Implement generic error handler in the web template - rcnet
        let error = err.response.data && err.response.data.Message;
        dispatch(onRequestResetPasswordFailure());
        dispatch(addNotification(error, "error"));
      });
  };
};

const onRequestResetPasswordRequest = () => ({
  type: ActionTypes.FORGOT_PASSWORD_RESET_REQUEST
});
const onRequestResetPasswordSuccess = () => ({
  type: ActionTypes.FORGOT_PASSWORD_RESET_SUCCESS
});
const onRequestResetPasswordFailure = () => ({
  type: ActionTypes.FORGOT_PASSWORD_RESET_FAILURE
});

// ----------------------------
//  Form Actions
// ----------------------------
export const formInputChange = (name, value, error) => ({
  type: ActionTypes.FORGOT_PASSWORD_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
