/**
 * Action Creator definitions
 */
import { ActionTypes } from "./action-types";
import { addNotification } from "../../../../redux/system/system-action-creators";
import AvetmissReportingValidationService from "../avetmiss-reporting-validation-service";
import { saveAs } from "file-saver";

// ----------------------------
//  Actions
// ----------------------------
export const onLoadForm = history => {
  return dispatch => {
    dispatch(LoadFormRequest());

    const service = AvetmissReportingValidationService();

    service
      .getStatus()
      .then(resp => {
        dispatch(LoadFormSuccess());

        if (!resp.data.validToken) {
          history.push("/Admin/Avetmiss/Authorise/");
          return;
        }

        if (
          resp.status !== 204 &&
          resp.data.reports !== null &&
          resp.data.reports !== [] &&
          resp.data.reports.length > 0
        ) {
          history.push("/Admin/Avetmiss/Submission/");
          return;
        } else {
          history.push("/Admin/Avetmiss/Reporting/");
          return;
        }
      })
      .catch(err => {
        dispatch(LoadFormFailure());
        history.push("/Admin/Avetmiss/Reporting/");
      });
  };
};

const LoadFormRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_LOAD_FORM_REQUEST
});

const LoadFormSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_LOAD_FORM_SUCCESS
});

const LoadFormFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_LOAD_FORM_FAILURE
});

// Async
export const onSubmit = (errors, history) => dispatch => {
  dispatch(onSubmitRequest());

  const service = AvetmissReportingValidationService();

  const model = errors
    .filter(x => x.updated)
    .map(error => ({
      natFileNumber: error.natFileNumber,
      version: error.version,
      fileId: error.fileId,
      recordNumber: error.recordNumber,
      fieldValue: error.fieldValue
    }));

  if (model.length > 0) {
    service
      .submitRequest(model)
      .then(resp => {
        dispatch(onSubmitSuccess());
        dispatch(addNotification("Changes saved.", "info"));
        history.push("/Admin/Avetmiss/Submission/");
      })
      .catch(err => {
        dispatch(onSubmitFailure());
        dispatch(
          addNotification("There was an issue updating the NAT files.", "error")
        );
      });
  } else {
    dispatch(onSubmitFailure());
    dispatch(addNotification("No changes have been detected.", "error"));
  }
};

const onSubmitRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_SUBMIT_REQUEST
});
const onSubmitSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_SUBMIT_SUCCESS
});
const onSubmitFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_SUBMIT_FAILURE
});

export const onDelete = history => dispatch => {
  dispatch(onDeleteRequest());

  const service = AvetmissReportingValidationService();

  service
    .deleteRequest()
    .then(resp => {
      dispatch(onDeleteSuccess());
      history.push("/Admin/Avetmiss/Reporting/");
    })
    .catch(err => {
      dispatch(onDeleteFailure());
      dispatch(
        addNotification("There was an issue deleting the NAT files.", "error")
      );
    });
};

const onDeleteRequest = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_DELETE_REQUEST
});
const onDeleteSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_DELETE_SUCCESS
});
const onDeleteFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_DELETE_FAILURE
});

export const toggleDeleteDialog = () => dispatch => {
  dispatch({
    type: ActionTypes.AVETMISS_REPORTING_VALIDATION_SHOW_DELETE_DIALOG
  });
};

export const onDownloadNatFiles = () => dispatch => {
  dispatch({
    type: ActionTypes.AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_REQUEST
  });
  let service = AvetmissReportingValidationService();
  service
    .downloadNatFiles()
    .then(resp => {
      dispatch(onDownloadNatFilesSuccess());
      const pdfBlob = new Blob([resp.data], { type: "application/zip" });
      saveAs(pdfBlob, "natfiles.zip");
    })
    .catch(() => {
      dispatch(onDownloadNatFilesFailure());
      dispatch(addNotification("Unable to the NAT files.", "error"));
    });
};

const onDownloadNatFilesSuccess = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_SUCCESS
});

const onDownloadNatFilesFailure = () => ({
  type: ActionTypes.AVETMISS_REPORTING_VALIDATION_DOWNLOAD_NAT_FILES_FAILURE
});

// ----------------------------
//  Form Actions
// ----------------------------

export const formInputChange = (name, value, error) => {
  return dispatch => {
    const natFileNumber = parseInt(name.match(/(\d+)-/im)[1]);
    const recordNumber = parseInt(name.match(/-(\d+)/im)[1]);

    dispatch({
      type: ActionTypes.AVETMISS_REPORTING_VALIDATION_FORM_INPUT_CHANGE,
      payload: {
        natFileNumber: natFileNumber,
        recordNumber: recordNumber,
        fieldValue: value
      }
    });
  };
};
