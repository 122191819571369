export const navLinks = [
  {
    title: "Courses",
    link: "/online-food-safety-training-courses/",
    subLinks: [],
    show: true,
  },
  {
    title: "FAQs",
    link: "/FAQs/",
    subLinks: [],
    show: true,
  },
  {
    title: "Business",
    link: "/online-food-safety-training-courses-for-your-business/",
    subLinks: [],
    show: true,
  },
  {
    title: "About",
    link: "/About-Train-to-Gain/",
    subLinks: [],
    show: true,
  },
  {
    title: "Contact",
    link: "/Contact-Us/",
    subLinks: [],
    show: true,
  },
  {
    title: "Login",
    link: "/login/",
    subLinks: [],
    show: true,
  },
].filter((x) => x.show);

export const privateNavLinks = [
  {
    title: "Your Courses",
    link: "/Your-Courses/",
    subLinks: [],
    show: true,
    roles: ["Student"],
  },
  {
    title: "Your Vouchers",
    link: "/Corporate/",
    subLinks: [],
    show: true,
    roles: ["Business"],
  },
  {
    title: "Registers",
    link: "/Admin/Users/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "Courses",
    link: "/Admin/User-Courses/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "Vouchers",
    link: "/Admin/Voucher-Purchases/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "NSW Certs",
    link: "/Admin/Nsw-Certificates/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "NSW Reprints",
    link: "/Admin/Nsw-Reprints/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "Reports",
    link: "/Admin/Download-Reports",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "Promo",
    link: "/Admin/Promo-Codes/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "Avetmiss",
    link: "/Admin/Avetmiss/Reporting/",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "Sync",
    link: "/Admin/Sync",
    subLinks: [],
    show: true,
    roles: ["Admin"],
  },
  {
    title: "FAQs",
    link: "/FAQs/",
    subLinks: [],
    show: true,
    roles: ["Student"],
  },
  {
    title: "Contact",
    link: "/Contact-Us/",
    subLinks: [],
    show: true,
    roles: ["Student"],
  },
  {
    title: "FAQs",
    link: "/FAQs/",
    subLinks: [],
    show: true,
    roles: ["Business"],
  },
  {
    title: "Contact",
    link: "/Contact-Us/",
    subLinks: [],
    show: true,
    roles: ["Business"],
  },
].filter((x) => x.show);

export const testimonials = [
  "Bakers-Delight.jpg",
  "Boost.jpg",
  "Comcater.jpg",
  "Community-Solutions.jpg",
  "Costco.jpg",
  "Crust.jpg",
  "Dept-of-Defence.jpg",
  "Employment-Innovations.jpg",
  "Global-Food-Equipment.jpg",
  "Gloria-Jeans.jpg",
  "Laundry-Hotels.jpg",
  "Mission-Australia.jpg",
  "Nandos.jpg",
  "Ozcare.jpg",
  "Palace-Cinemas.jpg",
  "Pancake-Parlour.jpg",
  "Quest-Hotels.jpg",
  "Ramsay.jpg",
  "Regis.jpg",
  "Ribs-and-Burgers.jpg",
  "Sarino-Russo.jpg",
  "Sodexo.jpg",
  "Spudbar.jpg",
  "Stix-Catering.jpg",
  "Subway.jpg",
  "Sunkids.jpg",
  "United-Petroleum.jpg",
  "Urban.jpg",
  "Yogurtland.jpg",
];

export const customerTestimonials = [
  {
    testimonial: "Fantastic customer service and phone support when needed.",
    name: "Sarah J",
  },
  {
    testimonial: "It’s flexible online learning I could do at my own pace.",
    name: "Christine N",
  },
  {
    testimonial:
      "Gave me the knowledge and the confidence to start my new job.",
    name: "Leah A",
  },
  {
    testimonial:
      "This was much more accessible for me living in a small rural town.",
    name: "Amy M",
  },
  {
    testimonial:
      "Explaining with video tutorials was interesting and informative.",
    name: "Rohan P",
  },
  {
    testimonial: "Training resources were available when I needed them.",
    name: "Li Jun F",
  },
  {
    testimonial:
      "I had the ability to look at the course guide and re-learn if I didn’t retain the information the first time.",
    name: "Karmara H",
  },
  {
    testimonial:
      "The videos and case studies were great and a practical way to learn.",
    name: "Chona E",
  },
  {
    testimonial:
      "Very concise information about each step that made it easy to understand.",
    name: "Jaseema M",
  },
  {
    testimonial:
      "Engaging delivery, practical examples, comprehensive content.",
    name: "Darwood L",
  },
  {
    testimonial: "Following the steps to enrol and start the course was easy.",
    name: "John T",
  },
];

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const googleApiKey = "AIzaSyDkUvfSQ1Xd1RMdaQqNe8SOaWSNtyU2Uow";

export const australianStates = [
  { value: "ACT", label: "Australia Capital Territory" },
  { value: "NSW", label: "New South Wales" },
  { value: "NT", label: "Northern Territory" },
  { value: "QLD", label: "Queensland" },
  { value: "SA", label: "South Australia" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" },
];

export const userTypes = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Student" },
  { value: 3, label: "Business" },
];

export const reportTypes = [
  { value: 0, label: "Users", order: 1 },
  { value: 1, label: "Course Completions", order: 4 },
  { value: 2, label: "Course Enrolments", order: 5 },
  { value: 3, label: "NSW Certificates", order: 6 },
  { value: 4, label: "Voucher Purchases", order: 8 },
  { value: 5, label: "Unused Vouchers", order: 9 },
  { value: 6, label: "Quiz Responses", order: 10 },
  { value: 7, label: "Survey Responses", order: 11 },
  { value: 8, label: "NSW Reprints", order: 7 },
  { value: 9, label: "Course Completions Summary", order: 2 },
  { value: 10, label: "Course Enrolments Summary", order: 3 },
];
