import { ActionTypes } from "./action-types";

const initialState = {
  isProcessing: false,
  fields: {},
  dialogs: {
    showDeleteDialog: false
  }
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADMIN_USER_COURSES_SHOW_DELETE_DIALOG:
      return {
        ...state,
        fields: action.payload ? action.payload : {},
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        }
      };
    case ActionTypes.ADMIN_USER_COURSES_DELETE_REQUEST:
      return {
        ...state,
        isProcessing: true
      };
    case ActionTypes.ADMIN_USER_COURSES_DELETE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        dialogs: {
          ...state.dialogs,
          showDeleteDialog: !state.dialogs.showDeleteDialog
        }
      };
    default:
      return state;
  }
}

export default Reducer;
