import { ActionTypes } from "./action-types";
import { addNotification } from "../../../redux/system/system-action-creators";
import HttpService from "./service";

export const onSubmit = (id, fields) => {
  const { oldPassword, password, confirmPassword } = fields;

  return dispatch => {
    if (!oldPassword || oldPassword === undefined || oldPassword === "") {
      dispatch(addNotification("Old password is required.", "warning"));
      return;
    }

    if (!password || password === undefined || password === "") {
      dispatch(addNotification("Password is required.", "warning"));
      return;
    }

    if (
      !confirmPassword ||
      confirmPassword === undefined ||
      confirmPassword === ""
    ) {
      dispatch(addNotification("Confirm Password is required.", "warning"));
      return;
    }

    dispatch(onSubmitRequest());
    HttpService()
      .changePassword(id, fields)
      .then(() => dispatch(onSubmitSuccess()))
      .catch(err => {
        dispatch(onSubmitFailure());
        dispatch(
          addNotification(
            err.response.data.Message
              ? err.response.data.Message
              : "Changing of password failed.",
            "warning"
          )
        );
      });
  };
};

const onSubmitRequest = () => ({
  type: ActionTypes.CHANGE_PASSWORD_ON_SUBMIT
});

const onSubmitSuccess = () => ({
  type: ActionTypes.CHANGE_PASSWORD_ON_SUCCESS
});

const onSubmitFailure = () => ({
  type: ActionTypes.CHANGE_PASSWORD_ON_FAILURE
});

export const formInputChange = (name, value, error) => ({
  type: ActionTypes.CHANGE_PASSWORD_FORM_INPUT_CHANGE,
  payload: {
    name: name,
    value: value,
    error: error
  }
});
