/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  COURSE_QUIZ_LOAD_SECTION_REQUEST: "COURSE_QUIZ_LOAD_SECTION_REQUEST",
  COURSE_QUIZ_LOAD_SECTION_SUCCESS: "COURSE_QUIZ_LOAD_SECTION_SUCCESS",
  COURSE_QUIZ_LOAD_SECTION_FAILURE: "COURSE_QUIZ_LOAD_SECTION_FAILURE",

  COURSE_QUIZ_SUBMIT_ANSWER_REQUEST: "COURSE_QUIZ_SUBMIT_ANSWER_REQUEST",
  COURSE_QUIZ_SUBMIT_ANSWER_SUCCESS: "COURSE_QUIZ_SUBMIT_ANSWER_SUCCESS",
  COURSE_QUIZ_SUBMIT_ANSWER_FAILURE: "COURSE_QUIZ_SUBMIT_ANSWER_FAILURE",

  COURSE_QUIZ_CHANGE_SELECTED_ANSWER: "COURSE_QUIZ_CHANGE_SELECTED_ANSWER",
  COURSE_QUIZ_DROPDOWN_CHANGE_SELECTED_ANSWER:
    "COURSE_QUIZ_DROPDOWN_CHANGE_SELECTED_ANSWER",

  COURSE_QUIZ_ALLOW_REDIRECT_TO_COURSE_CONTENT:
    "COURSE_QUIZ_ALLOW_REDIRECT_TO_COURSE_CONTENT",
  COURSE_QUIZ_RELOAD_SECTION_99_DATASET_OR_APPLY_ACTION:
    "COURSE_QUIZ_RELOAD_SECTION_99_DATASET_OR_APPLY_ACTION"
};
