import React from "react";
import { aboutPages } from "./about-us-page-content";

export const publicPages = [
  {
    pageName: "not-found",
    title: "Page Not Found",
    description: "",
    content: (
      <>
        <h1>
          Food Safety Training with Train to Gain Pty Ltd T/A Food Safety
          Education
        </h1>
      </>
    ),
  },
  ...aboutPages,
];
