/**
 * Utility functions to support the solvable-priority-nav component.
 * Includes logic to determine if items fit in the navigation bar and to move items between the main nav and dropdown.
 *
 * Uses lodash.debounce to optimize resize handling.
 *
 * Functions: doesItFit (handles item fitting logic), getClassName (concatenates custom and default class names).
 *
 * Dependencies: lodash (for debounce).
 * Adam Luck - Solvable - 2024-06-29
 */

import { debounce } from "lodash";

const moveItemToList = (dispatch, items, state) => {
  dispatch({
    type: "move",
    payload: {
      lastItem: items.get(state.children.length - 1),
    },
  });
};

const moveItemToNav = (dispatch) => {
  dispatch({
    type: "return",
  });
};

export const doesItFit = (
  nav,
  outerNav,
  state,
  items,
  dispatch,
  offset,
  debounceMs
) => {
  const debouncedFunction = debounce(() => {
    if (nav.current && outerNav.current) {
      const outerWidth = outerNav.current.offsetWidth;
      const totalWidth = nav.current.offsetWidth;

      if (state.children.length > 0 && totalWidth > outerWidth)
        moveItemToList(dispatch, items, state);
      else if (
        state.dropdownItems.length > 0 &&
        outerWidth >
          totalWidth +
            state.lastItemWidth[state.lastItemWidth.length - 1] +
            (offset || 0)
      )
        moveItemToNav(dispatch);
    }
  }, debounceMs);

  return debouncedFunction;
};

export const getClassName = (className, customClassName) => {
  return customClassName ? `${className} ${customClassName}` : className;
};
