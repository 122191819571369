import HttpClient from "../../../coreLib//http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const getCourses = (id) => {
    return httpClient.get(`v1/userCourse/${id}`);
  };

  const downloadCertificate = (courseId) => {
    return httpClient.get(`v1/userCourse/pdf/certificate/${courseId}`, {
      responseType: "blob",
    });
  };

  const downloadThirdPartyForm = (thirdPartyAccessToken) => {
    return httpClient.get(
      `v1/userCourse/pdf/third-party-form/${thirdPartyAccessToken}`,
      {
        responseType: "blob",
      }
    );
  };

  const downloadTaxInvoice = (invoiceId) => {
    return httpClient.get(`v1/userCourse/pdf/tax-invoice/${invoiceId}`, {
      responseType: "blob",
    });
  };

  return {
    getCourses,
    downloadCertificate,
    downloadThirdPartyForm,
    downloadTaxInvoice,
  };
};

export default Service;
