import React, { Component } from "react";
import { Form, FormGroup } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
import ToggleDisplay from "react-toggle-display";
import "./contact-box.scss";
import * as validators from "../Common/Fields/Validators/Field-Validators";
import BlockUiFx from "../Common/Block-Ui-Fx/Block-Ui-Fx";
import KeyboardEventHandler from "react-keyboard-event-handler";
import formHandler from "./../Common/Fields/form-handler";
import ButtonFx from "../Common/Button-Fx/Button-Fx";

// Combination of validations
const emailValidator = (fieldName, value) => {
  if (validators.required(fieldName, value))
    return validators.required(fieldName, value);
  if (validators.email(value)) return validators.email(value);

  return false;
};

class ContactBox extends Component {
  render() {
    const { name, email, phone, message } = this.props.contactBox.fields;
    const { hasBeenSent, isSending } = this.props.contactBox;
    const { onSubmit, onInputChange, onFieldError, validate } = this.props;

    const form = formHandler();

    return (
      <div>
        <ToggleDisplay if={hasBeenSent}>
          <section>
            <h3>
              Thanks, a member of the support team will be in contact with you
              soon.
            </h3>
          </section>
        </ToggleDisplay>
        <ToggleDisplay if={!hasBeenSent}>
          <BlockUiFx blocking={isSending} lowerZIndex={true}>
            <section>
              <Form className="ttg-form contact-box">
                <FormGroup>
                  <InputField
                    name="name"
                    placeholder="Your Name"
                    value={name}
                    validate={(val) => validators.required("Name", val)}
                    onFieldError={onFieldError}
                    ref={form.add}
                    onChange={onInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <InputField
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    ref={form.add}
                    validate={(val) => emailValidator("Email Address", val)}
                    onFieldError={onFieldError}
                    onChange={onInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <InputField
                    name="phone"
                    placeholder="Phone Number (optional)"
                    value={phone}
                    ref={form.add}
                    onFieldError={onFieldError}
                    onChange={onInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <InputField
                    name="message"
                    placeholder="Message"
                    value={message}
                    type="textarea"
                    rows="3"
                    ref={form.add}
                    validate={(val) => validators.required("Message", val)}
                    onFieldError={onFieldError}
                    onChange={onInputChange}
                  />
                  {/* TODO: change to textarea */}
                </FormGroup>
                <FormGroup>
                  <ButtonFx
                    type="submit"
                    className="ttg-btn"
                    isLoading={isSending}
                    onClick={() =>
                      form.validate({
                        validFn: () => onSubmit(),
                        invalidFn: () => console.log(form.getFieldErrors()),
                      })
                    }
                  >
                    Submit
                  </ButtonFx>
                </FormGroup>
              </Form>
              <KeyboardEventHandler
                handleKeys={["enter"]}
                onKeyEvent={(key, e) =>
                  form.validate({ validFn: () => onSubmit() })
                }
              />
            </section>
          </BlockUiFx>
        </ToggleDisplay>
      </div>
    );
  }
}

export default ContactBox;
