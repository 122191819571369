import React from "react";
export const usi = [
  {
    title: "What is a USI number?",
    content: (
      <>
        When you start your course, you will be asked to supply your Unique
        Student Identifier (USI). If you don&rsquo;t already have a USI
        you&rsquo;ll be asked to create one.
        <br />
        <br />
        The USI is a Government initiative launched on 1st January 2015, making
        it compulsory for all students participating in nationally recognised
        training to have this unique reference ID. The reference ID is 10
        characters long and is comprised of numbers and letters. You can apply
        for a USI{" "}
        <a
          href="https://www.usi.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . Once you have a USI, this unique number is assigned to you
        permanently.
        <br />
        <br />
        One of the benefits of having this USI is that you can easily access
        details of any nationally recognised training that you have completed by
        looking it up on the USI website. This means that if you lose your
        Statement of Attainment, can&rsquo;t remember the units that formed your
        qualification or don&rsquo;t know the name of the Registered Training
        Organisation (RTO) that issued your qualification it doesn&rsquo;t
        matter, as all of this information is stored in one central location.
        <br />
        <br />
        In the event that we do not have your correct USI number, Train to Gain
        Pty Ltd T/A Food Safety Education will have the authority to use your
        personal details gained from Train to Gain&rsquo;s enrolment form. This
        information will only be used to enter into the USI registry website to
        obtain your correct USI details.
        <br />
        <br />
        <a
          href="https://www.usi.gov.au/students"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.usi.gov.au/students
        </a>
      </>
    ),
  },
  {
    title: "I don’t have a USI number. How do I get one?",
    content: (
      <>
        Applying for a Unique Student Identifier (USI) is free of charge and
        just takes a few minutes to do. Begin by visiting{" "}
        <a
          href="https://www.usi.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.usi.gov.au/
        </a>
        <br />
        You&rsquo;ll need to have a form of ID ready to complete your
        application. This could be any of the following:
        <br />
        <br />
        <ul className="my-0">
          <li>Driver&rsquo;s Licence</li>
          <li>Medicare Card</li>
          <li>Australian Passport</li>
          <li>Visa</li>
          <li>Australian Birth Certificate</li>
          <li>Certificate of Registration by Descent</li>
          <li>Citizenship Certificate</li>
          <li>Immi Card</li>
        </ul>
        <br />
        <br />
        You&rsquo;ll then need to supply some personal information including
        your name, date of birth, place of birth, gender and country in which
        you intend to study. Finally, you need to provide answers to some check
        questions that you will be asked the next time you try to access the
        website.
        <br />
        <br />
        Once you have completed the online form, you will be issued with a USI
        that can be used to access your account and check training details at
        any time.
      </>
    ),
  },
  {
    title: "I’m an international student, do I need a USI?",
    content: (
      <>
        If you are an International student studying in Australia you will need
        a USI. All international students in Australia will have been issued
        with an Australian Visa. Once you arrive in Australia your visa will be
        activated and you will be able to apply for a USI. Please Note:
        <br />
        <br />
        We are not registered for CRICOS. The Commonwealth Register of
        Institutions and Courses for Overseas Students is a register of
        Australian education institutions that recruit, enrol and teach overseas
        students. Please go to this link for further information.{" "}
        <a
          href="http://cricos.education.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://cricos.education.gov.au/
        </a>
      </>
    ),
  },
  {
    title: "I’ve Forgotten my USI",
    content: (
      <>
        If you have forgotten or lost your USI, you can retrieve it online. You
        will need to enter a few details to verify who you are to find your USI.
        The details must be the same as those you entered when you applied for a
        USI or, if you did so, when you last updated your USI account. Go to{" "}
        <a
          href="https://www.usi.gov.au/faqs/i-have-forgotten-my-usi"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.usi.gov.au/faqs/i-have-forgotten-my-usi
        </a>
      </>
    ),
  },
  {
    title: "Will my USI appear on my training certificate?",
    content: (
      <>
        No, under legislation your USI must not be printed on your qualification
        or statement of attainment.
      </>
    ),
  },
  {
    title:
      "I’m not able to proceed with my enrolment but I know my USI is correct",
    content: (
      <>
        You must make sure that you use the exact same name and format that you
        used when applying for your USI. i.e. If you used a middle name when
        applying for your USI, you must include your middle name when enrolling
        in courses, (all letters are case sensitive). Go here to get more
        information about this{" "}
        <a
          href="https://www.usi.gov.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.usi.gov.au
        </a>
      </>
    ),
  },
];
