/**
 * Action types
 */
export const ActionTypes = {
  // Actions
  COURSE_CONTENT_LOAD_SECTION_REQUEST: "COURSE_CONTENT_LOAD_SECTION_REQUEST",
  COURSE_CONTENT_LOAD_SECTION_SUCCESS: "COURSE_CONTENT_LOAD_SECTION_SUCCESS",
  COURSE_CONTENT_LOAD_SECTION_FAILURE: "COURSE_CONTENT_LOAD_SECTION_FAILURE",

  COURSE_CONTENT_TOGGLE_NEXT_PAGE: "COURSE_CONTENT_TOGGLE_NEXT_PAGE",
  COURSE_CONTENT_TOGGLE_PREVIOUS_PAGE: "COURSE_CONTENT_TOGGLE_PREVIOUS_PAGE",

  COURSE_CONTENT_LOAD_QUIZ_REQUEST: "COURSE_CONTENT_LOAD_QUIZ_REQUEST",
  COURSE_CONTENT_LOAD_QUIZ_SUCCESS: "COURSE_CONTENT_LOAD_QUIZ_SUCCESS",
  COURSE_CONTENT_LOAD_QUIZ_FAILURE: "COURSE_CONTENT_LOAD_QUIZ_FAILURE"
};
