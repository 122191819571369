import { ActionTypes } from "./action-types";
import DateService from "../../../services/date-service";
import * as CorporateConstants from "../../Start/corporate-constants";

const initialState = {
  data: [],
  showEmailAddressDialog: false,
  emailDetails: null,
  isProcessing: false,
};

function CorporateVouchersReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CORPORATE_VOUCHERS_ON_INIT:
      return {
        ...state,
        data: [],
        showEmailAddressDialog: false,
        emailDetails: null,
        isProcessing: false,
      };
    case ActionTypes.CORPORATE_VOUCHERS_SEND_EMAIL_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.CORPORATE_VOUCHERS_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        showEmailAddressDialog: false,
        emailDetails: null,
        isProcessing: false,
      };
    case ActionTypes.CORPORATE_VOUCHERS_SEND_EMAIL_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case ActionTypes.CORPORATE_VOUCHERS_SHOW_EMAIL_DIALOG:
      return {
        ...state,
        showEmailAddressDialog: !state.showEmailAddressDialog,
      };
    case ActionTypes.CORPORATE_VOUCHERS_SET_DETAILS_FORM_EMAIL:
      return {
        ...state,
        emailDetails: action.payload,
      };
    case ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_REQUEST:
    case ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_TPF_REQUEST:
    case ActionTypes.CORPORATE_VOUCHERS_ON_LOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
      };
    case ActionTypes.CORPORATE_VOUCHERS_ON_LOAD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        data: action.payload.map((voucher) => {
          return {
            ...voucher,
            course: CorporateConstants.courses.find(
              (course) => course.id === voucher.courseId
            ).courseTitle,
            purchaseDate: DateService().formatDateFromString(
              voucher.purchaseDate,
              "DD/MM/YYYY"
            ),
          };
        }),
      };
    case ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_SUCCESS:
    case ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_CERTIFICATE_FAILURE:
    case ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_TPF_SUCCESS:
    case ActionTypes.CORPORATE_VOUCHERS_DOWNLOAD_TPF_FAILURE:
    case ActionTypes.CORPORATE_VOUCHERS_ON_LOAD_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    default:
      return state;
  }
}

export default CorporateVouchersReducer;
