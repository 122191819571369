import React, { useEffect } from "react";
import PageTitle from "../../components/Common/Page-Title/Page-Title";
import { Table, Input, Form, FormGroup, Label, Button } from "reactstrap";
import ContentSection from "components/Common/content-section";
import "./course-survey.scss";
import RadioButtonList from "./../../components/Common/Fields/Radio-Button-List";
import InputField from "./../../components/Common/Fields/Input-Field";
import { required } from "../../components/Common/Fields/Validators/Field-Validators";
import BlockUiFx from "./../../components/Common/Block-Ui-Fx/Block-Ui-Fx";
import ButtonFx from "./../../components/Common/Button-Fx/Button-Fx";
import formHandler from "./../../components/Common/Fields/form-handler";
import KeyboardEventHandler from "react-keyboard-event-handler";

const CourseSurvey = (props) => {
  const {
    aatItems,
    rplItems,
    surveyQuestions,
    isLoading,
    onSubmit,
    onFieldError,
    onInputChange,
    loadData,
    validate,
    fields,
    match,
  } = props;

  const form = formHandler();

  useEffect(() => {
    let userCourseToken = match.params.id;
    loadData(userCourseToken);
  }, []);

  const RenderQuestionnaires = (props) => {
    const isChecked = (id, compareTo) => {
      let result =
        props.fields[id] != null && props.fields[id] === compareTo
          ? true
          : false;
      return result;
    };

    const questions = props.src.map((o, i) => (
      <tr key={i}>
        <td>{o.question}</td>
        <td className="col-td">
          {/* Strongly Agree */}
          <Input
            name={`question-${o.questionId}`}
            id={`question-${o.questionId}-0`}
            type="radio"
            value={2}
            checked={isChecked(`question-${o.questionId}`, 2)}
            onChange={(e) =>
              props.onInputChange({
                name: e.target.name,
                value: parseInt(e.target.value),
                error: false,
              })
            }
          />
          <Label
            for={`question-${o.questionId}-0`}
            className="mobile-label ml-2"
          >
            Strongly Agree
          </Label>
        </td>
        <td>
          {/* Agree */}
          <Input
            name={`question-${o.questionId}`}
            id={`question-${o.questionId}-1`}
            type="radio"
            value={1}
            checked={isChecked(`question-${o.questionId}`, 1)}
            onChange={(e) =>
              props.onInputChange({
                name: e.target.name,
                value: parseInt(e.target.value),
                error: false,
              })
            }
          />
          <Label
            for={`question-${o.questionId}-1`}
            className="mobile-label ml-2"
          >
            Agree
          </Label>
        </td>
        <td>
          {/* Disagree */}
          <Input
            name={`question-${o.questionId}`}
            id={`question-${o.questionId}-2`}
            type="radio"
            value={0}
            checked={isChecked(`question-${o.questionId}`, 0)}
            onChange={(e) =>
              props.onInputChange({
                name: e.target.name,
                value: parseInt(e.target.value),
                error: false,
              })
            }
          />
          <Label
            for={`question-${o.questionId}-2`}
            className="mobile-label ml-2"
          >
            Disagree
          </Label>
        </td>
        <td>
          {/* Strongly Disagree */}
          <Input
            name={`question-${o.questionId}`}
            id={`question-${o.questionId}-3`}
            type="radio"
            value={-1}
            checked={isChecked(`question-${o.questionId}`, -1)}
            onChange={(e) =>
              props.onInputChange({
                name: e.target.name,
                value: parseInt(e.target.value),
                error: false,
              })
            }
          />
          <Label
            for={`question-${o.questionId}-3`}
            className="mobile-label ml-2"
          >
            Strongly Disagree
          </Label>
        </td>
      </tr>
    ));
    return questions;
  };

  return (
    <BlockUiFx blocking={isLoading} lowerZIndex={true}>
      <PageTitle title="Survey" />
      {!isLoading ? (
        <div id="courseSurvey">
          <ContentSection bgClass="veggies-bg off-white">
            <div className="ttg-container">
              <h1>Survey</h1>
              <p className="mt-3">
                Thanks! We really appreciate you filling in this form as much as
                you can, it helps us improve our service to you.
              </p>
              <Form className="ttg-form mt-3">
                <Table bordered hover striped>
                  <thead>
                    <tr>
                      <th></th>
                      <th width="100">
                        <strong>Strongly Agree</strong>
                      </th>
                      <th>
                        <strong>Agree</strong>
                      </th>
                      <th width="100">
                        <strong>Disagree</strong>
                      </th>
                      <th width="100">
                        <strong>Strongly Disagree</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <RenderQuestionnaires
                        src={surveyQuestions}
                        onInputChange={onInputChange}
                        fields={fields}
                      />
                    </>
                  </tbody>
                </Table>
                <FormGroup>
                  <Label className="field-label" for="bestAspect">
                    What were the BEST ASPECTS of the training? *
                  </Label>
                  <InputField
                    id="bestAspect"
                    name="bestAspects"
                    type="textarea"
                    value={fields.bestAspects}
                    validate={(val) => required("Best aspects", val)}
                    onFieldError={onFieldError}
                    onChange={onInputChange}
                    ref={form.add}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="field-label" for="needImprovement">
                    What aspects of the training were MOST IN NEED OF
                    IMPROVEMENT? *
                  </Label>
                  <InputField
                    id="needImprovement"
                    name="needImprovement"
                    type="textarea"
                    value={fields.needImprovement}
                    validate={(val) => required("Need of Improvement", val)}
                    onFieldError={onFieldError}
                    onChange={onInputChange}
                    ref={form.add}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="field-label" for="appreticeshipTraining">
                    Are you undertaking an APPRENTICESHIP OR TRAINEESHIP? *
                  </Label>
                  <div>
                    <RadioButtonList
                      id="appreticeshipTraining"
                      name="uat"
                      value={fields.uat}
                      items={aatItems}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) =>
                        val === "-1"
                          ? "Apprenticeship and Traineeship are required."
                          : false
                      }
                      ref={form.add}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label className="field-label" for="rpl">
                    Did you get any RECOGNITION OF PRIOR LEARNING towards your
                    training such as subject exemptions, course credits or
                    advanced standing? *
                  </Label>
                  <div>
                    <RadioButtonList
                      id="rpl"
                      name="rpl"
                      value={fields.rpl}
                      items={rplItems}
                      onChange={onInputChange}
                      onFieldError={onFieldError}
                      validate={(val) =>
                        val === "-1" ? "RPL is required." : false
                      }
                      ref={form.add}
                    />
                  </div>
                </FormGroup>
                <section className="mt-3">
                  <ButtonFx
                    type="submit"
                    className="ttg-btn"
                    onClick={() =>
                      form.validate({
                        validFn: () => onSubmit(),
                      })
                    }
                  >
                    Submit
                  </ButtonFx>
                </section>
                <KeyboardEventHandler
                  handleKeys={["enter"]}
                  onKeyEvent={(key, e) =>
                    form.validate({ validFn: () => onSubmit() })
                  }
                />
              </Form>
            </div>
          </ContentSection>
        </div>
      ) : null}
    </BlockUiFx>
  );
};

export default CourseSurvey;
