import LinkButton from "components/link-button/link-button";
import React from "react";
import { Card, CardBody, CardFooter, CardText, CardTitle } from "reactstrap";

const CourseInfoCard = ({
  courseId,
  courseTitle,
  courseName,
  courseTags,
  courseCost,
  courseImage,
  courseReadMorePage,
  hideReadMore = false,
  className,
}) => {
  return (
    <Card className={`course-card${className ? ` ${className}` : ""}`}>
      <img src={courseImage} alt={courseTitle} />

      <CardBody>
        <CardTitle>{courseName}</CardTitle>
        <CardText>{courseTags}</CardText>
      </CardBody>
      <CardFooter>
        <div>
          <LinkButton
            to={`/Certificates/Enrol/${courseId}/`}
            className="ttg-btn ttg-orange-outline-btn mr-2"
            btnText="Enrol Now"
          />
          {!hideReadMore && (
            <LinkButton
              to={courseReadMorePage}
              className="ttg-btn ttg-green-outline-btn"
              btnText="Read More"
            />
          )}
        </div>
        <div className="course-cost">${courseCost}</div>
      </CardFooter>
    </Card>
  );
};

export default CourseInfoCard;
