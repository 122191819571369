import React, { Component } from "react";
import { Button } from "reactstrap";
import PageTitle from "components/Common/Page-Title/Page-Title";
import ContentSection from "components/Common/content-section";
import CourseContentSections from "components/Course-Content-Sections/Course-Content-Sections";
import CourseQuizQuestion from "components/Course-Quiz-Question/Course-Quiz-Question";
import BlockUiFx from "components/Common/Block-Ui-Fx/Block-Ui-Fx";
import "./course-quiz.scss";

const OtherButton = (props) => {
  const {
    courseGuideUrl,
    onRedirectToContent,
    isFinalQuiz,
    userCourseId,
  } = props;

  return isFinalQuiz ? (
    <a
      href={courseGuideUrl}
      target="_blank"
      className="btn ttg-btn ttg-blue-btn mb-2 mr-2"
      rel="noopener noreferrer"
    >
      Course Guide
    </a>
  ) : (
    <Button
      className="ttg-btn ttg-blue-btn mb-2 mr-2"
      onClick={() => onRedirectToContent(userCourseId)}
    >
      Go Back to the Course Content
    </Button>
  );
};

class CourseQuiz extends Component {
  componentDidMount() {
    const { match, onLoadSection } = this.props;
    onLoadSection(match.params.userCourseId);
  }

  render() {
    const {
      courseName,
      totalSections,
      currentSection,
      currentQuestion,
      totalQuestions,
      currentProgress,
      currentQuestionIndex,
      showIncorrectAnswer,
      selectedIndex,
      isLoading,
      courseGuideUrl,
      isFinalQuiz,
    } = this.props.courseQuiz;

    const {
      onQuizInputChange,
      onSubmitAnswer,
      onRedirectToContent,
      match,
    } = this.props;

    return (
      <>
        <BlockUiFx
          blocking={isLoading}
          lowerZIndex={true}
          className="grey flex-100"
        >
          {!isLoading ? (
            <>
              <PageTitle
                title={`${courseName} - ${
                  isFinalQuiz ? "Final Quiz" : `Section ${currentSection} Quiz`
                }`}
              />
              <ContentSection bgClass="off-white">
                <div className="ttg-container">
                  <h3>
                    {courseName} -{" "}
                    {isFinalQuiz
                      ? "Final Quiz"
                      : `Section ${currentSection} Quiz`}
                  </h3>
                  <CourseContentSections
                    currentSection={currentSection}
                    totalSections={totalSections}
                  />
                </div>
              </ContentSection>
              <ContentSection bgClass="grey">
                <div className="course-quiz-container">
                  <CourseQuizQuestion
                    question={currentQuestion}
                    onQuizInputChange={onQuizInputChange}
                    selectedIndex={selectedIndex}
                    isFinalQuiz={isFinalQuiz}
                  />

                  <div className="my-3">
                    <div className="mb-3">
                      <Button
                        className="ttg-btn mb-2"
                        onClick={() =>
                          onSubmitAnswer(match.params.userCourseId)
                        }
                      >
                        Continue
                      </Button>
                    </div>
                    <OtherButton
                      isFinalQuiz={isFinalQuiz}
                      courseGuideUrl={courseGuideUrl}
                      onRedirectToContent={onRedirectToContent}
                      userCourseId={match.params.userCourseId}
                    />
                  </div>

                  {showIncorrectAnswer && (
                    <div className="quiz-alert my-2">
                      Sorry, your answer is incorrect. Please try again.
                    </div>
                  )}

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: currentProgress + "%" }}
                      aria-valuenow={currentProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="ml-2" style={{ position: "absolute" }}>
                        {" "}
                        Question {currentQuestionIndex + 1} of {totalQuestions}
                      </span>
                    </div>
                  </div>
                </div>
              </ContentSection>
            </>
          ) : (
            <div className="quiz-loading-content"></div>
          )}
        </BlockUiFx>
      </>
    );
  }
}

export default CourseQuiz;
