import React from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import InputField from "../Common/Fields/Input-Field";
import ButtonFx from "../Common/Button-Fx/Button-Fx";
import * as Constants from "../../constants";
import {
  required,
  strongPassword
} from "../Common/Fields/Validators/Field-Validators";
import formHandler from "./../Common/Fields/form-handler";
import KeyboardEventHandler from "react-keyboard-event-handler";

// Custom validators
const validatePasswordField = val => {
  if (required("Password", val)) return required("Password", val);
  if (strongPassword(val)) return strongPassword(val);

  return false;
};

const validateConfirmPasswordField = (password, confirmPwd) => {
  if (required("Confirm Password", confirmPwd))
    return required("Confirm Password", confirmPwd);
  if (password !== confirmPwd) return "Your passwords do not match.";

  return false;
};

//
// Presentation Component
//
const ResetPassword = props => {
  const { password, confirmPassword } = props.fields;
  const { onInputChange, onFieldError, onResetPassword, isLoading } = props;

  const form = formHandler();

  return (
    <Form className="ttg-form mt-3">
      <FormGroup>
        <InputField
          name="password"
          placeholder="New Password"
          type="password"
          value={password}
          validate={val => validatePasswordField(val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <InputField
          name="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          validate={val => validateConfirmPasswordField(password, val)}
          onFieldError={onFieldError}
          onChange={onInputChange}
          ref={form.add}
        />
      </FormGroup>
      <FormGroup>
        <ButtonFx
          type="submit"
          className="ttg-btn"
          isLoading={isLoading}
          onClick={() => form.validate({ validFn: () => onResetPassword() })}
        >
          Submit
        </ButtonFx>
      </FormGroup>
      <KeyboardEventHandler
        handleKeys={["enter"]}
        onKeyEvent={(key, e) =>
          form.validate({ validFn: () => onResetPassword() })
        }
      />
    </Form>
  );
};

export default ResetPassword;
