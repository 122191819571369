import { NswFoodSafetySupervisorCertificateThirdPartyFormTasks } from "./nsw-food-safety-supervisor-certificate.js";
import { NswFoodSafetySupervisorCertificateRefresherThirdPartyFormTasks } from "./nsw-food-safety-supervisor-certificate-refresher.js";
import { FoodHandlingLevel1CertificateHealthAndCommunityThirdPartyFormTasks } from "./food-handling-level-1-certificate-health-and-community.js";
import { FoodHandlingLevel1CertificateHospitalityAndRetailThirdPartyFormTasks } from "./food-handling-level-1-certificate-hospitality-and-retail.js";
import { FoodHandlingLevel2CertificateHospitalityAndRetailThirdPartyFormTasks } from "./food-handling-level-2-certificate-hospitality-and-retail.js";
import { FoodSafetySupervisorCertificateHealthAndCommunityThirdPartyFormTasks } from "./food-safety-supervisor-certificate-health-and-community.js";
import { FoodSafetySupervisorCertificateHospitalityAndRetailThirdPartyFormTasks } from "./food-safety-supervisor-certificate-hospitality-and-retail.js";

export const thirdPartyFormTasks = [
  ...NswFoodSafetySupervisorCertificateThirdPartyFormTasks,
  ...NswFoodSafetySupervisorCertificateRefresherThirdPartyFormTasks,
  ...FoodHandlingLevel1CertificateHealthAndCommunityThirdPartyFormTasks,
  ...FoodHandlingLevel1CertificateHospitalityAndRetailThirdPartyFormTasks,
  ...FoodHandlingLevel2CertificateHospitalityAndRetailThirdPartyFormTasks,
  ...FoodSafetySupervisorCertificateHealthAndCommunityThirdPartyFormTasks,
  ...FoodSafetySupervisorCertificateHospitalityAndRetailThirdPartyFormTasks,
];
