import { publicPages } from "constants/public-content/index";

const ContentService = () => {
  const getContentPage = (pageName, returnUndefinedIfNotFound = false) => {
    let page = publicPages.filter((page) => page.pageName === pageName);

    if (returnUndefinedIfNotFound && page.length === 0) return undefined;

    if (page.length === 0)
      page = publicPages.filter((page) => page.pageName === "not-found");

    return page[0];
  };

  return { getContentPage };
};

export default ContentService;
